import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_ctx.errorMessage)
    ? (_openBlock(), _createBlock(_component_ion_item, {
        key: 0,
        color: "danger"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, {
            slot: "start",
            icon: _ctx.warning
          }, null, 8, ["icon"]),
          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
            ]),
            _: 1
          }),
          (_ctx.noRetry === undefined || _ctx.noRetry === false)
            ? (_openBlock(), _createBlock(_component_ion_button, {
                key: 0,
                slot: "end",
                color: "light",
                fill: "clear",
                shape: "round",
                onClick: _ctx.onRetryButtonClicked
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "icon-only",
                    icon: _ctx.reloadSharp
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}