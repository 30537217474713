import {ComponentPublicInstance} from 'vue'

// noinspection JSMethodCanBeStatic
export class ComponentStackTraceFormatter {
  
  format(
    msg: string,
    instance: ComponentPublicInstance | null
  ): string {
    if(instance) {
      const lines = this.generateStacktraceLines(instance)
      return this.formatStacktraceLines(msg, lines)
    } else {
      return msg
    }
  }
  
  private formatStacktraceLines(
    msg: string,
    lines: Array<string>
  ): string {
    let format = msg
    for(const line of lines) {
      format += `\n  at ${line}`
    }
    return format
  }
  
  private generateStacktraceLines(
    instance: ComponentPublicInstance
  ): Array<string> {
    const lines: Array<string> = []
    let currentInstance: ComponentPublicInstance | null = instance
    while(currentInstance) {
      lines.push(this.formatLineForComponent(currentInstance))
      currentInstance = currentInstance.$parent
    }
    return lines
  }
  
  private formatLineForComponent(
    instance: ComponentPublicInstance
  ): string {
    const instanceName = this.getInstanceName(instance)
    return `<${instanceName}>`
  }
  
  private  getInstanceName(
    instance: ComponentPublicInstance
  ): string {
    const isRoot = instance.$parent == null
    const instanceName = instance.$.type.name
    if(instanceName) {
      return instanceName
    } else if(isRoot) {
      return 'App'
    } else {
      return 'Anonymous'
    }
  }
}
