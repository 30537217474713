import {ActionContext, Module} from 'vuex'
import {AppState} from '@/stores'
import {SynchronizationService, SynchronizeAllResult} from '@/service/SynchronizationService'
import {I18n} from 'vue-i18n'
import _ from 'lodash'
import {translateMessage} from '@/i18n'
import {WARNING_MESSAGE} from '@/stores/modules/MessageModule'
import {BroadcastPromise} from '@/utils/BroadcastPromise'
import {SynchronizePlugin} from '@/plugin/SynchronizationPlugin'
import {Capacitor} from '@capacitor/core'

export interface SyncModuleState {
  synchronizeAllPromise?: BroadcastPromise<SynchronizeAllResult>
}

export class SyncModule implements Module<SyncModuleState, AppState> {
  namespaced = true
  actions = {
    displayLastResult: this.displayLastResultAction.bind(this),
    synchronizeAll: this.synchronizeAllAction.bind(this),
    clearSyncModule: this.clearSyncModuleAction.bind(this)
  }
  mutations = {
    setSynchronizing: (state, synchronizing) => state.synchronizing = synchronizing,
    setSynchronizeAllPromise: (state, promise) => state.synchronizeAllPromise = promise
  }
  getters = {
    synchronizing: (state) => state.synchronizeAllPromise !== undefined
  }
  
  constructor(
    private readonly i18n: I18n,
    private readonly synchronizationService: SynchronizationService
  ) {
  }
  
  private async displayLastResultAction(
    context: ActionContext<SyncModuleState, AppState>
  ) {
    if (!Capacitor.isNativePlatform()) {
      return
    }
    const result = await SynchronizePlugin.getLastResult()
    await this.notifyIdsNotSynchronized(context, result.failed_ids)
  }
  
  private async synchronizeAllAction(
    context: ActionContext<SyncModuleState, AppState>,
  ): Promise<SynchronizeAllResult> {
    if (!Capacitor.isNativePlatform()) {
      return {
        pending: false,
        failedIds: []
      }
    }
    if (context.state.synchronizeAllPromise !== undefined) {
      return context.state.synchronizeAllPromise.wait()
    }
    
    const promise = new BroadcastPromise(
      this.doSynchronizeAll(context)
    )
    context.commit('setSynchronizeAllPromise', promise)
    return promise.wait()
  }
  
  private async doSynchronizeAll(
    context: ActionContext<SyncModuleState, AppState>
  ): Promise<SynchronizeAllResult> {
    const result = await this.synchronizationService.synchronizeAll()
    await this.notifyIdsNotSynchronized(context, result.failedIds)
    
    context.commit('setSynchronizeAllPromise', undefined)
    
    return result
  }
  
  private async notifyIdsNotSynchronized(
    context: ActionContext<SyncModuleState, AppState>,
    ids: Array<string>
  ) {
    if (ids.length > 0) {
      const message = translateMessage(this.i18n,'sync.failedIds', {
        count: ids.length,
        id: _.join(ids, ', ')
      })
      await context.dispatch(
        'message/setMessage',
        {
          message: message,
          ...WARNING_MESSAGE
        },
        {root: true}
      )
    }
  }
  
  private async clearSyncModuleAction() {
    await this.synchronizationService.clear()
  }
}
