import {createStore, Store} from 'vuex'
import {AuthenticationModule} from '@/stores/modules/AuthenticationModule'
import {I18n} from 'vue-i18n'
import {CurrentUserModule} from '@/stores/modules/CurrentUserModule'
import {DeliveryNoteModule} from '@/stores/modules/DeliveryNoteModule'
import {CurrentUserApi} from '@/service/api/CurrentUserApi'
import {DeliveryNoteConfirmModule} from '@/stores/modules/DeliveryNoteConfirmModule'
import {LocalStore} from '@/service/store/LocalStore'
import {DeliveryNoteBean} from '@/service/model/DeliveryNoteBean'
import {TruckRegistrationModule} from '@/stores/modules/TruckRegistrationModule'
import {DeliveryNotePictureApi} from '@/service/api/driver/DeliveryNotePictureApi'
import {LocationModule} from '@/stores/modules/LocationModule'
import {BackModule} from '@/stores/modules/BackModule'
import {DriverDeliveryNoteApi} from '@/service/api/driver/DriverDeliveryNoteApi'
import {SiteManagerDeliveryNoteApi} from '@/service/api/sitemanager/SiteManagerDeliveryNoteApi'
import {SiteManagerDeliveryNoteConfirmModule} from '@/stores/modules/SiteManagerDeliveryNoteConfirmModule'
import {AuthenticationService} from '@/service/authentication/AuthenticationService'
import {SiteManagerDeliveryNotePictureApi} from '@/service/api/sitemanager/SiteManagerDeliveryNotePictureApi'
import {AppVersionModule} from '@/stores/modules/AppVersionModule'
import {AppVersionApi} from '@/service/api/AppVersionApi'
import {PermissionModule} from '@/stores/modules/PermissionModule'
import {SiteManagerOrderApi} from '@/service/api/sitemanager/SiteManagerOrderApi'
import {OrderModule} from '@/stores/modules/OrderModule'
import {PushNotificationService} from '@/service/PushNotificationService'
import {IonicProxyService} from '@/service/IonicProxyService'
import {SiteManagerDeliveryNoteModule} from '@/stores/modules/SiteManagerDeliveryNoteModule'
import {makeSiteManagerModule} from '@/stores/modules/sitemanager'
import {ErrorReportingService} from '@/service/ErrorReportingService'
import {makeSuperCustomerModule} from '@/stores/modules/supercustomer'
import {AppConfigApi} from '@/service/api/AppConfigApi'
import {AppConfigModule} from '@/stores/modules/AppConfigModule'
import {PdfModule} from '@/stores/modules/PdfModule'
import {FileModule} from '@/stores/modules/FileModule'
import {SynchronizationService} from '@/service/SynchronizationService'
import {DeliveryNoteSyncService} from '@/service/DeliveryNoteSyncService'
import {SyncModule} from '@/stores/modules/SyncModule'
import {MessageModule} from '@/stores/modules/MessageModule'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AppState {
}

export function makeStore(
  i18n: I18n,
  ionicProxyService: IonicProxyService,
  authenticationService: AuthenticationService,
  errorReportingService: ErrorReportingService,
  pushNotificationService: PushNotificationService,
  synchronizationService: SynchronizationService
): Store<AppState> {
  const listStore = new LocalStore<Array<string>, string>({
    localStorageKey: 'lists'
  })
  const deliveryNoteStore = new LocalStore<DeliveryNoteBean, string>({
    localStorageKey: 'delivery-notes'
  })
  
  const appVersionApi = new AppVersionApi(i18n, authenticationService)
  const appConfigApi = new AppConfigApi(i18n, authenticationService)
  
  const currentUserApi = new CurrentUserApi(i18n, authenticationService)
  const driverDeliveryNoteApi = new DriverDeliveryNoteApi(i18n, authenticationService, synchronizationService)
  const siteManagerDeliveryNoteApi = new SiteManagerDeliveryNoteApi(i18n, authenticationService, synchronizationService)
  const deliveryNotePictureApi = new DeliveryNotePictureApi(i18n, authenticationService, synchronizationService)
  const siteManagerDeliveryNotePictureApi = new SiteManagerDeliveryNotePictureApi(i18n, authenticationService, synchronizationService)
  const orderApi = new SiteManagerOrderApi(i18n, authenticationService, synchronizationService)
  
  const deliveryNoteSyncService = new DeliveryNoteSyncService(synchronizationService)
  
  const appVersionModule = new AppVersionModule(appVersionApi)
  const appConfigModule = new AppConfigModule(appConfigApi)
  const fileModule = new FileModule(i18n)
  
  const backModule = new BackModule()
  const authenticationModule = new AuthenticationModule(authenticationService)
  const currentUserModule = new CurrentUserModule(i18n, currentUserApi, pushNotificationService)
  const truckRegistrationModule = new TruckRegistrationModule()
  const locationModule = new LocationModule(i18n)
  const permissionModule = new PermissionModule(i18n, pushNotificationService)
  const messageModule = new MessageModule()
  const syncModule = new SyncModule(i18n, synchronizationService)
  const pdfModule = new PdfModule(i18n)
  
  const siteManagerModule = makeSiteManagerModule(i18n, authenticationService, synchronizationService, deliveryNoteStore, listStore)
  const superCustomerModule = makeSuperCustomerModule(i18n, authenticationService, synchronizationService)
  
  const deliveryNoteModule = new DeliveryNoteModule(i18n, deliveryNoteStore, driverDeliveryNoteApi, siteManagerDeliveryNoteApi, deliveryNoteSyncService, pushNotificationService)
  const siteManagerDeliveryNoteModule = new SiteManagerDeliveryNoteModule(siteManagerDeliveryNoteApi, siteManagerDeliveryNotePictureApi, deliveryNoteSyncService)
  
  const deliveryNoteLoadModule = new DeliveryNoteConfirmModule(i18n, ionicProxyService, pushNotificationService, deliveryNoteSyncService, deliveryNoteStore, driverDeliveryNoteApi, deliveryNotePictureApi)
  
  const orderModule = new OrderModule(orderApi, pushNotificationService)
  const siteManagerDeliveryNoteConfirmModule = new SiteManagerDeliveryNoteConfirmModule(i18n, ionicProxyService, deliveryNoteSyncService, pushNotificationService, deliveryNoteStore, siteManagerDeliveryNoteApi, siteManagerDeliveryNotePictureApi)
  
  return createStore<AppState>({
    state: {},
    modules: {
      authenticationModule,
      currentUserModule,
      deliveryNoteModule,
      back: backModule,
      config: appConfigModule,
      message: messageModule,
      sync: syncModule,
      superCustomer: superCustomerModule,
      siteManager: siteManagerModule,
      load: deliveryNoteLoadModule,
      unload: siteManagerDeliveryNoteConfirmModule,
      truckRegistration: truckRegistrationModule,
      location: locationModule,
      appVersion: appVersionModule,
      permission: permissionModule,
      order: orderModule,
      siteManagerDeliveryNote: siteManagerDeliveryNoteModule,
      pdf: pdfModule,
      file: fileModule
    }
  })
}
