import {JSONSchemaType} from 'ajv'

export interface QuotationRequestBean {
  id: string
  material_id: string
  material_type: string
  material_quantity: number
  
  backload_material_id?: string | null
  backload_material_quantity?: number | null
}

export const quotationRequestSchema: JSONSchemaType<QuotationRequestBean> = {
  type: 'object',
  properties: {
    id: {
      type: 'string'
    },
    material_id: {
      type: ['string']
    },
    material_type: {
      type: ['string']
    },
    material_quantity: {
      type: ['number']
    },
    backload_material_id: {
      type: ['string'],
      nullable: true
    },
    backload_material_quantity: {
      type: ['number'],
      nullable: true
    }
  },
  required: [
    'id', 'material_type', 'material_quantity'
  ],
  additionalProperties: true
}
