import {JSONSchemaType} from 'ajv'
import {PersonBean, personSchema} from '@/service/model/PersonBean'

export interface SiteManagerBean extends PersonBean {
  phone_number?: string
}

export const siteManagerSchema: JSONSchemaType<SiteManagerBean> = {
  type: 'object',
  properties: {
    ...personSchema.properties,
    phone_number: {
      type: ['string'],
      nullable: true
    }
  },
  additionalProperties: true
}

export const siteManagerArraySchema: JSONSchemaType<Array<SiteManagerBean>> = {
  type: 'array',
  items: siteManagerSchema
}
