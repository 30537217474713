<template>
  <ion-app>
    <main-menu v-bind:disabled="mainMenuConfig.disabled" v-bind:items="mainMenuConfig.items"/>
    <ion-router-outlet id="main-content" ref="routerOutlet"/>
  </ion-app>
</template>

<script lang="ts">
import {IonApp, IonRouterOutlet, useBackButton} from '@ionic/vue'
import {defineComponent, inject, reactive} from 'vue'
import {mapActions, mapGetters} from 'vuex'
import {App, URLOpenListenerEvent} from '@capacitor/app'
import {pushNotificationServiceKey} from '@/service/PushNotificationService'
import {ROLE_DRIVER, ROLE_SITE_MANAGER} from '@/service/api/CurrentUserApi'
import {DeliveryNoteDetails} from '@/router/driver'
import {SiteManagerDeliveryNoteDetailsRoute} from '@/router/sitemanager'
import {Subscription} from '@/service/subscription/Subscription'
import {DeliveryNoteStatusChangedBean} from '@/service/model/DeliveryNoteStatusChangedBean'
import {PushNotificationEvent} from '@/service/model/PushNotificationEvent'
import MainMenu, {MainMenuItem} from '@/components/common/menu/MainMenu.vue'
import {mainMenuConfigKey} from '@/keys'
import {ComposableSubscription} from '@/service/subscription/ComposableSubscription'
import {registerToAppOpen} from '@/utils/AppOpenUtils'
import {getWebAppUrl} from '@/utils/OSUtils'
import {OrderEventReceivedBean} from '@/service/model/OrderEventReceivedBean'

export interface MainMenuConfig {
  disabled: boolean
  items: Array<MainMenuItem>
}

export default defineComponent({
  name: 'App',
  data() {
    return {
      subscription: undefined as Subscription | undefined
    }
  },
  setup() {
    const mainMenuConfig = reactive<MainMenuConfig>({
      disabled: false,
      items: []
    })
    return {
      mainMenuConfig,
      pushNotificationService: inject(pushNotificationServiceKey)
    }
  },
  provide() {
    return {
      [mainMenuConfigKey]: this.mainMenuConfig
    }
  },
  mounted() {
    this.registerToPushNotifications()

    // Register modules to store changes.
    // Required because modules cannot access context while reloading from store.
    this.loadRegisterForStoreChanges()
    this.unloadRegisterForStoreChanges()

    // Exit app if user cannot go back
    useBackButton(-1, this.onBackButton)

    // Check app version is deprecated.
    this.checkAppVersion()

    // Refresh application configuration
    this.refreshConfig()

    // Display result from last sync
    this.displayLastResult()

    // Remove loading style since we will load the app.
    document.body.classList.remove('app-loading')
  },
  unmounted() {
    this.subscription?.unregister()
  },
  methods: {
    ...mapActions(['isLoggedIn']),
    ...mapActions('appVersion', ['checkAppVersion']),
    ...mapActions('config', ['refreshConfig']),
    ...mapActions('sync', ['displayLastResult']),
    ...mapActions('load', {loadRegisterForStoreChanges: 'registerForStoreChanges'}),
    ...mapActions('unload', {unloadRegisterForStoreChanges: 'registerForStoreChanges'}),
    registerToPushNotifications() {
      const subscriptions = new ComposableSubscription()
      subscriptions.add(this.pushNotificationService.registerForEventAction({
        event: PushNotificationEvent.DELIVERY_NOTE_STATUS_CHANGED,
        callback: this.onDeliveryNoteStatusChangedAction.bind(this)
      }))
      subscriptions.add(this.pushNotificationService.registerForEventAction({
        event: PushNotificationEvent.NEW_ORDER_RECEIVED,
        callback: this.onOrderReceivedNotificationAction.bind(this)
      }))
      subscriptions.add(registerToAppOpen(this.onAppOpen.bind(this)))
      this.subscription = subscriptions
      this.pushNotificationService.register()
    },
    onDeliveryNoteStatusChangedAction(event: string, data: DeliveryNoteStatusChangedBean) {
      const deliveryNoteId = data.delivery_note_id
      if (!deliveryNoteId) {
        return
      }

      let route: string | undefined = undefined
      switch (this.currentUser?.role) {
      case ROLE_DRIVER:
        route = DeliveryNoteDetails
        break
      case ROLE_SITE_MANAGER:
        route = SiteManagerDeliveryNoteDetailsRoute
        break
      default:
      }
      if (route) {
        this.$router.replace({
          name: route,
          params: {id: deliveryNoteId}
        })
      }
    },
    onOrderReceivedNotificationAction(event: string, data: OrderEventReceivedBean) {
      const orderId = data.order_id
      if (!orderId) {
        return
      }

      let route: string | undefined = undefined
      if (this.currentUser?.role == ROLE_SITE_MANAGER) {
        route = 'SiteManagerOrder'
      }
      if (route) {
        this.$router.replace({
          name: route,
          params: {id: orderId}
        })
      }
    },
    onAppOpen(event: URLOpenListenerEvent) {
      const webAppUrl = getWebAppUrl()
      if (!webAppUrl) {
        return
      }
      if (!event.url.startsWith(webAppUrl)) {
        return
      }
      const path = event.url.substring(webAppUrl.length)
      const route = this.$router.resolve({path})
      if (route) {
        this.$router.replace(route)
      }
    },
    onBackButton(): void {
      const previousRoute = this.previousRoute
      if (previousRoute) {
        this.$router.replace(previousRoute)
      } else {
        App.exitApp()
      }
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    ...mapGetters('back', ['previousRoute'])
  },
  components: {
    MainMenu,
    IonApp, IonRouterOutlet
  }
})
</script>
