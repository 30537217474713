import {JSONSchemaType} from 'ajv'

export interface LocationBean {
  latitude: number
  longitude: number
}

export const locationSchema: JSONSchemaType<LocationBean> = {
  type: 'object',
  properties: {
    latitude: {
      type: ['number'],
      minimum: -180,
      maximum: 180
    },
    longitude: {
      type: ['number'],
      minimum: -180,
      maximum: 180
    }
  },
  required: ['latitude', 'longitude'],
  additionalProperties: true
}
