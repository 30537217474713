import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_ripple_effect = _resolveComponent("ion-ripple-effect")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.to)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        custom: "",
        replace: "",
        to: _ctx.to
      }, {
        default: _withCtx(({ navigate, isExactActive }) => [
          _createVNode(_component_ion_item, {
            class: _normalizeClass(["ion-activatable ripple-parent", {'active': isExactActive, 'inactive': !isExactActive}]),
            lines: "full",
            onClick: ($event: any) => (_ctx.onItemClicked(navigate))
          }, {
            default: _withCtx(() => [
              (_ctx.icon)
                ? (_openBlock(), _createBlock(_component_ion_icon, {
                    key: 0,
                    size: "small",
                    slot: "start",
                    icon: _ctx.icon
                  }, null, 8, ["icon"]))
                : isExactActive
                  ? (_openBlock(), _createBlock(_component_ion_icon, {
                      key: 1,
                      size: "small",
                      slot: "start",
                      icon: _ctx.checkmark
                    }, null, 8, ["icon"]))
                  : _createCommentVNode("", true),
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.label), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_ripple_effect)
            ]),
            _: 2
          }, 1032, ["class", "onClick"])
        ]),
        _: 1
      }, 8, ["to"]))
    : (_openBlock(), _createBlock(_component_ion_item, {
        key: 1,
        class: "ion-activatable ripple-parent",
        lines: "full",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onItemClicked(_ctx.emitsItemClick)))
      }, {
        default: _withCtx(() => [
          (_ctx.icon)
            ? (_openBlock(), _createBlock(_component_ion_icon, {
                key: 0,
                size: "small",
                slot: "start",
                icon: _ctx.icon
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.label), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_ripple_effect)
        ]),
        _: 1
      }))
}