import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f88b486"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = {
  key: 0,
  class: "section"
}
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "text" }
const _hoisted_5 = {
  key: 1,
  class: "section"
}
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { class: "text" }
const _hoisted_8 = {
  key: 2,
  class: "section"
}
const _hoisted_9 = { class: "title" }
const _hoisted_10 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_header_content_footer_layout = _resolveComponent("header-content-footer-layout")!
  const _component_page = _resolveComponent("page")!

  return (_openBlock(), _createBlock(_component_page, {
    container: "scrollable",
    modal: "",
    title: _ctx.t('title')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_header_content_footer_layout, { "footer-class": "padding-md" }, {
        default: _withCtx(() => [
          _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.t('header')), 1),
          (_ctx.showLocation)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.t('location.header')), 1),
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t('location.description')), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.showCamera)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.t('camera.header')), 1),
                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.t('camera.description')), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.showNotification)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("h2", _hoisted_9, _toDisplayString(_ctx.t('notification.header')), 1),
                _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.t('notification.description')), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_ion_button, {
            color: "rockease",
            expand: "block",
            onClick: _ctx.onOkButton
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('button.ok')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title"]))
}