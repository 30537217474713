import {ActionContext, Module} from 'vuex'
import {AppState} from '@/stores'
import {SiteModule} from '@/stores/modules/sitemanager/SiteModule'
import {MaterialModule} from '@/stores/modules/sitemanager/MaterialModule'
import {ExpressOrderModule} from '@/stores/modules/sitemanager/ExpressOrderModule'
import {SiteManagerCustomerModule} from '@/stores/modules/sitemanager/SiteManagerCustomerModule'
import {SiteManagerDeliveryRequestModule} from '@/stores/modules/sitemanager/SiteManagerDeliveryRequestModule'
import {SiteManagerDriverModule} from '@/stores/modules/sitemanager/SiteManagerDriverModule'
import {SiteManagerTruckModule} from '@/stores/modules/sitemanager/SiteManagerTruckModule'
import {SiteManagerQuotationRequestModule} from '@/stores/modules/sitemanager/SiteManagerQuotationRequestModule'
import {SiteManagerOrderModule} from '@/stores/modules/sitemanager/SiteManagerOrderModule'
import {SiteManagerCreatePickupModule} from '@/stores/modules/sitemanager/SiteManagerCreatePickupModule'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SiteManagerState {
}

export class SiteManagerModule implements Module<SiteManagerState, AppState> {
  namespaced = true
  modules = {}
  actions = {
    clearSiteManagerModule: this.clearSiteManagerModuleAction.bind(this)
  }
  
  constructor(
    customerModule: SiteManagerCustomerModule,
    siteModule: SiteModule,
    materialModule: MaterialModule,
    expressOrderModule: ExpressOrderModule,
    deliveryRequestModule: SiteManagerDeliveryRequestModule,
    driverModule: SiteManagerDriverModule,
    truckModule: SiteManagerTruckModule,
    quotationRequestModule: SiteManagerQuotationRequestModule,
    orderModule: SiteManagerOrderModule,
    pickupModule: SiteManagerCreatePickupModule
  ) {
    this.modules = {
      customer: customerModule,
      deliveryRequest: deliveryRequestModule,
      driver: driverModule,
      expressOrder: expressOrderModule,
      material: materialModule,
      site: siteModule,
      truck: truckModule,
      quotationRequest: quotationRequestModule,
      order: orderModule,
      pickup: pickupModule
    }
  }
  
  private async clearSiteManagerModuleAction(
    context: ActionContext<SiteManagerState, AppState>
  ) {
    await context.dispatch('siteManager/customer/clearCustomerModule', undefined, {root: true})
    await context.dispatch('siteManager/driver/clearDriverModule', undefined, {root: true})
    await context.dispatch('siteManager/material/clearMaterialModule', undefined, {root: true})
    await context.dispatch('siteManager/site/clearSiteModule', undefined, {root: true})
    await context.dispatch('siteManager/truck/clearTruckModule', undefined, {root: true})
    await context.dispatch('siteManager/quotationRequest/clearQuotationRequestModule', undefined, {root: true})
    await context.dispatch('siteManager/order/clearOrderModule', undefined, {root: true})
  }
}
