export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "fr",
    "resource": {
      "message": {
        "android": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettez à jour l'application Rockease depuis le Google Play store."])},
        "ios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettez à jour l'application Rockease depuis l'App Store."])}
      },
      "button": {
        "install": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installer"])}
      }
    }
  })
}
