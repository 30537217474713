import {JSONSchemaType} from 'ajv'

export interface SiteManagerTruckBean {
  truck_registration: string
}

export const siteManagerTruckSchema: JSONSchemaType<SiteManagerTruckBean> = {
  type: 'object',
  properties: {
    truck_registration: {
      type: ['string']
    }
  },
  required: ['truck_registration'],
  additionalProperties: true
}

export const siteManagerTruckArraySchema: JSONSchemaType<Array<SiteManagerTruckBean>> = {
  type: 'array',
  items: siteManagerTruckSchema
}
