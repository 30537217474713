import {ActionContext, Module} from 'vuex'
import {AppState} from '@/stores'
import {LocalStore} from '@/service/store/LocalStore'
import {getItemOperation} from '@/service/operation/GetOperation'
import {DeliveryNoteBean, DeliveryStatus, SynchronizedDeliveryNoteBean} from '@/service/model/DeliveryNoteBean'
import {DeliveryNoteConfirmLoadRoute, DeliveryNoteDetails} from '@/router/driver'
import {Picture, PictureBean, pictureSchema, PictureType} from '@/service/model/PictureBean'
import {JSONSchemaType} from 'ajv'
import {DeliveryNotePictureApi} from '@/service/api/driver/DeliveryNotePictureApi'
import {BroadcastPromise} from '@/utils/BroadcastPromise'
import moment from 'moment'
import {MaterialType} from '@/service/model/MaterialBean'
import {LocationBean, locationSchema} from '@/service/model/LocationBean'
import {DriverDeliveryNoteApi} from '@/service/api/driver/DriverDeliveryNoteApi'
import {PushNotificationService} from '@/service/PushNotificationService'
import {I18n} from 'vue-i18n'
import {translateMessage} from '@/i18n'
import {makeException} from '@/exception/Exception'
import _ from 'lodash'
import {PushNotificationEvent} from '@/service/model/PushNotificationEvent'
import {
  DeliveryNoteStatusChangedBean,
  deliveryNoteStatusChangedSchema
} from '@/service/model/DeliveryNoteStatusChangedBean'
import {IonicProxyService} from '@/service/IonicProxyService'
import {Subscription} from '@/service/subscription/Subscription'
import {SynchronizeResult} from '@/service/SynchronizationService'
import {DeliveryNoteSyncService} from '@/service/DeliveryNoteSyncService'
import {ROLE_DRIVER} from '@/service/api/CurrentUserApi'

export type ConfirmFlow = 'LOAD_MATERIAL' | 'UNLOAD_MATERIAL' | 'LOAD_WASTE' | 'UNLOAD_WASTE'
export type ConfirmArrivalType = 'BY_CODE' | 'BY_PICTURE' | 'BY_PUSH_NOTIFICATION'

const CONFIRM_STORAGE_KEY = 'confirm'
const CONFIRM_STORE_KEY = 'confirm'
const ARRIVAL_STORE_KEY = 'arrival'
const DELIVERY_NOTE_VALIDITY = 24 * 60 * 60 * 1000 // 24 h in ms

export interface ConfirmInfoBean {
  deliveryNoteId: string
  flow: ConfirmFlow
  arrivalType?: ConfirmArrivalType
  
  location?: LocationBean
  netWeight?: number
  truckRegistration?: string
  comment?: string
  
  weighingSlipPicture?: PictureBean
  loadPicture?: PictureBean
  unloadPicture?: PictureBean
}

export interface ExternalConfirmInfoBean {
  message: string
}

export interface SetPictureParams {
  picture: Picture
  type: PictureType
  location?: LocationBean
}

export interface SetPictureBeanParams {
  picture: PictureBean
  promise: BroadcastPromise<SynchronizeResult<void>>
}

export interface DeliveryNoteConfirmState {
  needReloading: boolean
  deliveryNote: SynchronizedDeliveryNoteBean | null
  info: ConfirmInfoBean | null
  externalInfo: ExternalConfirmInfoBean | null
  
  weighingSlipPictureUpload: BroadcastPromise<SynchronizeResult<void>> | null
  loadPictureUpload: BroadcastPromise<SynchronizeResult<void>> | null
  unloadPictureUpload: BroadcastPromise<SynchronizeResult<void>> | null
  
  storeSubscription?: Subscription
  pushNotificationSubscription?: Subscription
}

export const loadBeanSchema: JSONSchemaType<ConfirmInfoBean> = {
  type: 'object',
  properties: {
    deliveryNoteId: {
      type: 'string'
    },
    flow: {
      type: 'string'
    },
    arrivalType: {
      type: 'string',
      nullable: true
    },
    location: {
      ...locationSchema,
      nullable: true
    },
    netWeight: {
      type: 'number',
      nullable: true
    },
    truckRegistration: {
      type: 'string',
      nullable: true
    },
    comment: {
      type: 'string',
      nullable: true
    },
    weighingSlipPicture: {
      ...pictureSchema,
      nullable: true
    },
    loadPicture: {
      ...pictureSchema,
      nullable: true
    },
    unloadPicture: {
      ...pictureSchema,
      nullable: true
    }
  },
  required: ['deliveryNoteId', 'flow']
}

export function computeConfirmFlow(deliveryNote: DeliveryNoteBean): ConfirmFlow | undefined {
  switch (deliveryNote.status) {
  case DeliveryStatus.PENDING:
    if (deliveryNote.material.type === MaterialType.NORMAL) {
      return 'LOAD_MATERIAL'
    } else {
      return 'LOAD_WASTE'
    }
  case DeliveryStatus.LOADING:
  case DeliveryStatus.IN_DELIVERY:
    if (deliveryNote.material.type === MaterialType.NORMAL) {
      return 'UNLOAD_MATERIAL'
    } else {
      return 'UNLOAD_WASTE'
    }
  case DeliveryStatus.DELIVERED:
  default:
    return undefined
  }
}

// noinspection JSMethodCanBeStatic
export class DeliveryNoteConfirmModule implements Module<DeliveryNoteConfirmState, AppState> {
  namespaced = true
  state = {
    needReloading: true,
    deliveryNote: null,
    info: null,
    externalInfo: null,
    weighingSlipPictureUpload: null,
    loadPictureUpload: null,
    unloadPictureUpload: null
  }
  mutations = {
    reloadState: this.reloadState.bind(this),
    setDeliveryNote: this.setDeliveryNote.bind(this),
    resetOrUpdateDeliveryNote: this.resetOrUpdateDeliveryNote.bind(this),
    setExternalConfirmInfo: this.setExternalConfirmInfo.bind(this),
    setLocation: this.setLocation.bind(this),
    setNetWeight: this.setNetWeight.bind(this),
    setComment: this.setComment.bind(this),
    setTruckRegistration: this.setTruckRegistration.bind(this),
    setWeighingPictureBean: this.setWeighingPictureBean.bind(this),
    setLoadPictureBean: this.setLoadPictureBean.bind(this),
    setUnloadPictureBean: this.setUnloadPictureBean.bind(this),
    setArrivalType: this.setArrivalType.bind(this),
    setStoreSubscription: this.setStoreSubscription.bind(this),
    setPushNotificationSubscription: (state, subscription) => state.pushNotificationSubscription = subscription,
    resetState: this.resetState.bind(this)
  }
  getters = {
    deliveryNote: (state: DeliveryNoteConfirmState): DeliveryNoteBean | null => state.deliveryNote,
    confirmInfo: (state: DeliveryNoteConfirmState): ConfirmInfoBean | null => state.info,
    externalConfirmInfo: (state: DeliveryNoteConfirmState): ExternalConfirmInfoBean | null => state.externalInfo,
    load: (state: DeliveryNoteConfirmState): ConfirmInfoBean | null => state.info,
    getNextRoute: this.getNextRoute.bind(this),
    getPreviousRoute: this.getPreviousRoute.bind(this)
  }
  actions = {
    reloadState: this.reloadStateAction.bind(this),
    selectDeliveryNoteForConfirm: this.selectDeliveryNoteForConfirmAction.bind(this),
    registerForPushNotification: this.registerForPushNotificationAction.bind(this),
    registerForStoreChanges: this.registerForStoreChangesAction.bind(this),
    setLocation: this.setLocationAction.bind(this),
    setNetWeight: this.setNetWeightAction.bind(this),
    setTruckRegistration: this.setTruckRegistrationAction.bind(this),
    setComment: this.setCommentAction.bind(this),
    setPicture: this.setPictureAction.bind(this),
    setArrivalType: this.setArrivalTypeAction.bind(this),
    confirmDeparture: this.confirmDepartureAction.bind(this),
    notifyArrival: this.notifyArrivalAction.bind(this),
    confirmArrivalWithPicture: this.confirmArrivalWithPictureAction.bind(this),
    confirmArrivalWithSiteManagerCode: this.confirmArrivalWithSiteManagerCodeAction.bind(this),
    confirmArrivalWithPushNotification: this.confirmArrivalWithPushNotificationAction.bind(this),
    updateDeliveryNoteFromUpcoming: this.updateDeliveryNoteFromUpcomingAction.bind(this),
    clearConfirmModule: this.clearConfirmModuleAction.bind(this)
  }
  private readonly confirmInfoStore: LocalStore<ConfirmInfoBean, string>
  private readonly arrivalStore: LocalStore<boolean, string>
  
  constructor(
    private readonly i18n: I18n,
    private readonly ionicProxyService: IonicProxyService,
    private readonly pushNotificationService: PushNotificationService,
    private readonly deliveryNoteSyncService: DeliveryNoteSyncService,
    private readonly deliveryNoteStore: LocalStore<DeliveryNoteBean, string>,
    private readonly deliveryNoteApi: DriverDeliveryNoteApi,
    private readonly deliveryNotePictureApi: DeliveryNotePictureApi
  ) {
    this.confirmInfoStore = new LocalStore<ConfirmInfoBean, string>({
      localStorageKey: CONFIRM_STORAGE_KEY,
      schema: loadBeanSchema
    })
    this.arrivalStore = new LocalStore<boolean, string>({
      localStorageKey: ARRIVAL_STORE_KEY
    })
  }
  
  private async reloadStateAction(
    context: ActionContext<DeliveryNoteConfirmState, AppState>
  ) {
    const confirmInfo = this.confirmInfoStore.getItem(CONFIRM_STORE_KEY) || null
    
    let deliveryNote: SynchronizedDeliveryNoteBean | null = null
    if (confirmInfo) {
      const dn = this.deliveryNoteStore.getItem(confirmInfo.deliveryNoteId) || null
      if (dn) {
        deliveryNote = await this.deliveryNoteSyncService.syncWithPending('DRIVER', dn)
      }
    }
    
    // Since we lost the delivery note from the cache, we also remove the in progress load/unload.
    if (!confirmInfo || !deliveryNote) {
      if (confirmInfo) {
        await this.deliveryNoteSyncService.clearByIdAndType(confirmInfo.deliveryNoteId, confirmInfo.flow)
      }
      this.confirmInfoStore.deleteItem(CONFIRM_STORE_KEY)
      return
    }
    
    context.commit('reloadState', {
      info: confirmInfo,
      deliveryNote: deliveryNote
    })
  }
  
  private uploadPicture(
    deliveryNote: DeliveryNoteBean,
    pictureType: PictureType,
    picture?: PictureBean
  ): BroadcastPromise<SynchronizeResult<void>> | null {
    const confirmFlow = computeConfirmFlow(deliveryNote)
    if (!picture || !deliveryNote?.id || !confirmFlow) {
      return null
    }
    const promise = this.deliveryNotePictureApi.uploadPicture(confirmFlow, deliveryNote.id, pictureType, picture)
    const broadcastPromise = new BroadcastPromise(promise)
    broadcastPromise.wait().then(this.updatePictureBeanToUpdated.bind(this, picture))
    return broadcastPromise
  }
  
  private updatePictureBeanToUpdated(picture: PictureBean): void {
    picture.uploaded = true
    this.confirmInfoStore.saveItem(CONFIRM_STORE_KEY, this.state.info)
  }
  
  private updateAndSaveConfirmInfo(state: DeliveryNoteConfirmState, callback: (confirmInfo: ConfirmInfoBean) => void) {
    const confirmInfo = state.info
    if (confirmInfo) {
      callback(confirmInfo)
      this.saveConfirmInfo(confirmInfo)
    }
  }
  
  private saveConfirmInfo(info: ConfirmInfoBean) {
    const infoToSave = {...info}
    
    // Remove images that are blob since they cannot be serialized
    if (infoToSave.weighingSlipPicture?.picture instanceof Blob) {
      infoToSave.weighingSlipPicture = undefined
    }
    if (infoToSave.loadPicture?.picture instanceof Blob) {
      infoToSave.loadPicture = undefined
    }
    if (infoToSave.unloadPicture?.picture instanceof Blob) {
      infoToSave.unloadPicture = undefined
    }
    
    this.confirmInfoStore.saveItem(CONFIRM_STORE_KEY, infoToSave)
  }
  
  private async selectDeliveryNoteForConfirmAction(
    context: ActionContext<DeliveryNoteConfirmState, AppState>,
    deliveryNoteId: string
  ): Promise<void> {
    await context.dispatch('registerForPushNotification')
    
    if (context.state.needReloading) {
      await context.dispatch('reloadState')
    }
    
    const operation = getItemOperation(this.deliveryNoteStore, (id: string) => this.deliveryNoteApi.fetchDeliveryNote(id))
    const deliveryNote = await operation.get({
      params: deliveryNoteId,
      allowOnlyStore: true,
      expirationInMs: DELIVERY_NOTE_VALIDITY
    })
    if (deliveryNote === null) {
      throw makeException(this.i18n, 'confirm.deleted')
    }
    const syncDeliveryNote = await this.deliveryNoteSyncService.syncWithPending(ROLE_DRIVER, deliveryNote)
    context.commit('setDeliveryNote', syncDeliveryNote)
    
    await context.dispatch('registerForStoreChanges')
  }
  
  private async registerForStoreChangesAction(
    context: ActionContext<DeliveryNoteConfirmState, AppState>
  ) {
    context.commit('setStoreSubscription', undefined)
    
    const deliveryNoteId = context.state.info?.deliveryNoteId
    if (!deliveryNoteId) {
      return
    }
    
    const subscription = this.deliveryNoteStore.registerForChanges(deliveryNoteId, this.onDeliveryNoteChanges.bind(this, context))
    context.commit('setStoreSubscription', subscription)
  }
  
  // noinspection JSUnusedLocalSymbols
  private onDeliveryNoteChanges(
    context: ActionContext<DeliveryNoteConfirmState, AppState>,
    deliveryNoteId: string,
    deliveryNote: DeliveryNoteBean | null
  ) {
    if (deliveryNoteId !== context.state.info?.deliveryNoteId) {
      return
    }
    console.log('Updating delivery note in driver confirm flow.')
    if (deliveryNote !== null) {
      context.commit('resetOrUpdateDeliveryNote', deliveryNote)
    } else {
      context.commit('setDeliveryNote', null)
    }
  }
  
  private async registerForPushNotificationAction(
    context: ActionContext<DeliveryNoteConfirmState, AppState>
  ) {
    if (context.state.pushNotificationSubscription) {
      return
    }
    const callback = this.onDeliveryNoteStatusChanged.bind(this, context)
    const subscription = this.pushNotificationService.registerForEventReceived({
      event: PushNotificationEvent.DELIVERY_NOTE_STATUS_CHANGED,
      schema: deliveryNoteStatusChangedSchema,
      callback
    })
    context.commit('setPushNotificationSubscription', subscription)
  }
  
  // noinspection JSUnusedLocalSymbols
  private async onDeliveryNoteStatusChanged(
    context: ActionContext<DeliveryNoteConfirmState, AppState>,
    event: string,
    data: DeliveryNoteStatusChangedBean
  ) {
    const info = context.state.info
    if (!info) {
      return
    }
    if (info.deliveryNoteId !== data.delivery_note_id) {
      return
    }
    
    const externalConfirmInfo = this.getExternalConfirmInfo(info, data)
    if (!externalConfirmInfo) {
      return
    }
    console.info('Push notification triggered confirmation of delivery note.')
    context.commit('setExternalConfirmInfo', externalConfirmInfo)
  }
  
  private getExternalConfirmInfo(info: ConfirmInfoBean, data: DeliveryNoteStatusChangedBean): ExternalConfirmInfoBean | null {
    switch (info.flow) {
    case 'LOAD_MATERIAL':
      return this.getExternalConfirmInfoForLoadMaterial(data)
    case 'LOAD_WASTE':
      return this.getExternalConfirmInfoForLoadWaste(data)
    case 'UNLOAD_MATERIAL':
      return this.getExternalConfirmInfoForUnloadMaterial(data)
    case 'UNLOAD_WASTE':
      return this.getExternalConfirmInfoForUnloadWaste(data)
    default:
      return null
    }
  }
  
  private getExternalConfirmInfoForLoadMaterial(data: DeliveryNoteStatusChangedBean): ExternalConfirmInfoBean | null {
    if (data.status !== DeliveryStatus.IN_DELIVERY) {
      return null
    }
    return {
      message: translateMessage(this.i18n, 'externalConfirmation.driver.material.load')
    }
  }
  
  private getExternalConfirmInfoForLoadWaste(data: DeliveryNoteStatusChangedBean): ExternalConfirmInfoBean | null {
    if (data.status !== DeliveryStatus.IN_DELIVERY) {
      return null
    }
    return {
      message: translateMessage(this.i18n, 'externalConfirmation.driver.waste.load')
    }
  }
  
  private getExternalConfirmInfoForUnloadMaterial(data: DeliveryNoteStatusChangedBean): ExternalConfirmInfoBean | null {
    if (data.status !== DeliveryStatus.DELIVERED) {
      return null
    }
    return {
      message: translateMessage(this.i18n, 'externalConfirmation.driver.material.unload')
    }
  }
  
  private getExternalConfirmInfoForUnloadWaste(data: DeliveryNoteStatusChangedBean): ExternalConfirmInfoBean | null {
    if (data.status !== DeliveryStatus.DELIVERED) {
      return null
    }
    return {
      message: translateMessage(this.i18n, 'externalConfirmation.driver.waste.unload')
    }
  }
  
  private setLocationAction(context: ActionContext<DeliveryNoteConfirmState, AppState>, location: LocationBean) {
    context.commit('setLocation', location)
  }
  
  private setNetWeightAction(context: ActionContext<DeliveryNoteConfirmState, AppState>, netWeight: number) {
    context.commit('setNetWeight', netWeight)
  }
  
  private setCommentAction(context: ActionContext<DeliveryNoteConfirmState, AppState>, comment?: string) {
    context.commit('setComment', comment)
  }
  
  private setTruckRegistrationAction(
    context: ActionContext<DeliveryNoteConfirmState, AppState>,
    truckRegistration: string
  ) {
    context.commit('setTruckRegistration', truckRegistration)
  }
  
  private setArrivalTypeAction(
    context: ActionContext<DeliveryNoteConfirmState, AppState>,
    arrivalType: ConfirmArrivalType
  ) {
    context.commit('setArrivalType', arrivalType)
  }
  
  private setPictureAction(context: ActionContext<DeliveryNoteConfirmState, AppState>, params: SetPictureParams) {
    const deliveryNote = context.state.deliveryNote
    const confirmInfo = context.state.info
    if (!confirmInfo || !deliveryNote) {
      return
    }
    const picture: PictureBean = {
      picture: params.picture,
      location: params.location,
      date: moment().format(),
      uploaded: false
    }
    const promise = this.uploadPicture(deliveryNote, params.type, picture)
    switch (params.type) {
    case 'WEIGHING':
      context.commit('setWeighingPictureBean', {picture, promise})
      break
    case 'LOAD':
      context.commit('setLoadPictureBean', {picture, promise})
      break
    case 'UNLOAD':
      context.commit('setUnloadPictureBean', {picture, promise})
      break
    }
  }
  
  private async confirmDepartureAction(context: ActionContext<DeliveryNoteConfirmState, AppState>): Promise<DeliveryNoteBean | null> {
    const deliveryNote = context.state.deliveryNote
    const confirmInfo = context.state.info
    if (!confirmInfo || !deliveryNote) {
      throw makeException(this.i18n, 'confirm.unknown')
    }
    
    await this.waitForPictureUploads(context.state)
    
    const result = await this.deliveryNoteApi.confirmLoad(confirmInfo.flow, confirmInfo.deliveryNoteId, {
      date: moment().format(),
      net_weight: confirmInfo.netWeight,
      truck_registration: confirmInfo.truckRegistration,
      location: confirmInfo.location,
      comment: confirmInfo.comment
    })
    context.commit('setDeliveryNote', null)
    if (result.pending) {
      await context.dispatch(
        'message/setMessage',
        {message: translateMessage(this.i18n, 'confirm.pending'), timeoutInMs: 5000},
        {root: true}
      )
    }
    if (result.content !== undefined) {
      return result.content
    } else {
      return deliveryNote
    }
  }
  
  private async notifyArrivalAction(
    context: ActionContext<DeliveryNoteConfirmState, AppState>
  ): Promise<void> {
    const confirmInfo = context.state.info
    if (!confirmInfo) {
      throw makeException(this.i18n, 'confirm.unknown')
    }
    if (confirmInfo.flow !== 'UNLOAD_MATERIAL') {
      return
    }
    const hasAlreadyBeenNotified = this.arrivalStore.getItem(confirmInfo.deliveryNoteId)
    if (hasAlreadyBeenNotified) {
      return
    }
    await this.deliveryNoteApi.confirmArrival(confirmInfo.deliveryNoteId)
    this.arrivalStore.saveItem(confirmInfo.deliveryNoteId, true)
  }
  
  private async confirmArrivalWithSiteManagerCodeAction(
    context: ActionContext<DeliveryNoteConfirmState, AppState>,
    siteManagerCode: string
  ): Promise<DeliveryNoteBean> {
    const deliveryNote = context.state.deliveryNote
    const confirmInfo = context.state.info
    if (!confirmInfo || !deliveryNote) {
      throw makeException(this.i18n, 'confirm.unknown')
    }
    if (confirmInfo.flow !== 'UNLOAD_MATERIAL') {
      throw makeException(this.i18n, 'confirm.unknown')
    }
    if ((deliveryNote?.waiting_sync_fields?.length || 0) > 0) {
      throw makeException(this.i18n, 'confirm.cannotConfirmByCodeOffline')
    }
    
    const updatedDeliveryNote = await this.deliveryNoteApi.confirmUnloadWithSiteManagerCode(
      confirmInfo.flow,
      confirmInfo.deliveryNoteId,
      {
        date: moment().format(),
        code: siteManagerCode,
        location: confirmInfo.location
      }
    )
    
    console.info('Cleaning confirm flow after confirm action.')
    context.commit('resetState', false)
    this.confirmInfoStore.deleteItem(CONFIRM_STORE_KEY)
    
    return updatedDeliveryNote
  }
  
  private async confirmArrivalWithPictureAction(context: ActionContext<DeliveryNoteConfirmState, AppState>): Promise<DeliveryNoteBean> {
    const confirmInfo = context.state.info
    const deliveryNote = context.state.deliveryNote
    if (!confirmInfo || !deliveryNote) {
      throw makeException(this.i18n, 'confirm.unknown')
    }
    if (confirmInfo.flow !== 'UNLOAD_MATERIAL' && confirmInfo.flow !== 'UNLOAD_WASTE') {
      throw makeException(this.i18n, 'confirm.unknown')
    }
    
    const body: any = {
      date: moment().format(),
      location: confirmInfo.location
    }
    if (confirmInfo.flow === 'UNLOAD_WASTE') {
      body.net_weight = confirmInfo.netWeight
    }
    
    await this.waitForPictureUploads(context.state)
    const result = await this.deliveryNoteApi.confirmUnloadWithPicture(confirmInfo.flow, confirmInfo.deliveryNoteId, body)
    
    console.info('Cleaning confirm flow after confirm action.')
    context.commit('resetState', false)
    this.confirmInfoStore.deleteItem(CONFIRM_STORE_KEY)
    
    if (result.pending) {
      await context.dispatch(
        'message/setMessage',
        {successMessage: translateMessage(this.i18n, 'confirm.pending'), timeoutInMs: 5000},
        {root: true}
      )
    }
    if (result.content !== undefined) {
      this.deliveryNoteStore.saveItem(result.content.id, result.content)
      return result.content
    } else {
      return deliveryNote
    }
  }
  
  private async confirmArrivalWithPushNotificationAction(
    context: ActionContext<DeliveryNoteConfirmState, AppState>
  ): Promise<DeliveryNoteBean> {
    const externalInfo = context.state.externalInfo
    const deliveryNote = context.state.deliveryNote
    if (!deliveryNote || !externalInfo) {
      throw new Error('No delivery note selected for confirmation.')
    }
    
    console.info('Cleaning confirm flow after confirm action.')
    context.commit('resetState', false)
    this.confirmInfoStore.deleteItem(CONFIRM_STORE_KEY)
    
    return deliveryNote
  }
  
  /**
   * We only wait for the first upload of images to finish either successfully or not.
   *
   * Handling of error is done by the sync module, it will put our confirm request in pending
   * in case the picture failed to upload due to missing internet.
   */
  private async waitForPictureUploads(state: DeliveryNoteConfirmState): Promise<void> {
    const uploads: Array<Promise<SynchronizeResult<void>>> = []
    
    if (state.weighingSlipPictureUpload) {
      uploads.push(state.weighingSlipPictureUpload.wait())
    }
    if (state.loadPictureUpload) {
      uploads.push(state.loadPictureUpload.wait())
    }
    if (state.unloadPictureUpload) {
      uploads.push(state.unloadPictureUpload.wait())
    }
    
    await Promise.all(uploads)
  }
  
  private updateDeliveryNoteFromUpcomingAction(
    context: ActionContext<DeliveryNoteConfirmState, AppState>,
    updatedDeliveryNotes: Array<DeliveryNoteBean>
  ) {
    const confirmInfo = context.state.info
    if (!confirmInfo) {
      return
    }
    if (this.isUserInFlow()) {
      console.info('Do not update delivery note from upcoming since user is in flow.')
      return
    }
    const updatedDeliveryNote = _.find(updatedDeliveryNotes, it => it.id == confirmInfo.deliveryNoteId)
    // Clear the cache since it has been completed by another user.
    if (!updatedDeliveryNote) {
      context.commit('setDeliveryNote', null)
    }
  }
  
  private isUserInFlow(): boolean {
    const routeName = this.ionicProxyService.router?.currentRoute?.value?.name?.toString()
    if (!routeName) {
      return false
    }
    return _.valuesIn(DeliveryNoteConfirmLoadRoute).indexOf(routeName) !== -1
  }
  
  private clearConfirmModuleAction(context: ActionContext<DeliveryNoteConfirmState, AppState>) {
    context.commit('setDeliveryNote', null)
  }
  
  private reloadState(
    state: DeliveryNoteConfirmState,
    newState: { info: ConfirmInfoBean; deliveryNote: DeliveryNoteBean }
  ) {
    state.needReloading = false
    state.info = newState.info
    state.deliveryNote = newState.deliveryNote
  }
  
  private setDeliveryNote(state: DeliveryNoteConfirmState, deliveryNote: DeliveryNoteBean | null) {
    if (!deliveryNote) {
      console.info('Cleaning driver confirm flow.')
      this.resetState(state)
      this.confirmInfoStore.deleteItem(CONFIRM_STORE_KEY)
      return
    }
    
    const flow = computeConfirmFlow(deliveryNote)
    if (!flow) {
      throw makeException(this.i18n, 'confirm.alreadyDelivered')
    }
    
    if (deliveryNote.id === state.info?.deliveryNoteId && flow === state.info?.flow) {
      console.info('Updating delivery note since flow has not changed.')
      state.deliveryNote = deliveryNote
      return
    }
    
    console.info(`Setting flow (${flow}) for delivery note.`)
    
    this.resetState(state)
    
    state.deliveryNote = deliveryNote
    state.info = {
      deliveryNoteId: deliveryNote.id,
      flow: flow
    }
    this.confirmInfoStore.saveItem(CONFIRM_STORE_KEY, state.info)
  }
  
  private resetOrUpdateDeliveryNote(state: DeliveryNoteConfirmState, deliveryNote: DeliveryNoteBean) {
    if (deliveryNote.id !== state.info?.deliveryNoteId) {
      return
    }
    
    const currentFlow = state.info?.flow
    
    if (this.isUserInFlow()) {
      console.info(`Only updating delivery note in flow (${currentFlow}) since the user is in flow.`)
      state.deliveryNote = deliveryNote
      return
    }
    
    const newFlow = computeConfirmFlow(deliveryNote)
    
    if (newFlow !== currentFlow) {
      console.info(`Resetting delivery note in flow (${currentFlow}) since new flow (${newFlow}) is required.`)
      this.resetState(state)
      this.confirmInfoStore.deleteItem(CONFIRM_STORE_KEY)
    } else {
      console.info(`Updating delivery note in flow (${currentFlow}).`)
      state.deliveryNote = deliveryNote
    }
  }
  
  private resetState(
    state: DeliveryNoteConfirmState,
    resetSync?: boolean
  ) {
    if (resetSync === true) {
      if (state.info) {
        this.deliveryNoteSyncService.clearByIdAndType(state.info.deliveryNoteId, state.info.flow).then()
      }
    }
    state.deliveryNote = null
    state.info = null
    state.externalInfo = null
    state.weighingSlipPictureUpload = null
    state.loadPictureUpload = null
    state.unloadPictureUpload = null
    this.setStoreSubscription(state, undefined)
  }
  
  private setExternalConfirmInfo(state: DeliveryNoteConfirmState, externalInfo: ExternalConfirmInfoBean | null) {
    state.externalInfo = externalInfo
  }
  
  private setLocation(state: DeliveryNoteConfirmState, location: LocationBean) {
    this.updateAndSaveConfirmInfo(state, info => {
      info.location = location
    })
  }
  
  private setNetWeight(state: DeliveryNoteConfirmState, netWeight: number) {
    this.updateAndSaveConfirmInfo(state, info => {
      info.netWeight = netWeight
      // We reset the image since the user changed the weight.
      state.weighingSlipPictureUpload = null
      info.weighingSlipPicture = undefined
    })
  }
  
  private setTruckRegistration(state: DeliveryNoteConfirmState, truckRegistration: string) {
    this.updateAndSaveConfirmInfo(state, info => {
      info.truckRegistration = truckRegistration
    })
  }
  
  private setComment(state: DeliveryNoteConfirmState, comment?: string) {
    this.updateAndSaveConfirmInfo(state, info => {
      info.comment = comment
    })
  }
  
  private setWeighingPictureBean(state: DeliveryNoteConfirmState, params: SetPictureBeanParams) {
    this.updateAndSaveConfirmInfo(state, info => info.weighingSlipPicture = params.picture)
    state.weighingSlipPictureUpload = params.promise
  }
  
  private setLoadPictureBean(state: DeliveryNoteConfirmState, params: SetPictureBeanParams) {
    this.updateAndSaveConfirmInfo(state, load => load.loadPicture = params.picture)
    state.loadPictureUpload = params.promise
  }
  
  private setUnloadPictureBean(state: DeliveryNoteConfirmState, params: SetPictureBeanParams) {
    this.updateAndSaveConfirmInfo(state, info => info.unloadPicture = params.picture)
    state.unloadPictureUpload = params.promise
  }
  
  private setArrivalType(state: DeliveryNoteConfirmState, arrivalType: ConfirmArrivalType) {
    this.updateAndSaveConfirmInfo(state, info => info.arrivalType = arrivalType)
  }
  
  private setStoreSubscription(state: DeliveryNoteConfirmState, subscription?: Subscription) {
    if (state.storeSubscription !== subscription) {
      state.storeSubscription?.unregister()
      state.storeSubscription = subscription
    }
  }
  
  private getNextRoute(state: DeliveryNoteConfirmState): () => string | undefined {
    return () => {
      const deliveryNote = state.deliveryNote
      const info = state.info
      const externalInfo = state.externalInfo
      if (!info || !deliveryNote) {
        return DeliveryNoteConfirmLoadRoute.INITIAL
      }
      switch (info.flow) {
      case 'LOAD_MATERIAL':
        return this.getNextRouteForLoadMaterial(deliveryNote, info, externalInfo)
      case 'UNLOAD_MATERIAL':
        return this.getNextRouteForUnloadMaterial(deliveryNote, info)
      case 'LOAD_WASTE':
        return this.getNextRouteForLoadWaste(deliveryNote, info, externalInfo)
      case 'UNLOAD_WASTE':
        return this.getNextRouteForUnloadWaste(deliveryNote, info, externalInfo)
      }
    }
  }
  
  private getNextRouteForLoadMaterial(deliveryNote: DeliveryNoteBean, confirmInfo: ConfirmInfoBean, externalInfo: ExternalConfirmInfoBean | null): string {
    if (externalInfo) {
      return DeliveryNoteConfirmLoadRoute.CONFIRM_DEPARTURE
    }
    if (!deliveryNote.net_weight) {
      if (!confirmInfo.netWeight) {
        return DeliveryNoteConfirmLoadRoute.WEIGHT
      }
      if (!confirmInfo.weighingSlipPicture) {
        return DeliveryNoteConfirmLoadRoute.WEIGHING_SLIP
      }
    }
    if (!deliveryNote.truck_registration && !confirmInfo.truckRegistration) {
      return DeliveryNoteConfirmLoadRoute.TRUCK_REGISTRATION
    }
    return DeliveryNoteConfirmLoadRoute.CONFIRM_DEPARTURE
  }
  
  private getNextRouteForUnloadMaterial(_: DeliveryNoteBean, confirmInfo: ConfirmInfoBean): string {
    const arrivalType = confirmInfo?.arrivalType
    if (arrivalType === 'BY_PUSH_NOTIFICATION') {
      return DeliveryNoteConfirmLoadRoute.COMMENTS
    }
    if (arrivalType === 'BY_PICTURE') {
      return DeliveryNoteConfirmLoadRoute.UNLOAD
    }
    return DeliveryNoteConfirmLoadRoute.SITE_MANAGER_CODE
  }
  
  private getNextRouteForLoadWaste(deliveryNote: DeliveryNoteBean, confirmInfo: ConfirmInfoBean, externalInfo: ExternalConfirmInfoBean | null): string {
    if (externalInfo) {
      return DeliveryNoteConfirmLoadRoute.CONFIRM_DEPARTURE
    }
    if (!deliveryNote.truck_registration && !confirmInfo.truckRegistration) {
      return DeliveryNoteConfirmLoadRoute.TRUCK_REGISTRATION
    }
    if (!confirmInfo.loadPicture) {
      return DeliveryNoteConfirmLoadRoute.LOAD
    }
    return DeliveryNoteConfirmLoadRoute.CONFIRM_DEPARTURE
  }
  
  private getNextRouteForUnloadWaste(_: DeliveryNoteBean, confirmInfo: ConfirmInfoBean, externalInfo: ExternalConfirmInfoBean | null): string {
    if (externalInfo) {
      return DeliveryNoteConfirmLoadRoute.COMMENTS
    }
    if (!confirmInfo.netWeight) {
      return DeliveryNoteConfirmLoadRoute.WEIGHT
    }
    return DeliveryNoteConfirmLoadRoute.WEIGHING_SLIP
  }
  
  private getPreviousRoute(state: DeliveryNoteConfirmState): (currentRoute: string) => string | undefined {
    return (currentRoute) => {
      const info = state.info
      const deliveryNote = state.deliveryNote
      const externalInfo = state.externalInfo
      if (!info || !deliveryNote) {
        return DeliveryNoteDetails
      }
      if (externalInfo) {
        return DeliveryNoteDetails
      }
      switch (info.flow) {
      case 'LOAD_MATERIAL':
        return this.getPreviousRouteForLoadMaterial(currentRoute, deliveryNote)
      case 'UNLOAD_MATERIAL':
        return this.getPreviousRouteForUnloadMaterial(currentRoute)
      case 'LOAD_WASTE':
        return this.getPreviousRouteForLoadWaste(currentRoute, deliveryNote)
      case 'UNLOAD_WASTE':
        return this.getPreviousRouteForUnloadWaste(currentRoute)
      }
    }
  }
  
  private getPreviousRouteForLoadMaterial(currentRoute: string, deliveryNote: DeliveryNoteBean): string {
    switch (currentRoute) {
    case DeliveryNoteConfirmLoadRoute.CONFIRM_DEPARTURE:
      if (!deliveryNote.truck_registration) {
        return DeliveryNoteConfirmLoadRoute.TRUCK_REGISTRATION
      }
      // eslint-disable-next-line no-fallthrough
    case DeliveryNoteConfirmLoadRoute.TRUCK_REGISTRATION:
      if (!deliveryNote.net_weight) {
        return DeliveryNoteConfirmLoadRoute.WEIGHING_SLIP
      }
      // eslint-disable-next-line no-fallthrough
    case DeliveryNoteConfirmLoadRoute.WEIGHING_SLIP:
      if (!deliveryNote.net_weight) {
        return DeliveryNoteConfirmLoadRoute.WEIGHT
      }
      // eslint-disable-next-line no-fallthrough
    case DeliveryNoteConfirmLoadRoute.WEIGHT:
    case DeliveryNoteConfirmLoadRoute.INITIAL:
    default:
      return DeliveryNoteDetails
    }
  }
  
  private getPreviousRouteForUnloadMaterial(currentRoute: string): string {
    switch (currentRoute) {
    case DeliveryNoteConfirmLoadRoute.UNLOAD:
      return DeliveryNoteConfirmLoadRoute.SITE_MANAGER_CODE
    case DeliveryNoteConfirmLoadRoute.SITE_MANAGER_CODE:
    default:
      return DeliveryNoteDetails
    }
  }
  
  private getPreviousRouteForLoadWaste(currentRoute: string, deliveryNote: DeliveryNoteBean): string {
    switch (currentRoute) {
    case DeliveryNoteConfirmLoadRoute.CONFIRM_DEPARTURE:
      return DeliveryNoteConfirmLoadRoute.LOAD
      // eslint-disable-next-line no-fallthrough
    case DeliveryNoteConfirmLoadRoute.LOAD:
      if (!deliveryNote.truck_registration) {
        return DeliveryNoteConfirmLoadRoute.TRUCK_REGISTRATION
      }
      // eslint-disable-next-line no-fallthrough
    case DeliveryNoteConfirmLoadRoute.TRUCK_REGISTRATION:
    case DeliveryNoteConfirmLoadRoute.INITIAL:
    default:
      return DeliveryNoteDetails
    }
  }
  
  private getPreviousRouteForUnloadWaste(currentRoute: string): string {
    switch (currentRoute) {
    case DeliveryNoteConfirmLoadRoute.WEIGHING_SLIP:
      return DeliveryNoteConfirmLoadRoute.WEIGHT
    case DeliveryNoteConfirmLoadRoute.WEIGHT:
    default:
      return DeliveryNoteDetails
    }
  }
}
