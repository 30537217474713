<template>
  <ion-item-divider color="light">
    <ion-label>{{ label }}</ion-label>
  </ion-item-divider>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {IonItemDivider, IonLabel} from '@ionic/vue'

export default defineComponent({
  name: 'main-menu-divider',
  props: {
    label: String
  },
  components: {
    IonItemDivider, IonLabel
  }
})
</script>

<style scoped>

ion-label {
  margin-top: 22px;
  margin-bottom: 8px;
}

</style>
