<template>
  <ion-item v-if="requiresAppUpgrade" color="rockease" lines="full">
    <span>
      {{ message }}
    </span>
    <ion-button slot="end"
                color="rockease"
                target="_blank"
                v-bind:href="storeUrl">
      {{ t('button.install') }}
    </ion-button>
  </ion-item>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {IonButton, IonItem} from '@ionic/vue'
import {getStoreUrl} from '@/utils/AppStoreUtils'
import {getOperatingSystem} from '@/utils/OSUtils'
import {mapGetters} from 'vuex'
import {useI18n} from 'vue-i18n'

export default defineComponent({
  name: 'upgrade-app',
  setup() {
    const {t} = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })
    return {t}
  },
  computed: {
    ...mapGetters('appVersion', ['requiresAppUpgrade']),
    message(): string | undefined {
      switch (getOperatingSystem()) {
      case 'android':
        return this.t('message.android')
      case 'ios':
        return this.t('message.ios')
      default:
        return undefined
      }
    },
    storeUrl(): string | undefined {
      return getStoreUrl()
    }
  },
  components: {
    IonItem, IonButton
  }
})
</script>

<style scoped>

span {
  font-size: var(--font-size-sm);
  padding: var(--padding-sm) 0;
}

</style>

<i18n locale="en">
{
  "message": {
    "android": "Update the Rockease app from the Google Play store.",
    "ios": "Update the Rockease app from the App Store."
  },
  "button": {
    "install": "Install"
  }
}
</i18n>

<i18n locale="fr">
{
  "message": {
    "android": "Mettez à jour l'application Rockease depuis le Google Play store.",
    "ios": "Mettez à jour l'application Rockease depuis l'App Store."
  },
  "button": {
    "install": "Installer"
  }
}
</i18n>
