import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b2339a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_message_header = _resolveComponent("message-header")!
  const _component_gps_permission_denied = _resolveComponent("gps-permission-denied")!
  const _component_install_app = _resolveComponent("install-app")!
  const _component_upgrade_app = _resolveComponent("upgrade-app")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.message)
      ? (_openBlock(), _createBlock(_component_message_header, { key: 0 }))
      : (_ctx.gpsPermissionDenied)
        ? (_openBlock(), _createBlock(_component_gps_permission_denied, { key: 1 }))
        : _createCommentVNode("", true),
    _createVNode(_component_install_app),
    _createVNode(_component_upgrade_app)
  ]))
}