import Ajv, {JSONSchemaType} from 'ajv'
import {ApiException, makeApiException} from '@/exception/ApiException'
import {I18n} from 'vue-i18n'
import {translateMessage} from '@/i18n'
import {Capacitor} from '@capacitor/core'
import {AuthenticationService} from '@/service/authentication/AuthenticationService'
import {Device} from '@capacitor/device'
import {App} from '@capacitor/app'

const BASE_URL = process.env.VUE_APP_API_BASE_URL

const DEFAULT_REQUEST_TIMEOUT = 8000

export function getURL(path: string): string {
  return new URL(path, BASE_URL).href
}

export interface ErrorBean {
  status: number
  message: string | null
}

interface RequestInitResult {
  init: RequestInit
  timeout?: NodeJS.Timeout
}

export const errorBeanSchema: JSONSchemaType<ErrorBean> = {
  type: 'object',
  properties: {
    status: {type: 'number'},
    message: {type: 'string'}
  },
  required: ['status'],
  additionalProperties: true
}

export interface QueryOptions {
  path: string
  authentication: 'required' | 'optional' | 'none'
  queryParams?: Map<string, object>
  body?: any
  rawBody?: any
  requestTimeout?: number
}

export interface JsonQueryOptions<T> extends QueryOptions {
  schema?: JSONSchemaType<T>
}

export interface BinaryQueryOptions extends QueryOptions {
  contentType: string
}

// noinspection JSMethodCanBeStatic
export class AbstractApi {
  private readonly ajv: Ajv
  
  constructor(
    protected readonly i18n: I18n,
    private readonly authenticationService: AuthenticationService
  ) {
    this.ajv = new Ajv()
  }
  
  async get<T>(options: JsonQueryOptions<T>): Promise<T> {
    const response = await this.internalFetch('get', options)
    return this.parseResponseContent(response, options)
  }
  
  async getBinary(options: BinaryQueryOptions): Promise<Blob> {
    const response = await this.internalFetch('get', options)
    return this.parseBinaryContent(response, options)
  }
  
  async put<T>(options: JsonQueryOptions<T>): Promise<T> {
    const response = await this.internalFetch('put', options)
    return this.parseResponseContent(response, options)
  }
  
  async post<T>(options: JsonQueryOptions<T>): Promise<T> {
    const response = await this.internalFetch('post', options)
    return this.parseResponseContent(response, options)
  }
  
  async putVoid(options: QueryOptions): Promise<void> {
    const response = await this.internalFetch('put', options)
    if (!response.ok) {
      // We still want to parse the error message in this case.
      return this.parseResponseContent(response, options)
    }
  }
  
  async postVoid(options: QueryOptions): Promise<void> {
    const response = await this.internalFetch('post', options)
    if (!response.ok) {
      // We still want to parse the error message in this case.
      return this.parseResponseContent(response, options)
    }
  }
  
  async deleteVoid(options: QueryOptions): Promise<void> {
    const response = await this.internalFetch('delete', options)
    if (!response.ok) {
      // We still want to parse the error message in this case.
      return this.parseResponseContent(response, options)
    }
  }
  
  async internalFetch(method: 'get' | 'put' | 'post' | 'delete', options: QueryOptions): Promise<Response> {
    const url = this.getURL(options)
    const requestInit = await this.getRequestInit(method, options)
    
    try {
      return await fetch(url, requestInit.init)
    } catch (error: any) {
      console.error(`Failed to fetch ${url}.`, error)
      if (error instanceof DOMException && error.code == DOMException.ABORT_ERR) {
        throw makeApiException(this.i18n, 'api.timeout')
      }
      throw makeApiException(this.i18n, 'api.unknown')
    } finally {
      if (requestInit.timeout) {
        clearTimeout(requestInit.timeout)
      }
    }
  }
  
  async getRequestInit(method: string, options: QueryOptions): Promise<RequestInitResult> {
    const requestTimeout = options.requestTimeout || DEFAULT_REQUEST_TIMEOUT
    let controller: AbortController | undefined
    let timeout: NodeJS.Timeout | undefined
    if (requestTimeout > 0) {
      controller = new AbortController()
      timeout = setTimeout(() => controller?.abort(), requestTimeout)
    }
    
    return {
      init: {
        method: method,
        headers: await this.makeHeaders(options),
        body: this.makeBody(options),
        credentials: 'omit',
        signal: controller?.signal
      },
      timeout: timeout
    }
  }
  
  async makeHeaders(options: QueryOptions): Promise<Headers> {
    const headers = new Headers()
    headers.set('Accept', 'application/json')
    await this.addPlatformHeaders(headers)
    if (options.body) {
      headers.set('Content-Type', 'application/json')
    }
    if (options.authentication === 'required') {
      await this.authenticationService.setAuthenticationHeaders(headers)
    }
    if (options.authentication === 'optional') {
      try {
        await this.authenticationService.setAuthenticationHeaders(headers)
      } catch (e) {
        // Ignore since authentication is optional.
      }
    }
    return headers
  }
  
  private async addPlatformHeaders(headers: Headers): Promise<void> {
    const platform = Capacitor.getPlatform()
    if (platform == 'web') {
      return
    }
  
    switch (platform) {
    case 'android':
      headers.set('X-Rockease-Platform', 'Android')
      break
    case 'ios':
      headers.set('X-Rockease-Platform', 'iOS')
      break
    }
    
    const [deviceInfo, appInfo] = await Promise.all([
      Device.getInfo(),
      App.getInfo()
    ])
    
    headers.set('X-Rockease-OS-Version', deviceInfo.osVersion)
    headers.set('X-Rockease-Device', `${deviceInfo.manufacturer} ${deviceInfo.model}`)
    headers.set('X-Rockease-AppVersion', appInfo.version)
  }
  
  async checkContentType(response: Response, options: QueryOptions, expectedContentType: string) {
    const contentType = response.headers.get('Content-Type')
    if (!contentType || !contentType.includes(expectedContentType)) {
      console.error('Server responded with unexpected content-type %o while fetching path %o.', contentType, options.path)
      throw makeApiException(this.i18n, 'api.unknown')
    }
  }
  
  async checkResponseOk(response: Response, options: QueryOptions) {
    if (!response.ok) {
      await this.checkContentType(response, options, 'application/json')
      const content = await response.json()
      console.error('Server responded with status %d while fetching path %o: %o', response.status, options.path, content)
      throw this.convertResponseToApiException(content, response)
    }
  }
  
  async parseBinaryContent(response: Response, options: BinaryQueryOptions): Promise<Blob> {
    await this.checkResponseOk(response, options)
    await this.checkContentType(response, options, options.contentType)
    return response.blob()
  }
  
  async parseResponseContent<T>(response: Response, options: JsonQueryOptions<T>): Promise<T> {
    await this.checkResponseOk(response, options)
    await this.checkContentType(response, options, 'application/json')
    
    const content = await response.json()
    if (options.schema) {
      const validate = this.ajv.compile(options.schema)
      if (!validate(content)) {
        console.error('Server responded with content not expected schema while fetching path %o.', options.path,
          JSON.stringify(options.schema),
          JSON.stringify(content))
        throw makeApiException(this.i18n, 'api.unknown')
      }
    }
    return content
  }
  
  private getURL(options: QueryOptions): string {
    return getURL(options.path)
  }
  
  private makeBody(options: QueryOptions): BodyInit | null {
    if (!options.body && !options.rawBody) {
      return null
    }
    if (options.rawBody) {
      return options.rawBody
    }
    return JSON.stringify(options.body)
  }
  
  convertResponseToApiException(content: any, response: Response): ApiException {
    if (!this.ajv.validate(errorBeanSchema, content)) {
      return makeApiException(this.i18n, 'api.unknown')
    }
    const errorBean = content as ErrorBean
    let message = errorBean.message
    if (!message) {
      message = translateMessage(this.i18n, 'api.unknown')
    }
    return new ApiException(message, response)
  }
}
