import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_ripple_effect = _resolveComponent("ion-ripple-effect")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    class: "ion-activatable",
    lines: "none"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card, {
        class: "ion-activatable",
        onClick: _ctx.onClick
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              (_ctx.loading)
                ? (_openBlock(), _createBlock(_component_ion_row, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, { size: "8" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_skeleton_text, {
                            animated: "",
                            width: "100%"
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : (_ctx.truckRegistration)
                  ? (_openBlock(), _createBlock(_component_ion_row, { key: 1 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_col, {
                          class: "truck-registration",
                          size: "12"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.truckRegistration), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_ripple_effect)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }))
}