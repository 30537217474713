<template>
  <page container="scrollable"
        modal
        v-bind:title="t('title')">
    <header-content-footer-layout footer-class="padding-md">
      <template v-slot:default>
        <h1 class="title">{{ t('header') }}</h1>
        <div v-if="showLocation" class="section">
          <h2 class="title">{{ t('location.header') }}</h2>
          <p class="text">{{ t('location.description') }}</p>
        </div>
        <div v-if="showCamera" class="section">
          <h2 class="title">{{ t('camera.header') }}</h2>
          <p class="text">{{ t('camera.description') }}</p>
        </div>
        <div v-if="showNotification" class="section">
          <h2 class="title">{{ t('notification.header') }}</h2>
          <p class="text">{{ t('notification.description') }}</p>
        </div>
      </template>

      <template v-slot:footer>
        <ion-button color="rockease"
                    expand="block"
                    v-on:click="onOkButton">{{ t('button.ok') }}
        </ion-button>
      </template>
    </header-content-footer-layout>
  </page>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import Page from '@/components/Page.vue'
import {useI18n} from 'vue-i18n'
import {mapActions} from 'vuex'
import {IonButton, modalController} from '@ionic/vue'
import {AskedPermissions, PermissionStatuses} from '@/stores/modules/PermissionModule'
import HeaderContentFooterLayout from '@/components/layout/HeaderContentFooterLayout.vue'

export default defineComponent({
  name: 'PermissionsModal',
  props: {
    askedPermissions: Object as PropType<AskedPermissions>,
    permissionStatuses: Object as PropType<PermissionStatuses>
  },
  setup() {
    const {t} = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })
    return {t}
  },
  methods: {
    ...mapActions('permission', ['requestPermissions']),
    async onOkButton(): Promise<void> {
      try {
        const permissionStatuses = await this.requestPermissions(this.askedPermissions)
        await modalController.dismiss(permissionStatuses)
      } catch (error) {
        await modalController.dismiss(error)
      }
    }
  },
  computed: {
    showLocation(): boolean {
      if (!this.askedPermissions || !this.askedPermissions.location) {
        return false
      }
      const state = this.permissionStatuses?.locationState
      return state === 'prompt' || state === 'prompt-with-rationale'
    },
    showCamera(): boolean {
      if (!this.askedPermissions || !this.askedPermissions.camera) {
        return false
      }
      const state = this.permissionStatuses?.cameraState
      return state === 'prompt' || state === 'prompt-with-rationale'
    },
    showNotification(): boolean {
      if (!this.askedPermissions || !this.askedPermissions.notification) {
        return false
      }
      const state = this.permissionStatuses?.notificationState
      return state === 'prompt' || state === 'prompt-with-rationale'
    }
  },
  components: {
    HeaderContentFooterLayout,
    Page,
    IonButton
  }
})
</script>

<style scoped>

h1 {
  margin-bottom: var(--margin-xl);
}

.section {
  margin-bottom: var(--margin-xl);
}

</style>

<i18n locale="en">
{
  "title": "Permissions",
  "header": "Rockease requires the following permissions:",
  "location": {
    "header": "Access to your location",
    "description": "To ensure the proper tracking of the deliveries, Rockease requires access to your location."
  },
  "camera": {
    "header": "Take picture",
    "description": "To take picture of paper slips and materials."
  },
  "notification": {
    "header": "Display notifications",
    "description": "To notify of the departure/arrival of the deliveries, ."
  }
}
</i18n>

<i18n locale="fr">
{
  "title": "Permissions",
  "header": "Rockease nécessite les permissions suivantes:",
  "location": {
    "header": "Accès à votre position",
    "description": "Pour faciliter le suivi des livraisons."
  },
  "camera": {
    "header": "Prise de photo",
    "description": "Pour prendre en photo les bons papiers et les matériaux."
  },
  "notification": {
    "header": "Affichage de notifications",
    "description": "Pour vous notifier des départs/arrivées de vos livraisons."
  }
}
</i18n>
