import {ActionContext, Module} from 'vuex'
import {AppState} from '@/stores'
import {getItemOperation, getListOperation, GetOperation} from '@/service/operation/GetOperation'
import {LocalStore} from '@/service/store/LocalStore'
import {SiteManagerQuotationRequestBean} from '@/service/model/sitemanager/SiteManagerQuotationRequestBean'
import {QuotationRequestApi} from '@/service/api/sitemanager/QuotationRequestApi'
import Result from '@/service/operation/Result'
import {makeException} from '@/exception/Exception'
import {I18n} from 'vue-i18n'
import {
  SiteManagerCreateQuotationRequestModule
} from '@/stores/modules/sitemanager/SiteManagerCreateQuotationRequestModule'
import {DeliveryNoteState} from '@/stores/modules/DeliveryNoteModule'
import {SiteManagerQuotationResponseApi} from '@/service/api/sitemanager/SiteManagerQuotationResponseApi'
import {SiteManagerQuotationResponseBean} from '@/service/model/sitemanager/SiteManagerQuotationResponseBean'
import {
  SiteManagerSelectQuotationResponseModule
} from '@/stores/modules/sitemanager/SiteManagerSelectQuotationResponseModule'

const quotationRequestsStoreKey = 'quotationRequests'
const currentQuotationResponseStoreKey = 'currentQuotationResponseStoreKey'

export interface SiteManagerQuotationRequestModuleState {
  quotationRequestsOperation: GetOperation<Array<SiteManagerQuotationRequestBean>, string>
  quotationRequestsResult: Result<Array<SiteManagerQuotationRequestBean>>
  currentQuotationRequestResult: GetOperation<Array<SiteManagerQuotationRequestBean>, string>
  currentQuotationResponsesResult: GetOperation<Array<SiteManagerQuotationRequestBean>, string>
}

export class SiteManagerQuotationRequestModule implements Module<SiteManagerQuotationRequestModuleState, AppState> {
  namespaced = true
  modules = {}
  actions = {
    fetchQuotationRequests: this.fetchQuotationRequestsAction.bind(this),
    refreshQuotationRequests: this.refreshQuotationRequestsAction.bind(this),
    
    selectQuotationRequest: this.selectQuotationRequestAction.bind(this),
    
    clearQuotationRequestModule: this.clearQuotationRequestModuleAction.bind(this),
  }
  mutations = {
    setQuotationRequestsOperation: (state, operation) => state.quotationRequestsOperation = operation,
    setQuotationRequestsResult: (state, result) => state.quotationRequestsResult = result,
    setCurrentQuotationRequestResult: (state, result) => state.currentQuotationRequestResult = result,
    setCurrentQuotationResponsesResult: (state, result) => state.currentQuotationResponsesResult = result
  }
  getters = {
    quotationRequestsResult: (state) => state.quotationRequestsResult,
    currentQuotationRequestResult: (state) => state.currentQuotationRequestResult,
    currentQuotationResponsesResult: (state) => state.currentQuotationResponsesResult
  }
  
  constructor(
    private readonly i18n: I18n,
    private readonly listStore: LocalStore<Array<string>, string>,
    private readonly quotationRequestStore: LocalStore<SiteManagerQuotationRequestBean, string>,
    private readonly quotationRequestApi: QuotationRequestApi,
    private readonly quotationResponseStore: LocalStore<SiteManagerQuotationResponseBean, string>,
    private readonly quotationResponseApi: SiteManagerQuotationResponseApi,
    createModule: SiteManagerCreateQuotationRequestModule,
    selectModule: SiteManagerSelectQuotationResponseModule,
  ) {
    this.modules = {
      create: createModule,
      select: selectModule
    }
  }
  
  private async fetchQuotationRequestsAction(
    context: ActionContext<SiteManagerQuotationRequestModuleState, AppState>
  ): Promise<Array<SiteManagerQuotationRequestBean> | null> {
    const operation = getListOperation(
      this.quotationRequestStore,
      this.listStore,
      quotationRequestsStoreKey,
      it => it.id,
      () => this.quotationRequestApi.fetchQuotationRequests()
    )
    
    context.commit('setQuotationRequestsOperation', operation)
    
    return await operation.get(
      {params: null},
      it => {
        context.commit('setQuotationRequestsResult', it)
      }
    )
  }
  
  async refreshQuotationRequestsAction(
    context: ActionContext<SiteManagerQuotationRequestModuleState, AppState>
  ): Promise<Array<SiteManagerQuotationRequestBean> | null> {
    const operation = context.state.quotationRequestsOperation
    if (!operation) {
      throw makeException(this.i18n, 'error.unknown')
    }
    return operation.refresh(true)
  }
  
  private async selectQuotationRequestAction(
    context: ActionContext<DeliveryNoteState, AppState>,
    requestId: string
  ): Promise<any> {
    const requestOperation = getItemOperation(
      this.quotationRequestStore,
      id => this.quotationRequestApi.fetchQuotationRequest(id)
    )
    
    const responseOperation = getListOperation(
      this.quotationResponseStore,
      this.listStore,
      currentQuotationResponseStoreKey,
      (it: SiteManagerQuotationResponseBean) => it.id,
      () => this.quotationResponseApi.fetchQuotationResponsesByRequest(requestId)
    )
    
    context.commit('setCurrentQuotationRequestResult', undefined)
    context.commit('setCurrentQuotationResponsesResult', undefined)
    
    const requestPromise = requestOperation.get(
      {params: requestId},
      it => context.commit('setCurrentQuotationRequestResult', it)
    )
    const responsesPromise = responseOperation.get(
      {params: null},
      it => context.commit('setCurrentQuotationResponsesResult', it)
    )
    return Promise.all([requestPromise, responsesPromise])
  }
  
  clearQuotationRequestModuleAction(
    context: ActionContext<SiteManagerQuotationRequestModuleState, AppState>
  ) {
    context.commit('setQuotationRequestsResult', undefined)
    context.commit('setQuotationRequestsOperation', undefined)
    context.commit('setCurrentQuotationRequestResult', undefined)
    context.commit('setCurrentQuotationResponsesResult', undefined)
    this.listStore.deleteItem(quotationRequestsStoreKey)
    this.listStore.deleteItem(currentQuotationResponseStoreKey)
    this.quotationRequestStore.clear()
    this.quotationResponseStore.clear()
  }
}
