<template>
  <ion-item class="ion-activatable" lines="none">
    <ion-card class="ion-activatable"
              v-on:click="onClick">
      <ion-grid>
        <ion-row v-if="loading">
          <ion-col size="8">
            <ion-skeleton-text animated width="100%"/>
          </ion-col>
        </ion-row>
        <ion-row v-else-if="truckRegistration">
          <ion-col class="truck-registration" size="12">
            {{ truckRegistration }}
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-ripple-effect/>
    </ion-card>
  </ion-item>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import {IonCard, IonCol, IonGrid, IonItem, IonRippleEffect, IonRow, IonSkeletonText} from '@ionic/vue'
import {SiteManagerTruckBean} from '@/service/model/sitemanager/SiteManagerTruckBean'

export default defineComponent({
  name: 'site-manager-truck-list-item',
  emits: ['select'],
  props: {
    truck: Object as PropType<SiteManagerTruckBean | undefined>,
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick() {
      if (this.truck !== undefined) {
        this.$emit('select', this.truck)
      }
    }
  },
  computed: {
    truckRegistration(): string | undefined {
      return this.truck?.truck_registration
    }
  },
  components: {
    IonItem, IonCard,
    IonGrid, IonCol, IonRow,
    IonRippleEffect, IonSkeletonText
  }
})
</script>

<style scoped>

ion-item {
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-start: 0;
  --inner-padding-end: 0;
}

ion-card {
  width: 100%;
}

.truck-registration {
  color: var(--ion-text-color);
  font-weight: 600;
}

</style>
