import {AbstractPictureApi} from '@/service/api/AbstractPictureApi'
import {PictureBean} from '@/service/model/PictureBean'
import {UserPictureType} from '@/service/model/UserPictureType'
import {SynchronizationService, SynchronizeResult} from '@/service/SynchronizationService'
import {I18n} from 'vue-i18n'
import {AuthenticationService} from '@/service/authentication/AuthenticationService'

export class SiteManagerDeliveryNotePictureApi extends AbstractPictureApi {
  
  constructor(
    i18n: I18n,
    authenticationService: AuthenticationService,
    synchronizationService: SynchronizationService
  ) {
    super(i18n, authenticationService, synchronizationService)
  }
  
  async uploadWeighingSlipPicture(
    type: string,
    deliveryNoteId: string,
    picture: PictureBean
  ): Promise<SynchronizeResult<void>> {
    return this.putOrSyncPicture(
      type, deliveryNoteId,
      `/api/mp/v1/site-manager/delivery-notes/${deliveryNoteId}/weighing-slip`,
      'WEIGHING_SLIP',
      picture
    )
  }
  
  async getPictureBin(dnId: string, type: string): Promise<Blob> {
    return this.getPicture({
      path: `/api/mp/v1/site-manager/delivery-notes/${dnId}/${this.getPictureTypePath(type)}`,
      authentication: 'required'
    })
  }
  
  private getPictureTypePath(type: string): string | undefined {
    switch (type) {
    case UserPictureType.WEIGHING_SLIP:
      return 'weighing-slip'
    case UserPictureType.LOAD:
      return 'load'
    case UserPictureType.UNLOAD:
      return 'unload'
    }
    return undefined
  }
}
