import {parsePhoneNumber} from 'libphonenumber-js'

export function formatPhoneNumber(internationalPhoneNumber: string): string {
  try {
    const phoneNumber = parsePhoneNumber(internationalPhoneNumber)
    return phoneNumber.formatNational()
  } catch (error) {
    return internationalPhoneNumber
  }
}
