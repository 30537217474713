import {isNotBlank} from '@/utils/StringUtils'

export interface Location {
  latitude: number
  longitude: number
}

export function isSameLocation(location?: Location | null, otherLocation?: Location | null) {
  return location?.latitude === otherLocation?.latitude
    && location?.longitude === otherLocation?.longitude
}

export interface Address {
  address_1?: string | null
  address_2?: string | null
  postal_code?: string | null
  city?: string | null
}

export function isAddressNotBlank(address?: Address): boolean {
  return isNotBlank(address?.address_1)
    || isNotBlank(address?.address_2)
    || isNotBlank(address?.city)
    || isNotBlank(address?.postal_code)
}

export function isAddressValid(address?: Address): boolean {
  return isNotBlank(address?.address_1)
    && isNotBlank(address?.city)
    && isNotBlank(address?.postal_code)
}

export function isSameAddress(address?: Address, otherAddress?: Address): boolean {
  return address?.address_1 === otherAddress?.address_1
    && address?.address_2 === otherAddress?.address_2
    && address?.city === otherAddress?.city
    && address?.postal_code === otherAddress?.postal_code
}

export function formatAddressAsUrlPart(address: Address): string {
  let path = ''
  for (const component of [address.address_1, address.address_2, address.postal_code, address.city]) {
    if (component) {
      if (path.length > 0) {
        path += encodeURIComponent(' ')
      }
      path += encodeURIComponent(component)
    }
  }
  return path
}

export function formatLocationAsUrlPart(location: Location): string {
  return encodeURIComponent(`${location.latitude},${location.longitude}`)
}

export function formatGoogleMapsLink(object: { location?: Location } & Address): string {
  const location = object.location
  if (location) {
    return `https://www.google.com/maps/search/?api=1&query=${formatLocationAsUrlPart(location)}`
  } else {
    return `https://www.google.com/maps/search/?api=1&query=${formatAddressAsUrlPart(object)}`
  }
}

export function formatAppleMapsLink(object: { location?: Location } & Address, label?: string): string {
  const location = object.location
  if (location) {
    const l = label || `${location.latitude},${location.longitude}`
    return `https://maps.apple.com/?q=${encodeURIComponent(l)}&ll=${location.latitude},${location.longitude}`
  } else {
    const l = label || formatAddressAsUrlPart(object)
    return `https://maps.apple.com/?q=${encodeURIComponent(l)}&address=${formatAddressAsUrlPart(object)}`
  }
}

export function formatGPS(latOrLong: number): string {
  return latOrLong.toFixed(6)
}
