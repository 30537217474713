import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_menu = _resolveComponent("main-menu")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_main_menu, {
        disabled: _ctx.mainMenuConfig.disabled,
        items: _ctx.mainMenuConfig.items
      }, null, 8, ["disabled", "items"]),
      _createVNode(_component_ion_router_outlet, {
        id: "main-content",
        ref: "routerOutlet"
      }, null, 512)
    ]),
    _: 1
  }))
}