import {AbstractApi} from '@/service/api/AbstractApi'
import {I18n} from 'vue-i18n'
import {AuthenticationService} from '@/service/authentication/AuthenticationService'

export interface ErrorReportBean {
  tag?: string
  path?: string
  component?: string
  message: string
  stacktrace?: string
}

export class ErrorReportingApi extends AbstractApi {
  
  constructor(
    i18n: I18n,
    authenticationService: AuthenticationService
  ) {
    super(i18n, authenticationService)
  }

  report(body: ErrorReportBean): Promise<void> {
    return this.postVoid({
      path: '/api/v1/errors',
      authentication: 'optional',
      body: body
    })
  }
}
