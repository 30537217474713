import {SiteManagerCustomerBean, siteManagerCustomerSchema} from '@/service/model/sitemanager/SiteManagerCustomerBean'
import {SiteBean, siteSchema} from '@/service/model/SiteBean'
import {SiteManagerMaterialBean, siteManagerMaterialSchema} from '@/service/model/sitemanager/SiteManagerMaterialBean'
import {JSONSchemaType} from 'ajv'

export interface SiteManagerQuotationRequestBean {
  id: string
  
  customer: SiteManagerCustomerBean
  site: SiteBean
  
  material: SiteManagerMaterialBean
  material_quantity: number
  material_cadence?: number | null
  material_cadence_periodicity?: number | null
  
  backload_material?: SiteManagerMaterialBean | null
  backload_material_quantity?: number | null
  
  delivered: boolean
  start_day: string
  truck_type?: string | null
  comment?: string | null
  
  requires_validation: boolean
  fee_percent: number
}

export const siteManagerQuotationRequestSchema: JSONSchemaType<SiteManagerQuotationRequestBean> = {
  type: 'object',
  properties: {
    id: {
      type: ['string']
    },
    customer: {
      ...siteManagerCustomerSchema
    },
    site: {
      ...siteSchema
    },
    material: {
      ...siteManagerMaterialSchema
    },
    material_quantity: {
      type: ['number']
    },
    material_cadence: {
      type: ['number'],
      nullable: true
    },
    material_cadence_periodicity: {
      type: ['number'],
      nullable: true
    },
    backload_material: {
      ...siteManagerMaterialSchema,
      nullable: true
    },
    backload_material_quantity: {
      type: ['number'],
      nullable: true
    },
    delivered: {
      type: ['boolean']
    },
    start_day: {
      type: ['string']
    },
    truck_type: {
      type: ['string'],
      nullable: true
    },
    comment: {
      type: ['string'],
      nullable: true
    },
    requires_validation: {
      type: ['boolean']
    },
    fee_percent: {
      type: ['number']
    }
  },
  required: [
    'id', 'customer', 'site',
    'material', 'material_quantity',
    'delivered', 'start_day', 'requires_validation', 'fee_percent'
  ],
  additionalProperties: true
}

export const siteManagerQuotationRequestArraySchema: JSONSchemaType<Array<SiteManagerQuotationRequestBean>> = {
  type: 'array',
  items: siteManagerQuotationRequestSchema
}

