
export class BroadcastPromise<T> {
  private readonly originalPromise: Promise<T>
  private readonly resolveFunctions: ((value: T | PromiseLike<T>) => void)[] = []
  private readonly rejectFunctions: ((reason?: any) => void)[] = []
  
  private resolved = false
  private rejected = false
  private value?: T | PromiseLike<T> = undefined
  private reason?: any = undefined
  
  constructor(promise: Promise<T>) {
    this.originalPromise = promise
    promise.then(
      this.completeWithValue.bind(this),
      this.completeWithError.bind(this)
    )
  }
  
  private completeWithValue(value: T | PromiseLike<T>) {
    this.resolved = true
    this.value = value
    for (const resolveFunction of this.resolveFunctions) {
      resolveFunction(value)
    }
  }
  
  private completeWithError(reason?: any) {
    this.rejected = true
    this.reason = reason
    for (const rejectFunction of this.rejectFunctions) {
      rejectFunction(reason)
    }
  }
  
  wait(): Promise<T> {
    if (this.resolved) {
      return Promise.resolve(this.value as T)
    } else if (this.rejected) {
      return Promise.reject(this.reason)
    } else {
      return new Promise<T>((resolve, reject) => {
        this.resolveFunctions.push(resolve)
        this.rejectFunctions.push(reject)
      })
    }
  }
  
  failed(): boolean {
    if (!this.resolved && !this.rejected) {
      return false
    }
    return this.rejected
  }
}
