import {ActionContext, Module} from 'vuex'
import {AppState} from '@/stores'
import {RouteLocationRaw} from 'vue-router'

export interface BackState {
  previousRoute?: RouteLocationRaw
}

export class BackModule implements Module<BackState, AppState> {
  namespaced = true
  state = {}
  actions = {
    setPreviousRoute: this.setPreviousRouteAction.bind(this)
  }
  mutations = {
    setPreviousRoute: this.setPreviousRoute.bind(this)
  }
  getters = {
    previousRoute: (state) => state.previousRoute
  }
  
  setPreviousRouteAction(
    context: ActionContext<BackState, AppState>,
    params: RouteLocationRaw | undefined
  ) {
    context.commit('setPreviousRoute', params)
  }
  
  setPreviousRoute(state: BackState, previousRoute: RouteLocationRaw | undefined) {
    state.previousRoute = previousRoute
  }
}
