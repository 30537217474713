import {JSONSchemaType} from 'ajv'
import {MaterialCategory} from '@/service/model/MaterialCategory'
import {MaterialType} from '@/service/model/MaterialBean'

export interface SiteManagerMaterialBean {
  id: string
  name: string
  unit: string
  quarry_name?: string | null
  
  type: string
  category: string
  
  other?: boolean | null
  allowed_express_order?: boolean | null
}

export const siteManagerMaterialSchema: JSONSchemaType<SiteManagerMaterialBean> = {
  type: 'object',
  properties: {
    id: {
      type: ['string']
    },
    name: {
      type: ['string']
    },
    unit: {
      type: ['string'],
    },
    quarry_name: {
      type: ['string'],
      nullable: true
    },
    type: {
      type: ['string'],
      enum: [MaterialType.NORMAL, MaterialType.WASTE]
    },
    category: {
      type: ['string'],
      enum: [MaterialCategory.MATERIAL, MaterialCategory.ASPHALT, MaterialCategory.CONCRETE, MaterialCategory.WASTE]
    },
    other: {
      type: ['boolean'],
      nullable: true
    },
    allowed_express_order: {
      type: ['boolean'],
      nullable: true
    }
  },
  required: ['name', 'unit', 'type', 'category'],
  additionalProperties: true
}

export const siteManagerMaterialArraySchema: JSONSchemaType<Array<SiteManagerMaterialBean>> = {
  type: 'array',
  items: siteManagerMaterialSchema
}
