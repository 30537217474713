import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderSlot as _renderSlot, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_toolbar, {
      color: "rockease",
      part: "toolbar"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_buttons, { slot: "start" }, {
          default: _withCtx(() => [
            (_ctx.closable)
              ? (_openBlock(), _createBlock(_component_ion_button, {
                  key: 0,
                  fill: "clear",
                  shape: "round",
                  size: "default",
                  onClick: _ctx.onCloseButtonClicked
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "icon-only",
                      icon: _ctx.closeOutline
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : (_ctx.previousRoute)
                ? (_openBlock(), _createBlock(_component_ion_button, {
                    key: 1,
                    fill: "clear",
                    shape: "round",
                    size: "default",
                    onClick: _ctx.onBackButtonClicked
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "icon-only",
                        icon: _ctx.arrowBackOutline
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : (_ctx.showMainMenuButton)
                  ? (_openBlock(), _createBlock(_component_ion_menu_button, {
                      key: 2,
                      menu: "burger"
                    }))
                  : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_ion_title, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.title), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _renderSlot(_ctx.$slots, "toolbar", {}, undefined, true),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_ion_progress_bar, {
          key: 0,
          color: "medium",
          type: "indeterminate"
        }))
      : _createCommentVNode("", true)
  ], 64))
}