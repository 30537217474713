import {AbstractApi} from '@/service/api/AbstractApi'
import {I18n} from 'vue-i18n'
import {AuthenticationService} from '@/service/authentication/AuthenticationService'
import {siteManagerMaterialArraySchema, SiteManagerMaterialBean} from '@/service/model/sitemanager/SiteManagerMaterialBean'

export class SiteManagerMaterialApi extends AbstractApi {
  
  constructor(i18n: I18n, authenticationService: AuthenticationService) {
    super(i18n, authenticationService)
  }
  
  async fetchMaterials(): Promise<Array<SiteManagerMaterialBean>> {
    return this.get({
      path: '/api/mp/v1/site-manager/materials',
      authentication: 'required',
      schema: siteManagerMaterialArraySchema
    })
  }
}
