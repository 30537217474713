export const MaterialCategory = {
  MATERIAL: 'MATERIAL',
  CONCRETE: 'CONCRETE',
  ASPHALT: 'ASPHALT',
  WASTE: 'WASTE',
}

export function getMaterialCategoryName(t: any, category?: string): string | undefined {
  if (!category) {
    return undefined
  }
  const categoryKey = `category.${category.toLowerCase()}`
  return t(categoryKey)
}
