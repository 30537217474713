export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "fr",
    "resource": {
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre position n'est pas disponible"])},
      "alert": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer votre position"])},
        "message": {
          "siteManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez activer le partage de votre position afin de permettre à l'application de fonctionner pleinement. Elle ne sera partagée avec Rockease uniquement lors de la création d'un chantier géolocalisé ou de la prise en compte d'un bon de livraison."])},
          "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez activer le partage de votre position afin de permettre à l'application de fonctionner pleinement. Elle ne sera partagée avec Rockease uniquement lors de la prise en compte d'un bon de livraison."])}
        }
      }
    }
  })
}
