import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-363b12a5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "text" }
const _hoisted_3 = { class: "text" }
const _hoisted_4 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_page = _resolveComponent("page")!

  return (_openBlock(), _createBlock(_component_page, {
    container: "fixed",
    title: _ctx.t('title')
  }, {
    default: _withCtx(() => [
      _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.t('header')), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.t('description')), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.t('resolution')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ion_button, {
          color: "rockease",
          expand: "block",
          href: _ctx.storeLink
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.goToStore), 1)
          ]),
          _: 1
        }, 8, ["href"])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}