<template>
  <ion-item v-if="message" color="rockease" lines="full">
    <span>
      {{ message }}
    </span>
    <ion-button slot="end"
                color="rockease"
                target="_blank"
                v-bind:href="storeLink">
      {{ t('button.install') }}
    </ion-button>
  </ion-item>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {useI18n} from 'vue-i18n'
import {IonButton, IonItem} from '@ionic/vue'
import {getOperatingSystemFromUserAgent, isWebApp} from '@/utils/OSUtils'
import {getStoreUrl} from '@/utils/AppStoreUtils'

export default defineComponent({
  name: 'install-app',
  setup() {
    const {t} = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })
    return {t}
  },
  computed: {
    message(): string | undefined {
      if (!isWebApp) {
        return undefined
      }
      switch (getOperatingSystemFromUserAgent()) {
      case 'android':
        return this.t('message.android')
      case 'ios':
        return this.t('message.ios')
      default:
        return undefined
      }
    },
    storeLink(): string | unknown {
      return getStoreUrl()
    }
  },
  components: {
    IonItem, IonButton
  }
})
</script>

<style scoped>

span {
  font-size: var(--font-size-sm);
  padding: var(--padding-sm) 0;
}

</style>

<i18n locale="en">
{
  "message": {
    "android": "Install the Rockease app from the Google Play store.",
    "ios": "Install the Rockease app from the App Store."
  },
  "button": {
    "install": "Install"
  }
}
</i18n>

<i18n locale="fr">
{
  "message": {
    "android": "Installez l'application Rockease depuis le Google Play store.",
    "ios": "Installez l'application Rockease depuis l'App Store."
  },
  "button": {
    "install": "Installer"
  }
}
</i18n>
