export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "fr",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour nécessaire"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La version de l'app Rockease est trop ancienne."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'accès à l'application est désactivé car certaines fonctionnalités ne sont plus compatibles."])},
      "resolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur le bouton ci-dessous pour accéder au store et mettre manuellement à jour l'app."])},
      "button": {
        "goToStore": {
          "android": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accéder au Play store"])},
          "ios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accéder a l'App store"])},
          "web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accéder au store"])}
        }
      }
    }
  })
}
