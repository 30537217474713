import {AbstractApi} from '@/service/api/AbstractApi'
import {I18n} from 'vue-i18n'
import {AuthenticationService} from '@/service/authentication/AuthenticationService'
import {AppVersionRequirementsBean, appVersionRequirementsSchema} from '@/service/model/AppVersionRequirementsBean'

export class AppVersionApi extends AbstractApi {
  
  constructor(i18n: I18n, authenticationService: AuthenticationService) {
    super(i18n, authenticationService)
  }
  
  async getRequirements(): Promise<AppVersionRequirementsBean> {
    return this.get<AppVersionRequirementsBean>({
      path: '/api/mp/v1/app-version',
      authentication: 'none',
      schema: appVersionRequirementsSchema
    })
  }
}
