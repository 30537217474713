import {AbstractApi} from '@/service/api/AbstractApi'
import {I18n} from 'vue-i18n'
import {AuthenticationService} from '@/service/authentication/AuthenticationService'
import {
  siteManagerQuotationResponseArraySchema,
  SiteManagerQuotationResponseBean
} from '@/service/model/sitemanager/SiteManagerQuotationResponseBean'

export class SiteManagerQuotationResponseApi extends AbstractApi {
  
  constructor(i18n: I18n, authenticationService: AuthenticationService) {
    super(i18n, authenticationService)
  }
  
  async fetchQuotationResponsesByRequest(requestId: string): Promise<Array<SiteManagerQuotationResponseBean>> {
    return this.get({
      path: `/api/mp/v1/site-manager/quotation-requests/${requestId}/responses`,
      authentication: 'required',
      schema: siteManagerQuotationResponseArraySchema
    })
  }
  
  async getTechSpec(requestId: string, responseId: string): Promise<Blob> {
    return this.getBinary({
      path: `/api/mp/v1/site-manager/quotation-requests/${requestId}/responses/${responseId}/tech-spec`,
      authentication: 'required',
      contentType: 'application/pdf'
    })
  }
}
