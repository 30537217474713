import {ActionContext, Module} from 'vuex'
import {AppState} from '@/stores'
import {AppConfigApi} from '@/service/api/AppConfigApi'
import {LocalStore} from '@/service/store/LocalStore'
import {AppConfigBean, appConfigSchema, DEFAULT_CONFIG} from '@/service/model/config/AppConfigBean'
import _ from 'lodash'
import deepmerge from 'deepmerge'
import {LocalStoreItem} from '@/service/store/LocalStoreItem'
import {getItemOperation} from '@/service/operation/GetOperation'

const CONFIG_STORAGE_KEY = 'config'
const CONFIG_STORE_KEY = 'config'
const CONFIG_EXPIRATION_TIME_MS = 24 * 60 * 60000 // 1 day

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AppConfigState {
  configStoreItem?: LocalStoreItem<AppConfigBean, string>
}

// noinspection JSMethodCanBeStatic
export class AppConfigModule implements Module<AppConfigState, AppState> {
  namespaced = true
  state = {}
  actions = {
    refreshConfig: this.refreshConfigAction.bind(this),
    getConfig: this.getConfigAction.bind(this),
    clearAppConfigModule: this.clearAppConfigModuleAction.bind(this)
  }
  mutations = {
    setConfigStoreItem: (state, configStoreItem) => state.configStoreItem = configStoreItem
  }
  
  private readonly appConfigStore: LocalStore<AppConfigBean, string>
  
  constructor(
    readonly appConfigApi: AppConfigApi
  ) {
    this.appConfigStore = new LocalStore<AppConfigBean, string>({
      localStorageKey: CONFIG_STORAGE_KEY,
      schema: appConfigSchema
    })
    
    Object.assign(this.state, this.reloadOrCreateState())
  }
  
  private reloadOrCreateState(): AppConfigState {
    return {
      configStoreItem: this.appConfigStore.getStoreItem(CONFIG_STORE_KEY)
    }
  }
  
  private async refreshConfigAction(
    context: ActionContext<AppConfigState, AppState>
  ): Promise<void> {
    const configStoreItem = context.state.configStoreItem
    if (!this.shouldRefreshConfig(configStoreItem)) {
      console.log('No need to refresh application configuration.')
      return
    }
    
    console.log('Application configuration is missing or expired. Refreshing.')
    const operation = getItemOperation(
      this.appConfigStore,
      () => this.appConfigApi.getConfig()
    )
    await operation.get({
      params: CONFIG_STORE_KEY,
      bypassStore: true,
      expirationInMs: CONFIG_EXPIRATION_TIME_MS
    })
    
    console.log('Application configuration has been refreshed.')
    context.commit('setConfigStoreItem', this.appConfigStore.getStoreItem(CONFIG_STORE_KEY))
  }
  
  private shouldRefreshConfig(configStoreItem: LocalStoreItem<AppConfigBean, string> | undefined) {
    if (configStoreItem === undefined) {
      return true
    }
    return configStoreItem.isExpired(CONFIG_EXPIRATION_TIME_MS)
  }
  
  private async getConfigAction(
    context: ActionContext<AppConfigState, AppState>
  ): Promise<AppConfigBean> {
    let config: AppConfigBean = _.cloneDeep(DEFAULT_CONFIG)
    
    const storedConfig = context.state.configStoreItem?.item
    if (storedConfig != null) {
      config = deepmerge(config, storedConfig, {
        clone: false
      })
    }
    
    return config
  }
  
  clearAppConfigModuleAction(
    context: ActionContext<AppConfigState, AppState>
  ) {
    context.commit('setConfigStoreItem', undefined)
    this.appConfigStore.clear()
  }
}
