import {Flow, FlowStep} from '@/service/flow/Flow'
import {RouteLocationRaw} from 'vue-router'
import {SiteManagerExpressOrderRoute} from '@/router/sitemanager'
import {
  QuotationRequestTemporarySiteBean,
  QuotationRequestValidationResultBean
} from '@/service/api/sitemanager/QuotationRequestApi'
import {isAddressNotBlank} from '@/utils/GeoFormatters'

export interface ExpressOrderFlowState {
  siteId?: string
  useTemporarySite: boolean
  temporarySite?: ExpressFlowTemporarySite
  materialId?: string
  materialType?: string
  materialQuantity?: number
  backloadMaterialId?: string
  backloadMaterialQuantity?: number
  responseId?: string
  result?: QuotationRequestValidationResultBean
}

export interface ExpressFlowTemporarySite extends Partial<QuotationRequestTemporarySiteBean> {
  autoSelectedCustomer: boolean
}

export const ExpressOrderFlowSiteStep: FlowStep<null, ExpressOrderFlowState> = {
  routeName: SiteManagerExpressOrderRoute.SITE,
  isCompleted: state => state.siteId !== undefined
}

export const ExpressOrderFlowCustomerStep: FlowStep<null, ExpressOrderFlowState> = {
  routeName: SiteManagerExpressOrderRoute.CUSTOMER,
  canEnter: (item, state) => state.useTemporarySite && !state.temporarySite?.autoSelectedCustomer,
  isCompleted: state => state.temporarySite?.customer_id !== undefined
}

export const ExpressOrderTemporarySiteStep: FlowStep<null, ExpressOrderFlowState> = {
  routeName: SiteManagerExpressOrderRoute.TEMPORARY_SITE,
  canEnter: (item, state) => state.useTemporarySite,
  isCompleted: state => {
    const site = state.temporarySite
    if (site === undefined) {
      return false
    } else if (site.location !== undefined) {
      return true
    } else {
      return isAddressNotBlank(site)
    }
  }
}

export const ExpressOrderFlowMaterialStep: FlowStep<null, ExpressOrderFlowState> = {
  routeName: SiteManagerExpressOrderRoute.MATERIAL,
  isCompleted: state => state.materialId !== undefined && state.materialType !== undefined
}

export const ExpressOrderFlowQuantityStep: FlowStep<null, ExpressOrderFlowState> = {
  routeName: SiteManagerExpressOrderRoute.QUANTITY,
  isCompleted: state => state.materialQuantity !== undefined
}

export const ExpressOrderFlowResponsesStep: FlowStep<null, ExpressOrderFlowState> = {
  routeName: SiteManagerExpressOrderRoute.RESPONSES,
  isCompleted: state => state.responseId !== undefined
}

export const ExpressOrderFlowConfirmStep: FlowStep<null, ExpressOrderFlowState> = {
  routeName: SiteManagerExpressOrderRoute.CONFIRM
}

export class ExpressOrderFlow extends Flow<null, null, ExpressOrderFlowState> {
  
  constructor(
    private readonly initialRoute?: RouteLocationRaw
  ) {
    super(null, null, {
      useTemporarySite: false
    })
  }
  
  getOutputRoute(): RouteLocationRaw {
    if (this.initialRoute !== undefined) {
      return this.initialRoute
    } else {
      return {
        name: 'SiteManagerOrders'
      }
    }
  }
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getCancelRoute(): RouteLocationRaw {
    if (this.initialRoute !== undefined) {
      return this.initialRoute
    } else {
      return {
        name: 'SiteManagerOrders'
      }
    }
  }
  
  getSteps(): Array<FlowStep<null, ExpressOrderFlowState>> {
    return [
      ExpressOrderFlowSiteStep,
      ExpressOrderFlowCustomerStep,
      ExpressOrderTemporarySiteStep,
      ExpressOrderFlowMaterialStep,
      ExpressOrderFlowQuantityStep,
      ExpressOrderFlowResponsesStep,
      ExpressOrderFlowConfirmStep
    ]
  }
}
