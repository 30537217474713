import {DeliveryStatus, SynchronizedDeliveryNoteBean} from '@/service/model/DeliveryNoteBean'
import {PendingSync} from '@/service/SynchronizationService'
import {ConfirmLoadBean, ConfirmUnloadBean} from '@/service/api/driver/DriverDeliveryNoteApi'
import moment from 'moment/moment'

export class DriverDeliveryNoteSyncService {
  
  applyPendingChangesToDeliveryNote(
    dn: SynchronizedDeliveryNoteBean,
    pendingSync: PendingSync<any>
  ) {
    switch (pendingSync.type) {
    case 'COMMENT':
      this.applyCommentToDeliveryNote(dn, pendingSync.content)
      return
    case 'LOAD_MATERIAL':
    case 'LOAD_WASTE':
      this.applyPendingLoadToDeliveryNote(dn, pendingSync.content)
      return
    case 'UNLOAD_MATERIAL':
    case 'UNLOAD_WASTE':
      this.applyPendingUnloadToDeliveryNote(dn, pendingSync.content)
    }
  }
  
  private applyCommentToDeliveryNote(
    dn: SynchronizedDeliveryNoteBean,
    bean: {comment: string}
  ) {
    dn.driver_comment = bean.comment
    dn.waiting_sync_fields?.push('driver_comment')
  }
  
  private applyPendingLoadToDeliveryNote(
    dn: SynchronizedDeliveryNoteBean,
    bean: ConfirmLoadBean
  ) {
    dn.status = DeliveryStatus.IN_DELIVERY
    dn.load_date = moment(bean.date).utc().format(moment.HTML5_FMT.DATETIME_LOCAL)
    dn.waiting_sync_fields?.push('status')
    
    if (bean.net_weight !== undefined) {
      dn.waiting_sync_fields?.push('net_weight')
      dn.net_weight = bean.net_weight
    }
    if (bean.truck_registration !== undefined) {
      dn.truck_registration = bean.truck_registration
      dn.waiting_sync_fields?.push('truck_registration')
    }
  }
  
  private applyPendingUnloadToDeliveryNote(
    dn: SynchronizedDeliveryNoteBean,
    bean: ConfirmUnloadBean
  ) {
    dn.status = DeliveryStatus.DELIVERED
    dn.unload_date = moment(bean.date).utc().format(moment.HTML5_FMT.DATETIME_LOCAL)
    dn.waiting_sync_fields?.push('status')
    
    if (bean.comment !== undefined) {
      dn.driver_comment = bean.comment
      dn.waiting_sync_fields?.push('driver_comment')
    }
  }
}
