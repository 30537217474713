import {ActionContext, Module} from 'vuex'
import {AppState} from '@/stores'
import {Geolocation} from '@capacitor/geolocation'
import {LocationBean} from '@/service/model/LocationBean'
import {I18n} from 'vue-i18n'
import {Exception, makeException} from '@/exception/Exception'
import {Capacitor} from '@capacitor/core'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LocationState {

}

export interface GetCurrentLocationOptions {
  enableHighAccuracy?: boolean
  ignoreError?: boolean
}

export class LocationModule implements Module<LocationState, AppState> {
  namespaced = true
  state = {}
  mutations = {}
  getters = {
    locationDisabledError: this.getLocationDisabledError.bind(this)
  }
  actions = {
    getCurrentLocation: this.getCurrentLocationAction.bind(this),
    convertErrorToException: this.convertErrorToExceptionAction.bind(this)
  }
  private i18n: I18n
  
  constructor(i18n: I18n) {
    this.i18n = i18n
  }
  
  async getCurrentLocationAction(
    context: ActionContext<LocationState, AppState>,
    options?: GetCurrentLocationOptions
  ): Promise<LocationBean | undefined> {
    const enableHighAccuracy = options?.enableHighAccuracy || false
    let ignoreError = true
    if (options?.ignoreError !== undefined) {
      ignoreError = options.ignoreError
    }
    try {
      const position = await Geolocation.getCurrentPosition({
        enableHighAccuracy: enableHighAccuracy,
        timeout: 2000
      })
      return {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      }
    } catch (error) {
      if (!ignoreError) {
        throw this.convertErrorToExceptionAction(context, error)
      }
      return undefined
    }
  }
  
  convertErrorToExceptionAction(
    context: ActionContext<LocationState, AppState>,
    error: any
  ) {
    if (Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios') {
      return this.convertNativeErrorToException(error)
    } else {
      return this.convertWebErrorToException(context, error)
    }
  }
  
  convertNativeErrorToException(error: any): Exception | undefined {
    if (
      error.message === 'Location services are not enabled' // Android & iOS
      || error.message === 'location disabled' // Android
    ) {
      return this.getLocationDisabledError()
    } else if (error.message === 'location unavailable') {
      return makeException(this.i18n, 'error.location.unknown')
    } else {
      return makeException(this.i18n, 'error.location.unknown')
    }
  }
  
  convertWebErrorToException(
    context: ActionContext<LocationState, AppState>,
    error: any
  ): Exception | undefined {
    if (error instanceof GeolocationPositionError) {
      switch (error.code) {
      case GeolocationPositionError.POSITION_UNAVAILABLE:
      case GeolocationPositionError.TIMEOUT:
        return makeException(this.i18n, 'error.location.unknown')
      case GeolocationPositionError.PERMISSION_DENIED:
        return context.rootGetters['permission/locationPermissionDeniedError']
      }
    } else {
      return makeException(this.i18n, 'error.location.unknown')
    }
  }
  
  getLocationDisabledError(): Exception {
    return makeException(this.i18n, 'error.location.disabled')
  }
}
