import {Capacitor} from '@capacitor/core'

export const webAppUrl = process.env.VUE_APP_WEBAPP_URL
export const isWebApp = (process.env.VUE_APP_WEBAPP === 'true')

export function getWebAppUrl(): string | undefined {
  if (!webAppUrl) {
    return undefined
  }
  return (webAppUrl as unknown) as string
}

export function getOperatingSystemFromUserAgent(): 'android' | 'ios' | 'unknown' {
  const userAgent = navigator.userAgent || navigator.vendor || window['opera']
  if (/android/i.test(userAgent)) {
    return 'android'
  }
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'ios'
  }
  return 'unknown'
}

export function getOperatingSystem(): 'android' | 'ios' | 'unknown' {
  const platform = Capacitor.getPlatform()
  switch (platform) {
  case 'ios':
  case 'android':
    return platform
  default:
    return getOperatingSystemFromUserAgent()
  }
}
