import moment from 'moment'

export class LocalStoreItem<T, ID> {
  id: ID
  item: T | null
  updateDate: number
  expirationDate: number | null
  
  constructor(
    id: ID,
    item: T | null,
    updateDate: number,
    expirationDate: number | null
  ) {
    if (expirationDate && expirationDate < updateDate) {
      throw new Error('Expiration must not be before update date.')
    }
    
    this.id = id
    this.item = item
    this.updateDate = updateDate
    this.expirationDate = expirationDate
  }
  
  isExpired(
    overrideExpirationInMs?: number
  ): boolean {
    const now = moment().valueOf()
    if (this.expirationDate && this.expirationDate < now) {
      return true
    }
    if (overrideExpirationInMs) {
      return this.updateDate + overrideExpirationInMs < now
    }
    return false
  }
}
