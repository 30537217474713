import {DeliveryStatus, SynchronizedDeliveryNoteBean} from '@/service/model/DeliveryNoteBean'
import {PendingSync} from '@/service/SynchronizationService'
import {
  ConfirmLoadBean,
  ConfirmUnloadBean,
  ConfirmUnloadWithPictureBean
} from '@/service/api/sitemanager/SiteManagerDeliveryNoteApi'
import moment from 'moment'

export class SiteManagerDeliveryNoteSyncService {
  
  applyPendingChangesToDeliveryNote(
    dn: SynchronizedDeliveryNoteBean,
    pendingSync: PendingSync<any>
  ) {
    switch (pendingSync.type) {
    case 'COMMENT':
      this.applyCommentToDeliveryNote(dn, pendingSync.content)
      return
    case 'LOAD_MATERIAL':
    case 'LOAD_WASTE':
      this.applyPendingLoadToDeliveryNote(dn, pendingSync.content)
      return
    case 'UNLOAD_MATERIAL':
    case 'UNLOAD_WASTE':
    case 'UNLOAD_MATERIAL_CUSTOMER':
    case 'UNLOAD_WASTE_CUSTOMER':
      this.applyPendingUnloadToDeliveryNote(dn, pendingSync.content)
    }
  }
  
  private applyCommentToDeliveryNote(
    dn: SynchronizedDeliveryNoteBean,
    bean: {comment: string}
  ) {
    dn.site_manager_comment = bean.comment
    dn.waiting_sync_fields?.push('site_manager_comment')
  }
  
  private applyPendingLoadToDeliveryNote(
    dn: SynchronizedDeliveryNoteBean,
    bean: ConfirmLoadBean
  ) {
    dn.status = DeliveryStatus.IN_DELIVERY
    if (bean.day != null) {
      dn.load_date = moment(bean.day, moment.HTML5_FMT.DATE).format(moment.HTML5_FMT.DATETIME_LOCAL)
    } else {
      dn.load_date = moment(bean.date).utc().format(moment.HTML5_FMT.DATETIME_LOCAL)
    }
    dn.waiting_sync_fields?.push('status', 'load_date')
    
    if (bean.truck_registration !== undefined) {
      dn.truck_registration = bean.truck_registration
      dn.waiting_sync_fields?.push('truck_registration')
    }
    if (bean.comment !== undefined) {
      dn.site_manager_comment = bean.comment
      dn.waiting_sync_fields?.push('site_manager_comment')
    }
  }
  
  private applyPendingUnloadToDeliveryNote(
    dn: SynchronizedDeliveryNoteBean,
    bean: ConfirmUnloadBean | ConfirmUnloadWithPictureBean
  ) {
    dn.status = DeliveryStatus.DELIVERED
    if (bean.day != null) {
      dn.unload_date = moment(bean.day, moment.HTML5_FMT.DATE).format(moment.HTML5_FMT.DATETIME_LOCAL)
    } else {
      dn.unload_date = moment(bean.date).utc().format(moment.HTML5_FMT.DATETIME_LOCAL)
    }
    dn.waiting_sync_fields?.push('status', 'unload_date')
    
    if ('net_weight' in bean && bean.net_weight !== undefined) {
      dn.net_weight = bean.net_weight
      dn.waiting_sync_fields?.push('truck_registration')
    }
    if ('truck_registration' in bean && bean.truck_registration !== undefined) {
      dn.truck_registration = bean.truck_registration
      dn.waiting_sync_fields?.push('truck_registration')
    }
    if ('comment' in bean && bean.comment !== undefined) {
      dn.site_manager_comment = bean.comment
      dn.waiting_sync_fields?.push('site_manager_comment')
    }
  }
}
