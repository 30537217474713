import {Subscription} from '@/service/subscription/Subscription'
import {PluginListenerHandle} from '@capacitor/core'

export class PluginListenerHandleSubscription implements Subscription {
  
  constructor(
    private readonly handles: Array<Promise<PluginListenerHandle> & PluginListenerHandle>
  ) {
  }
  
  unregister() {
    for (const handle of this.handles) {
      // noinspection JSIgnoredPromiseFromCall
      handle.remove()
    }
  }
}
