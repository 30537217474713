export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GPS is disabled."])},
      "alert": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable your GPS position"])},
        "message": {
          "siteManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enable your GPS location to unlock all app features. Your position will be shared exclusively with Rockease when creating a temporary site or completing a delivery note."])},
          "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enable your GPS location to unlock all app features. Your position will be shared exclusively with Rockease when completing a delivery note."])}
        }
      }
    }
  })
}
