import {I18n, NamedValue} from 'vue-i18n'
import {translateMessage} from '@/i18n'

type ResolveFunction = () => Promise<void> | void

export class Exception {
  message?: string
  resolve?: ResolveFunction

  constructor(message?: string, resolve?: ResolveFunction) {
    this.message = message
    this.resolve = resolve
  }
}

export function makeException(i18n: I18n, messageKey: string, values?: NamedValue, resolve?: ResolveFunction): Exception {
  return new Exception(translateMessage(i18n, messageKey, values), resolve)
}

export function getErrorMessage(t: any, exception: any): string | undefined {
  if (exception instanceof Exception) {
    return exception.message || t('error.unknown')
  } else {
    console.error(exception)
    return t('error.unknown')
  }
}
