import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_error_message_item = _resolveComponent("error-message-item")!
  const _component_site_manager_truck_list_item = _resolveComponent("site-manager-truck-list-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_page = _resolveComponent("page")!

  return (_openBlock(), _createBlock(_component_page, {
    closeable: "",
    container: "scrollable",
    modal: "",
    "uses-cards": "",
    "is-loading": _ctx.isLoading && !_ctx.isRefreshing,
    title: _ctx.t('title')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_refresher, {
        slot: "fixed",
        onIonRefresh: _ctx.onRefresh
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_refresher_content)
        ]),
        _: 1
      }, 8, ["onIonRefresh"]),
      _createVNode(_component_error_message_item, {
        "error-message": _ctx.errorMessage,
        onRetry: _ctx.onRetry
      }, null, 8, ["error-message", "onRetry"]),
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_ion_list, {
            key: 0,
            class: "list-with-cards"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (item) => {
                return _createVNode(_component_site_manager_truck_list_item, {
                  key: item,
                  loading: ""
                })
              }), 64))
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_ion_list, {
            key: 1,
            class: "list-with-cards"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trucks, (truck) => {
                return (_openBlock(), _createBlock(_component_site_manager_truck_list_item, {
                  key: truck.truck_registration,
                  truck: truck,
                  onSelect: _ctx.onTruckSelected
                }, null, 8, ["truck", "onSelect"]))
              }), 128))
            ]),
            _: 1
          }))
    ]),
    _: 1
  }, 8, ["is-loading", "title"]))
}