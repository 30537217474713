import {ActionContext, Module} from 'vuex'
import {AppState} from '@/stores'
import Result from '@/service/operation/Result'
import {SiteBean} from '@/service/model/SiteBean'
import {LocalStore} from '@/service/store/LocalStore'
import {DeliveryNoteState} from '@/stores/modules/DeliveryNoteModule'
import {getListOperation, GetOperation} from '@/service/operation/GetOperation'
import {SiteManagerCustomerBean} from '@/service/model/sitemanager/SiteManagerCustomerBean'
import {SiteManagerCustomerApi} from '@/service/api/sitemanager/SiteManagerCustomerApi'
import {makeException} from '@/exception/Exception'
import {I18n} from 'vue-i18n'
import {GetOptions} from '@/service/operation/GetAndTransformOperation'

const materialsStoreKey = 'materials'

export interface SiteManagerCustomerState {
  customersOperation: GetOperation<Array<SiteManagerCustomerBean>, string>
  customers?: Result<Array<SiteManagerCustomerBean>>
}

export class SiteManagerCustomerModule implements Module<SiteManagerCustomerState, AppState> {
  namespaced = true
  mutations = {
    setCustomers: (state, customers) => state.customers = customers,
    setCustomersOperation: (state, operation) => state.customersOperation = operation
  }
  getters = {
    customers: (state) => state.customers
  }
  actions = {
    fetchCustomers: this.fetchCustomersAction.bind(this),
    refreshCustomers: this.refreshCustomersAction.bind(this),
    clearCustomerModule: this.clearCustomerModuleAction.bind(this)
  }
  
  constructor(
    private readonly i18n: I18n,
    private readonly listStore: LocalStore<Array<string>, string>,
    private readonly customerStore: LocalStore<SiteManagerCustomerBean, string>,
    private readonly customerApi: SiteManagerCustomerApi
  ) {
  }
  
  private async fetchCustomersAction(
    context: ActionContext<DeliveryNoteState, AppState>,
    params: GetOptions<any>
  ): Promise<Array<SiteBean> | null> {
    const operation = getListOperation(
      this.customerStore,
      this.listStore,
      materialsStoreKey,
      it => it.id,
      () => this.customerApi.fetchCustomers()
    )
    
    context.commit('setCustomersOperation', operation)
    
    return await operation.get(
      params,
      it => {
        context.commit('setCustomers', it)
      }
    )
  }
  
  refreshCustomersAction(
    context: ActionContext<SiteManagerCustomerState, AppState>
  ) {
    const operation = context.state.customersOperation
    if (!operation) {
      throw makeException(this.i18n, 'error.unknown')
    }
    return operation.refresh(true)
  }
  
  clearCustomerModuleAction(
    context: ActionContext<SiteManagerCustomerState, AppState>
  ) {
    context.commit('setCustomers', undefined)
    context.commit('setCustomersOperation', undefined)
    this.customerStore.clear()
  }
}
