import {AbstractApi} from '@/service/api/AbstractApi'
import {I18n} from 'vue-i18n'
import {AuthenticationService} from '@/service/authentication/AuthenticationService'
import {driverArraySchema, DriverBean, driverSchema} from '@/service/model/DriverBean'

export interface SiteManagerDriverCreationBean {
  customer_id: string
  firstname?: string
  lastname?: string
  phone_number: string
}

export class SiteManagerDriverApi extends AbstractApi {
  
  constructor(i18n: I18n, authenticationService: AuthenticationService) {
    super(i18n, authenticationService)
  }
  
  listDrivers(): Promise<Array<DriverBean>> {
    return this.get<Array<DriverBean>>({
      path: '/api/mp/v1/site-manager/drivers',
      authentication: 'required',
      schema: driverArraySchema
    })
  }
  
  add(bean: SiteManagerDriverCreationBean): Promise<DriverBean> {
    return this.post<DriverBean>({
      path: '/api/mp/v1/site-manager/drivers',
      authentication: 'required',
      schema: driverSchema,
      body: bean
    })
  }
}
