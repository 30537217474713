import Ajv, {JSONSchemaType} from 'ajv'
import {LocalStoreItem} from '@/service/store/LocalStoreItem'
import moment from 'moment'
import _ from 'lodash'
import {Subscription} from '@/service/subscription/Subscription'

export interface StoreOptions<T> {
  localStorageKey: string
  schema?: JSONSchemaType<T>
}

export interface GetItemOptions {
  /**
   * Virtually override the expiration of the item to make it shorter for given use cases.
   *
   * This expiration will not cause the item to be removed from the underlying storage.
   */
  expirationInMs?: number
}

export type ItemChangedCallback<T, ID> = (id: ID, newItem: T | null) => void

class ItemChangedSubscription<T, ID> implements Subscription {
  
  constructor(
    private readonly store: LocalStore<T, ID>,
    readonly id: ID,
    readonly callback: ItemChangedCallback<T, ID>
  ) {
  }
  
  unregister() {
    this.store.unregisterForChanges(this)
  }
}

export class LocalStore<T, ID> {
  readonly ajv: Ajv
  private options: StoreOptions<T>
  private items: Map<ID, LocalStoreItem<T, ID>>
  private readonly itemChangedSubscriptions: Array<ItemChangedSubscription<T, ID>>
  
  constructor(options: StoreOptions<T>) {
    this.options = options
    this.ajv = new Ajv()
    this.items = this.reloadItemsFromLocalStorage()
    this.itemChangedSubscriptions = []
  }
  
  getStoreItem(id: ID, options?: GetItemOptions): LocalStoreItem<T, ID> | undefined {
    const item = this.items.get(id)
    if (!item) {
      return undefined
    }
    if (item.isExpired(options?.expirationInMs)) {
      return undefined
    }
    return item
  }
  
  getItem(id: ID, options?: GetItemOptions): T | null | undefined {
    return this.getStoreItem(id, options)?.item
  }
  
  registerForChanges(id: ID, callback: ItemChangedCallback<T, ID>): Subscription {
    const subscription = new ItemChangedSubscription<T, ID>(this, id, callback)
    this.itemChangedSubscriptions.push(subscription)
    return subscription
  }
  
  unregisterForChanges(subscription: ItemChangedSubscription<T, ID>) {
    _.remove(this.itemChangedSubscriptions, it => it === subscription)
  }
  
  saveItem(
    id: ID,
    item: T | null,
    expirationDate?: number
  ): LocalStoreItem<T, ID> {
    const updateDate = moment().valueOf()
    const storeItem = new LocalStoreItem(
      id,
      item,
      updateDate,
      expirationDate || null
    )
    this.items.set(storeItem.id, storeItem)
    
    this.notifyCallbacksForItemChanged(id, item)
    
    this.saveItemsInLocalStorage()
    return storeItem
  }
  
  private notifyCallbacksForItemChanged(id: ID, item: T | null) {
    const subscriptions = _.filter(this.itemChangedSubscriptions, it => it.id === id)
    for (const subscription of subscriptions) {
      subscription.callback(id, item)
    }
  }
  
  deleteItem(id: ID) {
    if (this.items.delete(id)) {
      this.saveItemsInLocalStorage()
    }
  }
  
  clear() {
    this.items = new Map<ID, LocalStoreItem<T, ID>>()
    localStorage.removeItem(this.options.localStorageKey)
  }
  
  reloadItemsFromLocalStorage(): Map<ID, LocalStoreItem<T, ID>> {
    const itemMap = new Map<ID, LocalStoreItem<T, ID>>()
    
    const jsonItems = localStorage.getItem(this.options.localStorageKey)
    if (!jsonItems) {
      return itemMap
    }
    
    let rawItems: any[]
    try {
      rawItems = JSON.parse(jsonItems)
    } catch (error) {
      console.error('Failed to reload stored items for %o: %o', this.options.localStorageKey, error)
      rawItems = []
    }
    for (const rawItem of rawItems) {
      const storeItem = this.convertToStoreItem(rawItem)
      if (storeItem && !storeItem.isExpired()) {
        itemMap.set(storeItem.id, storeItem)
      }
    }
    return itemMap
  }
  
  convertToStoreItem(rawStoreItem: any): LocalStoreItem<T, ID> | undefined {
    const id = rawStoreItem.id
    const item = rawStoreItem.item
    const updateDate = rawStoreItem.updateDate
    const expirationDate = rawStoreItem.expirationDate
    if (!id || !updateDate) {
      return undefined
    }
    return new LocalStoreItem<T, ID>(id, item, updateDate, expirationDate)
  }
  
  private saveItemsInLocalStorage() {
    const rawItems: LocalStoreItem<T, ID>[] = []
    for (const item of this.items.values()) {
      rawItems.push(item)
    }
    const jsonItems = JSON.stringify(rawItems)
    localStorage.setItem(this.options.localStorageKey, jsonItems)
  }
}
