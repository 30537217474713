import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item_divider = _resolveComponent("ion-item-divider")!

  return (_openBlock(), _createBlock(_component_ion_item_divider, { color: "light" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_label, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.label), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}