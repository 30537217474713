import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-834b98e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasHeaderSlot)
      ? (_openBlock(), _createElementBlock("header", {
          key: 0,
          class: _normalizeClass([_ctx.headerClass])
        }, [
          _renderSlot(_ctx.$slots, "header", {}, undefined, true)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("main", {
      class: _normalizeClass([_ctx.mainClass])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2),
    _createElementVNode("footer", {
      class: _normalizeClass([_ctx.footerClass])
    }, [
      _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
    ], 2)
  ]))
}