import {JSONSchemaType} from 'ajv'

export interface SiteManagerCustomerBean {
  id: string
  name: string
  company_name?: string | null
}

export const siteManagerCustomerSchema: JSONSchemaType<SiteManagerCustomerBean> = {
  type: 'object',
  properties: {
    id: {
      type: ['string']
    },
    name: {
      type: ['string']
    },
    company_name: {
      type: ['string'],
      nullable: true
    },
  },
  required: ['id', 'name'],
  additionalProperties: true
}

export const siteManagerCustomerArraySchema: JSONSchemaType<Array<SiteManagerCustomerBean>> = {
  type: 'array',
  items: siteManagerCustomerSchema
}
