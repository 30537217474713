import {Capacitor} from '@capacitor/core'
import {makeApiException} from '@/exception/ApiException'
import {I18n} from 'vue-i18n'
import {ErrorReportingService} from '@/service/ErrorReportingService'
import {
  AcquireTokenParams,
  AuthPlatform,
  PlatformAuthenticationService
} from '@/service/authentication/PlatformAuthenticationService'
import {makeException} from '@/exception/Exception'
import Ajv, {JSONSchemaType} from 'ajv'
import moment from 'moment'

type LoginType = 'by-phone' | 'by-mail'

const AUTH_PLATFORM_KEY = 'msal-auth-platform'
const LAST_AUTH_PARAMS_KEY = 'msal-last-auth-params'

// This authority must be present in the MSAL configuration.
export const B2C_CLIENT_ID = process.env.VUE_APP_B2C_CLIENT_ID
export const B2C_AUTHORITY = process.env.VUE_APP_B2C_AUTHORITY_URL
export const B2C_BY_PHONE_POLICY = process.env.VUE_APP_B2C_BY_PHONE_POLICY
export const B2C_BY_EMAIL_POLICY = process.env.VUE_APP_B2C_BY_EMAIL_POLICY
export const B2C_SCOPE = process.env.VUE_APP_B2C_SCOPE

export interface AuthenticationParams {
  loginType: LoginType
  clientId: string
  authority: string
  scope: string
  customPolicy: string
}

const authenticationParamsSchema: JSONSchemaType<AuthenticationParams> = {
  type: 'object',
  properties: {
    loginType: {
      type: ['string']
    },
    clientId: {
      type: ['string']
    },
    authority: {
      type: ['string']
    },
    scope: {
      type: ['string']
    },
    customPolicy: {
      type: ['string']
    }
  },
  required: ['loginType', 'clientId', 'authority', 'scope', 'customPolicy']
}

export interface AuthenticationToken {
  token: string
  expiresOn: number | undefined // Expiration as a Unix timestamp (in milliseconds) in UTC timezone.
}

/**
 * Service in charge of routing the authentication between the web and the native implementation:
 * - web implementation is used for local development in the browser.
 * - everywhere else the native implementation is used.
 *
 * The user is considered connected if we have a token in the local storage even if it is expired.
 * We assume we will be able to refresh it using the Msal library.
 */
export class AuthenticationService {
  private authPlatform?: AuthPlatform = undefined
  private lastAuthParams?: AuthenticationParams = undefined
  private token?: AuthenticationToken = undefined
  private siteManagerCode?: string
  
  private errorReportingService: ErrorReportingService
  private ajv = new Ajv()
  
  constructor(
    private readonly i18n: I18n,
    private readonly platformServices: Array<PlatformAuthenticationService>
  ) {
    const authPlatform = localStorage.getItem(AUTH_PLATFORM_KEY)
    if (authPlatform === 'web' || authPlatform === 'native') {
      this.authPlatform = authPlatform
    }
    this.lastAuthParams = this.loadLastAuthParamsFromLocalStorage()
  }
  
  inject(errorReportingService: ErrorReportingService) {
    this.errorReportingService = errorReportingService
    for (const platformService of this.platformServices) {
      platformService.inject(errorReportingService)
    }
  }
  
  private loadLastAuthParamsFromLocalStorage(): AuthenticationParams | undefined {
    const jsonLastAuthParams = localStorage.getItem(LAST_AUTH_PARAMS_KEY)
    if (jsonLastAuthParams !== null) {
      try {
        const lastAuthParams = JSON.parse(jsonLastAuthParams)
        if (this.ajv.validate(authenticationParamsSchema, lastAuthParams)) {
          return lastAuthParams
        }
      } catch (e) {
        localStorage.removeItem(LAST_AUTH_PARAMS_KEY)
      }
    }
    return undefined
  }
  
  private async setAuthenticationParams(params: AuthenticationParams | undefined) {
    if (!this.isSameParams(this.lastAuthParams, params)) {
      await this.logOut()
      this.lastAuthParams = params
      localStorage.setItem(LAST_AUTH_PARAMS_KEY, JSON.stringify(params))
    }
  }
  
  private isSameParams(p1?: AuthenticationParams, p2?: AuthenticationParams) {
    return p1?.loginType === p2?.loginType && p1?.authority === p2?.authority && p1?.clientId === p2?.clientId && p1?.scope === p2?.scope && p1?.customPolicy === p2?.customPolicy
  }
  
  private clearAuthenticationParams() {
    this.lastAuthParams = undefined
    localStorage.removeItem(LAST_AUTH_PARAMS_KEY)
  }
  
  async useCapacitorPlatform(): Promise<AuthPlatform> {
    if (Capacitor.getPlatform() === 'web') {
      await this.setAuthPlatform('web')
      return 'web'
    } else {
      await this.setAuthPlatform('native')
      return 'native'
    }
  }
  
  async setAuthPlatform(platform: AuthPlatform | undefined) {
    if (this.authPlatform !== platform) {
      // Reset stored authentication params since we are changing platform.
      await this.setAuthenticationParams(undefined)
      
      this.authPlatform = platform
      if (platform !== undefined) {
        localStorage.setItem(AUTH_PLATFORM_KEY, platform)
      } else {
        localStorage.removeItem(AUTH_PLATFORM_KEY)
      }
    }
  }
  
  setSiteManagerCode(siteManagerCode: string | undefined) {
    this.siteManagerCode = siteManagerCode
  }
  
  hasSiteManagerCode(): boolean {
    return this.siteManagerCode !== undefined
  }
  
  private getPlatformService(): PlatformAuthenticationService | undefined {
    if (this.hasSiteManagerCode()) {
      return undefined
    }
    return this.platformServices.find(it => it.getPlatform() === this.authPlatform)
  }
  
  async isLoggedIn(): Promise<boolean> {
    // If acquireToken has never been called.
    const platformService = this.getPlatformService()
    if (platformService === undefined || this.lastAuthParams === undefined) {
      return false
    }
    // If we have a valid token in memory.
    if (this.token !== undefined && this.isNotExpired(this.token)) {
      return true
    }
    // Otherwise we use Msal to refresh the token.
    try {
      this.token = await platformService.acquireTokenSilent({
        clientId: this.lastAuthParams.clientId,
        authority: this.lastAuthParams.authority,
        scope: this.lastAuthParams.scope,
        customPolicy: this.lastAuthParams.customPolicy
      })
      return this.token?.token !== undefined
    } catch (e) {
      return false
    }
  }
  
  async loginWithPhoneNumber(): Promise<boolean> {
    return await this.loginWith('by-phone')
  }
  
  async loginWithEmail(): Promise<boolean> {
    return await this.loginWith('by-mail')
  }
  
  private async loginWith(loginType: LoginType): Promise<boolean> {
    const platformService = this.getPlatformService()
    if (platformService === undefined) {
      const message = 'No platform selected to perform login.'
      console.error(message)
      this.errorReportingService.report('loginWithPhoneNumber', message)
      throw makeException(this.i18n, 'auth.unknown')
    }
    const params: AcquireTokenParams = {
      clientId: B2C_CLIENT_ID || '',
      authority: B2C_AUTHORITY || '',
      scope: B2C_SCOPE || '',
      customPolicy: this.getCustomPolicy(loginType)
    }
    await this.setAuthenticationParams({
      loginType: loginType,
      ...params
    })
    try {
      this.token = await platformService.acquireToken(params)
      return true
    } catch (e) {
      if (!platformService.isCanceled(e)) {
        console.error(`Failed to login with login type ${loginType}.`, e)
        this.errorReportingService.report('loginWithPhoneNumber', e)
        throw makeException(this.i18n, 'auth.unknown')
      } else {
        return false
      }
    }
  }
  
  private getCustomPolicy(loginType: LoginType): string {
    switch (loginType) {
    case 'by-phone':
      return B2C_BY_PHONE_POLICY || ''
    case 'by-mail':
      return B2C_BY_EMAIL_POLICY || ''
    }
  }
  
  async setAuthenticationHeaders(headers: Headers): Promise<void> {
    if (this.siteManagerCode) {
      headers.set('Authorization', `SMC ${this.siteManagerCode}`)
    } else {
      const authorization = await this.getAuthorization()
      headers.set('Authorization', `Bearer ${authorization}`)
    }
  }
  
  async getAuthorization(): Promise<string> {
    const platformService = this.getPlatformService()
    const loginType = this.lastAuthParams?.loginType
    if (platformService === undefined || loginType === undefined) {
      throw makeApiException(this.i18n, 'auth.requiresAuth')
    } else if (this.token?.token !== undefined && this.isNotExpired(this.token)) {
      return this.token.token
    } else if (this.lastAuthParams !== undefined) {
      this.token = await platformService.acquireTokenSilent({
        clientId: this.lastAuthParams.clientId,
        authority: this.lastAuthParams.authority,
        scope: this.lastAuthParams.scope,
        customPolicy: this.lastAuthParams.customPolicy
      })
      if (this.token?.token === undefined) {
        throw makeApiException(this.i18n, 'api.requiresAuth')
      }
      return this.token.token
    } else {
      throw makeApiException(this.i18n, 'api.requiresAuth')
    }
  }
  
  private isNotExpired(token: AuthenticationToken): boolean {
    return token.expiresOn === undefined || token.expiresOn > moment.utc().valueOf()
  }
  
  async logOut(): Promise<void> {
    const platformService = this.getPlatformService()
    if (platformService !== undefined && this.lastAuthParams !== undefined) {
      try {
        await platformService.signOut({
          clientId: this.lastAuthParams.clientId,
          authority: this.lastAuthParams.authority,
          scope: this.lastAuthParams.scope,
          customPolicy: this.lastAuthParams.customPolicy
        })
      } catch (e) {
        console.error('Failed to logout', e)
        this.errorReportingService.report('logOut', e)
      }
    }
    this.clearAuthenticationParams()
  }
}
