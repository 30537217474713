<template>
  <div class="header">
    <message-header v-if="message"/>
    <gps-permission-denied v-else-if="gpsPermissionDenied"/>

    <install-app/>
    <upgrade-app/>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {mapGetters} from 'vuex'
import InstallApp from '@/components/toolbar/InstallApp.vue'
import UpgradeApp from '@/components/toolbar/UpgradeApp.vue'
import GpsPermissionDenied from '@/components/toolbar/GpsPermissionDenied.vue'
import {GeoPermissionState} from '@/stores/modules/PermissionModule'
import MessageHeader from '@/components/MessageHeader.vue'

export default defineComponent({
  name: 'page-header',
  computed: {
    ...mapGetters('message', ['message']),
    ...mapGetters('permission', ['permissionStatuses']),
    gpsPermissionDenied(): boolean {
      if (this.permissionStatuses === undefined) {
        return false
      }
      const locationState: GeoPermissionState = this.permissionStatuses.locationState
      return locationState === 'prompt-with-rationale' || locationState === 'denied'
    }
  },
  components: {
    MessageHeader,
    GpsPermissionDenied,
    InstallApp, UpgradeApp
  }
})
</script>

<style scoped>

.header {
  z-index: 999;
}

</style>
