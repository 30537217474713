import {ActionContext, Module} from 'vuex'
import Result from '@/service/operation/Result'
import {SiteManagerOrderBean} from '@/service/model/sitemanager/SiteManagerOrderBean'
import {AppState} from '@/stores'
import {SiteManagerOrderApi} from '@/service/api/sitemanager/SiteManagerOrderApi'
import {LocalStore} from '@/service/store/LocalStore'
import {getItemOperation} from '@/service/operation/GetOperation'
import {PushNotificationEvent} from '@/service/model/PushNotificationEvent'
import {DeliveryNoteState} from '@/stores/modules/DeliveryNoteModule'
import {SiteManagerOrderModuleState} from '@/stores/modules/sitemanager/SiteManagerOrderModule'
import {
  OrderEventReceivedBean,
  orderEventReceivedSchema,
} from '@/service/model/OrderEventReceivedBean'
import {PushNotificationService} from '@/service/PushNotificationService'

const fetchPickupOrdersStoreKey = 'fetchPickupOrders'

interface OrderState {
  pickupOrders?: Result<Array<SiteManagerOrderBean>>
}

export class OrderModule implements Module<OrderState, AppState> {
  namespaced = true
  state = {}
  mutations = {
    setPickupOrders: (state, orders) => state.pickupOrders = orders
  }
  getters = {
    pickupOrders: (state) => state.pickupOrders
  }
  actions = {
    fetchPickupOrders: this.fetchPickupOrdersAction.bind(this),
    registerForPushNotification: this.registerForPushNotificationAction.bind(this),
    clearOrderModule: this.clearOrderModuleAction.bind(this)
  }
  private readonly orderListStore = new LocalStore<Array<SiteManagerOrderBean>, string>({
    localStorageKey: 'order-lists'
  })
  
  constructor(
    private readonly orderApi: SiteManagerOrderApi,
    private readonly pushNotificationService: PushNotificationService
  ) {
  }
  
  private async fetchPickupOrdersAction(
    context: ActionContext<OrderState, AppState>
  ): Promise<Array<SiteManagerOrderBean> | null> {
    const operation = getItemOperation<Array<SiteManagerOrderBean> | null, string>(
      this.orderListStore,
      () => this.orderApi.fetchPickupOrders()
    )
    return operation.get(
      {params: fetchPickupOrdersStoreKey},
      it => context.commit('setPickupOrders', it)
    )
  }
  
  private async clearOrderModuleAction(
    context: ActionContext<OrderState, AppState>
  ) {
    context.commit('setPickupOrders', undefined)
    this.orderListStore.clear()
  }
  private registerForPushNotificationAction(
    context: ActionContext<DeliveryNoteState, AppState>
  ) {
    if (context.state.pushNotificationSubscription) {
      return
    }
    const callback = this.onOrderReceivedNotification.bind(this, context)
    const subscription = this.pushNotificationService.registerForEventReceived({
      event: PushNotificationEvent.NEW_ORDER_RECEIVED,
      schema: orderEventReceivedSchema,
      callback
    })
    context.commit('setPushNotificationSubscription', subscription)
  }
  
  // noinspection JSUnusedLocalSymbols
  private onOrderReceivedNotification(
    context: ActionContext<SiteManagerOrderModuleState, AppState>,
    data: OrderEventReceivedBean
  ) {
    const operation = context.state.currentOrderOperation
    const result = context.state.currentOrderResult
    if (!operation || !result) {
      return
    }
    // We do not try to refresh if we do not have a response from the network.
    if (!result.itemFromNetwork) {
      return
    }
    // Only refresh if the selected delivery note is the one in the notification.
    if (result.itemFromNetwork.id !== data.order_id) {
      return
    }
    operation.refresh(false).catch(error => console.error('Failed to refresh order update after push notification.', error))
  }
  
}
