export default {
  "error": {
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unexpected error occurred."])},
    "permissionModalDenied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rockease requires displayed permissions to continue. Retry and click on 'Ok' to continue."])},
    "location": {
      "denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rockease requires access to your location."])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your GPS location is disabled. Enable your GPS location and retry."])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your GPS location is not available."])},
      "fix": {
        "android": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 - Go to your device Settings. <br/>2 - Tap 'Applications'. <br/>3 - Select 'Rockease' app in the list. <br/>4 - Tap on 'Authorizations' then tap on 'Locations' and select 'Authorize'."])},
        "ios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 - Go to your device Settings. <br/>2 - Select 'Rockease' app in the list. <br/>3 - Tap on 'Location'. <br/>4 - Select 'While Using the App' and enable 'Precise Location'."])}
      }
    }
  },
  "auth": {
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unexpected error occurred while authenticating. Please try to disconnect and retry."])},
    "msal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["An unexpected error occurred while authenticating (", _interpolate(_named("errorCode")), "). Please try to disconnect and retry."])},
    "noDeliveryNoteAssociatedToCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No delivery note associated to this code."])},
    "noDeliveryRequestAssociatedToCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No delivery request associated to this code."])}
  },
  "api": {
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unexpected error occurred while contacting the online application. Please retry later."])},
    "timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet connection is slow or unavailable. Please retry later."])},
    "requiresAuth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to be logged in to execute this operation."])},
    "missingPicturePath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing source for picture."])}
  },
  "sync": {
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronization failed. Please retry later once an internet connection is available."])},
    "failedIds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " note (", _interpolate(_named("id")), ") was changed before sync."]), _normalize([_interpolate(_named("count")), " notes (", _interpolate(_named("ids")), ") changed before sync."])])},
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unexpected error occurred while contacting the online application. Please retry later."])}
  },
  "confirm": {
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unexpected error occurred. Please go back to the delivery note details and try again."])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery note has been deleted."])},
    "alreadyDelivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery note has already been delivered."])},
    "cannotConfirmByCodeOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This delivery note cannot be confirmed using a code offline. Try again once an internet connection is available."])},
    "siteManager": {
      "pictureUploadFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload of the picture failed. Please retry later."])}
    },
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery note will be automatically synchronized once an internet connection is available."])}
  },
  "expressOrder": {
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unexpected error occurred. Please retry later."])}
  },
  "customerConfirm": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery note added !"])},
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unexpected error occurred. Please retry later."])}
  },
  "deliveryRequest": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request received !"])},
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unexpected error occurred. Please retry later."])},
    "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for informing us of the problem encountered. We will contact the supplier and get back to you as soon as possible."])},
    "disputeReasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason of dispute"])},
    "disputeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of the problem"])},
    "NON_HONORED_DELIVERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non honored delivery"])},
    "INCORRECT_QUANTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect quantity"])},
    "PRODUCT_NOT_CONFORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poor quality product"])},
    "TRANSPORTATION_ISSUES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transportation issues"])},
    "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
  },
  "addDriver": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver added !"])},
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unexpected error occurred. Please retry later."])}
  },
  "addTruck": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truck added !"])},
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unexpected error occurred. Please retry later."])}
  },
  "quotationRequest": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quotation request added !"])},
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unexpected error occurred. Please retry later."])}
  },
  "selectQuotationResponse": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order added !"])},
    "requiresValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This order requires to be confirmed by your site supervisor. A mail and a SMS have been sent automatically."])},
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unexpected error occurred. Please retry later."])}
  },
  "validation": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required."])},
    "notNumeric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Value must be a number between ", _interpolate(_named("min")), " and ", _interpolate(_named("max")), "."])},
    "belowMin": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Value must be greater than ", _interpolate(_named("max")), "."])},
    "aboveMax": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Value must be lower than ", _interpolate(_named("min")), "."])}
  },
  "category": {
    "material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materials"])},
    "concrete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concretes / Cements"])},
    "asphalt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asphalts"])},
    "waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wastes"])}
  },
  "truckType": {
    "semi_30t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semi 30T"])},
    "_8x4_19t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8x4 17-19T"])},
    "_6x4_12t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6X4 12T"])},
    "semi_11m3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mixer Semi 11m3"])},
    "_8x4_8m3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mixer 8x4 8m3"])},
    "_6x4_6m3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mixer 6x4 6m3"])},
    "_4x2_4m3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mixer 4x2 4m3"])}
  },
  "deliveryType": {
    "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Picked up"])},
    "quarryHauler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivered"])},
    "thirdPartyHauler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivered"])}
  },
  "cadencePeriodicity": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["day"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["48h"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["72h"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["week"])}
  },
  "title": {
    "deliveryNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Note"])},
    "newDeliveryNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Delivery Note"])},
    "deliveryNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Notes"])},
    "expressOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Express Order"])},
    "customerConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paper Delivery Note"])},
    "deliveryRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery request"])},
    "deliveryRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery requests"])},
    "addDriver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Driver"])},
    "addTruck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Truck"])},
    "quotationRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quotation request"])}
  },
  "button": {
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retry"])},
    "fix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fix"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip"])},
    "gpsDisabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GPS disabled"])},
    "deliveryNote": {
      "confirmLoad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Load"])},
      "confirmArrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Arrival"])},
      "confirmDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Delivery"])},
      "forceConfirmDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force Closure"])}
    }
  },
  "externalConfirmation": {
    "driver": {
      "material": {
        "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load has been confirmed by the quarry. Complete the steps to return to the delivery note."])},
        "unload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery has been confirmed by the site manager. Complete the steps to return to the delivery note."])}
      },
      "waste": {
        "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load has been confirmed by the site manager. Complete the steps to return to the delivery note."])},
        "unload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery has been confirmed by the quarry. Complete the steps to return to the delivery note."])}
      }
    },
    "siteManager": {
      "material": {
        "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load has been confirmed by the driver. Complete the steps to return to the delivery note."])},
        "unload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery has been confirmed by the driver. Complete the steps to return to the delivery note."])}
      },
      "waste": {
        "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load has been confirmed by the driver. Complete the steps to return to the delivery note."])},
        "unload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery has been confirmed by the driver. Complete the steps to return to the delivery note."])}
      }
    }
  },
  "format": {
    "percentage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percentage")), "%"])},
    "quantity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("quantity")), _interpolate(_named("unit"))])},
    "cadence": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cadence")), _interpolate(_named("unit")), "/", _interpolate(_named("period"))])},
    "fullDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dddd, MMMM Do, YYYY"])}
  },
  "pdf": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The document is being downloaded. Please wait."])}
  },
  "disputeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you report a problem, you will not be able to confirm further deliveries."])}
}