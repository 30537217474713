export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "message": {
        "android": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update the Rockease app from the Google Play store."])},
        "ios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update the Rockease app from the App Store."])}
      },
      "button": {
        "install": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install"])}
      }
    }
  })
}
