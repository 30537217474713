import {ActionContext, Module} from 'vuex'
import {AppState} from '@/stores'
import {SiteManagerQuotationRequestBean} from '@/service/model/sitemanager/SiteManagerQuotationRequestBean'
import {SiteManagerQuotationResponseBean} from '@/service/model/sitemanager/SiteManagerQuotationResponseBean'
import {makeException} from '@/exception/Exception'
import {I18n} from 'vue-i18n'
import {QuotationRequestApi, QuotationRequestValidationResultBean} from '@/service/api/sitemanager/QuotationRequestApi'
import {translateMessage} from '@/i18n'
import {LocalStore} from '@/service/store/LocalStore'
import {SiteManagerOrderBean} from '@/service/model/sitemanager/SiteManagerOrderBean'
import {SiteManagerQuotationResponseApi} from '@/service/api/sitemanager/SiteManagerQuotationResponseApi'

export interface SiteManagerSelectQuotationResponseModuleState {
  request?: SiteManagerQuotationRequestBean
  response?: SiteManagerQuotationResponseBean
}

export class SiteManagerSelectQuotationResponseModule implements Module<SiteManagerSelectQuotationResponseModuleState, AppState> {
  namespaced = true
  actions = {
    selectRequestAndResponse: this.selectRequestAndResponseAction.bind(this),
    downloadAndOpenTechSpec: this.downloadAndOpenTechSpecAction.bind(this),
    validateQuotationResponse: this.validateQuotationResponseAction.bind(this)
  }
  mutations = {
    setRequest: (state, request) => state.request = request,
    setResponse: (state, response) => state.response = response
  }
  getters = {
    request: (state) => state.request,
    response: (state) => state.response
  }
  
  constructor(
    private readonly i18n: I18n,
    private readonly orderStore: LocalStore<SiteManagerOrderBean, string>,
    private readonly quotationRequestApi: QuotationRequestApi,
    private readonly quotationResponseApi: SiteManagerQuotationResponseApi
  ) {
  }
  
  selectRequestAndResponseAction(
    context: ActionContext<SiteManagerSelectQuotationResponseModuleState, AppState>,
    params: { request: SiteManagerQuotationRequestBean; response: SiteManagerQuotationResponseBean }
  ) {
    context.commit('setRequest', params.request)
    context.commit('setResponse', params.response)
  }
  
  async downloadAndOpenTechSpecAction(
    context: ActionContext<SiteManagerSelectQuotationResponseModuleState, AppState>
  ) {
    const requestId = context.state.request?.id
    const responseId = context.state.response?.id
    if (requestId == undefined || responseId === undefined) {
      return
    }
    const downloadPdf = () => {
      return this.quotationResponseApi.getTechSpec(requestId, responseId)
    }
    await context.dispatch(
      'pdf/downloadAndOpenPdf',
      {
        directory: 'tech-specs',
        fileName: `${responseId}`,
        downloadPdf: downloadPdf
      },
      {root: true}
    )
  }
  
  async validateQuotationResponseAction(
    context: ActionContext<SiteManagerSelectQuotationResponseModuleState, AppState>
  ): Promise<QuotationRequestValidationResultBean> {
    const requestId = context.state.request?.id
    const responseId = context.state.response?.id
    if (!requestId || !responseId) {
      throw makeException(this.i18n, 'selectQuotationResponse.unknown')
    }
    
    const result = await this.quotationRequestApi.validateResponse(requestId, responseId)
    
    if (result.order != null) {
      this.orderStore.saveItem(result.order.id, result.order)
    }
    
    let message: string | undefined = undefined
    if (result.order_created) {
      message = translateMessage(this.i18n, 'selectQuotationResponse.success')
    } else if (result.requires_validation) {
      message = translateMessage(this.i18n, 'selectQuotationResponse.requiresValidation')
    }
    await context.dispatch(
      'message/setMessage',
      {message: message},
      {root: true}
    )
    
    return result
  }
}
