import {LocalStore} from '@/service/store/LocalStore'
import {Commit} from 'vuex'
import {
  FetchFromNetworkFunction,
  GetAndTransformOperation,
  GetOptions,
  getStoreKey,
  LoadFromCacheFunction,
  reloadListFromStores,
  SaveIntoCacheFunction,
  saveListIntoStores
} from '@/service/operation/GetAndTransformOperation'

export class GetOperation<T, Params> extends GetAndTransformOperation<T, T, Params> {
  
  constructor(
    fetchFromNetwork: FetchFromNetworkFunction<T, Params>,
    loadFromCache?: LoadFromCacheFunction<T, Params>,
    saveIntoCache?: SaveIntoCacheFunction<T, Params>
  ) {
    super(
      fetchFromNetwork,
      async (it) => it,
      loadFromCache,
      saveIntoCache
    )
  }
}

export function getItemOperation<T, ID>(
  store: LocalStore<T, ID>,
  fetchFromNetwork: FetchFromNetworkFunction<T, ID>
): GetOperation<T, ID> {
  return new GetOperation<T, ID>(
    id => fetchFromNetwork(id),
    (id, options) => store.getItem(id, options),
    (id, item) => store.saveItem(id, item)
  )
}

export function getListOperation<T, ID, Params>(
  store: LocalStore<T, ID>,
  listStore: LocalStore<ID[], string>,
  mutation: string,
  identifyItem: (T) => ID,
  fetchFromNetwork: FetchFromNetworkFunction<Array<T>, Params>
): GetOperation<Array<T>, Params> {
  return new GetOperation<Array<T>, Params>(
    params => fetchFromNetwork(params),
    params => reloadListFromStores(store, listStore, getStoreKey(mutation, params)),
    (params, items) => saveListIntoStores(store, listStore, getStoreKey(mutation, params), items, identifyItem)
  )
}

export function getListOperationAction<T, ID, Params>(
  store: LocalStore<T, ID>,
  listStore: LocalStore<ID[], string>,
  mutation: string,
  identifyItem: (T) => ID,
  fetchFromNetwork: FetchFromNetworkFunction<Array<T>, Params>
): (injectee: { commit: Commit }, options: GetOptions<Params>) => Promise<Array<T> | null> {
  const operation = new GetOperation<Array<T>, Params>(
    params => fetchFromNetwork(params),
    params => reloadListFromStores(store, listStore, getStoreKey(mutation, params)),
    (params, items) => saveListIntoStores(store, listStore, getStoreKey(mutation, params), items, identifyItem)
  )
  return function (injectee, options): Promise<Array<T> | null> {
    return operation.get(
      options,
      items => injectee.commit(mutation, items)
    )
  }
}
