import {NavigationGuardNext, RouteLocationNormalized, Router, RouteRecordRaw} from 'vue-router'
import {Store} from 'vuex'
import {AppState} from '@/stores'
import {IonRouter} from '@ionic/vue'

export const DeliveryNoteDetails = 'DeliveryNoteDetails'
export const DriverDeliveryNotesRoute = {
  UPCOMING: 'DriverUpcomingDeliveryNotes',
  PENDING: 'DriverPendingDeliveryNotes',
  DELIVERED: 'DriverDeliveredDeliveryNotes'
}
export const DeliveryNoteConfirmLoadRoute = {
  INITIAL: 'DeliveryNoteConfirmInitial',
  WEIGHT: 'DeliveryNoteConfirmWeight',
  WEIGHING_SLIP: 'DeliveryNoteTakeWeighingBill',
  LOAD: 'DeliveryNoteTakeLoad',
  TRUCK_REGISTRATION: 'DeliveryNoteConfirmTruckRegistration',
  CONFIRM_DEPARTURE: 'DeliveryNoteConfirmDeparture',
  SITE_MANAGER_CODE: 'DeliveryNoteConfirmSiteManagerCode',
  UNLOAD: 'DeliveryNoteTakeUnload',
  COMMENTS: 'DeliveryNoteUpdateComments'
}

export function makeDriverRoutes(
  store: Store<AppState>
): Array<RouteRecordRaw> {
  
  function checkHasLoadingDeliveryNoteBeforeEnter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    const deliveryNoteId = to.params.id
    const deliveryNote = store.getters['load/deliveryNote']
    if (!deliveryNote || deliveryNote.id !== deliveryNoteId) {
      next({
        name: DeliveryNoteConfirmLoadRoute.INITIAL,
        params: {id: to.params.id}
      })
    } else next()
  }
  
  const routes: Array<RouteRecordRaw> = [
    {
      path: '/driver',
      name: 'DriverHome',
      redirect: {name: 'DriverUpcomingDeliveryNotes'},
    },
    {
      path: '/driver/upcoming',
      name: DriverDeliveryNotesRoute.UPCOMING,
      component: () => import(/* webpackChunkName: "driver" */ '@/views/driver/DriverUpcomingDeliveryNoteListPage.vue'),
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/driver/pending',
      name: DriverDeliveryNotesRoute.PENDING,
      component: () => import(/* webpackChunkName: "driver" */ '@/views/driver/DriverPendingDeliveryNoteListPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/driver/delivered',
      name: DriverDeliveryNotesRoute.DELIVERED,
      component: () => import(/* webpackChunkName: "driver" */ '@/views/driver/DriverDeliveredDeliveryNoteListPage.vue'),
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/driver/delivery-notes/code',
      name: 'DeliveryNoteCode',
      component: () => import(/* webpackChunkName: "driver" */ '@/views/driver/DeliveryNoteCodePage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/driver/delivery-notes/:id',
      name: DeliveryNoteDetails,
      component: () => import(/* webpackChunkName: "driver" */ '@/views/driver/DeliveryNoteDetailsPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/driver/delivery-notes/:id/confirm',
      name: DeliveryNoteConfirmLoadRoute.INITIAL,
      component: () => import(/* webpackChunkName: "driver" */ '@/views/driver/confirm/DeliveryNoteConfirmInitialPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/driver/delivery-notes/:id/confirm-weight',
      name: DeliveryNoteConfirmLoadRoute.WEIGHT,
      component: () => import(/* webpackChunkName: "driver" */ '@/views/driver/confirm/DeliveryNoteConfirmWeightPage.vue'),
      beforeEnter: checkHasLoadingDeliveryNoteBeforeEnter,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/driver/delivery-notes/:id/take-weighing-slip',
      name: DeliveryNoteConfirmLoadRoute.WEIGHING_SLIP,
      component: () => import(/* webpackChunkName: "driver" */ '@/views/driver/confirm/DeliveryNoteTakeWeighingSlipPicturePage.vue'),
      beforeEnter: checkHasLoadingDeliveryNoteBeforeEnter,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/driver/delivery-notes/:id/take-load',
      name: DeliveryNoteConfirmLoadRoute.LOAD,
      component: () => import(/* webpackChunkName: "driver" */ '@/views/driver/confirm/DeliveryNoteTakeLoadPicturePage.vue'),
      beforeEnter: checkHasLoadingDeliveryNoteBeforeEnter,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/driver/delivery-notes/:id/confirm-truck-registration',
      name: DeliveryNoteConfirmLoadRoute.TRUCK_REGISTRATION,
      component: () => import(/* webpackChunkName: "driver" */ '@/views/driver/confirm/DeliveryNoteConfirmTruckRegistrationPage.vue'),
      beforeEnter: checkHasLoadingDeliveryNoteBeforeEnter,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/driver/delivery-notes/:id/confirm-departure',
      name: DeliveryNoteConfirmLoadRoute.CONFIRM_DEPARTURE,
      beforeEnter: checkHasLoadingDeliveryNoteBeforeEnter,
      component: () => import(/* webpackChunkName: "driver" */ '@/views/driver/confirm/DeliveryNoteConfirmDeparturePage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/driver/delivery-notes/:id/confirm-site-manager-code',
      name: DeliveryNoteConfirmLoadRoute.SITE_MANAGER_CODE,
      beforeEnter: checkHasLoadingDeliveryNoteBeforeEnter,
      component: () => import(/* webpackChunkName: "driver" */ '@/views/driver/confirm/DeliveryNoteConfirmSiteManagerCodePage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/driver/delivery-notes/:id/take-unload',
      name: DeliveryNoteConfirmLoadRoute.UNLOAD,
      component: () => import(/* webpackChunkName: "driver" */ '@/views/driver/confirm/DeliveryNoteTakeUnloadPicturePage.vue'),
      beforeEnter: checkHasLoadingDeliveryNoteBeforeEnter,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/driver/delivery-notes/:id/comments',
      name: DeliveryNoteConfirmLoadRoute.COMMENTS,
      component: () => import(/* webpackChunkName: "driver" */ '@/views/driver/DeliveryNoteUpdateCommentsPage.vue'),
      meta: {
        requiresAuth: true
      }
    }
  ]
  
  return routes
}

export async function goBackToDeliveryNoteDetails(
  ionRouter: IonRouter,
  router: Router,
  deliveryNoteId: string,
) {
  await router.replace({
    name: DeliveryNoteDetails,
    params: {id: deliveryNoteId}
  })
}

export async function goToDeliveryNoteUpdateComments(
  ionRouter: IonRouter,
  router: Router,
  deliveryNoteId: string,
  successMessage?: string
) {
  await router.replace({
    name: DeliveryNoteConfirmLoadRoute.COMMENTS,
    params: {
      id: deliveryNoteId,
      successMessage: successMessage
    }
  })
}
