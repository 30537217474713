import {JSONSchemaType} from 'ajv'

export interface AppVersionRequirementsBean {
  min_version: number
  recommended_version: number
}

export const appVersionRequirementsSchema: JSONSchemaType<AppVersionRequirementsBean> = {
  type: 'object',
  properties: {
    min_version: {
      type: ['number']
    },
    recommended_version: {
      type: ['number']
    }
  },
  required: ['min_version', 'recommended_version'],
  additionalProperties: true
}
