export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "fr",
    "resource": {
      "documents": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
        "userGuide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuel Rockease"])},
        "transportReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récépissé transport"])}
      },
      "others": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres"])},
        "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
        "contactSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacter le support"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])}
      }
    }
  })
}
