<template>
  <ion-page>
    <ion-header v-bind:translucent="!isIOs">
      <Toolbar v-bind:closable="isCloseable"
               v-bind:is-loading="isLoading"
               v-bind:previous-route="previousRoute"
               v-bind:title="title"
               v-on:close="onClose">
        <template v-slot:toolbar>
          <slot name="toolbar"/>
        </template>
      </Toolbar>
    </ion-header>

    <page-header/>

    <!-- Why force-overscroll false : https://github.com/ionic-team/ionic-framework/issues/25840 -->
    <ion-content v-bind:class="{'page-with-cards': usesCards}"
                 v-bind:force-overscroll="false"
                 v-bind:fullscreen="!isIOs">
      <ion-header v-if="!isIOs" collapse="condense">
        <Toolbar v-bind:closable="isCloseable"
                 v-bind:is-loading="isLoading"
                 v-bind:previous-route="previousRoute"
                 v-bind:title="title"
                 v-on:close="onClose">
          <template v-slot:toolbar>
            <slot name="toolbar"/>
          </template>
        </Toolbar>
      </ion-header>

      <template v-if="container === 'scrollable'">
        <slot/>
      </template>

      <div v-if="container === 'fixed'" class="container-fixed">
        <slot/>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">

import {defineComponent, inject, PropType} from 'vue'
import {IonContent, IonHeader, IonPage, modalController} from '@ionic/vue'
import Toolbar from '@/components/toolbar/Toolbar.vue'
import {RouteLocationRaw} from 'vue-router'
import {mapActions} from 'vuex'
import {Capacitor} from '@capacitor/core'
import {getOperatingSystemFromUserAgent} from '@/utils/OSUtils'
import {RefresherCustomEvent} from '@/utils/RefresherCustomEvent'
import {MainMenuItem} from '@/components/common/menu/MainMenu.vue'
import {mainMenuConfigKey} from '@/keys'
import {MainMenuConfig} from '@/App.vue'
import PageHeader from '@/components/PageHeader.vue'

export default defineComponent({
  name: 'page',
  emits: ['refresh'],
  props: {
    title: String,
    modal: {
      type: Boolean,
      default: false
    },
    closeable: {
      type: Boolean,
      default: false
    },
    previousRoute: Object as PropType<RouteLocationRaw>,
    menuItems: Object as PropType<Array<MainMenuItem>>,
    usesCards: Boolean,
    container: {
      type: String as PropType<'fixed' | 'scrollable'>,
      required: true
    },
    isLoading: Boolean
  },
  data() {
    return {
      previousMainMenuConfig: undefined as MainMenuConfig | undefined
    }
  },
  setup() {
    return {
      mainMenuConfig: inject(mainMenuConfigKey)
    }
  },
  mounted() {
    this.setPreviousRoute(this.previousRoute)

    if (this.modal === true) {
      this.previousMainMenuConfig = {...this.mainMenuConfig}
    }
    this.configureMainMenu()
  },
  unmounted() {
    if (this.previousMainMenuConfig) {
      this.mainMenuConfig.disabled = this.previousMainMenuConfig.disabled
      this.mainMenuConfig.items = this.previousMainMenuConfig.items
    }
  },
  methods: {
    ...mapActions('back', ['setPreviousRoute']),
    configureMainMenu() {
      let disabled = false
      let menuItems = []
      if (this.previousRoute || this.modal === true) {
        disabled = true
      } else {
        disabled = false
        menuItems = this.menuItems || []
      }
      this.mainMenuConfig.disabled = disabled
      this.mainMenuConfig.items = menuItems
    },
    onClose() {
      modalController.dismiss()
    },
    onRefresh(event: RefresherCustomEvent) {
      this.$emit('refresh', event)
    }
  },
  computed: {
    isIOs(): boolean {
      if (Capacitor.getPlatform() == 'ios') {
        return true
      } else if (Capacitor.getPlatform() == 'web' || getOperatingSystemFromUserAgent() == 'ios') {
        return true
      }
      return false
    },
    isCloseable(): boolean {
      if (!this.modal) {
        return false
      }
      return this.closeable
    }
  },
  components: {
    PageHeader,
    IonPage, IonContent, IonHeader,
    Toolbar
  }
})

</script>

<style scoped>

.container-fixed {
  width: 100%;
  height: 100%;
  overflow: hidden;
  overscroll-behavior-y: none;
}

.ios .container-fixed {
  position: absolute;
}

.md .container-fixed {
  position: relative;
}

</style>
