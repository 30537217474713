import {CurrentUserApi, CurrentUserBean, schema} from '@/service/api/CurrentUserApi'
import {ActionContext, Module} from 'vuex'
import {AppState} from '@/stores'
import Ajv from 'ajv'
import {PushNotificationService} from '@/service/PushNotificationService'
import {getErrorMessage} from '@/exception/Exception'
import {I18n} from 'vue-i18n'

const SELECTED_ROLE_KEY = 'selectedRole'
const CURRENT_USER_KEY = 'currentUser'

export interface UpdateInfoParams {
  firstname: string
  lastname: string
}

export interface CurrentUserState {
  selectedRole?: string
  isFetchingCurrentUser: boolean
  currentUser?: CurrentUserBean
  currentUserErrorMessage?: string
}

// noinspection JSMethodCanBeStatic
export class CurrentUserModule implements Module<CurrentUserState, AppState> {
  state = {
    selectedRole: this.loadSelectedRole(),
    isFetchingCurrentUser: false,
    currentUser: this.loadCurrentUser()
  }
  mutations = {
    setSelectedRole: this.setSelectedRole.bind(this),
    setIsFetchingCurrentUser: (state, isFetching) => state.isFetchingCurrentUser = isFetching,
    setCurrentUser: this.setCurrentUser.bind(this),
    setCurrentUserErrorMessage: (state, errorMessage) => state.currentUserErrorMessage = errorMessage
  }
  getters = {
    selectedRole: state => state.selectedRole,
    isFetchingCurrentUser: state => state.isFetchingCurrentUser,
    currentUser: state => state.currentUser,
    currentUserErrorMessage: state => state.currentUserErrorMessage
  }
  actions = {
    selectRole: this.selectRoleAction.bind(this),
    fetchCurrentUser: this.fetchCurrentUserAction.bind(this),
    updateInfo: this.updateInfoAction.bind(this),
    markCurrentUserForDeletion: this.markCurrentUserForDeletionAction.bind(this),
    clearCurrentUserModule: this.clearCurrentUserModuleAction.bind(this)
  }
  
  constructor(
    private readonly i18n: I18n,
    private readonly currentUserApi: CurrentUserApi,
    private readonly pushNotificationService: PushNotificationService
  ) {
    this.pushNotificationService.setDeviceTokenChangedCallback(this.onDeviceTokenChanged.bind(this))
  }
  
  private onDeviceTokenChanged(newDeviceToken: string) {
    this.updateDeviceTokenIfNecessary(newDeviceToken).catch(error => console.error('Failed to update device token due to error.', error))
  }
  
  private async updateDeviceTokenIfNecessary(newDeviceToken: string | undefined) {
    const currentUser = this.state.currentUser
    if (!currentUser || !newDeviceToken) {
      return
    }
    
    const deviceToken = this.state.currentUser?.device_token
    if (deviceToken !== newDeviceToken) {
      const newCurrentUser = await this.currentUserApi.updateDeviceToken(newDeviceToken)
      this.setCurrentUser(this.state, newCurrentUser)
    }
  }
  
  private loadSelectedRole(): string | undefined {
    const role = localStorage.getItem(SELECTED_ROLE_KEY)
    if (!role) {
      return undefined
    }
    return role
  }
  
  private loadCurrentUser(): CurrentUserBean | undefined {
    const jsonCurrentUser = localStorage.getItem(CURRENT_USER_KEY)
    if (!jsonCurrentUser) {
      return undefined
    }
    
    const currentUser = JSON.parse(jsonCurrentUser)
    // Do not reload from local storage if it does not match the schema anymore.
    if (!new Ajv().validate(schema, currentUser)) {
      localStorage.removeItem(CURRENT_USER_KEY)
      return undefined
    }
    return currentUser
  }
  
  private selectRoleAction(
    context: ActionContext<CurrentUserState, AppState>,
    selectedRole: string | null
  ) {
    context.commit('setSelectedRole', selectedRole)
  }
  
  private async fetchCurrentUserAction(
    context: ActionContext<CurrentUserState, AppState>
  ): Promise<CurrentUserBean> {
    const selectedRole = context.state.selectedRole
    if (!selectedRole) {
      throw new Error('No role selected.')
    }
    
    context.commit('setCurrentUser', undefined)
    context.commit('setCurrentUserErrorMessage', undefined)
    
    let currentUser: CurrentUserBean
    try {
      context.commit('setIsFetchingCurrentUser', true)
      currentUser = await this.currentUserApi.updateCurrentUserRole(selectedRole)
      context.commit('setCurrentUser', currentUser)
    } catch (error) {
      const errorMessage = getErrorMessage(this.i18n, error)
      context.commit('setCurrentUserErrorMessage', errorMessage)
      throw error
    } finally {
      context.commit('setIsFetchingCurrentUser', false)
    }
    
    this.updateDeviceTokenIfNecessary(this.pushNotificationService.getDeviceToken()).catch(error => console.error('Failed to update device token due to error.', error))
    
    return currentUser
  }
  
  private async updateInfoAction(
    context: ActionContext<CurrentUserState, AppState>,
    params: UpdateInfoParams
  ): Promise<CurrentUserBean> {
    const currentUser = await this.currentUserApi.updateInfo(
      params.firstname,
      params.lastname
    )
    context.commit('setCurrentUser', currentUser)
    return currentUser
  }
  
  private async markCurrentUserForDeletionAction(): Promise<void> {
    return this.currentUserApi.markCurrentUserForDeletion()
  }
  
  private clearCurrentUserModuleAction(
    context: ActionContext<CurrentUserState, AppState>,
    clearRole?: boolean
  ) {
    if (clearRole === undefined || clearRole) {
      context.commit('setSelectedRole', undefined)
    }
    context.commit('setCurrentUser', undefined)
    context.commit('setCurrentUserErrorMessage', undefined)
  }
  
  private setSelectedRole(state: CurrentUserState, selectedRole?: string) {
    state.selectedRole = selectedRole
    if (selectedRole) {
      localStorage.setItem(SELECTED_ROLE_KEY, selectedRole)
    } else {
      localStorage.removeItem(SELECTED_ROLE_KEY)
    }
  }
  
  private setCurrentUser(state: CurrentUserState, currentUser?: CurrentUserBean) {
    state.currentUser = currentUser
    if (currentUser) {
      localStorage.setItem(CURRENT_USER_KEY, JSON.stringify(currentUser))
    } else {
      localStorage.removeItem(CURRENT_USER_KEY)
    }
  }
}
