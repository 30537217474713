import {AbstractApi} from '@/service/api/AbstractApi'
import {I18n} from 'vue-i18n'
import {AuthenticationService} from '@/service/authentication/AuthenticationService'
import {AppConfigBean, appConfigSchema} from '@/service/model/config/AppConfigBean'

export class AppConfigApi extends AbstractApi {
  
  constructor(i18n: I18n, authenticationService: AuthenticationService) {
    super(i18n, authenticationService)
  }
  
  getConfig(): Promise<AppConfigBean> {
    return this.get<AppConfigBean>({
      path: '/api/mp/v1/app-config',
      authentication: 'none',
      schema: appConfigSchema
    })
  }
}
