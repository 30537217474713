export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update required"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Rockease app version is too old."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The app is disabled because some features are no longer supported."])},
      "resolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the button bellow to go to the store and manually update the app."])},
      "button": {
        "goToStore": {
          "android": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Play store"])},
          "ios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to App store"])},
          "web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to store"])}
        }
      }
    }
  })
}
