import {
  AcquireTokenParams, AcquireTokenSilentParams,
  AuthPlatform,
  CommonAcquireTokenParams,
  PlatformAuthenticationService
} from '@/service/authentication/PlatformAuthenticationService'
import {AuthenticationToken} from '@/service/authentication/AuthenticationService'
import {BrowserAuthError, Configuration, PublicClientApplication} from '@azure/msal-browser'
import {makeApiException} from '@/exception/ApiException'
import {I18n} from 'vue-i18n'

// Must be registered as a SPA redirectURI in AD B2C app registration
const REDIRECT_URI = 'http://localhost:8081/'

/**
 * This implementation is only used when developing the web app in a browser.
 */
export class WebAuthenticationService implements PlatformAuthenticationService {
  
  private client: PublicClientApplication | undefined
  private clientParams: CommonAcquireTokenParams | undefined
  
  constructor(
    private readonly i18n: I18n
  ) {
  }
  
  inject() {
  }
  
  getPlatform(): AuthPlatform {
    return 'web'
  }
  
  private async getClient(params: CommonAcquireTokenParams): Promise<PublicClientApplication> {
    if (this.client !== undefined && this.clientParams !== undefined) {
      if (this.areSameParams(this.clientParams, params)) {
        return this.client
      } else {
        console.error('Trying to use Msal client with a configuration different that the one in cache.')
        throw makeApiException(this.i18n, 'error.unknown')
      }
    }
    this.client = await this.initializeClient(params)
    this.clientParams = {...params}
    return this.client
  }
  
  private areSameParams(p1: CommonAcquireTokenParams, p2: CommonAcquireTokenParams): boolean {
    return p1.clientId === p2.clientId && p1.authority === p2.authority && p1.scope === p2.scope && p1.customPolicy === p2.customPolicy
  }
  
  private async initializeClient(params: CommonAcquireTokenParams): Promise<PublicClientApplication> {
    const authority = `${params.authority}/${params.customPolicy}`
    const configuration: Configuration = {
      auth: {
        clientId: params.clientId,
        authority: authority,
        redirectUri: REDIRECT_URI,
        knownAuthorities: [params.authority]
      },
      cache: {
        cacheLocation: 'sessionStorage'
      }
    }
    const client = new PublicClientApplication(configuration)
    await client.initialize()
    return client
  }
  
  async acquireToken(params: AcquireTokenParams): Promise<AuthenticationToken> {
    const client = await this.getClient(params)
    const request = {
      scopes: [params.scope],
      redirectUri: REDIRECT_URI
    }
    const result = await client.acquireTokenPopup(request)
    return {
      token: result.accessToken,
      expiresOn: result.expiresOn?.getUTCDate()
    }
  }
  
  async acquireTokenSilent(params: AcquireTokenSilentParams): Promise<AuthenticationToken | undefined> {
    const client = await this.getClient(params)
    
    const accounts = client.getAllAccounts()
    if (accounts.length === 0) {
      return undefined
    }
    
    const request = {
      account: accounts[0],
      scopes: [params.scope]
    }
    const result = await client.acquireTokenSilent(request)
    return {
      token: result.accessToken,
      expiresOn: result.expiresOn?.getUTCDate()
    }
  }
  
  async signOut(): Promise<void> {
    this.clearMsalSessionKeys()
    if (this.client !== undefined) {
      await this.client.logoutPopup()
    }
    this.client = undefined
    this.clientParams = undefined
  }
  
  /**
   * Clear session keys to avoid MSAL returning error interaction_in_progress if an authentication has started
   * but has failed for any reason.
   * @private
   */
  private clearMsalSessionKeys() {
    
    sessionStorage.clear()
  }
  
  isCanceled(error: any): boolean {
    return error instanceof BrowserAuthError && error.errorCode === 'user_cancelled'
  }
}
