import {I18n} from 'vue-i18n'
import {AuthenticationService} from '@/service/authentication/AuthenticationService'
import {LocalStore} from '@/service/store/LocalStore'
import {SiteManagerModule} from '@/stores/modules/sitemanager/SiteManagerModule'
import {SiteBean, siteSchema} from '@/service/model/SiteBean'
import {MaterialModule} from '@/stores/modules/sitemanager/MaterialModule'
import {SiteManagerMaterialApi} from '@/service/api/sitemanager/SiteManagerMaterialApi'
import {SiteModule} from '@/stores/modules/sitemanager/SiteModule'
import {SiteManagerSiteApi} from '@/service/api/sitemanager/SiteManagerSiteApi'
import {ExpressOrderModule} from '@/stores/modules/sitemanager/ExpressOrderModule'
import {QuotationRequestApi} from '@/service/api/sitemanager/QuotationRequestApi'
import {SiteManagerMaterialBean, siteManagerMaterialSchema} from '@/service/model/sitemanager/SiteManagerMaterialBean'
import {SiteManagerCustomerApi} from '@/service/api/sitemanager/SiteManagerCustomerApi'
import {SiteManagerCustomerModule} from '@/stores/modules/sitemanager/SiteManagerCustomerModule'
import {SiteManagerCustomerBean, siteManagerCustomerSchema} from '@/service/model/sitemanager/SiteManagerCustomerBean'
import {SiteManagerDeliveryRequestModule} from '@/stores/modules/sitemanager/SiteManagerDeliveryRequestModule'
import {SiteManagerOrderApi} from '@/service/api/sitemanager/SiteManagerOrderApi'
import {SiteManagerDriverApi} from '@/service/api/sitemanager/SiteManagerDriverApi'
import {SiteManagerDriverModule} from '@/stores/modules/sitemanager/SiteManagerDriverModule'
import {SiteManagerAddDriverModule} from '@/stores/modules/sitemanager/SiteManagerAddDriverModule'
import {SiteManagerTruckApi} from '@/service/api/sitemanager/SiteManagerTruckApi'
import {SiteManagerTruckModule} from '@/stores/modules/sitemanager/SiteManagerTruckModule'
import {SiteManagerAddTruckModule} from '@/stores/modules/sitemanager/SiteManagerAddTruckModule'
import {
  SiteManagerCreateQuotationRequestModule
} from '@/stores/modules/sitemanager/SiteManagerCreateQuotationRequestModule'
import {
  SiteManagerQuotationRequestBean,
  siteManagerQuotationRequestSchema
} from '@/service/model/sitemanager/SiteManagerQuotationRequestBean'
import {SiteManagerQuotationRequestModule} from '@/stores/modules/sitemanager/SiteManagerQuotationRequestModule'
import {
  SiteManagerQuotationResponseBean,
  siteManagerQuotationResponseSchema
} from '@/service/model/sitemanager/SiteManagerQuotationResponseBean'
import {SiteManagerQuotationResponseApi} from '@/service/api/sitemanager/SiteManagerQuotationResponseApi'
import {
  SiteManagerSelectQuotationResponseModule
} from '@/stores/modules/sitemanager/SiteManagerSelectQuotationResponseModule'
import {SiteManagerOrderBean, siteManagerOrderSchema} from '@/service/model/sitemanager/SiteManagerOrderBean'
import {SiteManagerOrderModule} from '@/stores/modules/sitemanager/SiteManagerOrderModule'
import {DeliveryNoteBean} from '@/service/model/DeliveryNoteBean'
import {SiteManagerCreatePickupModule} from '@/stores/modules/sitemanager/SiteManagerCreatePickupModule'
import {SiteManagerDeliveryNoteApi} from '@/service/api/sitemanager/SiteManagerDeliveryNoteApi'
import {SynchronizationService} from '@/service/SynchronizationService'

export function makeSiteManagerModule(
  i18n: I18n,
  authenticationService: AuthenticationService,
  synchronizationService: SynchronizationService,
  deliveryNoteStore: LocalStore<DeliveryNoteBean, string>,
  listStore: LocalStore<Array<string>, string>
): SiteManagerModule {
  const customerStore = new LocalStore<SiteManagerCustomerBean, string>({
    localStorageKey: 'customers',
    schema: siteManagerCustomerSchema
  })
  const siteStore = new LocalStore<SiteBean, string>({
    localStorageKey: 'sites',
    schema: siteSchema
  })
  const materialStore = new LocalStore<SiteManagerMaterialBean, string>({
    localStorageKey: 'materials',
    schema: siteManagerMaterialSchema
  })
  const quotationRequestStore = new LocalStore<SiteManagerQuotationRequestBean, string>({
    localStorageKey: 'quotationRequests',
    schema: siteManagerQuotationRequestSchema
  })
  const quotationResponseStore = new LocalStore<SiteManagerQuotationResponseBean, string>({
    localStorageKey: 'quotationResponses',
    schema: siteManagerQuotationResponseSchema
  })
  const orderStore = new LocalStore<SiteManagerOrderBean, string>({
    localStorageKey: 'orders',
    schema: siteManagerOrderSchema
  })
  
  const customerApi = new SiteManagerCustomerApi(i18n, authenticationService)
  const materialApi = new SiteManagerMaterialApi(i18n, authenticationService)
  const siteApi = new SiteManagerSiteApi(i18n, authenticationService)
  const quotationRequestApi = new QuotationRequestApi(i18n, authenticationService)
  const quotationResponseApi = new SiteManagerQuotationResponseApi(i18n, authenticationService)
  const orderApi = new SiteManagerOrderApi(i18n, authenticationService, synchronizationService)
  const driverApi = new SiteManagerDriverApi(i18n, authenticationService)
  const truckApi = new SiteManagerTruckApi(i18n, authenticationService)
  const deliveryNoteApi = new SiteManagerDeliveryNoteApi(i18n, authenticationService, synchronizationService)
  
  const customerModule = new SiteManagerCustomerModule(i18n, listStore, customerStore, customerApi)
  const materialModule = new MaterialModule(listStore, materialStore, materialApi)
  const siteModule = new SiteModule(listStore, siteStore, siteApi)
  const expressOrderModule = new ExpressOrderModule(i18n, quotationRequestApi)
  const deliveryRequestModule = new SiteManagerDeliveryRequestModule(i18n, orderApi)
  const addDriverModule = new SiteManagerAddDriverModule(i18n, driverApi)
  const driverModule = new SiteManagerDriverModule(i18n, driverApi, addDriverModule)
  const addTruckModule = new SiteManagerAddTruckModule(i18n, truckApi)
  const truckModule = new SiteManagerTruckModule(i18n, truckApi, addTruckModule)
  
  const createQuotationRequestModule = new SiteManagerCreateQuotationRequestModule(i18n, quotationRequestApi)
  const selectQuotationResponseModule = new SiteManagerSelectQuotationResponseModule(
    i18n, orderStore,
    quotationRequestApi, quotationResponseApi
  )
  const quotationRequestModule = new SiteManagerQuotationRequestModule(
    i18n, listStore,
    quotationRequestStore, quotationRequestApi,
    quotationResponseStore, quotationResponseApi,
    createQuotationRequestModule, selectQuotationResponseModule
  )
  const createPickupModule = new SiteManagerCreatePickupModule(i18n, deliveryNoteApi)
  
  const orderModule = new SiteManagerOrderModule(i18n, listStore, deliveryNoteStore, orderStore, orderApi)
  
  return new SiteManagerModule(
    customerModule,
    siteModule,
    materialModule,
    expressOrderModule,
    deliveryRequestModule,
    driverModule,
    truckModule,
    quotationRequestModule,
    orderModule,
    createPickupModule
  )
}
