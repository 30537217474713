import {AbstractApi} from '@/service/api/AbstractApi'
import {I18n} from 'vue-i18n'
import {AuthenticationService} from '@/service/authentication/AuthenticationService'
import {siteManagerSiteArraySchema, SiteBean} from '@/service/model/SiteBean'

export class SiteManagerSiteApi extends AbstractApi {
  
  constructor(i18n: I18n, authenticationService: AuthenticationService) {
    super(i18n, authenticationService)
  }
  
  async fetchSites(): Promise<Array<SiteBean>> {
    return this.get({
      path: '/api/mp/v1/site-manager/sites',
      authentication: 'required',
      schema: siteManagerSiteArraySchema
    })
  }
}
