export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "fr",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rockease nécessite les permissions suivantes:"])},
      "location": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès à votre position"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour faciliter le suivi des livraisons."])}
      },
      "camera": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prise de photo"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour prendre en photo les bons papiers et les matériaux."])}
      },
      "notification": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affichage de notifications"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour vous notifier des départs/arrivées de vos livraisons."])}
      }
    }
  })
}
