import {AbstractApi} from '@/service/api/AbstractApi'
import {JSONSchemaType} from 'ajv'
import {I18n} from 'vue-i18n'
import {AuthenticationService} from '@/service/authentication/AuthenticationService'
import {isBlankOrEmpty} from '@/utils/StringUtils'

export const ROLE_ADMIN = 'ADMIN'
export const ROLE_DRIVER = 'DRIVER'
export const ROLE_QUARRY = 'QUARRY'
export const ROLE_SITE_MANAGER = 'SITE_MANAGER'
export const ROLE_SUPER_CUSTOMER_USER = 'SUPER_CUSTOMER_USER'

export interface CurrentUserBean {
  id: string
  role: string
  device_token?: string | null
  firstname?: string | null
  lastname?: string | null
}

export const schema: JSONSchemaType<CurrentUserBean> = {
  type: 'object',
  properties: {
    id: {type: 'string'},
    role: {type: 'string'},
    device_token: {
      type: 'string',
      nullable: true
    },
    firstname: {
      type: 'string',
      nullable: true
    },
    lastname: {
      type: 'string',
      nullable: true
    }
  },
  required: ['id', 'role'],
  additionalProperties: true
}

export class CurrentUserApi extends AbstractApi {
  
  constructor(i18n: I18n, authenticationService: AuthenticationService) {
    super(i18n, authenticationService)
  }
  
  async fetchCurrentUser(): Promise<CurrentUserBean> {
    return this.get<CurrentUserBean>({
      path: '/api/v1/me',
      authentication: 'required',
      schema: schema
    })
  }
  
  async updateCurrentUserRole(
    role: string
  ): Promise<CurrentUserBean> {
    return this.put<CurrentUserBean>({
      path: '/api/v1/me',
      authentication: 'required',
      body: {
        role: role
      },
      schema: schema
    })
  }
  
  async updateDeviceToken(deviceToken: string): Promise<CurrentUserBean> {
    return this.put<CurrentUserBean>({
      path: '/api/v1/me/device-token',
      authentication: 'required',
      body: {
        device_token: deviceToken
      },
      schema: schema
    })
  }
  
  async updateInfo(firstname: string, lastname: string): Promise<CurrentUserBean> {
    return this.put<CurrentUserBean>({
      path: '/api/v1/me/info',
      authentication: 'required',
      body: {
        firstname,
        lastname
      },
      schema: schema
    })
  }
  
  async markCurrentUserForDeletion(): Promise<void> {
    return this.deleteVoid({
      path: '/api/v1/me',
      authentication: 'required'
    })
  }
}

export function isMissingPersonalInfo(currentUser: CurrentUserBean): boolean {
  return isBlankOrEmpty(currentUser.firstname) || isBlankOrEmpty(currentUser.lastname)
}
