import {ActionContext, Module} from 'vuex'
import {AppState} from '@/stores'
import {
  QuotationRequestAndResponsesBean,
  QuotationRequestApi,
  QuotationRequestTemporarySiteBean,
  QuotationRequestValidationResultBean
} from '@/service/api/sitemanager/QuotationRequestApi'
import {ExpressFlowTemporarySite, ExpressOrderFlow} from '@/service/flow/sitemanager/ExpressOrderFlow'
import {SiteBean} from '@/service/model/SiteBean'
import {MaterialBean} from '@/service/model/MaterialBean'
import {QuotationResponseBean} from '@/service/model/QuotationResponseBean'
import {makeException} from '@/exception/Exception'
import {I18n} from 'vue-i18n'
import {SiteManagerMaterialBean} from '@/service/model/sitemanager/SiteManagerMaterialBean'
import {SiteManagerCustomerBean} from '@/service/model/sitemanager/SiteManagerCustomerBean'
import {LocationBean} from '@/service/model/LocationBean'
import {Address, isAddressNotBlank, isAddressValid, isSameAddress, isSameLocation} from '@/utils/GeoFormatters'
import {CustomerBean} from '@/service/model/CustomerBean'
import {MaterialCategory} from '@/service/model/MaterialCategory'
import _ from 'lodash'
import {RouteLocationRaw} from 'vue-router'

export interface ExpressOrderState {
  flow?: ExpressOrderFlow
  site?: SiteBean
  material?: SiteManagerMaterialBean
  backloadMaterial?: SiteManagerMaterialBean
  bean?: QuotationRequestAndResponsesBean
  selectedResponse?: QuotationResponseBean
}

// noinspection JSMethodCanBeStatic
export class ExpressOrderModule implements Module<ExpressOrderState, AppState> {
  namespaced = true
  getters = {
    flow: (state) => state.flow,
    site: (state) => state.site,
    temporarySite: (state) => state.flow?.getState()?.temporarySite,
    material: (state) => state.material,
    backloadMaterial: (state) => state.backloadMaterial,
    bean: (state) => state.bean,
    feePercent: (state) => state.bean?.fee_percent,
    selectedResponse: (state) => state.selectedResponse
  }
  mutations = {
    setFlow: this.resetWithFlow.bind(this),
    setSite: this.setSite.bind(this),
    setUseTemporarySite: this.setUseTemporarySite.bind(this),
    setCustomer: this.setCustomer.bind(this),
    setLocation: this.setLocation.bind(this),
    setTemporarySite: this.setTemporarySite.bind(this),
    setMaterial: this.setMaterial.bind(this),
    setMaterialQuantity: this.setMaterialQuantity.bind(this),
    setBackloadMaterial: this.setBackloadMaterial.bind(this),
    setBackloadMaterialQuantity: this.setBackloadMaterialQuantity.bind(this),
    setBean: (state, bean) => state.bean = bean,
    setSelectedResponse: this.setSelectedResponse.bind(this),
  }
  actions = {
    createExpressOrderFlow: this.createExpressOrderFlowAction.bind(this),
    setSite: this.setSiteAction.bind(this),
    useTemporarySite: this.useTemporarySiteAction.bind(this),
    setCustomer: this.setCustomerAction.bind(this),
    setLocation: this.setLocationAction.bind(this),
    setTemporarySite: this.setTemporarySiteAction.bind(this),
    setMaterial: this.setMaterialAction.bind(this),
    setMaterialQuantity: this.setMaterialQuantityAction.bind(this),
    setBackloadMaterial: this.setBackloadMaterialAction.bind(this),
    setBackloadMaterialQuantity: this.setBackloadMaterialQuantityAction.bind(this),
    createExpressOrderRequest: this.createExpressOrderRequestAction.bind(this),
    setSelectedResponse: this.setSelectedResponseAction.bind(this),
    validateExpressOrderResponse: this.validateExpressOrderResponseAction.bind(this)
  }
  
  constructor(
    private readonly i18n: I18n,
    private readonly quotationRequestApi: QuotationRequestApi
  ) {
  }
  
  private resetWithFlow(
    state: ExpressOrderState,
    flow?: ExpressOrderFlow
  ) {
    state.flow = flow
    state.site = undefined
    state.material = undefined
    this.resetResponses(state)
  }
  
  private resetResponses(
    state: ExpressOrderState
  ) {
    state.bean = undefined
    state.selectedResponse = undefined
  }
  
  private resetTemporarySite(
    state: ExpressOrderState
  ) {
    state.flow?.assignState({
      temporarySite: undefined
    })
  }
  
  private resetTemporarySiteAddressAndLocation(
    state: ExpressOrderState
  ) {
    state.flow?.assignState({
      temporarySite: {
        location: undefined,
        address_1: undefined,
        address_2: undefined,
        postal_code: undefined,
        city: undefined
      }
    })
  }
  
  private setSite(
    state: ExpressOrderState,
    site: SiteBean
  ) {
    const flowState = state.flow?.getState()
    if (flowState?.useTemporarySite === true || state.site?.id !== site.id) {
      this.resetResponses(state)
    }
    state.site = site
    state.flow?.assignState({
      siteId: site.id,
      useTemporarySite: false,
      temporarySite: undefined
    })
  }
  
  private setUseTemporarySite(
    state: ExpressOrderState,
    useTemporarySite: boolean
  ) {
    const flowState = state.flow?.getState()
    if (flowState?.useTemporarySite === false && useTemporarySite) {
      this.resetTemporarySite(state)
      this.resetResponses(state)
      
      state.flow?.assignState({
        siteId: undefined,
        useTemporarySite: true
      })
    }
  }
  
  private setCustomer(
    state: ExpressOrderState,
    options: { customer: SiteManagerCustomerBean; autoSelected: boolean }
  ) {
    const site = state.flow?.getState()?.temporarySite
    const customer = options.customer
    if (site?.customer_id !== customer.id) {
      this.resetTemporarySite(state)
      this.resetResponses(state)
      
      state.flow?.assignState({
        temporarySite: {
          customer_id: customer.id,
          autoSelectedCustomer: options.autoSelected
        }
      })
    }
  }
  
  private setLocation(
    state: ExpressOrderState,
    s: {name?: string; reference?: string; location: LocationBean}
  ) {
    const site = state.flow?.getState()?.temporarySite
    if (isAddressNotBlank(site) || !this.isSameNameAndRef(site, s) || !isSameLocation(site?.location, s.location)) {
      this.resetTemporarySiteAddressAndLocation(state)
      this.resetResponses(state)
      
      state.flow?.assignState({
        temporarySite: {
          name: s.name,
          reference: s.reference,
          location: s.location
        }
      })
    }
  }
  
  private setTemporarySite(
    state: ExpressOrderState,
    s: { name?: string; reference?: string; address: Address }
  ) {
    const site = state.flow?.getState()?.temporarySite
    if (site?.location !== undefined || !this.isSameNameAndRef(site, s) ||  !isSameAddress(site, s.address)) {
      this.resetTemporarySiteAddressAndLocation(state)
      this.resetResponses(state)
      
      state.flow?.assignState({
        temporarySite: {
          name: s.name,
          reference: s.reference,
          address_1: s.address.address_1,
          address_2: s.address.address_2,
          postal_code: s.address.postal_code,
          city: s.address.city
        }
      })
    }
  }
  
  private isSameNameAndRef(
    site: ExpressFlowTemporarySite | undefined,
    s: {name?: string; reference?: string}
  ): boolean {
    if (site === undefined) {
      return false
    }
    return site.name === s.name && site.reference === s.reference
  }
  
  private setMaterial(
    state: ExpressOrderState,
    material: SiteManagerMaterialBean
  ) {
    if (state.material?.id !== material.id) {
      this.resetResponses(state)
    }
    state.material = material
    state.flow?.assignState({
      materialId: material.id,
      materialType: material.type
    })
  }
  
  private setMaterialQuantity(
    state: ExpressOrderState,
    materialQuantity: number
  ) {
    if (state.flow?.getState()?.materialQuantity !== materialQuantity) {
      this.resetResponses(state)
    }
    state.flow?.assignState({
      materialQuantity: materialQuantity
    })
  }
  
  private setBackloadMaterial(
    state: ExpressOrderState,
    backloadMaterial: SiteManagerMaterialBean | undefined
  ) {
    if (state.material?.id !== backloadMaterial?.id) {
      this.resetResponses(state)
    }
    state.backloadMaterial = backloadMaterial
    state.flow?.assignState({
      backloadMaterialId: backloadMaterial?.id
    })
  }
  
  private setBackloadMaterialQuantity(
    state: ExpressOrderState,
    backloadMaterialQuantity: number | undefined
  ) {
    if (state.flow?.getState()?.backloadMaterialQuantity !== backloadMaterialQuantity) {
      this.resetResponses(state)
    }
    state.flow?.assignState({
      backloadMaterialQuantity: backloadMaterialQuantity
    })
  }
  
  private setSelectedResponse(
    state: ExpressOrderState,
    response: QuotationResponseBean
  ) {
    state.selectedResponse = response
    state.flow?.assignState({
      responseId: response.id
    })
  }
  
  private async createExpressOrderFlowAction(
    context: ActionContext<ExpressOrderState, AppState>,
    params?: {initialRoute?: RouteLocationRaw}
  ): Promise<ExpressOrderFlow> {
    const flow = new ExpressOrderFlow(params?.initialRoute)
    context.commit('setFlow', flow)
    
    // Pre-fetch sites & materials
    Promise.all([
      context.dispatch('siteManager/customer/fetchCustomers', {}, {root: true}),
      context.dispatch('siteManager/site/fetchSites', {}, {root: true}),
      context.dispatch('siteManager/material/fetchMaterials', {}, {root: true})
    ]).then()
    
    return flow
  }
  
  private async setSiteAction(
    context: ActionContext<ExpressOrderState, AppState>,
    site: SiteBean
  ) {
    context.commit('setSite', site)
  }
  
  private async useTemporarySiteAction(
    context: ActionContext<ExpressOrderState, AppState>
  ) {
    context.commit('setUseTemporarySite', true)
  }
  
  private async setCustomerAction(
    context: ActionContext<ExpressOrderState, AppState>,
    customer: CustomerBean
  ) {
    context.commit('setCustomer', customer)
  }
  
  private async setLocationAction(
    context: ActionContext<ExpressOrderState, AppState>,
    location: LocationBean
  ) {
    context.commit('setLocation', location)
  }
  
  private async setTemporarySiteAction(
    context: ActionContext<ExpressOrderState, AppState>,
    address: Address
  ) {
    context.commit('setTemporarySite', address)
  }
  
  private async setMaterialAction(
    context: ActionContext<ExpressOrderState, AppState>,
    material: MaterialBean
  ) {
    context.commit('setMaterial', material)
  }
  
  private async setMaterialQuantityAction(
    context: ActionContext<ExpressOrderState, AppState>,
    quantity: number
  ) {
    context.commit('setMaterialQuantity', quantity)
  }
  
  private async setBackloadMaterialAction(
    context: ActionContext<ExpressOrderState, AppState>,
    backloadMaterial: MaterialBean | undefined
  ) {
    context.commit('setBackloadMaterial', backloadMaterial)
  }
  
  private async setBackloadMaterialQuantityAction(
    context: ActionContext<ExpressOrderState, AppState>,
    quantity: number | undefined
  ) {
    context.commit('setBackloadMaterialQuantity', quantity)
  }
  
  private async createExpressOrderRequestAction(
    context: ActionContext<ExpressOrderState, AppState>
  ) {
    const flowState = context.state.flow?.getState()
    if (!flowState || !flowState.materialId || !flowState.materialType || !flowState.materialQuantity) {
      throw makeException(this.i18n, 'expressOrder.unknown')
    }
    
    let siteId: string | undefined = undefined
    let temporarySite: QuotationRequestTemporarySiteBean | undefined = undefined
    if (flowState.siteId) {
      siteId = flowState.siteId
    } else if (flowState.temporarySite) {
      const flowSite = flowState.temporarySite
      if (!flowSite.customer_id) {
        throw makeException(this.i18n, 'expressOrder.unknown')
      }
      if (!flowSite.location && !isAddressValid(flowSite)) {
        throw makeException(this.i18n, 'expressOrder.unknown')
      }
      temporarySite = {
        customer_id: flowSite.customer_id,
        ...flowSite
      }
    } else {
      throw makeException(this.i18n, 'expressOrder.unknown')
    }
    
    const bean = await this.quotationRequestApi.create({
      site_id: siteId,
      temporary_site: temporarySite,
      
      express_order: true,
      
      material_id: flowState.materialId,
      material_type: flowState.materialType,
      material_quantity: flowState.materialQuantity,
      backload_material_id: flowState.backloadMaterialId,
      backload_material_quantity: flowState.backloadMaterialQuantity
    })
    
    context.commit('setBean', bean)
  }
  
  private async setSelectedResponseAction(
    context: ActionContext<ExpressOrderState, AppState>,
    response: QuotationResponseBean
  ) {
    context.commit('setSelectedResponse', response)
  }
  
  private async validateExpressOrderResponseAction(
    context: ActionContext<ExpressOrderState, AppState>
  ): Promise<QuotationRequestValidationResultBean> {
    const requestId = context.state.bean?.request?.id
    const responseId = context.state.selectedResponse?.id
    
    if (!requestId || !responseId) {
      throw makeException(this.i18n, 'expressOrder.unknown')
    }
    
    return await this.quotationRequestApi.validateResponse(requestId, responseId)
  }
}

export function getBackloadMaterialForExpressOrder(
  materials: Array<SiteManagerMaterialBean> | undefined
): SiteManagerMaterialBean | undefined {
  return _.find(materials, it => it.category === MaterialCategory.WASTE && it.allowed_express_order === true)
}
