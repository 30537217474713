import {ActionContext, Module} from 'vuex'
import {AppState} from '@/stores'
import {SiteManagerDriverApi} from '@/service/api/sitemanager/SiteManagerDriverApi'
import {LocalStore} from '@/service/store/LocalStore'
import {getItemOperation, GetOperation} from '@/service/operation/GetOperation'
import Result from '@/service/operation/Result'
import {modalController} from '@ionic/vue'
import SiteManagerSelectDriverModal from '@/views/sitemanager/driver/SiteManagerSelectDriverModal.vue'
import {driverArraySchema, DriverBean} from '@/service/model/DriverBean'
import {I18n} from 'vue-i18n'
import {makeException} from '@/exception/Exception'
import {SiteManagerAddDriverModule} from '@/stores/modules/sitemanager/SiteManagerAddDriverModule'

const DRIVERS_STORAGE_KEY = 'drivers'
const DRIVERS_STORE_KEY = 'drivers'

interface SiteManagerDriverModuleState {
  driversOperation: GetOperation<Array<DriverBean>, string>
  driversResult: Result<Array<DriverBean>>
}

export class SiteManagerDriverModule implements Module<SiteManagerDriverModuleState, AppState> {
  namespaced = true
  actions = {
    selectDriver: this.selectDriverAction.bind(this),
    fetchDrivers: this.fetchDriversAction.bind(this),
    refreshDrivers: this.refreshDriversAction.bind(this),
    clearDriverModule: this.clearDriverModuleAction.bind(this)
  }
  mutations = {
    setDriversOperation: (state, operation) => state.driversOperation = operation,
    setDriversResult: (state, result) => state.driversResult = result
  }
  getters = {
    driversResult: (state) => state.driversResult
  }
  modules = {}
  
  private readonly driversStore: LocalStore<Array<DriverBean>, string>
  
  constructor(
    private readonly i18n: I18n,
    private readonly siteManagerDriverApi: SiteManagerDriverApi,
    private addDriverModule: SiteManagerAddDriverModule
  ) {
    this.driversStore = new LocalStore<Array<DriverBean>, string>({
      localStorageKey: DRIVERS_STORAGE_KEY,
      schema: driverArraySchema
    })
    
    this.modules = {
      add: addDriverModule
    }
  }
  
  async fetchDriversAction(
    context: ActionContext<SiteManagerDriverModuleState, AppState>
  ): Promise<Array<DriverBean> | null> {
    const operation = getItemOperation(
      this.driversStore,
      () => this.siteManagerDriverApi.listDrivers()
    )
    
    context.commit('setDriversOperation', operation)
    
    return await operation.get(
      {
        params: DRIVERS_STORE_KEY
      },
      it => {
        context.commit('setDriversResult', it)
      }
    )
  }
  
  async refreshDriversAction(
    context: ActionContext<SiteManagerDriverModuleState, AppState>
  ): Promise<Array<DriverBean> | null> {
    const operation = context.state.driversOperation
    if (!operation) {
      throw makeException(this.i18n, 'error.unknown')
    }
    return operation.refresh(true)
  }
  
  async selectDriverAction(): Promise<DriverBean | undefined> {
    const modal = await modalController.create({
      component: SiteManagerSelectDriverModal,
      backdropDismiss: false,
      swipeToClose: false
    })
    await modal.present()
    
    const result = await modal.onDidDismiss<DriverBean | undefined>()
    return result.data
  }
  
  clearDriverModuleAction(
    context: ActionContext<SiteManagerDriverModuleState, AppState>
  ) {
    context.commit('setDriversResult', undefined)
    context.commit('setDriversOperation', undefined)
    this.driversStore.clear()
  }
}
