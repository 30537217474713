import {JSONSchemaType} from 'ajv'

export interface AppPictureConfigBean {
  max_height: number
  max_width: number
  quality: number
}

export const appPictureConfigSchema: JSONSchemaType<AppPictureConfigBean> = {
  type: 'object',
  properties: {
    max_height: {
      type: ['number']
    },
    max_width: {
      type: ['number']
    },
    quality: {
      type: ['number']
    }
  },
  required: ['max_height', 'max_width', 'quality'],
  additionalProperties: true
}
