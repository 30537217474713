import {Flow, FlowStep} from '@/service/flow/Flow'
import {SiteManagerAddDriverRoute} from '@/router/sitemanager'
import {SiteManagerCustomerBean} from '@/service/model/sitemanager/SiteManagerCustomerBean'
import {RouteLocationRaw} from 'vue-router'
import {CustomerBean} from '@/service/model/CustomerBean'

export interface DriverInfo {
  firstname?: string
  lastname?: string
  phoneNumber?: string
}

export interface AddDriverFlowState {
  customer?: SiteManagerCustomerBean
  driver?: DriverInfo
}

export const AddDriverFlowSelectCustomerStep: FlowStep<null, AddDriverFlowState> = {
  routeName: SiteManagerAddDriverRoute.SELECT_CUSTOMER,
  isCompleted: state => state.customer !== undefined
}

export const AddDriverFlowConfirmStep: FlowStep<null, AddDriverFlowState> = {
  routeName: SiteManagerAddDriverRoute.CONFIRM,
  isCompleted: state => state.driver?.phoneNumber !== undefined
}

export class AddDriverFlow extends Flow<null, null, AddDriverFlowState> {
  
  constructor() {
    super(null, null, {})
  }
  
  setCustomer(customer?: CustomerBean | undefined) {
    this.assignState({
      customer: customer
    })
  }
  
  setDriverInfo(driver?: DriverInfo | undefined) {
    this.assignState({
      driver: driver
    })
  }
  
  getOutputRoute(): RouteLocationRaw {
    return {
      name: 'SiteManagerDrivers'
    }
  }
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getCancelRoute(): RouteLocationRaw {
    return {
      name: 'SiteManagerDrivers'
    }
  }
  
  getSteps(): Array<FlowStep<null, AddDriverFlowState>> {
    return [
      AddDriverFlowSelectCustomerStep,
      AddDriverFlowConfirmStep
    ]
  }
}
