import {ActionContext, Module} from 'vuex'
import {AppState} from '@/stores'
import {LocalStoreItem} from '@/service/store/LocalStoreItem'
import {LocalStore} from '@/service/store/LocalStore'

const STORE_KEY = 'truckRegistration'
const TRUCK_REGISTRATION_VALIDITY = 8 * 3600 * 1000 // 8 hours

export interface TruckRegistrationState {
  truckRegistration?: LocalStoreItem<string, string>
}

export class TruckRegistrationModule implements Module<TruckRegistrationState, AppState> {
  namespaced = true
  state = {}
  actions = {
    updateTruckRegistration: this.updateTruckRegistrationAction.bind(this),
    clearTruckRegistrationModule: this.clearTruckRegistrationModuleAction.bind(this)
  }
  mutations = {
    setTruckRegistration: this.setTruckRegistration.bind(this)
  }
  getters = {
    storedTruckRegistration: (state: TruckRegistrationState): string | undefined | null => {
      return state.truckRegistration?.item
    },
    shouldConfirmTruckRegistration: this.shouldConfirmTruckRegistration.bind(this)
  }
  private readonly truckRegistrationStore: LocalStore<string, string>
  
  constructor() {
    this.truckRegistrationStore = new LocalStore<string, string>({
      localStorageKey: 'truck-registration'
    })
    
    this.reloadOrCreateState()
  }
  
  private reloadOrCreateState() {
    this.state = {
      truckRegistration: this.truckRegistrationStore.getStoreItem(STORE_KEY)
    }
  }
  
  private updateTruckRegistrationAction(
    context: ActionContext<TruckRegistrationState, AppState>,
    truckRegistration: string
  ) {
    context.commit('setTruckRegistration', truckRegistration)
  }
  
  private clearTruckRegistrationModuleAction(context: ActionContext<TruckRegistrationState, AppState>) {
    context.commit('setTruckRegistration', null)
  }
  
  private setTruckRegistration(state: TruckRegistrationState, truckRegistration: string | null) {
    if (truckRegistration) {
      state.truckRegistration = this.truckRegistrationStore.saveItem(STORE_KEY, truckRegistration)
    } else {
      state.truckRegistration = undefined
      this.truckRegistrationStore.deleteItem(STORE_KEY)
    }
  }
  
  private shouldConfirmTruckRegistration(state: TruckRegistrationState): boolean {
    const truckRegistration = state.truckRegistration
    if (!truckRegistration) {
      return false
    }
    return truckRegistration.isExpired(TRUCK_REGISTRATION_VALIDITY)
  }
}
