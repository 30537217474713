import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-348a2316"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 2,
  class: "container-fixed"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toolbar = _resolveComponent("Toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_page_header = _resolveComponent("page-header")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, {
        translucent: !_ctx.isIOs
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Toolbar, {
            closable: _ctx.isCloseable,
            "is-loading": _ctx.isLoading,
            "previous-route": _ctx.previousRoute,
            title: _ctx.title,
            onClose: _ctx.onClose
          }, {
            toolbar: _withCtx(() => [
              _renderSlot(_ctx.$slots, "toolbar", {}, undefined, true)
            ]),
            _: 3
          }, 8, ["closable", "is-loading", "previous-route", "title", "onClose"])
        ]),
        _: 3
      }, 8, ["translucent"]),
      _createVNode(_component_page_header),
      _createVNode(_component_ion_content, {
        class: _normalizeClass({'page-with-cards': _ctx.usesCards}),
        "force-overscroll": false,
        fullscreen: !_ctx.isIOs
      }, {
        default: _withCtx(() => [
          (!_ctx.isIOs)
            ? (_openBlock(), _createBlock(_component_ion_header, {
                key: 0,
                collapse: "condense"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Toolbar, {
                    closable: _ctx.isCloseable,
                    "is-loading": _ctx.isLoading,
                    "previous-route": _ctx.previousRoute,
                    title: _ctx.title,
                    onClose: _ctx.onClose
                  }, {
                    toolbar: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "toolbar", {}, undefined, true)
                    ]),
                    _: 3
                  }, 8, ["closable", "is-loading", "previous-route", "title", "onClose"])
                ]),
                _: 3
              }))
            : _createCommentVNode("", true),
          (_ctx.container === 'scrollable')
            ? _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true)
            : _createCommentVNode("", true),
          (_ctx.container === 'fixed')
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 3
      }, 8, ["class", "fullscreen"])
    ]),
    _: 3
  }))
}