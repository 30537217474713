export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "documents": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
        "userGuide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User guide"])},
        "transportReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport receipt"])}
      },
      "others": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Others"])},
        "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameters"])},
        "contactSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact support"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log-out"])}
      }
    }
  })
}
