<template>
  <ion-toolbar color="rockease" part="toolbar">
    <ion-buttons slot="start">
      <!-- Close button -->
      <ion-button v-if="closable"
                  fill="clear"
                  shape="round"
                  size="default"
                  v-on:click="onCloseButtonClicked">
        <ion-icon slot="icon-only" v-bind:icon="closeOutline"/>
      </ion-button>

      <!-- Back button -->
      <ion-button v-else-if="previousRoute"
                  fill="clear"
                  shape="round"
                  size="default"
                  v-on:click="onBackButtonClicked">
        <ion-icon slot="icon-only" v-bind:icon="arrowBackOutline"/>
      </ion-button>

      <!-- Hamburger button -->
      <ion-menu-button v-else-if="showMainMenuButton" menu="burger"/>
    </ion-buttons>
    <ion-title>{{ title }}</ion-title>
  </ion-toolbar>
  <slot name="toolbar"/>
  <ion-progress-bar v-if="isLoading" color="medium" type="indeterminate"/>
</template>

<script lang="ts">

import {defineComponent, inject, PropType} from 'vue'
import {IonButton, IonButtons, IonIcon, IonMenuButton, IonProgressBar, IonTitle, IonToolbar} from '@ionic/vue'
import {arrowBackOutline, ellipsisHorizontal, ellipsisVertical, closeOutline} from 'ionicons/icons'
import {RouteLocationRaw} from 'vue-router'
import {isWebApp} from '@/utils/OSUtils'
import {mainMenuConfigKey} from '@/keys'

export default defineComponent({
  name: 'Toolbar',
  emits: ['close'],
  props: {
    title: String,
    closable: Boolean,
    previousRoute: Object as PropType<RouteLocationRaw>,
    isLoading: Boolean
  },
  setup() {
    return {
      isWebApp,
      ellipsisVertical, ellipsisHorizontal, arrowBackOutline, closeOutline,
      mainMenuConfig: inject(mainMenuConfigKey)
    }
  },
  methods: {
    async onCloseButtonClicked(): Promise<void> {
      this.$emit('close')
    },
    async onBackButtonClicked(): Promise<void> {
      await this.$router.replace(this.previousRoute)
    }
  },
  computed: {
    showMainMenuButton(): boolean {
      if (isWebApp) {
        return false
      }
      if (this.previousRoute) {
        return false
      }
      return !this.mainMenuConfig.disabled
    }
  },
  components: {
    IonToolbar,
    IonMenuButton,
    IonButtons, IonButton,
    IonTitle, IonIcon, IonProgressBar
  }
})

</script>

<style scoped>

ion-title {
  padding-inline-end: 0;
}

.ios ion-title {
  padding-inline-start: 0;
}

.ios .logo {
  display: none;
}

</style>
