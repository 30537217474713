import {JSONSchemaType} from 'ajv'

export interface PersonBean {
  firstname?: string | null
  lastname?: string | null
}

export const personSchema: JSONSchemaType<PersonBean> = {
  type: 'object',
  properties: {
    firstname: {
      type: ['string'],
      nullable: true
    },
    lastname: {
      type: ['string'],
      nullable: true
    }
  },
  additionalProperties: true
}
