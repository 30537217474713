import {JSONSchemaType} from 'ajv'

export interface DeliveryNoteFeaturesBean {
  automatic_integration: boolean
}

export const deliveryNoteFeaturesBeanSchema: JSONSchemaType<DeliveryNoteFeaturesBean> = {
  type: 'object',
  properties: {
    'automatic_integration': {
      type: ['boolean']
    }
  },
  required: ['automatic_integration'],
  additionalProperties: true
}
