import {ActionContext, Module} from 'vuex'
import {AppState} from '@/stores'
import {getItemOperation, GetOperation} from '@/service/operation/GetOperation'
import {DriverBean} from '@/service/model/DriverBean'
import Result from '@/service/operation/Result'
import {LocalStore} from '@/service/store/LocalStore'
import {siteManagerTruckArraySchema, SiteManagerTruckBean} from '@/service/model/sitemanager/SiteManagerTruckBean'
import {I18n} from 'vue-i18n'
import {SiteManagerTruckApi} from '@/service/api/sitemanager/SiteManagerTruckApi'
import {makeException} from '@/exception/Exception'
import {modalController} from '@ionic/vue'
import SiteManagerSelectTruckModal from '@/views/sitemanager/truck/SiteManagerSelectTruckModal.vue'
import {SiteManagerAddTruckModule} from '@/stores/modules/sitemanager/SiteManagerAddTruckModule'

const TRUCKS_STORAGE_KEY = 'trucks'
const TRUCKS_STORE_KEY = 'trucks'

export interface SiteManagerTruckModuleState {
  trucksOperation: GetOperation<Array<DriverBean>, string>
  trucksResult: Result<Array<DriverBean>>
}

export class SiteManagerTruckModule implements Module<SiteManagerTruckModuleState, AppState> {
  namespaced = true
  actions = {
    selectTruck: this.selectTruckAction.bind(this),
    fetchTrucks: this.fetchTrucksAction.bind(this),
    refreshTrucks: this.refreshTrucksAction.bind(this),
    clearTruckModule: this.clearTruckModuleAction.bind(this),
  }
  mutations = {
    setTrucksOperation: (state, operation) => state.trucksOperation = operation,
    setTrucksResult: (state, result) => state.trucksResult = result
  }
  getters = {
    trucksResult: (state) => state.trucksResult
  }
  modules = {}
  
  private readonly trucksStore: LocalStore<Array<SiteManagerTruckBean>, string>
  
  constructor(
    private readonly i18n: I18n,
    private readonly siteManagerTruckApi: SiteManagerTruckApi,
    private readonly addTruckModule: SiteManagerAddTruckModule
  ) {
    this.trucksStore = new LocalStore<Array<SiteManagerTruckBean>, string>({
      localStorageKey: TRUCKS_STORAGE_KEY,
      schema: siteManagerTruckArraySchema
    })
    
    this.modules = {
      add: addTruckModule
    }
  }
  
  async fetchTrucksAction(
    context: ActionContext<SiteManagerTruckModuleState, AppState>
  ): Promise<Array<SiteManagerTruckBean> | null> {
    const operation = getItemOperation(
      this.trucksStore,
      () => this.siteManagerTruckApi.listTrucks()
    )
    
    context.commit('setTrucksOperation', operation)
    
    return await operation.get(
      {
        params: TRUCKS_STORE_KEY
      },
      it => {
        context.commit('setTrucksResult', it)
      }
    )
  }
  
  async refreshTrucksAction(
    context: ActionContext<SiteManagerTruckModuleState, AppState>
  ): Promise<Array<DriverBean> | null> {
    const operation = context.state.trucksOperation
    if (!operation) {
      throw makeException(this.i18n, 'error.unknown')
    }
    return operation.refresh(true)
  }
  
  async selectTruckAction(): Promise<DriverBean | undefined> {
    const modal = await modalController.create({
      component: SiteManagerSelectTruckModal,
      backdropDismiss: false,
      swipeToClose: false
    })
    await modal.present()
    
    const result = await modal.onDidDismiss<DriverBean | undefined>()
    return result.data
  }
  
  clearTruckModuleAction(
    context: ActionContext<SiteManagerTruckModuleState, AppState>
  ) {
    context.commit('setTrucksResult', undefined)
    context.commit('setTrucksOperation', undefined)
    this.trucksStore.clear()
  }
}
