import {Flow, FlowStep} from '@/service/flow/Flow'
import {CustomerBean} from '@/service/model/CustomerBean'
import {SiteBean} from '@/service/model/SiteBean'
import {QuarryBean} from '@/service/model/QuarryBean'
import {MaterialBean} from '@/service/model/MaterialBean'
import {CustomerOpenOrder} from '@/service/model/customer/CustomerOpenOrder'
import {RouteLocationRaw} from 'vue-router'
import {Picture} from '@/service/model/PictureBean'
import _ from 'lodash'
import {SuperCustomerConfirmRoute} from '@/router/supercustomer'

export interface SuperCustomerConfirmFlowState {
  customer?: CustomerBean
  site?: SiteBean
  quarry?: QuarryBean
  deliveryType?: string
  material?: MaterialBean
  picture?: Picture
  day?: string
  truckRegistration?: string
  netWeight?: number
}

export function getCustomers(orders: Array<CustomerOpenOrder>): Array<CustomerBean> {
  const customers = {}
  for (const order of orders) {
    customers[order.customer.id] = order.customer
  }
  return _.values(customers)
}

export function getSites(orders: Array<CustomerOpenOrder>, state: SuperCustomerConfirmFlowState): Array<SiteBean> {
  const sites = {}
  for (const order of orders) {
    if (state.customer?.id !== order.customer.id) {
      continue
    }
    sites[order.site.id || ''] = order.site
  }
  return _.values(sites)
}

export function getQuarries(orders: Array<CustomerOpenOrder>, state: SuperCustomerConfirmFlowState): Array<QuarryBean> {
  const quarries = {}
  for (const order of orders) {
    if (state.customer?.id !== order.customer.id) {
      continue
    }
    if (state.site?.id !== order.site.id) {
      continue
    }
    quarries[order.quarry.id] = order.quarry
  }
  return _.values(quarries)
}

export function getDeliveryTypes(
  orders: Array<CustomerOpenOrder>,
  state: SuperCustomerConfirmFlowState,
  quarry: QuarryBean
): Array<string> {
  const deliveryTypes: Array<string> = []
  for (const order of orders) {
    if (state.customer?.id !== order.customer.id) {
      continue
    }
    if (state.site?.id !== order.site.id) {
      continue
    }
    if (quarry.id !== order.quarry.id) {
      continue
    }
    if (!deliveryTypes.includes(order.delivery_type)) {
      deliveryTypes.push(order.delivery_type)
    }
  }
  return deliveryTypes
}

export function getMaterials(
  orders: Array<CustomerOpenOrder>,
  state: SuperCustomerConfirmFlowState,
  quarry: QuarryBean,
  deliveryType: string
): Array<MaterialBean> {
  const materials = {}
  for (const order of orders) {
    if (state.customer?.id !== order.customer.id) {
      continue
    }
    if (state.site?.id !== order.site.id) {
      continue
    }
    if (quarry.id !== order.quarry.id) {
      continue
    }
    if (deliveryType !== order.delivery_type) {
      continue
    }
    if (order.quarry.id === quarry.id) {
      materials[order.material.id] = order.material
      if (order.backload_material) {
        materials[order.backload_material.id] = order.backload_material
      }
    }
  }
  return _.values(materials)
}

export const SuperCustomerConfirmSelectCustomerStep: FlowStep<Array<CustomerOpenOrder>, SuperCustomerConfirmFlowState> = {
  routeName: SuperCustomerConfirmRoute.SELECT_CUSTOMER,
  canEnter: (item) => {
    if (item.length === 0) { // Allow to go to this step when flow reset.
      return true
    }
    return getCustomers(item).length > 1
  },
  isCompleted: state => state.customer !== undefined
}

export const SuperCustomerConfirmSelectSiteStep: FlowStep<Array<CustomerOpenOrder>, SuperCustomerConfirmFlowState> = {
  routeName: SuperCustomerConfirmRoute.SELECT_SITE,
  canEnter: (item, state) => {
    return getSites(item, state).length > 1
  },
  isCompleted: state => state.site !== undefined
}

export const SuperCustomerConfirmSelectMaterialStep: FlowStep<Array<CustomerOpenOrder>, SuperCustomerConfirmFlowState> = {
  routeName: SuperCustomerConfirmRoute.SELECT_MATERIAL,
  isCompleted: state => state.quarry !== undefined && state.material !== undefined
}

export const SuperCustomerConfirmTakePictureStep: FlowStep<Array<CustomerOpenOrder>, SuperCustomerConfirmFlowState> = {
  routeName: SuperCustomerConfirmRoute.PICTURE,
  isCompleted: state => state.picture !== undefined
}

export const SuperCustomerConfirmDayStep: FlowStep<Array<CustomerOpenOrder>, SuperCustomerConfirmFlowState> = {
  routeName: SuperCustomerConfirmRoute.DAY,
  isCompleted: state => state.day !== undefined
}

export const SuperCustomerConfirmTruckRegistrationStep: FlowStep<Array<CustomerOpenOrder>, SuperCustomerConfirmFlowState> = {
  routeName: SuperCustomerConfirmRoute.TRUCK_REGISTRATION,
  isCompleted: state => state.truckRegistration !== undefined
}

export const SuperCustomerConfirmWeightStep: FlowStep<Array<CustomerOpenOrder>, SuperCustomerConfirmFlowState> = {
  routeName: SuperCustomerConfirmRoute.WEIGHT,
  isCompleted: state => state.netWeight !== undefined
}

export class SuperCustomerConfirmFlow extends Flow<null, Array<CustomerOpenOrder>, SuperCustomerConfirmFlowState> {
  
  constructor() {
    super(null, [], {})
  }
  
  getKeysToCopyWithoutDeepMerge(): Array<string> {
    return ['picture']
  }
  
  setCustomer(customer: CustomerBean | undefined) {
    this.assignState({
      customer: customer,
      site: undefined,
      quarry: undefined,
      deliveryType: undefined,
      material: undefined,
      picture: undefined,
      date: undefined,
      truckRegistration: undefined,
      netWeight: undefined
    })
  }
  
  setSite(site: SiteBean | undefined) {
    this.assignState({
      site: site,
      quarry: undefined,
      deliveryType: undefined,
      material: undefined,
      picture: undefined,
      date: undefined,
      truckRegistration: undefined,
      netWeight: undefined
    })
  }
  
  setQuarry(quarry: QuarryBean | undefined) {
    this.assignState({
      quarry: quarry,
      deliveryType: undefined,
      material: undefined,
      picture: undefined,
      date: undefined,
      truckRegistration: undefined,
      netWeight: undefined
    })
  }
  
  setDeliveryType(deliveryType: string | undefined) {
    this.assignState({
      deliveryType: deliveryType,
      material: undefined,
      picture: undefined,
      date: undefined,
      truckRegistration: undefined,
      netWeight: undefined
    })
  }
  
  setMaterial(material: MaterialBean | undefined) {
    this.assignState({
      material: material,
      picture: undefined,
      date: undefined,
      netWeight: undefined
    })
  }
  
  setPicture(picture: Picture | undefined) {
    this.assignState({
      picture: picture
    })
  }
  
  setDay(day: string | undefined) {
    this.assignState({
      day: day
    })
  }
  
  setTruckRegistration(truckRegistration: string | undefined) {
    this.assignState({
      truckRegistration: truckRegistration
    })
  }
  
  setNetWeight(netWeight: number | undefined) {
    this.assignState({
      netWeight: netWeight
    })
  }
  
  getCancelRoute(): RouteLocationRaw {
    return {
      name: SuperCustomerConfirmRoute.SELECT_CUSTOMER
    }
  }
  
  getOutputRoute(): RouteLocationRaw {
    return {
      name: SuperCustomerConfirmRoute.SELECT_CUSTOMER
    }
  }
  
  getSteps(): Array<FlowStep<Array<CustomerOpenOrder>, SuperCustomerConfirmFlowState>> {
    return [
      SuperCustomerConfirmSelectCustomerStep,
      SuperCustomerConfirmSelectSiteStep,
      SuperCustomerConfirmSelectMaterialStep,
      SuperCustomerConfirmTakePictureStep,
      SuperCustomerConfirmDayStep,
      SuperCustomerConfirmTruckRegistrationStep,
      SuperCustomerConfirmWeightStep
    ]
  }
}
