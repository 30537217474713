import {AbstractApi} from '@/service/api/AbstractApi'
import {I18n} from 'vue-i18n'
import {AuthenticationService} from '@/service/authentication/AuthenticationService'
import {
  siteManagerCustomerArraySchema,
  SiteManagerCustomerBean
} from '@/service/model/sitemanager/SiteManagerCustomerBean'

export class SiteManagerCustomerApi extends AbstractApi {
  
  constructor(i18n: I18n, authenticationService: AuthenticationService) {
    super(i18n, authenticationService)
  }
  
  async fetchCustomers(): Promise<Array<SiteManagerCustomerBean>> {
    return this.get({
      path: '/api/mp/v1/site-manager/customers',
      authentication: 'required',
      schema: siteManagerCustomerArraySchema
    })
  }
}
