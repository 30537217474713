import {ActionContext, Module} from 'vuex'
import {AppState} from '@/stores'
import {I18n} from 'vue-i18n'
import {isBlankOrEmpty} from '@/utils/StringUtils'
import {makeException} from '@/exception/Exception'
import {Directory, Filesystem} from '@capacitor/filesystem'
import {blobToBase64} from '@/utils/Base64Utils'

export interface DownloadParams {
  directory: string
  fileName: string
  contentType: string
  exported: string
  download: () => Promise<Blob>
}

export interface DownloadResult {
  path: string
  directory: Directory
  uri: string
  exportedUri: string | undefined
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FileModuleState {}

export class FileModule implements Module<FileModuleState, AppState> {
  namespaced = true
  actions = {
    download: this.downloadAction.bind(this),
    clearFileModule: this.clearFileModuleAction.bind(this)
  }
  
  constructor(
    private readonly i18n: I18n
  ) {
  }
  
  async downloadAction(
    context: ActionContext<FileModule, AppState>,
    params: DownloadParams
  ): Promise<DownloadResult> {
    const path = this.getFilePath(params)
    await this.createDirectoryIfNotExist(params)
    
    // FIXME: Do not download if exists
    
    const blob = await params.download()
    const base64Pdf = await blobToBase64(blob)
    
    await Filesystem.writeFile({
      path: path,
      data: base64Pdf,
      directory: Directory.Cache
    })
    
    const result = await Filesystem.getUri({
      path: path,
      directory: Directory.Cache
    })
    return {
      path: path,
      directory: Directory.Cache,
      uri: result.uri,
      exportedUri: this.getExportedUri(params)
    }
  }
  
  private getDirectoryPath(params: DownloadParams) {
    if (isBlankOrEmpty(params.directory)) {
      throw makeException(this.i18n, 'error.unknown')
    }
    if (params.exported) {
      return `exported/${params.directory}`
    } else {
      return `internal/${params.directory}`
    }
  }
  
  private async createDirectoryIfNotExist(params: DownloadParams) {
    try {
      await Filesystem.mkdir({
        path: this.getDirectoryPath(params),
        directory: Directory.Cache,
        recursive: true
      })
    } catch (e) {
      // We must ignore the error since it will trigger: Directory exists
    }
  }
  
  private getFilePath(params: DownloadParams) {
    if (isBlankOrEmpty(params.fileName)) {
      throw makeException(this.i18n, 'error.unknown')
    }
    return `${this.getDirectoryPath(params)}/${params.fileName}`
  }
  
  private getExportedUri(params: DownloadParams): string | undefined {
    if (!params.exported) {
      return undefined
    }
    return `content://io.rockease.app.fileprovider/exported_cache_files/${params.directory}/${params.fileName}`
  }
  
  private async clearFileModuleAction() {
    // FIXME: Delete all files downloaded
  }
}
