import {I18n} from 'vue-i18n'
import {AuthenticationService} from '@/service/authentication/AuthenticationService'
import {CustomerOrderApi} from '@/service/api/customer/CustomerOrderApi'
import {SuperCustomerConfirmModule} from '@/stores/modules/supercustomer/CustomerConfimModule'
import {SuperCustomerModule} from '@/stores/modules/supercustomer/SuperCustomerModule'
import {SynchronizationService} from '@/service/SynchronizationService'

export function makeSuperCustomerModule(
  i18n: I18n,
  authenticationService: AuthenticationService,
  synchronizationService: SynchronizationService
): SuperCustomerModule {
  const orderApi = new CustomerOrderApi(i18n, authenticationService, synchronizationService)
  
  const confirmModule = new SuperCustomerConfirmModule(i18n, orderApi)
  
  return new SuperCustomerModule(
    confirmModule
  )
}
