import {DeliveryNoteBean, SynchronizedDeliveryNoteBean} from '@/service/model/DeliveryNoteBean'
import {PendingSync, SynchronizationService} from '@/service/SynchronizationService'
import {ROLE_DRIVER, ROLE_SITE_MANAGER} from '@/service/api/CurrentUserApi'
import {DriverDeliveryNoteSyncService} from '@/service/DriverDeliveryNoteSyncService'
import {SiteManagerDeliveryNoteSyncService} from '@/service/SiteManagerDeliveryNoteSyncService'
import _ from 'lodash'

export class DeliveryNoteSyncService {
  
  private readonly driverSync = new DriverDeliveryNoteSyncService()
  private readonly siteManagerSync = new SiteManagerDeliveryNoteSyncService()
  
  constructor(
    private readonly synchronizationService: SynchronizationService
  ) {
  }
  
  async getPendingSyncs(): Promise<Array<string>> {
    return this.synchronizationService.getPendingSyncs()
  }
  
  async syncArrayWithPending(
    role: string,
    deliveryNotes: Array<DeliveryNoteBean>
  ): Promise<Array<SynchronizedDeliveryNoteBean>> {
    const pendingIds = await this.getPendingSyncs()
    
    const syncDeliveryNotePromises: Array<Promise<SynchronizedDeliveryNoteBean>> = []
    for (const deliveryNote of deliveryNotes) {
      if (pendingIds.includes(deliveryNote.id)) {
        syncDeliveryNotePromises.push(this.syncWithPending(role, deliveryNote))
      }
    }
    
    const syncDeliveryNoteMap = _.keyBy(
      await Promise.all(syncDeliveryNotePromises),
      'id'
    )
    return deliveryNotes.map(it => syncDeliveryNoteMap[it.id] || it)
  }
  
  async syncWithPending(
    role: string,
    deliveryNote: DeliveryNoteBean
  ): Promise<SynchronizedDeliveryNoteBean> {
    const types = await this.synchronizationService.getPendingTypes(deliveryNote.id)
    if (types.length === 0) {
      return deliveryNote
    }
    
    const syncedDN = {
      ...deliveryNote,
      waiting_sync_fields: []
    }
    
    const pendingSyncPromises = types.map(it => {
      return this.synchronizationService.getPendingSync({
        id: deliveryNote.id,
        type: it
      })
    })
    const pendingSyncs = await Promise.all(pendingSyncPromises)
    
    for (const pendingSync of pendingSyncs) {
      this.applyPendingChangesToDeliveryNote(role, syncedDN, pendingSync)
    }
    return syncedDN
  }
  
  private applyPendingChangesToDeliveryNote(
    role: string,
    dn: SynchronizedDeliveryNoteBean,
    pendingSync: PendingSync<any>
  ) {
    if (role === ROLE_DRIVER) {
      this.driverSync.applyPendingChangesToDeliveryNote(dn, pendingSync)
    } else if (role === ROLE_SITE_MANAGER) {
      this.siteManagerSync.applyPendingChangesToDeliveryNote(dn, pendingSync)
    }
  }
  
  async clearByIdAndType(id: string, type: string): Promise<void> {
    return this.synchronizationService.clearByIdAndType(id, type)
  }
}
