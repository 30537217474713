import {JSONSchemaType} from 'ajv'
import {PersonBean, personSchema} from '@/service/model/PersonBean'

export interface DriverBean extends PersonBean {
  phone_number?: string | null
}

export const driverSchema: JSONSchemaType<DriverBean> = {
  type: 'object',
  properties: {
    ...personSchema.properties,
    phone_number: {
      type: ['string'],
      nullable: true
    }
  },
  additionalProperties: true
}

export const driverArraySchema: JSONSchemaType<Array<DriverBean>> = {
  type: 'array',
  items: driverSchema
}
