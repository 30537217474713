import {ActionContext, Module} from 'vuex'
import {AppState} from '@/stores'
import {
  QuotationRequestFlow,
  QuotationRequestFlowState,
  QuotationRequestTemporarySite
} from '@/service/flow/sitemanager/QuotationRequestFlow'
import {SiteBean} from '@/service/model/SiteBean'
import {I18n} from 'vue-i18n'
import {MaterialBean} from '@/service/model/MaterialBean'
import moment, {Moment} from 'moment/moment'
import {SiteManagerDeliveryNoteConfirmState} from '@/stores/modules/SiteManagerDeliveryNoteConfirmModule'
import {
  QuotationRequestAndResponsesBean,
  QuotationRequestApi,
  QuotationRequestCreationBean,
  QuotationRequestTemporarySiteBean
} from '@/service/api/sitemanager/QuotationRequestApi'
import {makeException} from '@/exception/Exception'
import {translateMessage} from '@/i18n'
import {RouteLocationRaw} from 'vue-router'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SiteManagerCreateQuotationRequestModuleState {
  flow: QuotationRequestFlow
}

export class SiteManagerCreateQuotationRequestModule implements Module<SiteManagerCreateQuotationRequestModuleState, AppState> {
  namespaced = true
  actions = {
    createQuotationRequestFlow: this.createQuotationRequestFlow.bind(this),
    setSite: this.setSiteAction.bind(this),
    useTemporarySite: this.useTemporarySiteAction.bind(this),
    setCustomer: this.setCustomerAction.bind(this),
    setTemporarySite: this.setTemporarySiteAction.bind(this),
    setDeliveryType: this.setDeliveryTypeAction.bind(this),
    setWithBackload: this.setWithBackloadAction.bind(this),
    setMaterial: this.setMaterialAction.bind(this),
    setBackloadMaterial: this.setBackloadMaterialAction.bind(this),
    setMaterialQuantity: this.setMaterialQuantityAction.bind(this),
    setMaterialCadence: this.setMaterialCadenceAction.bind(this),
    setMaterialCadencePeriodicity: this.setMaterialCadencePeriodicityAction.bind(this),
    setBackloadMaterialQuantity: this.setBackloadMaterialQuantityAction.bind(this),
    setTruckType: this.setTruckTypeAction.bind(this),
    setDay: this.setDayAction.bind(this),
    setComment: this.setCommentAction.bind(this),
    requestQuotation: this.requestQuotationAction.bind(this),
  }
  mutations = {
    setFlow: (state, flow) => state.flow = flow,
    setSite: (state, site) => state.flow?.setSite(site),
    setUseTemporarySite: (state, useTemporarySite) => state.flow?.setUseTemporarySite(useTemporarySite),
    setCustomer: (state, customer) => state.flow?.setCustomer(customer),
    setTemporarySite: (state, temporarySite) => state.flow?.setTemporarySite(temporarySite),
    setDeliveryType: (state, deliveryType) => state.flow?.setDeliveryType(deliveryType),
    setWithBackload: (state, withBackload) => state.flow?.setWithBackload(withBackload),
    setMaterial: (state, material) => state.flow?.setMaterial(material),
    setBackloadMaterial: (state, material) => state.flow?.setBackloadMaterial(material),
    setMaterialQuantity: (state, quantity) => state.flow?.setMaterialQuantity(quantity),
    setMaterialCadence: (state, cadence) => state.flow?.setMaterialCadence(cadence),
    setMaterialCadencePeriodicity: (state, period) => state.flow?.setMaterialCadencePeriodicity(period),
    setBackloadMaterialQuantity: (state, quantity) => state.flow?.setBackloadMaterialQuantity(quantity),
    setTruckType: (state, truckType) => state.flow?.setTruckType(truckType),
    setDay: (state, day) => state.flow?.setDay(day),
    setComment: (state, comment) => state.flow?.setComment(comment),
  }
  getters = {
    flow: (state) => state.flow
  }
  
  constructor(
    private readonly i18n: I18n,
    private readonly quotationRequestApi: QuotationRequestApi
  ) {
  
  }
  
  async createQuotationRequestFlow(
    context: ActionContext<SiteManagerCreateQuotationRequestModuleState, AppState>,
    params?: {initialRoute?: RouteLocationRaw}
  ): Promise<QuotationRequestFlow> {
    const flow = new QuotationRequestFlow(params?.initialRoute)
    context.commit('setFlow', flow)
    return flow
  }
  
  setSiteAction(
    context: ActionContext<SiteManagerCreateQuotationRequestModuleState, AppState>,
    site: SiteBean | undefined
  ) {
    context.commit('setUseTemporarySite', false)
    context.commit('setSite', site)
  }
  
  useTemporarySiteAction(
    context: ActionContext<SiteManagerCreateQuotationRequestModuleState, AppState>
  ) {
    context.commit('setUseTemporarySite', true)
  }
  
  setCustomerAction(
    context: ActionContext<SiteManagerCreateQuotationRequestModuleState, AppState>,
    customer: QuotationRequestTemporarySiteBean | undefined
  ) {
    context.commit('setCustomer', customer)
  }
  
  setTemporarySiteAction(
    context: ActionContext<SiteManagerCreateQuotationRequestModuleState, AppState>,
    temporarySite: QuotationRequestTemporarySite | undefined
  ) {
    context.commit('setTemporarySite', temporarySite)
  }
  
  setDeliveryTypeAction(
    context: ActionContext<SiteManagerCreateQuotationRequestModuleState, AppState>,
    deliveryType: string | undefined
  ) {
    context.commit('setDeliveryType', deliveryType)
  }
  
  setWithBackloadAction(
    context: ActionContext<SiteManagerCreateQuotationRequestModuleState, AppState>,
    withBackload: boolean | undefined
  ) {
    context.commit('setWithBackload', withBackload)
  }
  
  setMaterialAction(
    context: ActionContext<SiteManagerCreateQuotationRequestModuleState, AppState>,
    material: MaterialBean | undefined
  ) {
    context.commit('setMaterial', material)
  }
  
  setBackloadMaterialAction(
    context: ActionContext<SiteManagerCreateQuotationRequestModuleState, AppState>,
    material: MaterialBean | undefined
  ) {
    context.commit('setBackloadMaterial', material)
  }
  
  setMaterialQuantityAction(
    context: ActionContext<SiteManagerCreateQuotationRequestModuleState, AppState>,
    quantity: number | undefined
  ) {
    context.commit('setMaterialQuantity', quantity)
  }
  
  setMaterialCadenceAction(
    context: ActionContext<SiteManagerCreateQuotationRequestModuleState, AppState>,
    cadence: number | undefined
  ) {
    context.commit('setMaterialCadence', cadence)
  }
  
  setMaterialCadencePeriodicityAction(
    context: ActionContext<SiteManagerCreateQuotationRequestModuleState, AppState>,
    period: number | undefined
  ) {
    context.commit('setMaterialCadencePeriodicity', period)
  }
  
  setBackloadMaterialQuantityAction(
    context: ActionContext<SiteManagerCreateQuotationRequestModuleState, AppState>,
    quantity: number | undefined
  ) {
    context.commit('setBackloadMaterialQuantity', quantity)
  }
  
  setBackloadMaterialCadenceAction(
    context: ActionContext<SiteManagerCreateQuotationRequestModuleState, AppState>,
    cadence: number | undefined
  ) {
    context.commit('setBackloadMaterialCadence', cadence)
  }
  
  setTruckTypeAction(
    context: ActionContext<SiteManagerCreateQuotationRequestModuleState, AppState>,
    truckType: string | undefined
  ) {
    context.commit('setTruckType', truckType)
  }
  
  async setDayAction(context: ActionContext<SiteManagerDeliveryNoteConfirmState, AppState>, day?: Moment) {
    if (day === undefined) {
      context.commit('setDay', undefined)
      return
    }
    const formattedDay = day.format(moment.HTML5_FMT.DATE)
    context.commit('setDay', formattedDay)
  }
  
  setCommentAction(
    context: ActionContext<SiteManagerCreateQuotationRequestModuleState, AppState>,
    comment: string | undefined
  ) {
    context.commit('setComment', comment)
  }
  
  async requestQuotationAction(
    context: ActionContext<SiteManagerCreateQuotationRequestModuleState, AppState>
  ): Promise<QuotationRequestAndResponsesBean> {
    const bean = this.getBean(context.state)
    if (bean === undefined) {
      throw makeException(this.i18n, 'quotationRequest.unknown')
    }
  
    const result = await this.quotationRequestApi.create(bean)
  
    await context.dispatch(
      'message/setMessage',
      {message: translateMessage(this.i18n, 'quotationRequest.success')},
      {root: true}
    )
    return result
  }
  
  getBean(
    state: SiteManagerCreateQuotationRequestModuleState
  ): QuotationRequestCreationBean | undefined {
    const flowState: QuotationRequestFlowState | undefined = state.flow?.getState()
    if (
      flowState?.deliveryType === undefined ||
      flowState?.material === undefined ||
      flowState?.materialQuantity === undefined
    ) {
      return undefined
    }
    
    const temporarySite = this.getTemporarySiteBean(flowState)
    if (flowState.useTemporarySite && temporarySite === undefined) {
      return undefined
    }
    
    return {
      site_id: flowState.site?.id,
      temporary_site: temporarySite,
      
      express_order: false,
      delivery_type: flowState.deliveryType,
      truck_type: flowState.truckType,
      
      start_day: flowState.day,
      
      material_id: flowState.material?.id,
      material_type: flowState.material?.type,
      material_quantity: flowState.materialQuantity,
      material_cadence: flowState.materialCadence,
      material_cadence_periodicity: flowState.materialCadencePeriodicity,
      
      backload_material_id: flowState.backloadMaterial?.id,
      backload_material_quantity: flowState.backloadMaterialQuantity,
      
      comment: flowState.comment
    }
  }
  
  getTemporarySiteBean(
    flowState: QuotationRequestFlowState
  ): QuotationRequestTemporarySiteBean | undefined {
    const temporarySite = flowState.temporarySite
    if (
      temporarySite?.customer_id === undefined ||
      temporarySite?.name === undefined ||
      temporarySite?.address_1 === undefined ||
      temporarySite?.postal_code === undefined ||
      temporarySite?.city === undefined
    ) {
      return undefined
    }
    return {
      customer_id: temporarySite.customer_id,
      name: temporarySite.name,
      reference: temporarySite.reference,
      address_1: temporarySite.address_1,
      address_2: temporarySite.address_2,
      postal_code: temporarySite.postal_code,
      city: temporarySite.city
    }
  }
}
