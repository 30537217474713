import {createI18n, I18n, LocaleMessages, NamedValue, VueMessageType} from 'vue-i18n'

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages(): LocaleMessages<VueMessageType> {
  if (process.env.NODE_ENV === 'test') {
    return {}
  }
  
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages: LocaleMessages<VueMessageType> = {}
  if (locales !== undefined) {
    locales.keys().forEach(key => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i)
      if (matched && matched.length > 1) {
        const locale = matched[1]
        messages[locale] = locales(key).default
      }
    })
  }
  return messages
}

export function translateMessage(i18n: I18n, messageKey: string, values?: NamedValue): string {
  if (!values) {
    values = {}
  }
  const {t} = i18n.global
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return t(messageKey, values)
}

export default createI18n({
  legacy: false,
  // for i18n-t tag to work properly: https://github.com/intlify/vue-i18n-next/issues/350#issuecomment-782007971
  globalInjection: true,
  locale: navigator.language,
  missingWarn: false,
  fallbackWarn: false,
  fallbackLocale: 'en',
  fallbackRoot: true,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  messages: loadLocaleMessages()
})
