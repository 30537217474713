import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_menu_item = _resolveComponent("main-menu-item")!

  return (_openBlock(), _createBlock(_component_main_menu_item, {
    target: "_blank",
    icon: _ctx.icon,
    label: _ctx.label,
    onClick: _ctx.onClicked
  }, null, 8, ["icon", "label", "onClick"]))
}