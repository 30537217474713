import {registerPlugin} from '@capacitor/core'

export interface GetLastResultResult {
  ids: Array<string>
  failed_ids: Array<string>
}

export interface GetPendingIdsResult {
  ids: Array<string>
}

export interface GetPendingTypesParams {
  id: string
}

export interface GetPendingTypesResult {
  types: Array<string>
}

export interface ClearByIdAndTypeParams {
  id: string
  type: string
}

/**
 * Requests sharing id will be synchronized together and in order they were scheduled initially.
 * This is done in order to keep the states coherent on the server side.
 * Ex. delivery note must before go IN_DELIVERY before being DELIVERED.
 */
export interface SynchronizePluginParams {
  id: string
  type: string
  sub_type?: string
  method: string
  url: string
  
  msal_client_id: string
  msal_authority: string
  msal_scope: string
  msal_custom_policy?: string
  
  content_type: string
  content: any
}

export interface SynchronizePluginParamsFormField {
  content?: string
  
  content_type?: string
  file_name?: string
  path?: string
}

export interface GetWaitingContentParams {
  id: string
  type: string
}

export interface GetWaitingContentResult {
  id: string
  type: string
  sub_type?: string
  url: string
  contentType: string
  content: any
}

export interface SynchronizePluginResult {
  /**
   * The requests could not be executed immediately and are scheduled for sync later.
   */
  pending: boolean
  /**
   * The requests failed and the error reason prevent us to retry.
   */
  failed: boolean
  response_code?: number
  response_content_type?: string
  response_content?: any
}

export interface SynchronizeAllPluginResult {
  pending: boolean
  failed_ids: Array<string>
}

export interface GetPendingRequestParams {
  id: string
  type: string
  sub_type?: string
}

export interface GetPendingRequestResult {
  content_type?: string
  content?: any
}

export interface SynchronizePlugin {
  
  getLastResult(): Promise<GetLastResultResult>
  
  getPendingIds(): Promise<GetPendingIdsResult>
  
  getPendingTypes(params: GetPendingTypesParams): Promise<GetPendingTypesResult>
  
  synchronize(params: SynchronizePluginParams): Promise<SynchronizePluginResult>
  
  synchronizeAll(): Promise<SynchronizeAllPluginResult>
  
  synchronizeOne(params: {id: string}): Promise<SynchronizeAllPluginResult>
  
  getPendingRequest(params: GetPendingRequestParams): Promise<GetPendingRequestResult>
  
  clear(): Promise<void>
  
  clearByIdAndType(params: ClearByIdAndTypeParams): Promise<void>
}

export const SynchronizePlugin = registerPlugin<SynchronizePlugin>('Synchronize')
