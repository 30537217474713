import {ActionContext, Module} from 'vuex'
import {AppState} from '@/stores'
import {alertController} from '@ionic/vue'
import {I18n} from 'vue-i18n'
import {Capacitor} from '@capacitor/core'
import {WebIntent} from '@awesome-cordova-plugins/web-intent'
import {isBlankOrEmpty} from '@/utils/StringUtils'
import {makeException} from '@/exception/Exception'
import {Directory, Filesystem, ReadFileOptions, WriteFileOptions} from '@capacitor/filesystem'
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser'
import {DownloadResult} from '@/stores/modules/FileModule'
import {FileOpener, FileOpenerOptions} from '@capacitor-community/file-opener'
import {translateMessage} from '@/i18n'

export interface PdfParams {
  directory: string
  fileName: string
}

export interface DownloadAndOpenPdfParams extends PdfParams {
  downloadPdf: () => Promise<Blob>
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PdfModuleState {}

export class PdfModule implements Module<PdfModuleState, AppState> {
  namespaced = true
  actions = {
    downloadAndOpenPdf: this.downloadAndOpenPdfAction.bind(this)
  }
  
  constructor(
    private readonly i18n: I18n
  ) {
  }
  
  async downloadAndOpenPdfAction(
    context: ActionContext<PdfModuleState, AppState>,
    params: DownloadAndOpenPdfParams
  ): Promise<void> {
    if (isBlankOrEmpty(params.directory) || isBlankOrEmpty(params.fileName)) {
      throw makeException(this.i18n, 'error.unknown')
    }
    
    const alert = await alertController.create({
      header: translateMessage(this.i18n, 'pdf.title'),
      message: translateMessage(this.i18n, 'pdf.message'),
      buttons: [translateMessage(this.i18n, 'button.cancel')],
    })
    
    this.downloadAndWritePdf(context, params).then(
      result => alert.dismiss(result, 'success'),
      e => alert.dismiss(e, 'error')
    )
    
    await alert.present()
    const result = await alert.onDidDismiss()
    if (result.role === 'success') {
      await this.openPdf(result.data)
    }
    if (result.role === 'error') {
      throw result.data
    }
  }
  
  private async downloadAndWritePdf(
    context: ActionContext<PdfModuleState, AppState>,
    params: DownloadAndOpenPdfParams
  ): Promise<DownloadResult> {
    // We must add the .pdf extension to the file otherwise some app may refuse to open the file on Android
    // including Adobe official one.
    let fileName = params.fileName
    if (!fileName.endsWith('.pdf')) {
      fileName = fileName + '.pdf'
    }
    
    return context.dispatch(
      'file/download',
      {
        directory: params.directory,
        fileName: fileName,
        exported: true,
        download: params.downloadPdf
      },
      {root: true}
    )
  }
  
  private async openPdf(result: DownloadResult) {
    if (Capacitor.getPlatform() === 'android') {
      await this.openPdfAndroid(result)
    } else if (Capacitor.getPlatform() === 'ios') {
      await this.openPdfIos(result)
    } else if (Capacitor.getPlatform() === 'web') {
      await this.openPdfWeb(result)
    }
  }
  
  private async openPdfAndroid(result: DownloadResult) {
    const options = {
      action: WebIntent.ACTION_VIEW,
      type: 'application/pdf',
      url: result.exportedUri,
      flags: [1] // Context.FLAG_GRANT_READ_URI_PERMISSION
    }
    await WebIntent.startActivity(options)
  }
  
  private async openPdfIos(result: DownloadResult) {
    const readOptions: ReadFileOptions = {
      path: result.path,
      directory: result.directory
    }
    const readResult = await Filesystem.readFile(readOptions)
    
    const writeOptions: WriteFileOptions = {
      path: 'rockease.pdf',
      data: readResult.data,
      directory: Directory.External
    }
    const writeResult = await Filesystem.writeFile(writeOptions)
    
    const openOptions: FileOpenerOptions = {
      filePath: writeResult.uri,
      contentType: 'application/pdf'
    }
    await FileOpener.open(openOptions)
  }
  
  private async openPdfWeb(result: DownloadResult) {
    const options = {
      path: result.path,
      directory: result.directory
    }
    const fileResult = await Filesystem.readFile(options)
    
    const byteCharacters = atob(fileResult.data as string)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const file = new Blob([byteArray], {type: 'application/pdf'})
    const pdfUrl = (window.URL || window.webkitURL).createObjectURL(file)
    
    InAppBrowser.create(pdfUrl, '_blank', {}).show()
  }
}
