<template>
  <ion-item v-if="errorMessage" color="danger">
    <ion-icon slot="start" :icon="warning"/>
    <ion-label class="ion-text-wrap">{{ errorMessage }}</ion-label>
    <ion-button v-if="noRetry === undefined || noRetry === false"
                slot="end" color="light"
                fill="clear" shape="round"
                @click="onRetryButtonClicked">
      <ion-icon slot="icon-only" v-bind:icon="reloadSharp"></ion-icon>
    </ion-button>
  </ion-item>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {reloadSharp, warning} from 'ionicons/icons'
import {IonButton, IonIcon, IonItem, IonLabel} from '@ionic/vue'

export default defineComponent({
  name: 'error-message-item',
  props: {
    errorMessage: String,
    noRetry: Boolean
  },
  emits: ['retry'],
  setup() {
    return {reloadSharp, warning}
  },
  methods: {
    onRetryButtonClicked() {
      this.$emit('retry')
    }
  },
  components: {
    IonItem,
    IonIcon,
    IonLabel,
    IonButton
  }
})
</script>
