import {Router, RouteRecordRaw} from 'vue-router'
import {Store} from 'vuex'
import {AppState} from '@/stores'
import {
  ROLE_ADMIN,
  ROLE_DRIVER,
  ROLE_QUARRY,
  ROLE_SITE_MANAGER,
  ROLE_SUPER_CUSTOMER_USER
} from '@/service/api/CurrentUserApi'
import {createRouter, createWebHistory} from '@ionic/vue-router'
import {makeDriverRoutes} from '@/router/driver'
import {makeSiteManagerRoutes} from '@/router/sitemanager'
import PathNotFoundPage from '@/views/PathNotFoundPage.vue'
import {isWebApp} from '@/utils/OSUtils'
import {makeSuperCustomerRoutes} from '@/router/supercustomer'

export function makeAppRoutes(
  store: Store<AppState>
): Array<RouteRecordRaw> {
  return [
    {
      path: '/',
      name: 'Home',
      redirect: () => {
        const currentUser = store.getters.currentUser
        if (!currentUser) {
          return {name: 'Login'}
        }
        switch (currentUser.role) {
        case ROLE_DRIVER:
          return {name: 'DriverHome'}
        case ROLE_QUARRY:
          return {name: 'QuarryHome'}
        case ROLE_SITE_MANAGER:
          return {name: 'SiteManagerHome'}
        case ROLE_SUPER_CUSTOMER_USER:
          return {name: 'SuperCustomerHome'}
        case ROLE_ADMIN:
          return {name: 'AdminHome'}
        default:
          return {name: 'Login'}
        }
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import(/* webpackChunkName: "login" */ '@/views/login/SelectRolePage.vue')
    },
    {
      path: '/parameters',
      name: 'Parameters',
      component: () => import(/* webpackChunkName: "common" */ '@/views/common/ParametersPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin',
      name: 'AdminHome',
      component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/AdminHomePage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    ...makeDriverRoutes(store),
    ...makeSuperCustomerRoutes(store)
  ]
}

export function makeWebAppRoutes(
  store: Store<AppState>
): Array<RouteRecordRaw> {
  return [
    {
      // This route is deep-linked to be opened in the app from the SMS.
      path: '/login/code/:code',
      // This is the same route as the previous one however it is not deep linked.
      // This allows the app to force the delivery note to be open in a web browser.
      alias: ['/login/web-code/:code'],
      name: 'LoginBySiteManagerCodePage',
      component: () => import(/* webpackChunkName: "login" */ '@/views/login/LoginBySiteManagerCodePage.vue')
    },
    ...makeSiteManagerRoutes(store)
  ]
}

export function makeRouter(
  store: Store<AppState>
): Router {
  const routes: Array<RouteRecordRaw> = []
  
  if (!isWebApp) {
    routes.push(...makeAppRoutes(store))
  }
  routes.push(...makeWebAppRoutes(store))
  
  // Make sure 404 route is always the last one declared.
  routes.push({
    path: '/:pathMatch(.*)*',
    component: PathNotFoundPage
  })
  
  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  })
  
  router.beforeEach((to, from, next) => {
    // Check if the route requires authentication and if we are logged in.
    if (to.matched.some(record => record.meta.requiresAuth)) {
      store.dispatch('isLoggedIn').then(
        (isLoggedIn: boolean) => {
          if (!isLoggedIn) {
            next({name: 'Login'})
          } else {
            next()
          }
        }
      )
    } else {
      next()
    }
  })
  
  return router
}
