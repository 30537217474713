import {QuarryBean, quarrySchema} from '@/service/model/QuarryBean'
import {JSONSchemaType} from 'ajv'
import {SiteManagerMaterialBean, siteManagerMaterialSchema} from '@/service/model/sitemanager/SiteManagerMaterialBean'
import {SiteManagerQuotationRequestBean} from '@/service/model/sitemanager/SiteManagerQuotationRequestBean'

export interface SiteManagerQuotationResponseBean {
  id: string
  quotation_request_id: string
  
  quarry: QuarryBean
  
  quarry_distance?: number | null
  quarry_travel_duration?: number | null
  co2?: number | null
  
  offered_material: SiteManagerMaterialBean
  quarry_material_name?: string | null
  quarry_backload_material_name?: string | null
  
  material_has_tech_spec: boolean
  
  material_price?: number | null
  backload_material_price?: number | null
  
  delivery_type: string
  
  comment?: string | null
  
  status: string
  validated: boolean
  above_spend_limit: boolean
}

export const siteManagerQuotationResponseSchema: JSONSchemaType<SiteManagerQuotationResponseBean> = {
  type: 'object',
  properties: {
    id: {
      type: ['string']
    },
    quotation_request_id: {
      type: ['string']
    },
    quarry: {
      ...quarrySchema
    },
    quarry_distance: {
      type: ['number'],
      nullable: true
    },
    quarry_travel_duration: {
      type: ['number'],
      nullable: true
    },
    co2: {
      type: ['number'],
      nullable: true
    },
    offered_material: {
      ...siteManagerMaterialSchema
    },
    quarry_material_name: {
      type: ['string'],
      nullable: true
    },
    quarry_backload_material_name: {
      type: ['string'],
      nullable: true
    },
    material_has_tech_spec: {
      type: ['boolean']
    },
    material_price: {
      type: ['number'],
      nullable: true
    },
    backload_material_price: {
      type: ['number'],
      nullable: true
    },
    delivery_type: {
      type: ['string']
    },
    comment: {
      type: ['string'],
      nullable: true
    },
    status: {
      type: ['string']
    },
    validated: {
      type: ['boolean']
    },
    above_spend_limit: {
      type: ['boolean']
    }
  },
  required: [
    'id', 'quotation_request_id', 'quarry', 'offered_material',
    'delivery_type', 'status', 'validated', 'above_spend_limit'
  ],
  additionalProperties: true
}

export function computeTotal(
  request: SiteManagerQuotationRequestBean,
  response: SiteManagerQuotationResponseBean
): number {
  let total = (response.material_price || 0) * request.material_quantity
  if (request.backload_material_quantity != null) {
    total += (response.backload_material_price || 0) * request.backload_material_quantity
  }
  return total
}

export function sortedByTotalAsc(
  request: SiteManagerQuotationRequestBean
): (a: SiteManagerQuotationResponseBean, b: SiteManagerQuotationResponseBean) => number {
  return (a, b) => computeTotal(request, a) - computeTotal(request, b)
}

export const siteManagerQuotationResponseArraySchema: JSONSchemaType<Array<SiteManagerQuotationResponseBean>> = {
  type: 'array',
  items: siteManagerQuotationResponseSchema
}
