import {App, AppState} from '@capacitor/app'
import {Subscription} from '@/service/subscription/Subscription'
import {PluginListenerHandleSubscription} from '@/service/subscription/PluginListenerHandleSubscription'

export function waitForAppToResume(): Promise<void> {
  return new Promise((resolve, reject) => {
    App.addListener('appStateChange', (appState: AppState) => {
      if (appState.isActive) {
        resolve()
      }
    }).catch(e => reject(e))
  })
}

export function registerToAppState(callback: (appState: AppState) => void): Subscription {
  const handle = App.addListener('appStateChange', callback)
  return new PluginListenerHandleSubscription([handle])
}
