import {SiteManagerCustomerBean} from '@/service/model/sitemanager/SiteManagerCustomerBean'
import {AddDriverFlowState} from '@/service/flow/sitemanager/AddDriverFlow'
import {Flow, FlowStep} from '@/service/flow/Flow'
import {CustomerBean} from '@/service/model/CustomerBean'
import {RouteLocationRaw} from 'vue-router'
import {SiteManagerAddTruckRoute} from '@/router/sitemanager'

export interface AddTruckFlowState {
  customer?: SiteManagerCustomerBean
  truckRegistration?: string
}

export const AddTruckFlowSelectCustomerStep: FlowStep<null, AddTruckFlowState> = {
  routeName: SiteManagerAddTruckRoute.SELECT_CUSTOMER,
  isCompleted: state => state.customer !== undefined
}

export const AddTruckFlowConfirmStep: FlowStep<null, AddTruckFlowState> = {
  routeName: SiteManagerAddTruckRoute.CONFIRM,
  isCompleted: state => state.truckRegistration !== undefined
}

export class AddTruckFlow extends Flow<null, null, AddTruckFlowState> {
  
  constructor() {
    super(null, null, {})
  }
  
  setCustomer(customer?: CustomerBean | undefined) {
    this.assignState({
      customer: customer
    })
  }
  
  setTruckRegistration(truckRegistration?: string | undefined) {
    this.assignState({
      truckRegistration: truckRegistration
    })
  }
  
  getOutputRoute(): RouteLocationRaw {
    return {
      name: 'SiteManagerTrucks'
    }
  }
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getCancelRoute(): RouteLocationRaw {
    return {
      name: 'SiteManagerTrucks'
    }
  }
  
  getSteps(): Array<FlowStep<null, AddDriverFlowState>> {
    return [
      AddTruckFlowSelectCustomerStep,
      AddTruckFlowConfirmStep
    ]
  }
}
