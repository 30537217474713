import {BroadcastPromise} from '@/utils/BroadcastPromise'
import Result from '@/service/operation/Result'
import {ApiException} from '@/exception/ApiException'
import {GetItemOptions, LocalStore} from '@/service/store/LocalStore'

export type LoadFromCacheFunction<T, Params> = (params: Params, options?: GetItemOptions) => T | null | undefined
export type FetchFromNetworkFunction<T, Params> = (params: Params) => Promise<T | null>
export type SaveIntoCacheFunction<T, Params> = (params: Params, item: T | null) => void
export type TransformResultFunction<T, R> = (item: T) => Promise<R>
export type CommitResultFunction<T> = (item: Result<T>) => void

export interface GetOptions<Params> extends GetItemOptions {
  params: Params
  /**
   * Do not read from store and directly fetch from network.
   */
  bypassStore?: boolean
  /**
   * Do not fetch from the network if we get a value from the store.
   */
  allowOnlyStore?: boolean
}

export class GetAndTransformOperation<T, R, Params> {
  
  private readonly fetchFromNetwork: FetchFromNetworkFunction<T, Params>
  private readonly transformFunction: TransformResultFunction<T, R>
  private readonly loadFromCache?: LoadFromCacheFunction<T, Params>
  private readonly saveIntoCacheFunction?: SaveIntoCacheFunction<T, Params>
  
  private options?: GetOptions<Params>
  private commit?: CommitResultFunction<R>
  private promise?: BroadcastPromise<R | null>
  private result?: Result<R>
  
  constructor(
    fetchFromNetwork: FetchFromNetworkFunction<T, Params>,
    transform: TransformResultFunction<T, R>,
    loadFromCache?: LoadFromCacheFunction<T, Params>,
    saveIntoCache?: SaveIntoCacheFunction<T, Params>
  ) {
    this.fetchFromNetwork = fetchFromNetwork
    this.transformFunction = transform
    this.loadFromCache = loadFromCache
    this.saveIntoCacheFunction = saveIntoCache
  }
  
  async get(
    options: GetOptions<Params>,
    commit?: CommitResultFunction<R>
  ): Promise<R | null> {
    if (this.options || this.commit || this.result) {
      throw new Error('GetOperation cannot be reused.')
    }
    this.options = options
    this.commit = commit
    
    // Config
    const bypassStore = options?.bypassStore || false
    const allowOnlyStore = options?.allowOnlyStore || false
    
    // Load from cache and emit value to vuex Module.
    let transformedItemFromCache: R | null | undefined = undefined
    if (this.loadFromCache && !bypassStore) {
      const itemFromCache = this.loadFromCache(options.params, options)
      if (itemFromCache !== undefined && itemFromCache !== null) {
        transformedItemFromCache = await this.transformFunction(itemFromCache)
      } else if (itemFromCache === null) {
        transformedItemFromCache = null
      }
    }
    
    if (transformedItemFromCache !== undefined) {
      this.result = new Result<R>(transformedItemFromCache)
      if (commit) {
        commit(this.result)
      }
    } else {
      this.result = new Result<R>(undefined)
    }
    
    // Return immediately if allowOnlyStore
    if (allowOnlyStore && transformedItemFromCache !== undefined) {
      return Promise.resolve(transformedItemFromCache)
    }
    
    // Then fetch from network
    return this.fetchFromNetworkAndCommit(options, true, transformedItemFromCache).wait()
  }
  
  /**
   * Fetch again the item from the network.
   */
  async refresh(commitOnError: boolean): Promise<R | null> {
    const options = this.options
    const commit = this.commit
    if (!options || !commit) {
      throw new Error('get must be called before calling refresh.')
    }
    
    // If we already have a get or a refresh ongoing we simply wait for the result.
    const promise = this.promise
    if (promise) {
      return promise.wait()
    }
    
    // If we received a response to a previous load or refresh, we use it as the new itemFromStore.
    // Otherwise, we default to use the previous itemFromStore.
    let itemFromCache: R | null | undefined = undefined
    if (this.result?.itemFromNetwork !== undefined) {
      itemFromCache = this.result?.itemFromNetwork
    } else if (this.result?.itemFromStore !== undefined) {
      itemFromCache = this.result?.itemFromStore
    }
    if (itemFromCache !== undefined) {
      this.result = new Result<R>(itemFromCache)
      if (commit) {
        commit(this.result)
      }
    }
    
    return this.fetchFromNetworkAndCommit(options, commitOnError, itemFromCache).wait()
  }
  
  /**
   * @internal
   */
  fetchFromNetworkAndCommit(
    options: GetOptions<Params>,
    commitOnError: boolean,
    itemFromCache?: R | null
  ): BroadcastPromise<R | null> {
    const promise = this.fetchResultFromNetwork(options, itemFromCache).then(result => {
      this.promise = undefined
      this.result = result
      if (result.itemFromNetwork !== undefined) {
        if (this.commit) {
          this.commit(result)
        }
        return result.itemFromNetwork
      } else {
        this.promise = undefined
        this.result = result
        if (commitOnError && this.commit) {
          this.commit(result)
        }
        throw result.errorFromNetwork
      }
    })
    this.promise = new BroadcastPromise<R | null>(promise)
    return this.promise
  }
  
  /**
   * @internal
   */
  fetchResultFromNetwork(options: GetOptions<Params>, itemFromCache?: R | null): Promise<Result<R>> {
    return new Promise((resolve) => {
      this.fetchFromNetwork(options.params).then(it => {
        if (this.saveIntoCacheFunction) {
          this.saveIntoCacheFunction(options.params, it)
        }
        return it
      }).then(it => {
        if (it !== null) {
          return this.transformFunction(it)
        } else {
          return null
        }
      }).then(it => {
        const result = new Result<R>(itemFromCache, it, undefined)
        resolve(result)
      }).catch(error => {
        if (error instanceof ApiException && error.response?.status === 404) {
          if (this.saveIntoCacheFunction) {
            this.saveIntoCacheFunction(options.params, null)
          }
          const result = new Result<R>(itemFromCache, null, undefined)
          resolve(result)
        } else {
          const result = new Result<R>(itemFromCache, undefined, error)
          resolve(result)
        }
      })
    })
  }
}

export function getAndTransformItemOperation<T, R, ID>(
  store: LocalStore<T, ID>,
  fetchFromNetwork: FetchFromNetworkFunction<T, ID>,
  transform: TransformResultFunction<T, R>
): GetAndTransformOperation<T, R, ID> {
  return new GetAndTransformOperation<T, R, ID>(
    id => fetchFromNetwork(id),
    transform,
    (id, options) => store.getItem(id, options),
    (id, item) => store.saveItem(id, item)
  )
}

export function getStoreKey<Params>(mutation: string, params: Params) {
  if (params !== undefined && params !== null) {
    return `${mutation}_${JSON.stringify(params)}`
  } else {
    return mutation
  }
}

export function reloadListFromStores<T, ID>(
  store: LocalStore<T, ID>,
  listStore: LocalStore<ID[], string>,
  key: string
): T[] | null | undefined {
  const ids = listStore.getItem(key)
  if (!ids) {
    return ids
  }
  const items: T[] = []
  for (const id of ids) {
    const item = store.getItem(id)
    if (!item) {
      return undefined
    }
    items.push(item)
  }
  return items
}

export function saveListIntoStores<T, ID>(
  store: LocalStore<T, ID>,
  listStore: LocalStore<ID[], string>,
  key: string,
  items: T[] | null,
  identifyItem: (T) => ID
) {
  if (items) {
    items.forEach(it => store.saveItem(identifyItem(it), it))
    const ids = items.map(it => identifyItem(it))
    listStore.saveItem(key, ids)
  } else {
    listStore.saveItem(key, null)
  }
}

export function getAndTransformListOperation<T, R, ID, Params>(
  store: LocalStore<T, ID>,
  listStore: LocalStore<ID[], string>,
  mutation: string,
  identifyItem: (T) => ID,
  fetchFromNetwork: FetchFromNetworkFunction<Array<T>, Params>,
  transform: TransformResultFunction<Array<T>, Array<R>>
): GetAndTransformOperation<Array<T>, Array<R>, Params> {
  return new GetAndTransformOperation<Array<T>, Array<R>, Params>(
    params => fetchFromNetwork(params),
    transform,
    params => reloadListFromStores(store, listStore, getStoreKey(mutation, params)),
    (params, items) => saveListIntoStores(store, listStore, getStoreKey(mutation, params), items, identifyItem)
  )
}
