import {JSONSchemaType} from 'ajv'
import {QuarryBean, quarrySchema} from '@/service/model/QuarryBean'
import {CustomerBean, customerSchema} from '@/service/model/CustomerBean'
import {SiteBean, siteSchema} from '@/service/model/SiteBean'
import {HaulerBean, haulerSchema} from '@/service/model/HaulerBean'
import {DriverBean, driverSchema} from '@/service/model/DriverBean'
import {MaterialBean, materialSchema} from '@/service/model/MaterialBean'
import {siteManagerArraySchema, SiteManagerBean, siteManagerSchema} from '@/service/model/SiteManagerBean'
import {PicturesBean, picturesSchema} from '@/service/model/PicturesBean'
import {Moment} from 'moment'
import {parseDateLocal, parseDateTimeUTC} from '@/utils/DateUtils'
import {EMPTY_DAY} from '@/utils/DateFormatters'
import {
  DeliveryNoteFeaturesBean,
  deliveryNoteFeaturesBeanSchema
} from '@/service/model/DeliveryNoteFeaturesBean'

const DELIVERY_DAY_FORMAT = 'DD/MM'

export const DeliveryStatus = {
  PENDING: 'PENDING',
  LOADING: 'LOADING',
  IN_DELIVERY: 'IN_DELIVERY',
  DELIVERED: 'DELIVERED'
}

export const DeliveryType = {
  CUSTOMER: 'CUSTOMER',
  QUARRY_HAULER: 'QUARRY_HAULER',
  THIRD_PARTY_HAULER: 'THIRD_PARTY_HAULER'
}

export const DeliveryConfirmationType = {
  SITE_MANAGER: 'SITE_MANAGER',
  DRIVER_WITH_SITE_MANAGER_CODE: 'DRIVER_WITH_SITE_MANAGER_CODE',
  DRIVER_WITH_PICTURE: 'DRIVER_WITH_PICTURE'
}

export interface DeliveryNoteSiteBean extends SiteBean {
  site_managers?: Array<SiteManagerBean> | null
}

export interface DeliveryNoteBean {
  id: string
  status: string
  
  order_id?: string | null
  order_reference?: string | null
  order_index: number
  
  quarry: QuarryBean
  customer?: CustomerBean | null
  site: DeliveryNoteSiteBean
  hauler?: HaulerBean | null
  driver?: DriverBean | null
  material: MaterialBean
  pictures?: PicturesBean | null
  
  delivery_type: string
  delivery_day: string
  driver_binding_code?: string | null
  
  quantity?: number | null
  net_weight?: number | null
  
  truck_registration?: string | null
  load_date?: string | null
  
  delivery_confirmation_type?: string | null
  site_manager_delivery_code?: string | null
  confirmed_by_site_manager?: SiteManagerBean | null
  unload_date?: string | null
  
  quarry_comment?: string | null
  driver_comment?: string | null
  site_manager_comment?: string | null
  quarry_material_reference?: string | null
  
  features: DeliveryNoteFeaturesBean
  numberOfSimilarDn?: number | null
  delivery_request_id?: string | null
}

export interface SynchronizedDeliveryNoteBean extends DeliveryNoteBean {
  waiting_sync_fields?: Array<string>
}

export function formatDeliveryDay(deliveryNote?: DeliveryNoteBean): string {
  if (deliveryNote?.delivery_day == null) {
    return EMPTY_DAY
  }
  const deliveryDay = parseDateLocal(deliveryNote.delivery_day)
  return deliveryDay.format(DELIVERY_DAY_FORMAT)
}

export function getMostAccurateDay(deliveryNote: DeliveryNoteBean): Moment {
  if (deliveryNote.unload_date != null) {
    const dateTime = parseDateTimeUTC(deliveryNote.unload_date).local()
    return dateTime.startOf('day')
  } else if (deliveryNote.load_date != null) {
    const dateTime = parseDateTimeUTC(deliveryNote.load_date).local()
    return dateTime.startOf('day')
  } else if (deliveryNote.delivery_day != null) {
    return parseDateLocal(deliveryNote.delivery_day)
  } else {
    throw Error(`No load_date not delivery_day for delivery note ${deliveryNote.id}`)
  }
}

export function formatMostAccurateDay(deliveryNote?: DeliveryNoteBean): string {
  if (deliveryNote === undefined) {
    return EMPTY_DAY
  }
  const mostAccurateDay = getMostAccurateDay(deliveryNote)
  return mostAccurateDay.format(DELIVERY_DAY_FORMAT)
}

export const deliveryNoteSiteBean: JSONSchemaType<DeliveryNoteSiteBean> = {
  type: 'object',
  properties: {
    'site_managers': {
      ...siteManagerArraySchema
    },
    ...siteSchema.properties
  },
  required: ['name'],
  additionalProperties: true
}

export const deliveryNoteSchema: JSONSchemaType<DeliveryNoteBean> = {
  type: 'object',
  properties: {
    id: {
      type: 'string'
    },
    status: {
      type: 'string'
    },
    order_id: {
      type: ['string'],
      nullable: true
    },
    order_reference: {
      type: ['string'],
      nullable: true
    },
    order_index: {
      type: 'integer',
      minimum: 0
    },
    quarry: quarrySchema,
    customer: {
      ...customerSchema,
      nullable: true
    },
    site: deliveryNoteSiteBean,
    hauler: {
      ...haulerSchema,
      nullable: true
    },
    driver: {
      ...driverSchema,
      nullable: true
    },
    material: materialSchema,
    pictures: {
      ...picturesSchema,
      nullable: true
    },
    delivery_type: {
      type: 'string'
    },
    delivery_day: {
      type: 'string'
    },
    driver_binding_code: {
      type: ['string'],
      nullable: true
    },
    site_manager_delivery_code: {
      type: ['string'],
      nullable: true
    },
    quantity: {
      type: ['number'],
      nullable: true
    },
    net_weight: {
      type: ['number'],
      nullable: true
    },
    truck_registration: {
      type: ['string'],
      nullable: true
    },
    load_date: {
      type: ['string'],
      nullable: true
    },
    delivery_confirmation_type: {
      type: ['string'],
      nullable: true
    },
    confirmed_by_site_manager: {
      ...siteManagerSchema,
      nullable: true
    },
    unload_date: {
      type: ['string'],
      nullable: true
    },
    quarry_comment: {
      type: ['string'],
      nullable: true
    },
    driver_comment: {
      type: ['string'],
      nullable: true
    },
    site_manager_comment: {
      type: ['string'],
      nullable: true
    },
    delivery_request_id: {
      type: ['string'],
      nullable: true
    },
    quarry_material_reference: {
      type: ['string'],
      nullable: true
    },
    features: deliveryNoteFeaturesBeanSchema,
    numberOfSimilarDn: {
      type: ['number'],
      nullable: true
    }
  },
  required: [
    'id', 'status', 'order_index',
    'quarry', 'site', 'material', 'delivery_type', 'delivery_day',
    'features'
  ],
  additionalProperties: true
}

export const deliveryNoteArraySchema: JSONSchemaType<Array<DeliveryNoteBean>> = {
  type: 'array',
  items: deliveryNoteSchema
}
