import {Subscription} from '@/service/subscription/Subscription'

export class ComposableSubscription implements Subscription {
  private subscriptions: Subscription[] = []
  
  add(subscription: Subscription) {
    this.subscriptions.push(subscription)
  }
  
  unregister() {
    for (const subscription of this.subscriptions) {
      subscription.unregister()
    }
  }
}
