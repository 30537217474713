import {ActionContext, Module} from 'vuex'
import {AppState} from '@/stores'
import {checkmark, warningOutline} from 'ionicons/icons'

export interface MessageModuleState {
  message?: string
  icon?: string
  color?: string
  timeoutId?: number
}

export interface MessageParams {
  message?: string
  icon?: string
  color?: string
  timeoutInMs?: number
}

export const SUCCESS_MESSAGE: Partial<MessageParams> = {
  timeoutInMs: 3000,
  color: 'dark-success',
  icon: checkmark
}

export const WARNING_MESSAGE: Partial<MessageParams> = {
  timeoutInMs: 3000,
  color: 'warning',
  icon: warningOutline
}

export const ERROR_MESSAGE: Partial<MessageParams> = {
  timeoutInMs: 3000,
  color: 'danger',
  icon: warningOutline
}

export class MessageModule implements Module<MessageModuleState, AppState> {
  namespaced = true
  state = {}
  actions = {
    setMessage: this.setMessageAction.bind(this),
    clear: this.clearAction.bind(this)
  }
  mutations = {
    setMessage: (state, message) => state.message = message,
    setIcon: (state, icon) => state.icon = icon,
    setColor: (state, color) => state.color = color,
    setTimeoutId: (state, timeoutId) => state.timeoutId = timeoutId
  }
  getters = {
    message: (state) => state.message,
    icon: (state) => state.icon,
    color: (state) => state.color
  }
  
  async setMessageAction(
    context: ActionContext<MessageModuleState, AppState>,
    params?: MessageParams
  ) {
    if (params === undefined) {
      await context.dispatch('clear')
      return
    }
    
    const completedParams = {
      ...SUCCESS_MESSAGE,
      ...params
    }
  
    context.commit('setMessage', completedParams.message)
    context.commit('setIcon', completedParams.icon)
    context.commit('setColor', completedParams.color)
    if (completedParams.timeoutInMs !== undefined && completedParams.timeoutInMs >= 0) {
      const timeoutId = setTimeout(this.onTimeout.bind(this, context), completedParams.timeoutInMs)
      context.commit('setTimeoutId', timeoutId)
    } else {
      context.commit('setTimeoutId', undefined)
    }
  }
  
  clearAction(
    context: ActionContext<MessageModuleState, AppState>,
  ) {
    context.commit('setMessage', undefined)
    context.commit('setIcon', undefined)
    context.commit('setColor', undefined)
    context.commit('setTimeoutId', undefined)
  }
  
  async onTimeout(
    context: ActionContext<MessageModuleState, AppState>
  ) {
    await context.dispatch('clear')
  }
  
  setTimeoutId(
    state: MessageModuleState,
    timeoutId?: number
  ) {
    if (state.timeoutId !== undefined) {
      clearTimeout(state.timeoutId)
    }
    state.timeoutId = timeoutId
  }
}
