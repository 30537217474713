export function isBlankOrEmpty(value?: string | null): boolean {
  if (!value) {
    return true
  }
  const trimmedValue = value.trim()
  return trimmedValue.length == 0
}

export function isNotBlank(value?: string | null): boolean {
  return !isBlankOrEmpty(value)
}
