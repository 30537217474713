<template>
  <div class="container">
    <header v-if="hasHeaderSlot" v-bind:class="[headerClass]">
      <slot name="header"></slot>
    </header>
    <main v-bind:class="[mainClass]">
      <slot></slot>
    </main>
    <footer v-bind:class="[footerClass]">
      <slot name="footer"></slot>
    </footer>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'header-content-footer-layout',
  props: {
    headerClass: String,
    mainClass: String,
    footerClass: String
  },
  computed: {
    hasHeaderSlot(): boolean {
      return this.$slots['header'] !== undefined
    },
  }
})
</script>

<style scoped>

.container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
}

.ios .container {
  position: absolute;
}

header, main, footer {
  display: block;
  width: 100%;
}

header, footer {
  flex: 0 0 auto;
}

main {
  flex: 1 1 auto;
  /*overflow: scroll;*/
}

</style>
