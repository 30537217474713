import {getOperatingSystemFromUserAgent} from '@/utils/OSUtils'
import {Capacitor} from '@capacitor/core'

export const androidStoreUrl = 'https://play.google.com/store/apps/details?id=io.rockease.app'
export const iosStoreUrl = 'https://apps.apple.com/fr/app/rockease/id1603446186'

function getWebStoreUrl(): string | undefined {
  switch (getOperatingSystemFromUserAgent()) {
  case 'android':
    return androidStoreUrl
  case 'ios':
    return iosStoreUrl
  default:
    return undefined
  }
}

export function getStoreUrl(): string | undefined {
  switch (Capacitor.getPlatform()) {
  case 'android':
    return androidStoreUrl
  case 'ios':
    return iosStoreUrl
  default:
    return getWebStoreUrl()
  }
}
