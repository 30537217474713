import {Flow, FlowStep} from '@/service/flow/Flow'
import {SiteManagerOrderBean} from '@/service/model/sitemanager/SiteManagerOrderBean'
import {MaterialBean} from '@/service/model/MaterialBean'
import {RouteLocationRaw} from 'vue-router'
import {SiteManagerCreatePickupRoute, SiteManagerDeliveryNotesRoute} from '@/router/sitemanager'

export interface SiteManagerCreatePickupFlowState {
  preselectedOrder?: SiteManagerOrderBean
  order?: SiteManagerOrderBean
  roundTripsCount?: number
  material?: MaterialBean
  backloadMaterial?: MaterialBean
  driverPhoneNumber?: string
  truckRegistration?: string
  comment?: string
}

export const SiteManagerCreatePickupFlowSelectOrderStep: FlowStep<null, SiteManagerCreatePickupFlowState> = {
  routeName: SiteManagerCreatePickupRoute.SELECT_ORDER,
  canEnter: (item, state) => state.preselectedOrder === undefined,
  isCompleted: state => state.order !== undefined
}

export const SiteManagerCreatePickupFlowTripCountStep: FlowStep<null, SiteManagerCreatePickupFlowState> = {
  routeName: SiteManagerCreatePickupRoute.TRIP_COUNT,
  isCompleted: state => state.roundTripsCount !== undefined && state.material !== undefined
}

export const SiteManagerCreatePickupConfirmStep: FlowStep<null, SiteManagerCreatePickupFlowState> = {
  routeName: SiteManagerCreatePickupRoute.CONFIRM,
  isCompleted: () => true
}

export class SiteManagerCreatePickupFlow extends Flow<null, null, SiteManagerCreatePickupFlowState> {
  
  constructor(
    private readonly initialRoute?: RouteLocationRaw
  ) {
    super(null, null, {})
  }
  
  setPreselectedOrder(order: number | undefined) {
    this.assignState({
      preselectedOrder: order,
      order: order,
      roundTripsCount: undefined,
      material: undefined,
      backloadMaterial: undefined,
      driverPhoneNumber: undefined,
      truckRegistration: undefined,
      comment: undefined
    })
  }
  
  setOrder(order: number | undefined) {
    this.assignState({
      preselectedOrder: undefined,
      order: order,
      roundTripsCount: undefined,
      material: undefined,
      backloadMaterial: undefined,
      driverPhoneNumber: undefined,
      truckRegistration: undefined,
      comment: undefined
    })
  }
  
  setRoundTripsCount(roundTripsCount: number | undefined) {
    this.assignState({
      roundTripsCount: roundTripsCount
    })
  }
  
  setMaterial(material: MaterialBean | undefined) {
    this.assignState({
      material: material
    })
  }
  
  setBackloadMaterial(material: MaterialBean | undefined) {
    this.assignState({
      backloadMaterial: material
    })
  }
  
  setDriverPhoneNumber(phoneNumber: string | undefined) {
    this.assignState({
      driverPhoneNumber: phoneNumber
    })
  }
  
  setTruckRegistration(truckRegistration: string | undefined) {
    this.assignState({
      truckRegistration: truckRegistration
    })
  }
  
  setComment(comment: string | undefined) {
    this.assignState({
      comment: comment
    })
  }
  
  getOutputRoute(): RouteLocationRaw {
    if (this.initialRoute !== undefined) {
      return this.initialRoute
    } else {
      return {
        name: SiteManagerDeliveryNotesRoute.UPCOMING
      }
    }
  }
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getCancelRoute(): RouteLocationRaw {
    if (this.initialRoute !== undefined) {
      return this.initialRoute
    } else {
      return {
        name: SiteManagerDeliveryNotesRoute.UPCOMING
      }
    }
  }
  
  getSteps(): Array<FlowStep<null, SiteManagerCreatePickupFlowState>> {
    return [
      SiteManagerCreatePickupFlowSelectOrderStep,
      SiteManagerCreatePickupFlowTripCountStep,
      SiteManagerCreatePickupConfirmStep
    ]
  }
}
