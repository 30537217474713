import {Store} from 'vuex'
import {AppState} from '@/stores'
import {RouteRecordRaw} from 'vue-router'

export const SuperCustomerConfirmRoute = {
  SELECT_CUSTOMER: 'SuperCustomerConfirmSelectCustomer',
  SELECT_SITE: 'SuperCustomerConfirmSelectSite',
  SELECT_MATERIAL: 'SuperCustomerConfirmSelectMaterial',
  PICTURE: 'SuperCustomerConfirmTakePicture',
  DAY: 'SuperCustomerConfirmDay',
  TRUCK_REGISTRATION: 'SuperCustomerConfirmTruckRegistration',
  WEIGHT: 'SuperCustomerConfirmWeight',
}

export function makeSuperCustomerRoutes(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  store: Store<AppState>
): Array<RouteRecordRaw> {
  
  return [
    {
      path: '/super-customer/',
      name: 'SuperCustomerHome',
      redirect: {name: SuperCustomerConfirmRoute.SELECT_CUSTOMER}
    },
    {
      path: '/super-customer/confirm/select-customer',
      name: SuperCustomerConfirmRoute.SELECT_CUSTOMER,
      component: () => import(/* webpackChunkName: "supercustomer" */ '@/views/supercustomer/SuperCustomerConfirmSelectCustomerPage.vue'),
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/super-customer/confirm/select-site',
      name: SuperCustomerConfirmRoute.SELECT_SITE,
      component: () => import(/* webpackChunkName: "supercustomer" */ '@/views/supercustomer/SuperCustomerConfirmSelectSitePage.vue'),
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/super-customer/confirm/select-material',
      name: SuperCustomerConfirmRoute.SELECT_MATERIAL,
      component: () => import(/* webpackChunkName: "supercustomer" */ '@/views/supercustomer/SuperCustomerConfirmSelectMaterialPage.vue'),
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/super-customer/confirm/take-picture',
      name: SuperCustomerConfirmRoute.PICTURE,
      component: () => import(/* webpackChunkName: "supercustomer" */ '@/views/supercustomer/SuperCustomerConfirmTakePicturePage.vue'),
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/super-customer/confirm/day',
      name: SuperCustomerConfirmRoute.DAY,
      component: () => import(/* webpackChunkName: "supercustomer" */ '@/views/supercustomer/SuperCustomerConfirmDayPage.vue'),
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/super-customer/confirm/truck_registration',
      name: SuperCustomerConfirmRoute.TRUCK_REGISTRATION,
      component: () => import(/* webpackChunkName: "supercustomer" */ '@/views/supercustomer/SuperCustomerConfirmTruckRegistrationPage.vue'),
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/super-customer/confirm/weight',
      name: SuperCustomerConfirmRoute.WEIGHT,
      component: () => import(/* webpackChunkName: "supercustomer" */ '@/views/supercustomer/SuperCustomerConfirmWeightPage.vue'),
      meta: {
        requiresAuth: true,
      }
    }
  ]
}
