<template>
  <ion-item class="ion-activatable" lines="none">
    <ion-card class="ion-activatable"
              v-on:click="onClick">
      <ion-grid>
        <template v-if="loading">
          <ion-row>
            <ion-col size="8">
              <ion-skeleton-text animated width="100%"/>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="6">
              <ion-skeleton-text animated width="100%"/>
            </ion-col>
          </ion-row>
        </template>
        <template v-else>
          <ion-row v-if="name">
            <ion-col class="name" size="12">
              {{ name }}
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12">
              {{ phoneNumber }}
            </ion-col>
          </ion-row>
        </template>
      </ion-grid>
      <ion-ripple-effect/>
    </ion-card>
  </ion-item>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import {IonCard, IonCol, IonGrid, IonItem, IonRippleEffect, IonRow, IonSkeletonText} from '@ionic/vue'
import {DriverBean} from '@/service/model/DriverBean'
import {formatPersonName} from '@/utils/PersonFormatters'
import {formatPhoneNumber} from '@/utils/PhoneNumberUtils'

export default defineComponent({
  name: 'site-manager-driver-list-item',
  emits: ['select'],
  props: {
    driver: Object as PropType<DriverBean | undefined>,
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick() {
      if (this.driver !== undefined) {
        this.$emit('select', this.driver)
      }
    }
  },
  computed: {
    name(): string | undefined {
      return formatPersonName(this.driver)
    },
    phoneNumber(): string | undefined {
      if (this.driver?.phone_number == null) {
        return undefined
      }
      return formatPhoneNumber(this.driver.phone_number)
    }
  },
  components: {
    IonItem, IonCard,
    IonGrid, IonCol, IonRow,
    IonRippleEffect,
    IonSkeletonText
  }
})
</script>

<style scoped>

ion-item {
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-start: 0;
  --inner-padding-end: 0;
}

ion-card {
  width: 100%;
}

.name {
  color: var(--ion-text-color);
  font-weight: 600;
}

</style>
