import {ActionContext, Module} from 'vuex'
import {AppState} from '@/stores'
import {AuthenticationService} from '@/service/authentication/AuthenticationService'
import {ROLE_SITE_MANAGER} from '@/service/api/CurrentUserApi'

export interface AuthenticationState {}

// noinspection JSMethodCanBeStatic
export class AuthenticationModule implements Module<AuthenticationState, AppState> {
  state = {}
  actions = {
    isLoggedIn: this.isLoggedInAction.bind(this),
    loginWithPhoneNumber: this.loginWithPhoneNumberAction.bind(this),
    loginWithEmail: this.loginWithEmailAction.bind(this),
    loginWithSiteManagerCode: this.loginWithSiteManagerCodeAction.bind(this),
    logout: this.logoutAction.bind(this),
    clearAllOtherModules: this.clearAllOtherModulesAction.bind(this)
  }
  
  constructor(
    private readonly authenticationService: AuthenticationService
  ) {
  }
  
  private async isLoggedInAction(
    context: ActionContext<AuthenticationState, AppState>
  ): Promise<boolean> {
    if (this.authenticationService.hasSiteManagerCode()) {
      return true
    }
    if (!await this.authenticationService.isLoggedIn()) {
      await context.dispatch('logout')
      return false
    }
    return true
  }
  
  private async loginWithPhoneNumberAction(
    context: ActionContext<AuthenticationState, AppState>
  ): Promise<boolean> {
    // Start by logout to clear any remaining cache.
    await this.logoutAction(context, false)
    
    await this.authenticationService.useCapacitorPlatform()
    const isLoggedIn = await this.authenticationService.loginWithPhoneNumber()
    if (isLoggedIn) {
      await context.dispatch('fetchCurrentUser')
    }
    return isLoggedIn
  }
  
  private async loginWithSiteManagerCodeAction(
    context: ActionContext<AuthenticationState, AppState>,
    siteManagerCode: string
  ) {
    // Start by logout to clear any remaining cache.
    await this.logoutAction(context, false)
    
    this.authenticationService.setSiteManagerCode(siteManagerCode)
    await context.dispatch('selectRole', ROLE_SITE_MANAGER)
    await context.dispatch('fetchCurrentUser')
  }
  
  private async loginWithEmailAction(
    context: ActionContext<AuthenticationState, AppState>
  ): Promise<boolean> {
    // Start by logout to clear any remaining cache.
    await this.logoutAction(context, false)
    
    await this.authenticationService.useCapacitorPlatform()
    const isLoggedIn = await this.authenticationService.loginWithEmail()
    if (isLoggedIn) {
      await context.dispatch('fetchCurrentUser')
    }
    return isLoggedIn
  }
  
  private async logoutAction(
    context: ActionContext<AuthenticationState, AppState>,
    clearRole?: boolean
  ): Promise<void> {
    await this.authenticationService.logOut()
    await context.dispatch('clearCurrentUserModule', clearRole)
  }
  
  private async clearAllOtherModulesAction(
    context: ActionContext<AuthenticationState, AppState>
  ) {
    await context.dispatch('clearDeliveryNoteModule')
    await context.dispatch('sync/clearSyncModule')
    await context.dispatch('siteManager/clearSiteManagerModule')
    await context.dispatch('order/clearOrderModule')
    await context.dispatch('load/clearConfirmModule')
    await context.dispatch('unload/clearConfirmModule')
    await context.dispatch('truckRegistration/clearTruckRegistrationModule')
    await context.dispatch('config/clearAppConfigModule')
  }
}
