<template>
  <page closeable
        container="scrollable"
        modal
        uses-cards
        v-bind:is-loading="isLoading && !isRefreshing"
        v-bind:title="t('title')">
    <ion-refresher slot="fixed" v-on:ionRefresh="onRefresh">
      <ion-refresher-content/>
    </ion-refresher>

    <error-message-item v-bind:error-message="errorMessage"
                        v-on:retry="onRetry"/>

    <ion-list v-if="isLoading" class="list-with-cards">
      <site-manager-driver-list-item v-for="item in 3"
                                     v-bind:key="item"
                                     loading/>
    </ion-list>

    <ion-list v-else class="list-with-cards">
      <site-manager-driver-list-item v-for="driver of drivers"
                                     v-bind:key="driver.phone_number"
                                     v-bind:driver="driver"
                                     v-on:select="onDriverSelected"/>
    </ion-list>
  </page>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {useI18n} from 'vue-i18n'
import {mapActions, mapGetters} from 'vuex'
import Page from '@/components/Page.vue'
import {IonList, IonRefresher, IonRefresherContent, modalController} from '@ionic/vue'
import ErrorMessageItem from '@/components/common/ErrorMessageItem.vue'
import {getErrorMessage} from '@/exception/Exception'
import SiteManagerDriverListItem from '@/components/sitemanager/driver/SiteManagerDriverListItem.vue'
import {DriverBean} from '@/service/model/DriverBean'
import {RefresherCustomEvent} from '@/utils/RefresherCustomEvent'

export default defineComponent({
  name: 'SiteManagerSelectDriverModal',
  data() {
    return {
      isLoading: true,
      isRefreshing: false,
      loadErrorMessage: undefined as string | undefined,
    }
  },
  setup() {
    const {t} = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })
    return {t}
  },
  mounted() {
    this.loadDrivers(false)
  },
  methods: {
    ...mapActions('siteManager/driver', ['fetchDrivers', 'refreshDrivers']),
    async loadDrivers(refresh: boolean) {
      try {
        this.isLoading = true
        this.loadErrorMessage = undefined
        if (!refresh) {
          await this.fetchDrivers()
        } else {
          await this.refreshDrivers()
        }
      } catch (error) {
        this.loadErrorMessage = getErrorMessage(this.t, error)
      } finally {
        this.isLoading = false
      }
    },
    async onRetry() {
      await this.loadDrivers(false)
    },
    async onRefresh(event: RefresherCustomEvent) {
      this.isRefreshing = true
      try {
        await this.loadDrivers(true)
      } finally {
        event.detail.complete()
      }
      this.isRefreshing = false
    },
    onDriverSelected(driver: DriverBean) {
      modalController.dismiss(driver)
    }
  },
  computed: {
    ...mapGetters('siteManager/driver', ['driversResult']),
    drivers(): Array<DriverBean> {
      return this.driversResult?.item() || []
    },
    errorMessage(): string | undefined {
      return this.loadErrorMessage || this.driversResult?.errorMessage(this.t('error.unknown'))
    },
  },
  components: {
    Page,
    ErrorMessageItem,
    IonRefresher, IonRefresherContent,
    IonList, SiteManagerDriverListItem
  }
})
</script>

<i18n locale="en">
{
  "title": "Select driver"
}
</i18n>

<i18n locale="fr">
{
  "title": "Choisir un chauffeur"
}
</i18n>
