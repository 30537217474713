import {ActionContext, Module} from 'vuex'
import {AppState} from '@/stores'
import Result from '@/service/operation/Result'
import {SiteManagerMaterialApi} from '@/service/api/sitemanager/SiteManagerMaterialApi'
import {SiteBean} from '@/service/model/SiteBean'
import {LocalStore} from '@/service/store/LocalStore'
import {DeliveryNoteState} from '@/stores/modules/DeliveryNoteModule'
import {getListOperation} from '@/service/operation/GetOperation'
import {SiteManagerMaterialBean} from '@/service/model/sitemanager/SiteManagerMaterialBean'
import {GetOptions} from '@/service/operation/GetAndTransformOperation'

const materialsStoreKey = 'materials'

export interface MaterialState {
  materials?: Result<Array<SiteManagerMaterialBean>>
}

export class MaterialModule implements Module<MaterialState, AppState> {
  namespaced = true
  mutations = {
    setMaterials: (state, materials) => state.materials = materials
  }
  getters = {
    materials: (state) => state.materials
  }
  actions = {
    fetchMaterials: this.fetchMaterialsAction.bind(this),
    clearMaterialModule: this.clearMaterialModule.bind(this)
  }
  
  constructor(
    private readonly listStore: LocalStore<Array<string>, string>,
    private readonly materialStore: LocalStore<SiteManagerMaterialBean, string>,
    private readonly materialApi: SiteManagerMaterialApi
  ) {
  }
  
  private async fetchMaterialsAction(
    context: ActionContext<DeliveryNoteState, AppState>,
    params: GetOptions<any>
  ): Promise<Array<SiteBean> | null> {
    const operation = getListOperation(
      this.materialStore,
      this.listStore,
      materialsStoreKey,
      it => it.id,
      () => this.materialApi.fetchMaterials()
    )
    
    return await operation.get(
      params,
      it => {
        context.commit('setMaterials', it)
      }
    )
  }
  
  clearMaterialModule(
    context: ActionContext<DeliveryNoteState, AppState>
  ) {
    context.commit('setMaterials', undefined)
    this.listStore.deleteItem(materialsStoreKey)
    this.materialStore.clear()
  }
}
