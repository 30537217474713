import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_ripple_effect = _resolveComponent("ion-ripple-effect")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    class: "ion-activatable",
    lines: "none"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card, {
        class: "ion-activatable",
        onClick: _ctx.onClick
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              (_ctx.loading)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_ion_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_col, { size: "8" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_skeleton_text, {
                              animated: "",
                              width: "100%"
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_col, { size: "6" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_skeleton_text, {
                              animated: "",
                              width: "100%"
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (_ctx.name)
                      ? (_openBlock(), _createBlock(_component_ion_row, { key: 0 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_col, {
                              class: "name",
                              size: "12"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.name), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_ion_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_col, { size: "12" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.phoneNumber), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ], 64))
            ]),
            _: 1
          }),
          _createVNode(_component_ion_ripple_effect)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }))
}