import {ActionContext, Module} from 'vuex'
import {AppState} from '@/stores'
import {SiteManagerSiteApi} from '@/service/api/sitemanager/SiteManagerSiteApi'
import Result from '@/service/operation/Result'
import {SiteBean} from '@/service/model/SiteBean'
import {DeliveryNoteState} from '@/stores/modules/DeliveryNoteModule'
import {getListOperation} from '@/service/operation/GetOperation'
import {LocalStore} from '@/service/store/LocalStore'
import { GetOptions } from '@/service/operation/GetAndTransformOperation'

const sitesStoreKey = 'sites'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SiteState {
  sites?: Result<Array<SiteBean>>
}

export class SiteModule implements Module<SiteState, AppState> {
  namespaced = true
  mutations = {
    setSites: (state, sites) => state.sites = sites
  }
  getters = {
    sites: (state) => state.sites
  }
  actions = {
    fetchSites: this.fetchSitesAction.bind(this),
    clearSiteModule: this.clearSiteModuleAction.bind(this),
  }
  
  constructor(
    private readonly listStore: LocalStore<Array<string>, string>,
    private readonly siteStore: LocalStore<SiteBean, string>,
    private readonly siteApi: SiteManagerSiteApi
  ) {
  }
  
  private async fetchSitesAction(
    context: ActionContext<DeliveryNoteState, AppState>,
    params: GetOptions<any>
  ): Promise<Array<SiteBean> | null> {
    const operation = getListOperation(
      this.siteStore,
      this.listStore,
      sitesStoreKey,
      it => it.id,
      () => this.siteApi.fetchSites()
    )
    
    return await operation.get(
      params,
      it => {
        context.commit('setSites', it)
      }
    )
  }
  
  clearSiteModuleAction(
    context: ActionContext<DeliveryNoteState, AppState>
  ) {
    context.commit('setSites', undefined)
    this.listStore.deleteItem(sitesStoreKey)
    this.siteStore.clear()
  }
}
