import {ActionContext, Module} from 'vuex'
import {AppState} from '@/stores'
import {
  getCustomers,
  getDeliveryTypes,
  getMaterials,
  getQuarries,
  getSites,
  SuperCustomerConfirmFlow
} from '@/service/flow/supercustomer/SuperCustomerConfirmFlow'
import {CustomerOpenOrder} from '@/service/model/customer/CustomerOpenOrder'
import {CustomerDeliveryNoteInputBean, CustomerOrderApi} from '@/service/api/customer/CustomerOrderApi'
import {CustomerBean} from '@/service/model/CustomerBean'
import {SiteBean} from '@/service/model/SiteBean'
import {Picture} from '@/service/model/PictureBean'
import {QuarryBean} from '@/service/model/QuarryBean'
import {MaterialBean} from '@/service/model/MaterialBean'
import {makeException} from '@/exception/Exception'
import {I18n} from 'vue-i18n'
import moment from 'moment/moment'
import {Moment} from 'moment'
import _ from 'lodash'
import {LocationBean} from '@/service/model/LocationBean'
import {translateMessage} from '@/i18n'
import {PermissionStatuses} from '@/stores/modules/PermissionModule'

export interface CustomerConfirmModuleState {
  flow?: SuperCustomerConfirmFlow
  locationPromise?: Promise<LocationBean | undefined>
  location?: LocationBean
  orders?: Array<CustomerOpenOrder>
  previous?: Partial<CustomerDeliveryNoteInputBean>
}

export class SuperCustomerConfirmModule implements Module<CustomerConfirmModuleState, AppState> {
  namespaced = true
  state = {}
  actions = {
    createConfirmFlow: this.createConfirmFlowAction.bind(this),
    fetchOrders: this.fetchOrdersAction.bind(this),
    setCustomer: this.setCustomerAction.bind(this),
    setSite: this.setSiteAction.bind(this),
    setQuarry: this.setQuarryAction.bind(this),
    setDeliveryType: this.setDeliveryTypeAction.bind(this),
    setMaterial: this.setMaterialAction.bind(this),
    setPicture: this.setPictureAction.bind(this),
    setDay: this.setDayAction.bind(this),
    setTruckRegistration: this.setTruckRegistrationAction.bind(this),
    setNetWeight: this.setNetWeightAction.bind(this),
    confirm: this.confirmAction.bind(this)
  }
  mutations = {
    setFlow: (state, flow) => state.flow = flow,
    setLocation: (state, location) => state.location = location,
    setLocationPromise: (state, locationPromise) => state.locationPromise = locationPromise,
    setOrders: (state, openOrders) => state.flow.updateItem(openOrders),
    setCustomer: (state, customer) => state.flow.setCustomer(customer),
    setSite: (state, site) => state.flow.setSite(site),
    setQuarry: (state, quarry) => state.flow.setQuarry(quarry),
    setDeliveryType: (state, deliveryType) => state.flow.setDeliveryType(deliveryType),
    setMaterial: (state, material) => state.flow.setMaterial(material),
    setPicture: (state, picture) => state.flow.setPicture(picture),
    setDay: (state, date) => state.flow.setDay(date),
    setTruckRegistration: (state, truckRegistration) => state.flow.setTruckRegistration(truckRegistration),
    setNetWeight: (state, netWeight) => state.flow.setNetWeight(netWeight),
    setPrevious: (state, previous) => state.previous = previous
  }
  getters = {
    flow: (state) => state.flow,
    orders: (state) => state.flow?.getItem(),
    matchesPreviousSelection: this.matchesPreviousSelection.bind(this),
    matchesPrevious: this.matchesPrevious.bind(this),
  }
  
  constructor(
    private readonly i18n: I18n,
    private readonly orderApi: CustomerOrderApi
  ) {
  }
  
  async createConfirmFlowAction(
    context: ActionContext<CustomerConfirmModuleState, AppState>
  ) {
    const flow = new SuperCustomerConfirmFlow()
    context.commit('setFlow', flow)
    
    this.fetchLocationIfNecessary(context)
  }
  
  fetchLocationIfNecessary(
    context: ActionContext<CustomerConfirmModuleState, AppState>
  ) {
    if (context.state.location !== undefined || context.state.locationPromise !== undefined) {
      return
    }
    const permissionStatuses: PermissionStatuses | undefined = context.rootGetters['permission/permissionStatuses']
    if (permissionStatuses?.locationState !== 'granted') {
      return
    }
    const locationPromise = this.getCurrentLocation(context)
    context.commit('setLocationPromise', locationPromise)
  }
  
  async getCurrentLocation(context: ActionContext<CustomerConfirmModuleState, AppState>) {
    const permissionStatuses: PermissionStatuses = await context.dispatch(
      'permission/getPermissionStatuses',
      undefined,
      {root: true}
    )
    
    let location: LocationBean | undefined = undefined
    if (permissionStatuses.locationState === 'granted') {
      location = await context.dispatch(
        'location/getCurrentLocation',
        undefined,
        {root: true}
      )
    }
    
    context.commit('setLocation', location)
    context.commit('setLocationPromise', undefined)
  }
  
  async fetchOrdersAction(
    context: ActionContext<CustomerConfirmModuleState, AppState>
  ): Promise<boolean> {
    const orders = await this.orderApi.fetchOpenOrders()
    context.commit('setOrders', orders)
    
    const hasReset = await this.resetIfNecessaryAction(context)
    
    if (context.state.flow?.getState().customer === undefined) {
      const customers = getCustomers(orders)
      if (customers.length === 1) {
        await context.dispatch('setCustomer', customers[0])
        return true
      }
    }
    return hasReset
  }
  
  async resetIfNecessaryAction(
    context: ActionContext<CustomerConfirmModuleState, AppState>
  ): Promise<boolean> {
    const orders = context.state.flow?.getItem()
    const state = context.state.flow?.getState()
    if (!orders || !state) {
      return false
    }
    
    if (state.customer === undefined) {
      return false
    }
    const customers = getCustomers(orders)
    if (!customers.find(it => it.id === state.customer?.id)) {
      await context.dispatch('setCustomer', undefined)
      return true
    }
    
    if (state.site === undefined) {
      return false
    }
    const sites = getSites(orders, state)
    if (!sites.find(it => it.id === state.site?.id)) {
      await context.dispatch('setSite', undefined)
      return true
    }
    
    if (state.quarry === undefined) {
      return false
    }
    const quarries = getQuarries(orders, state)
    if (!quarries.find(it => it.id === state.quarry?.id)) {
      await context.dispatch('setQuarry', undefined)
      return true
    }
    
    if (state.deliveryType === undefined) {
      return false
    }
    const deliveryTypes = getDeliveryTypes(orders, state, state.quarry)
    if (!deliveryTypes.includes(state.deliveryType)) {
      // reset quarry since delivery type is selected by the same screen as quarry.
      await context.dispatch('setQuarry', undefined)
      return true
    }
    
    if (state.material === undefined) {
      return false
    }
    const materials = getMaterials(orders, state, state.quarry, state.deliveryType)
    if (!materials.find(it => it.id === state.material?.id)) {
      // reset quarry since material is selected by the same screen as quarry.
      await context.dispatch('setQuarry', undefined)
      return true
    }
    return false
  }
  
  async setCustomerAction(
    context: ActionContext<CustomerConfirmModuleState, AppState>,
    customer: CustomerBean | undefined
  ) {
    this.fetchLocationIfNecessary(context)
    const flow = context.state.flow
    if (flow === undefined) {
      return
    }
    
    context.commit('setCustomer', customer)
    
    const sites = getSites(flow.getItem(), flow.getState())
    if (sites.length === 1) {
      await context.dispatch('setSite', sites[0])
    }
  }
  
  async setSiteAction(
    context: ActionContext<CustomerConfirmModuleState, AppState>,
    site: SiteBean | undefined
  ) {
    this.fetchLocationIfNecessary(context)
    context.commit('setSite', site)
  }
  
  async setQuarryAction(
    context: ActionContext<CustomerConfirmModuleState, AppState>,
    quarry: QuarryBean | undefined
  ) {
    this.fetchLocationIfNecessary(context)
    context.commit('setQuarry', quarry)
  }
  
  async setDeliveryTypeAction(
    context: ActionContext<CustomerConfirmModuleState, AppState>,
    deliveryType: string | undefined
  ) {
    this.fetchLocationIfNecessary(context)
    context.commit('setDeliveryType', deliveryType)
  }
  
  async setMaterialAction(
    context: ActionContext<CustomerConfirmModuleState, AppState>,
    material: MaterialBean | undefined
  ) {
    this.fetchLocationIfNecessary(context)
    context.commit('setMaterial', material)
  }
  
  async setPictureAction(
    context: ActionContext<CustomerConfirmModuleState, AppState>,
    picture: Picture | undefined
  ) {
    this.fetchLocationIfNecessary(context)
    context.commit('setPicture', picture)
  }
  
  async setDayAction(
    context: ActionContext<CustomerConfirmModuleState, AppState>,
    date: Moment | undefined
  ) {
    this.fetchLocationIfNecessary(context)
    if (date === undefined) {
      context.commit('setDay', undefined)
      return
    }
    const formattedDay = date.format(moment.HTML5_FMT.DATE)
    context.commit('setDay', formattedDay)
  }
  
  async setTruckRegistrationAction(
    context: ActionContext<CustomerConfirmModuleState, AppState>,
    truckRegistration: string | null | undefined
  ) {
    this.fetchLocationIfNecessary(context)
    context.commit('setTruckRegistration', truckRegistration)
  }
  
  async setNetWeightAction(
    context: ActionContext<CustomerConfirmModuleState, AppState>,
    netWeight: number | undefined
  ) {
    this.fetchLocationIfNecessary(context)
    context.commit('setNetWeight', netWeight)
  }
  
  async confirmAction(
    context: ActionContext<CustomerConfirmModuleState, AppState>
  ) {
    this.fetchLocationIfNecessary(context)
    if (context.state.locationPromise) {
      await context.state.locationPromise
    }
    
    const state = context.state.flow?.getState()
    const bean = this.getBean(context.state)
    if (bean === undefined || state?.picture === undefined) {
      throw makeException(this.i18n, 'customerConfirm.unknown')
    }
    
    await this.orderApi.postDeliveryNote({
      ...bean,
      location: context.state.location
    }, state.picture)
    
    // Remember the previous data for the alert
    context.commit('setPrevious', bean)
    
    // Reset data inputted by the user
    context.commit('setPicture', undefined)
    context.commit('setDay', undefined)
    context.commit('setTruckRegistration', undefined)
    context.commit('setNetWeight', undefined)
    
    // Fetch updated open orders
    const orders = await context.dispatch('fetchOrders')
  
    //
    await context.dispatch(
      'message/setMessage',
      {message: translateMessage(this.i18n, 'customerConfirm.success')},
      {root: true}
    )
    
    return orders
  }
  
  getBean(state: CustomerConfirmModuleState): CustomerDeliveryNoteInputBean | undefined {
    const flowState = state.flow?.getState()
    if (
      flowState?.customer?.id === undefined
      || flowState?.site?.id === undefined
      || flowState?.quarry?.id === undefined
      || flowState?.deliveryType === undefined
      || flowState?.material?.id === undefined
      || flowState.picture === undefined
      || flowState?.day === undefined
      || flowState?.netWeight === undefined
    ) {
      return undefined
    }
    
    return {
      customer_id: flowState.customer?.id,
      site_id: flowState.site?.id,
      quarry_id: flowState.quarry?.id,
      delivery_type: flowState.deliveryType,
      material_id: flowState.material?.id,
      
      day: flowState.day,
      truck_registration: flowState.truckRegistration,
      net_weight: flowState.netWeight
    }
  }
  
  matchesPreviousSelection(state: CustomerConfirmModuleState): boolean {
    const flowState = state.flow?.getState()
    if (state.previous === undefined || flowState === undefined) {
      return false
    }
    const currentSelection = {
      customer_id: flowState.customer?.id,
      site_id: flowState.site?.id,
      quarry_id: flowState.quarry?.id,
      delivery_type: flowState.deliveryType,
      material_id: flowState.material?.id
    }
    const previousSelection = {
      customer_id: state.previous.customer_id,
      site_id: state.previous.site_id,
      quarry_id: state.previous.quarry_id,
      delivery_type: state.previous.delivery_type,
      material_id: state.previous.material_id
    }
    return _.isEqual(currentSelection, previousSelection)
  }
  
  matchesPrevious(state: CustomerConfirmModuleState): boolean {
    return _.isEqual(this.getBean(state), state.previous)
  }
}
