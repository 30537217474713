import {App, ComponentPublicInstance} from 'vue'
import {ErrorReportingService} from '@/service/ErrorReportingService'

function windowErrorHandler(
  errorReportingService: ErrorReportingService,
  event: Event | string,
  source?: string,
  lineno?: number,
  colno?: number,
  error?: Error
) {
  if (error) {
    console.error(`Error occurred in script ${source} at line ${lineno}, col ${colno}.`, error)
    errorReportingService.report('window', error)
  }
}

function unhandledPromiseErrorHandler(
  errorReportingService: ErrorReportingService,
  event: PromiseRejectionEvent
) {
  errorReportingService.report('unhandledrejection', event.reason)
}

function vueErrorHandler(
  errorReportingService: ErrorReportingService,
  tag: string,
  error: any,
  instance: ComponentPublicInstance | null
) {
  if (tag === 'warnHandler') {
    console.warn(errorReportingService.formatMessage(instance, error))
  } else {
    console.error(errorReportingService.formatMessage(instance, error))
  }
  
  if (instance) {
    errorReportingService.reportVueComponent(tag, instance, error)
  } else {
    errorReportingService.report(tag, error)
  }
}

export function registerErrorHandlers(app: App, errorReportingService: ErrorReportingService) {
  // Catch all
  window.onerror = windowErrorHandler.bind(null, errorReportingService)
  
  // Error thrown by promise without catch
  window.addEventListener('unhandledrejection', unhandledPromiseErrorHandler.bind(null, errorReportingService))
  
  // Vue errors
  app.config.errorHandler = vueErrorHandler.bind(null, errorReportingService, 'errorHandler')
  app.config.warnHandler = vueErrorHandler.bind(null, errorReportingService, 'warnHandler')
}
