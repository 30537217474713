import {LocationBean, locationSchema} from '@/service/model/LocationBean'
import {JSONSchemaType} from 'ajv'

export interface AlternativeQuarryBean {
  quarry_id: string
  quarry_name: string
  quarry_company?: string | null
  quarry_address_1?: string | null
  quarry_address_2?: string | null
  quarry_postal_code?: string | null
  quarry_city?: string | null
  quarry_location?: LocationBean | null
  
  quarry_phone_number?: string | null
  
  quarry_distance: number // in km
  quarry_travel_duration?: number | null // in minutes
}

export const alternativeQuarrySchema: JSONSchemaType<AlternativeQuarryBean> = {
  type: 'object',
  properties: {
    quarry_id: {
      type: ['string']
    },
    quarry_name: {
      type: ['string']
    },
    quarry_company: {
      type: ['string'],
      nullable: true
    },
    quarry_address_1: {
      type: ['string'],
      nullable: true
    },
    quarry_address_2: {
      type: ['string'],
      nullable: true
    },
    quarry_postal_code: {
      type: ['string'],
      nullable: true
    },
    quarry_city: {
      type: ['string'],
      nullable: true,
    },
    quarry_location: {
      ...locationSchema,
      nullable: true
    },
    quarry_phone_number: {
      type: ['string'],
      nullable: true
    },
    quarry_distance: {
      type: 'number'
    },
    quarry_travel_duration: {
      type: ['number'],
      nullable: true
    },
  },
  required: [
    'quarry_id', 'quarry_name',
    'quarry_distance'
  ],
  additionalProperties: true
}
