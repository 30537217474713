import _ from 'lodash'

export function deepFreeze<T>(object: T): T {
  if (!_.isObjectLike(object)) {
    return object
  }
  const frozenObject = Object.freeze(object)
  _.forOwn(frozenObject, function (value) {
    if (!_.isObjectLike(value) || Object.isFrozen(value)) {
      return
    }
    deepFreeze(value)
  })
  return frozenObject
}
