import {Capacitor} from '@capacitor/core'
import {ActionPerformed, PushNotifications, PushNotificationSchema, Token} from '@capacitor/push-notifications'
import {InjectionKey} from 'vue'
import _ from 'lodash'
import Ajv, {JSONSchemaType} from 'ajv'
import {Subscription} from '@/service/subscription/Subscription'

export type DeviceTokenChangedCallback = (deviceToken: string) => void | Promise<void>

export interface RegisterForCallbackParams<T> {
  event: string
  callback: (event: string, data: T) => void | Promise<void>
  schema: JSONSchemaType<T>
}

class EventCallbackSubscription<T> implements Subscription {
  constructor(
    private readonly pushNotificationService: PushNotificationService,
    readonly event: string,
    readonly callback: (event: string, data: T) => void | Promise<void>,
    readonly dataSchema?: JSONSchemaType<T>
  ) {
  }
  
  call(ajv: Ajv, data: any) {
    const event = data.event
    if (event !== this.event) {
      return
    }
    
    if (this.dataSchema) {
      const validate = ajv.compile(this.dataSchema)
      if (!validate(data)) {
        console.error('Ignoring notification \'%o\' because content is not matching expected schema: %o', event, data)
        return
      }
    }
    
    this.callback(event, data)
  }
  
  unregister() {
    this.pushNotificationService.unregister(this)
  }
}

export class PushNotificationService {
  private registering = false
  private deviceToken?: string
  private registrationError?: any
  private deviceTokenChangedCallback?: DeviceTokenChangedCallback
  
  private readonly ajv: Ajv
  private readonly eventReceivedSubscriptions: Array<EventCallbackSubscription<any>> = []
  private readonly eventActionSubscriptions: Array<EventCallbackSubscription<any>> = []
  
  constructor() {
    this.ajv = new Ajv()
    if (Capacitor.getPlatform() === 'web') {
      return
    }
    PushNotifications.addListener('registration', this.onRegistration.bind(this))
    PushNotifications.addListener('registrationError', this.onRegistrationFailed.bind(this))
    PushNotifications.addListener('pushNotificationReceived', this.onPushNotificationReceived.bind(this))
    PushNotifications.addListener('pushNotificationActionPerformed', this.onPushNotificationActionPerformed.bind(this))
  }
  
  async register(): Promise<void> {
    if (Capacitor.getPlatform() === 'web') {
      return
    }
    if (this.registering || this.deviceToken) {
      return
    }
    this.registering = true
    
    console.info('Registering to push notifications.')
    this.deviceToken = undefined
    this.registrationError = undefined
    const permissions = await PushNotifications.checkPermissions()
    if (permissions.receive === 'granted') {
      await PushNotifications.register()
    } else {
      this.registering = false
    }
  }
  
  getDeviceToken(): string | undefined {
    return this.deviceToken
  }
  
  setDeviceTokenChangedCallback(callback?: DeviceTokenChangedCallback) {
    this.deviceTokenChangedCallback = callback
  }
  
  registerForEventReceived<T>(params: RegisterForCallbackParams<T>): Subscription {
    const subscription = new EventCallbackSubscription<T>(
      this,
      params.event,
      params.callback,
      params.schema
    )
    this.eventReceivedSubscriptions.push(subscription)
    return subscription
  }
  
  registerForEventAction<T>(params: RegisterForCallbackParams<T>): Subscription {
    const subscription = new EventCallbackSubscription<T>(
      this,
      params.event,
      params.callback,
      params.schema
    )
    this.eventActionSubscriptions.push(subscription)
    return subscription
  }
  
  unregister(subscription: Subscription) {
    _.remove(this.eventReceivedSubscriptions, it => it === subscription)
    _.remove(this.eventActionSubscriptions, it => it === subscription)
  }
  
  private onRegistration(token: Token) {
    this.deviceToken = token.value
    
    console.log(`Registration to push notifications completed with token: ${token.value}`)
    
    const callback = this.deviceTokenChangedCallback
    if (callback) {
      callback(token.value)
    }
  }
  
  private onRegistrationFailed(error: any) {
    console.log('Failed to register to push notifications.', error)
    
    this.registrationError = error
  }
  
  private onPushNotificationReceived(notification: PushNotificationSchema) {
    const event = notification.data.event
    if (event) {
      console.log(`Push notification received with event '${event}'`)
      
      const subscriptions = _.filter(this.eventReceivedSubscriptions, it => it.event === event)
      for (const subscription of subscriptions) {
        subscription.call(this.ajv, notification.data)
      }
    }
  }
  
  private onPushNotificationActionPerformed(action: ActionPerformed) {
    const event = action.notification.data.event
    
    if (event) {
      console.log(`Push notification action performed with event '${event}'`)
      
      const subscriptions = _.filter(this.eventActionSubscriptions, it => it.event === event)
      for (const subscription of subscriptions) {
        subscription.call(this.ajv, action.notification.data)
      }
    }
  }
}

export const pushNotificationServiceKey: InjectionKey<PushNotificationService> = Symbol()
