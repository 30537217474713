import {LocationBean, locationSchema} from '@/service/model/LocationBean'
import {JSONSchemaType} from 'ajv'
import {AddressBean} from '@/service/model/AddressBean'
import Result from '@/service/operation/Result'
import {SiteManagerMaterialBean} from '@/service/model/sitemanager/SiteManagerMaterialBean'
import {MaterialBean} from '@/service/model/MaterialBean'
import {QuotationRequestBean} from '@/service/model/QuotationRequestBean'

export const QuotationResponseStatus = {
  WAITING_FOR_RESPONSE: 'WAITING_FOR_RESPONSE',
  RESPONDED_AUTO: 'RESPONDED_AUTO',
  RESPONDED_MANUAL: 'RESPONDED_MANUAL',
  NO_RESPONSE: 'NO_RESPONSE',
  DISCARDED: 'DISCARDED',
  OUTSIDE_PLATFORM: 'OUTSIDE_PLATFORM'
}

export interface QuotationResponseBean {
  id: string
  quotation_request_id: string
  
  quarry_id: string
  quarry_name: string
  quarry_company?: string | null
  quarry_address_1?: string | null
  quarry_address_2?: string | null
  quarry_postal_code?: string | null
  quarry_city?: string | null
  quarry_location?: LocationBean | null
  
  quarry_distance: number // in km
  quarry_travel_duration?: number | null // in minutes
  co2: number
  
  offered_material_id: string
  quarry_material_name?: string | null
  quarry_backload_material_name?: string | null
  tech_spec_generation?: number | null
  
  material_pickup_price?: number | null
  
  backload_material_pickup_price?: number | null
}

export function getQuarryAddress(response?: QuotationResponseBean): AddressBean | undefined {
  if (!response) {
    return undefined
  }
  return {
    address_1: response.quarry_address_1,
    address_2: response.quarry_address_2,
    postal_code: response.quarry_postal_code,
    city: response.quarry_city
  }
}

export const quotationResponseSchema: JSONSchemaType<QuotationResponseBean> = {
  type: 'object',
  properties: {
    id: {
      type: ['string']
    },
    quotation_request_id: {
      type: ['string']
    },
    quarry_id: {
      type: ['string']
    },
    quarry_name: {
      type: ['string']
    },
    quarry_company: {
      type: ['string'],
      nullable: true
    },
    quarry_address_1: {
      type: ['string'],
      nullable: true
    },
    quarry_address_2: {
      type: ['string'],
      nullable: true
    },
    quarry_postal_code: {
      type: ['string'],
      nullable: true
    },
    quarry_city: {
      type: ['string'],
      nullable: true,
    },
    quarry_location: {
      ...locationSchema,
      nullable: true
    },
    quarry_distance: {
      type: 'number'
    },
    quarry_travel_duration: {
      type: ['number'],
      nullable: true
    },
    co2: {
      type: 'number'
    },
    offered_material_id: {
      type: 'string'
    },
    quarry_material_name: {
      type: ['string'],
      nullable: true
    },
    quarry_backload_material_name: {
      type: ['string'],
      nullable: true
    },
    tech_spec_generation: {
      type: ['number'],
      nullable: true
    },
    material_pickup_price: {
      type: ['number'],
      nullable: true
    },
    backload_material_pickup_price: {
      type: ['number'],
      nullable: true
    }
  },
  required: [
    'id', 'quotation_request_id',
    'quarry_id', 'quarry_name', 'quarry_distance', 'co2',
    'offered_material_id'
  ],
  additionalProperties: true
}

export function getMaterialFromResponse(
  materials: Result<Array<SiteManagerMaterialBean>>,
  request: QuotationRequestBean | undefined,
  response: QuotationResponseBean | undefined
): { material: MaterialBean; alternative: boolean } | undefined {
  if (!request || !response) {
    return undefined
  }
  const materialList = materials?.item() || []
  const material = materialList.find(it => it.id === response.offered_material_id)
  if (!material) {
    return undefined
  }
  return {
    material: {
      id: material.id,
      name: material.name,
      unit: material.unit,
      category: material.category,
      type: material.type,
      quarry_name: response.quarry_material_name
    },
    alternative: request.material_id !== response.offered_material_id
  }
}

export function getBackloadMaterialFromResponse(
  materials: Result<Array<SiteManagerMaterialBean>>,
  request: QuotationRequestBean | undefined,
  response: QuotationResponseBean | undefined
): MaterialBean | undefined {
  if (!request?.backload_material_id || !response) {
    return undefined
  }
  const materialList = materials?.item() || []
  const material = materialList.find(it => it.id === request.backload_material_id)
  if (!material) {
    return undefined
  }
  return {
    id: material.id,
    name: material.name,
    unit: material.unit,
    category: material.category,
    type: material.type,
    quarry_name: response.quarry_backload_material_name
  }
}
