import {I18n} from 'vue-i18n'
import {AuthenticationService} from '@/service/authentication/AuthenticationService'
import {CustomerOpenOrder, customerOpenOrderArraySchema} from '@/service/model/customer/CustomerOpenOrder'
import {AbstractPictureApi, PICTURE_REQUEST_TIME} from '@/service/api/AbstractPictureApi'
import {Picture} from '@/service/model/PictureBean'
import {LocationBean} from '@/service/model/LocationBean'
import {SynchronizationService} from '@/service/SynchronizationService'

export interface CustomerDeliveryNoteInputBean {
  customer_id: string
  site_id: string
  quarry_id: string
  delivery_type: string
  material_id: string
  location?: LocationBean
  day: string
  truck_registration?: string
  net_weight: number
}

export class CustomerOrderApi extends AbstractPictureApi {
  
  constructor(
    i18n: I18n,
    authenticationService: AuthenticationService,
    synchronizationService: SynchronizationService
  ) {
    super(i18n, authenticationService, synchronizationService)
  }
  
  async fetchOpenOrders(): Promise<Array<CustomerOpenOrder>> {
    return this.get<Array<CustomerOpenOrder>>({
      path: '/api/mp/v1/super-customers/orders/open',
      authentication: 'required',
      schema: customerOpenOrderArraySchema
    })
  }
  
  async postDeliveryNote(
    bean: CustomerDeliveryNoteInputBean,
    picture: Picture
  ) {
    const formData = new FormData()
    formData.set('dn', new Blob([JSON.stringify(bean)], {type: 'application/json'}))
    await AbstractPictureApi.addPictureToFormData(formData, '', picture)
    
    return this.postVoid({
      path: '/api/mp/v1/super-customers/orders/open/delivery-notes',
      authentication: 'required',
      rawBody: formData,
      requestTimeout: PICTURE_REQUEST_TIME
    })
  }
}
