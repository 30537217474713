import {Exception} from '@/exception/Exception'
import {I18n, NamedValue} from 'vue-i18n'
import {translateMessage} from '@/i18n'

export class ApiException extends Exception {
  response?: Response

  constructor(message?: string, response?: Response) {
    super(message)
    this.response = response
  }
}

export function makeApiException(i18n: I18n, messageKey: string, values?: NamedValue, response?: Response): ApiException {
  return new ApiException(translateMessage(i18n, messageKey, values), response)
}
