import {JSONSchemaType} from 'ajv'

export interface HaulerBean {
  name?: string | null
  phone_number?: string | null
  email?: string | null
}

export const haulerSchema: JSONSchemaType<HaulerBean> = {
  type: 'object',
  properties: {
    name: {
      type: ['string'],
      nullable: true
    },
    phone_number: {
      type: ['string'],
      nullable: true
    },
    email: {
      type: ['string'],
      nullable: true
    }
  },
  additionalProperties: true
}
