import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_menu_divider = _resolveComponent("main-menu-divider")!
  const _component_main_menu_item = _resolveComponent("main-menu-item")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_main_menu_divider, {
      label: _ctx.t('deliveryNote.title')
    }, null, 8, ["label"]),
    _createVNode(_component_main_menu_item, {
      label: _ctx.t('deliveryNote.upcoming'),
      to: {name: _ctx.DriverDeliveryNotesRoute.UPCOMING}
    }, null, 8, ["label", "to"]),
    _createVNode(_component_main_menu_item, {
      label: _ctx.t('deliveryNote.delivered'),
      to: {name: _ctx.DriverDeliveryNotesRoute.DELIVERED}
    }, null, 8, ["label", "to"])
  ], 64))
}