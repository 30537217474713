import {AppState} from '@/stores'
import {ActionContext, Module} from 'vuex'
import {App} from '@capacitor/app'
import {AppVersionRequirementsBean} from '@/service/model/AppVersionRequirementsBean'
import {AppVersionApi} from '@/service/api/AppVersionApi'
import {modalController} from '@ionic/vue'
import DeprecatedAppVersionModal from '@/views/modal/DeprecatedAppVersionModal.vue'
import {Capacitor} from '@capacitor/core'
import {isWebApp} from '@/utils/OSUtils'

interface AppVersionState {
  currentVersion?: number
  currentAppVersion?: string
  requirements?: AppVersionRequirementsBean
}

// noinspection JSMethodCanBeStatic
export class AppVersionModule implements Module<AppVersionState, AppState> {
  namespaced = true
  state = {}
  actions = {
    checkAppVersion: this.checkAppVersionAction.bind(this)
  }
  mutations = {
    setRequirements: (state, requirements) => state.requirements = requirements,
    setCurrentVersion: (state, currentVersion) => state.currentVersion = currentVersion,
    setCurrentAppVersion: (state, currentAppVersion) => state.currentAppVersion = currentAppVersion
  }
  getters = {
    requiresAppUpgrade: this.requiresAppUpgrade.bind(this),
    currentAppVersion: (state) => state.currentAppVersion
  }
  
  constructor(
    private readonly appVersionApi: AppVersionApi
  ) {
  }
  
  private async checkAppVersionAction(
    context: ActionContext<AppVersionState, AppState>
  ): Promise<void> {
    if (isWebApp) {
      return
    }
    
    const [appVersion, requirements] = await Promise.all([
      this.getCurrentVersion(context),
      this.getAppVersionRequirements(context)
    ])
    
    if (this.isDeprecatedAppVersion(appVersion, requirements.min_version)) {
      await this.presentDeprecatedAppVersionModal()
    }
  }
  
  private isDeprecatedAppVersion(
    currentVersion: number | undefined,
    minVersion: number | undefined
  ): boolean {
    if (currentVersion === undefined || minVersion === undefined) {
      return false
    }
    return currentVersion < minVersion
  }
  
  private async presentDeprecatedAppVersionModal() {
    const modal = await modalController.create({
      component: DeprecatedAppVersionModal,
      backdropDismiss: false,
      swipeToClose: false
    })
    await modal.present()
  }
  
  private async getCurrentVersion(
    context: ActionContext<AppVersionState, AppState>
  ): Promise<number | undefined> {
    let currentVersion: number | undefined
    let currentAppVersion: string | undefined
    if (Capacitor.getPlatform() === 'web') {
      currentVersion = undefined
      currentAppVersion = undefined
    } else {
      const appInfo = await App.getInfo()
      currentVersion = parseInt(appInfo.build)
      currentAppVersion = appInfo.version
    }
    context.commit('setCurrentVersion', currentVersion)
    context.commit('setCurrentAppVersion', currentAppVersion)
    return currentVersion
  }
  
  private async getAppVersionRequirements(
    context: ActionContext<AppVersionState, AppState>
  ): Promise<AppVersionRequirementsBean> {
    const requirements = await this.appVersionApi.getRequirements()
    context.commit('setRequirements', requirements)
    return requirements
  }
  
  private requiresAppUpgrade(
    state: AppVersionState
  ): boolean {
    const currentVersion = state.currentVersion
    const minVersion = state.requirements?.min_version
    const recommendedVersion = state.requirements?.recommended_version
    if (this.isDeprecatedAppVersion(currentVersion, minVersion)) {
      return false
    }
    if (currentVersion === undefined || recommendedVersion === undefined) {
      return false
    }
    return currentVersion < recommendedVersion
  }
}
