import {
  SynchronizeAllPluginResult,
  SynchronizePlugin,
  SynchronizePluginParams,
  SynchronizePluginParamsFormField,
  SynchronizePluginResult
} from '@/plugin/SynchronizationPlugin'
import {makeException} from '@/exception/Exception'
import {I18n} from 'vue-i18n'
import Ajv, {JSONSchemaType} from 'ajv'
import {errorBeanSchema, getURL} from '@/service/api/AbstractApi'
import {ApiException, makeApiException} from '@/exception/ApiException'
import {Capacitor} from '@capacitor/core'
import {translateMessage} from '@/i18n'
import _ from 'lodash'
import {
  B2C_AUTHORITY,
  B2C_BY_PHONE_POLICY,
  B2C_CLIENT_ID,
  B2C_SCOPE
} from '@/service/authentication/AuthenticationService'

export interface SynchronizeParamsFormField {
  content?: string
  
  contentType?: string
  fileName?: string
  path?: string
}

export interface SynchronizeParams<T> {
  id: string
  type: string
  subType?: string
  method: string
  path: string
  jsonContent?: any
  blobContent?: Blob
  formContent?: { [name: string]: SynchronizeParamsFormField }
  expectContent: boolean
  schema?: JSONSchemaType<T>
}

export interface SynchronizeResult<T> {
  pending: boolean
  content?: T
}

export interface SynchronizeAllResult {
  pending: boolean
  failedIds: Array<string>
}

export interface GetPendingSyncParams {
  id: string
  type: string
  subType?: string
}

export interface PendingSync<T> {
  id: string
  type: string
  subType?: string
  content: T
}

export class SynchronizationService {
  
  private readonly ajv: Ajv
  
  constructor(
    private readonly i18n: I18n
  ) {
    this.ajv = new Ajv()
  }
  
  async getPendingTypes(id: string): Promise<Array<string>> {
    if (!Capacitor.isNativePlatform()) {
      return []
    }
    const result = await SynchronizePlugin.getPendingTypes({
      id: id
    })
    return result.types
  }
  
  async synchronize<T>(
    params: SynchronizeParams<T>
  ): Promise<SynchronizeResult<T>> {
    const pluginParams = await this.getParams(params)
    if (pluginParams === undefined) {
      throw makeException(this.i18n, 'error.unknown')
    }
    const result = await SynchronizePlugin.synchronize(pluginParams)
    if (result.pending) {
      return {
        pending: true
      }
    } else if (result.failed) {
      throw this.parseError(params, result)
    } else {
      if (params.expectContent) {
        if (result.response_content !== undefined) {
          return {
            pending: false,
            content: this.convertContentToJson(result.response_content, params.schema)
          }
        } else {
          console.error('Sync succeed but without response while fetching path %o.', result.response_content_type, params.path)
          throw makeException(this.i18n, 'error.unknown')
        }
      } else {
        return {
          pending: false
        }
      }
    }
  }
  
  private parseError<T>(
    params: SynchronizeParams<T>,
    result: SynchronizePluginResult
  ): ApiException {
    if (result.response_content_type === undefined || !result.response_content_type.includes('application/json')) {
      console.error('Server responded with an error but unexpected content-type %o while fetching path %o.', result.response_content_type, params.path)
      return makeApiException(this.i18n, 'sync.unknown')
    }
    if (result.response_content === undefined) {
      console.error('Server responded with an error but no content while fetching path %o.', result.response_content_type, params.path)
      return makeApiException(this.i18n, 'sync.unknown')
    }
    const errorBean = this.convertContentToJson(result, errorBeanSchema)
    let message = errorBean.message
    if (!message) {
      message = translateMessage(this.i18n, 'sync.unknown')
    }
    return new ApiException(message)
  }
  
  private convertContentToJson<T>(
    content: any,
    schema?: JSONSchemaType<T>
  ): T {
    const arrayContent = new Uint8Array(content)
    const stringContent = new TextDecoder().decode(arrayContent)
    const jsonContent = JSON.parse(stringContent)
    if (schema !== undefined) {
      if (!this.ajv.validate(schema, jsonContent)) {
        console.error(
          'Failed to validate content returned by plugin.',
          JSON.stringify(schema),
          JSON.stringify(jsonContent)
        )
        throw makeApiException(this.i18n, 'sync.unknown')
      }
    }
    return jsonContent as T
  }
  
  async callOrSynchronize<T>(
    params: SynchronizeParams<T>,
    call: () => Promise<T>
  ): Promise<SynchronizeResult<T>> {
    if (Capacitor.isNativePlatform()) {
      return this.synchronize<T>(params)
    } else {
      return {
        pending: false,
        content: await call()
      }
    }
  }
  
  async getParams<T>(params: SynchronizeParams<T>): Promise<SynchronizePluginParams | undefined> {
    let contentType: string | undefined = undefined
    let content: any | undefined = undefined
    if (params.jsonContent !== undefined) {
      contentType = 'application/json'
      content = JSON.stringify(params.jsonContent)
    }
    if (params.blobContent !== undefined) {
      contentType = params.blobContent.type
      content = await params.blobContent.arrayBuffer()
    }
    if (params.formContent !== undefined) {
      contentType = 'multipart/form-data'
      content = this.encodeFormData(params.formContent)
    }
    
    if (contentType === undefined || content === undefined) {
      return undefined
    }
    
    return {
      id: params.id,
      type: params.type,
      sub_type: params.subType,
      method: params.method,
      url: getURL(params.path),
      
      msal_client_id: B2C_CLIENT_ID || '',
      msal_authority: B2C_AUTHORITY || '',
      msal_scope: B2C_SCOPE || '',
      msal_custom_policy: B2C_BY_PHONE_POLICY,
      
      content_type: contentType,
      content: content
    }
  }
  
  private encodeFormData(input: { [name: string]: SynchronizeParamsFormField }): { [name: string]: SynchronizePluginParamsFormField } {
    const output: { [name: string]: SynchronizePluginParamsFormField } = {}
    for (const fieldName of _.keys(input)) {
      output[fieldName] = {
        content: input[fieldName].content,
        content_type: input[fieldName].contentType,
        file_name: input[fieldName].fileName,
        path: input[fieldName].path
      }
    }
    return output
  }
  
  async getPendingSyncs(): Promise<Array<string>> {
    if (!Capacitor.isNativePlatform()) {
      return []
    }
    const result = await SynchronizePlugin.getPendingIds()
    return result.ids
  }
  
  async getPendingSync<T>(params: GetPendingSyncParams): Promise<PendingSync<T>> {
    const result = await SynchronizePlugin.getPendingRequest({
      id: params.id,
      type: params.type,
      sub_type: params.subType
    })
    
    if (result.content_type !== 'application/json') {
      console.error(`Cannot convert content of type ${result.content_type} from pending sync (${params.type};${params.id};${params.subType}).`)
      throw makeException(this.i18n, 'error.unknown')
    }
    
    return {
      id: params.id,
      type: params.type,
      subType: params.subType,
      content: this.convertContentToJson(result.content) as T
    }
  }
  
  async synchronizeAll(): Promise<SynchronizeAllResult> {
    const result = await SynchronizePlugin.synchronizeAll()
    return this.convertSynchronizeAllResult(result)
  }
  
  private convertSynchronizeAllResult(result: SynchronizeAllPluginResult): SynchronizeAllResult {
    return {
      pending: result.pending,
      failedIds: result.failed_ids
    }
  }
  
  async synchronizeOne(id: string): Promise<SynchronizeAllResult> {
    const result = await SynchronizePlugin.synchronizeOne({
      id: id
    })
    return this.convertSynchronizeAllResult(result)
  }
  
  async clear() {
    if (!Capacitor.isNativePlatform()) {
      return
    }
    await SynchronizePlugin.clear()
  }
  
  async clearByIdAndType(
    id: string,
    type: string
  ) {
    if (!Capacitor.isNativePlatform()) {
      return
    }
    await SynchronizePlugin.clearByIdAndType({
      id: id,
      type: type
    })
  }
}
