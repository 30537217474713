<template>
  <main-menu-item target="_blank"
                  v-bind:icon="icon"
                  v-bind:label="label"
                  v-on:click="onClicked"/>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {Capacitor} from '@capacitor/core'
import {WebIntent} from '@awesome-cordova-plugins/web-intent'
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser'
import {mapGetters} from 'vuex'
import MainMenuItem from '@/components/common/menu/MainMenuItem.vue'

export default defineComponent({
  name: 'open-pdf-menu-item',
  props: {
    label: String,
    icon: String,
    href: String
  },
  methods: {
    onClicked() {
      if (Capacitor.getPlatform() === 'android') {
        this.onClickedAndroid()
      } else {
        this.onClickedOthers()
      }
    },
    onClickedAndroid() {
      const options = {
        action: WebIntent.ACTION_VIEW,
        type: 'application/pdf',
        url: this.href
      }
      WebIntent.startActivity(options)
    },
    onClickedOthers() {
      InAppBrowser.create(this.href, '_blank', this.inAppBrowserOptions)
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    inAppBrowserOptions(): string {
      switch (Capacitor.getPlatform()) {
      case 'android':
        return 'location=no,footer=yes'
      case 'ios':
        return 'location=no,toolbar=yes'
      default:
        return 'location=no'
      }
    }
  },
  components: {
    MainMenuItem
  }
})
</script>

<style scoped>

</style>
