import {Msal, MsalPluginError} from '@/plugin/MsalPlugin'
import {
  AcquireTokenParams,
  AcquireTokenSilentParams,
  AuthPlatform,
  PlatformAuthenticationService, SignOutParams
} from '@/service/authentication/PlatformAuthenticationService'
import {AuthenticationToken} from '@/service/authentication/AuthenticationService'
import {ErrorReportingService} from '@/service/ErrorReportingService'
import {ApiException, makeApiException} from '@/exception/ApiException'
import {I18n} from 'vue-i18n'

/**
 * Native implementation using a custom Msal plugin. The custom plugin allow to share the client between the
 * web app and the synchronization module.
 *
 * /!\ On Android, the client id and redirect uri are not controlled by the plugin but by the msal_config.xml
 * resources.
 */
export class NativeAuthenticationService implements PlatformAuthenticationService {
  
  private errorReportingService?: ErrorReportingService
  
  constructor(
    private readonly i18n: I18n
  ) {
  }
  
  inject(errorReportingService: ErrorReportingService) {
    this.errorReportingService = errorReportingService
  }
  
  getPlatform(): AuthPlatform {
    return 'native'
  }
  
  async acquireToken(params: AcquireTokenParams): Promise<AuthenticationToken> {
    try {
      const result = await Msal.acquireToken({
        client_id: params.clientId,
        authority: params.authority,
        scope: params.scope,
        custom_policy: params.customPolicy
      })
      return {
        token: result.token.token,
        expiresOn: result.token.expires_on
      }
    } catch (e) {
      throw this.convertPluginError('acquireToken', e)
    }
  }
  
  async acquireTokenSilent(params: AcquireTokenSilentParams): Promise<AuthenticationToken | undefined> {
    try {
      const result = await Msal.acquireTokenSilent({
        client_id: params.clientId,
        authority: params.authority,
        scope: params.scope,
        custom_policy: params.customPolicy
      })
      if (result.token !== undefined) {
        return {
          token: result.token.token,
          expiresOn: result.token.expires_on
        }
      } else {
        return undefined
      }
    } catch (e) {
      throw this.convertPluginError('acquireTokenSilent', e)
    }
  }
  
  async signOut(params: SignOutParams): Promise<void> {
    try {
      await Msal.signOut({
        client_id: params.clientId,
        authority: params.authority,
        scope: params.scope,
        custom_policy: params.customPolicy
      })
    } catch (e) {
      throw this.convertPluginError('signOut', e)
    }
  }
  
  isCanceled(): boolean {
    return false
  }
  
  private convertPluginError(method: string, e: any): any {
    if (e.data !== null) {
      let message: string | undefined = undefined
      let exception: ApiException | undefined = undefined
      const errorData = e.data as MsalPluginError | undefined
      if (errorData !== undefined && errorData.error_code !== undefined && errorData.message !== undefined) {
        message = `${method} - ${errorData.error_code} - ${errorData.message}`
        exception = makeApiException(this.i18n, 'api.msal', { 'errorCode': errorData.error_code })
      } else {
        message = `${method} - ${e}`
        exception = makeApiException(this.i18n, 'api.unknown')
      }
      this.errorReportingService?.report(method, message)
      console.error(`Failed to ${method} due to ${message}.`)
      return exception
    } else {
      return e
    }
  }
}
