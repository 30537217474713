// eslint-disable-next-line @typescript-eslint/no-empty-interface
import {AddDriverFlow, DriverInfo} from '@/service/flow/sitemanager/AddDriverFlow'
import {ActionContext, Module} from 'vuex'
import {AppState} from '@/stores'
import {SiteManagerCustomerBean} from '@/service/model/sitemanager/SiteManagerCustomerBean'
import {I18n} from 'vue-i18n'
import {SiteManagerDriverApi, SiteManagerDriverCreationBean} from '@/service/api/sitemanager/SiteManagerDriverApi'
import {DriverBean} from '@/service/model/DriverBean'
import {isBlankOrEmpty} from '@/utils/StringUtils'
import {makeException} from '@/exception/Exception'
import {translateMessage} from '@/i18n'
import {GetOptions} from '@/service/operation/GetAndTransformOperation'

interface SiteManagerAddDriverModuleState {
  flow?: AddDriverFlow
}

export class SiteManagerAddDriverModule implements Module<SiteManagerAddDriverModuleState, AppState> {
  namespaced = true
  state = {}
  getters = {
    flow: (state) => state.flow
  }
  actions = {
    createAddDriverFlow: this.createAddDriverFlowAction.bind(this),
    fetchCustomers: this.fetchCustomersAction.bind(this),
    setCustomer: this.setCustomerAction.bind(this),
    setDriverInfo: this.setDriverInfoAction.bind(this),
    add: this.addAction.bind(this)
  }
  mutations = {
    setFlow: (state, flow) => state.flow = flow,
    setCustomer: (state, customer) => state.flow?.setCustomer(customer),
    setDriverInfo: (state, driver) => state.flow?.setDriverInfo(driver)
  }
  
  constructor(
    private readonly i18n: I18n,
    private readonly driverApi: SiteManagerDriverApi
  ) {
  }
  
  createAddDriverFlowAction(
    context: ActionContext<SiteManagerAddDriverModuleState, AppState>
  ) {
    const flow = new AddDriverFlow()
    context.commit('setFlow', flow)
  }
  
  async fetchCustomersAction(
    context: ActionContext<SiteManagerAddDriverModuleState, AppState>,
    params: GetOptions<any>
  ): Promise<boolean> {
    const customers: Array<SiteManagerCustomerBean> = await context.dispatch(
      'siteManager/customer/fetchCustomers',
      params,
      {root: true}
    )
    
    if (customers.length === 1) {
      await context.dispatch('setCustomer', customers[0])
      return true
    }
    
    return false
  }
  
  setCustomerAction(
    context: ActionContext<SiteManagerAddDriverModuleState, AppState>,
    customer: SiteManagerCustomerBean | undefined
  ) {
    context.commit('setCustomer', customer)
  }
  
  setDriverInfoAction(
    context: ActionContext<SiteManagerAddDriverModuleState, AppState>,
    driver: DriverInfo | undefined
  ) {
    context.commit('setDriverInfo', driver)
  }
  
  async addAction(
    context: ActionContext<SiteManagerAddDriverModuleState, AppState>
  ): Promise<DriverBean> {
    const bean = this.getBean(context.state)
    if (bean === undefined) {
      throw makeException(this.i18n, 'addDriver.unknown')
    }
    
    const driver = await this.driverApi.add(bean)
    context.commit('setFlow', undefined)
  
    await context.dispatch(
      'message/setMessage',
      {message: translateMessage(this.i18n, 'addDriver.success')},
      {root: true}
    )
    
    await context.dispatch('siteManager/driver/fetchDrivers', undefined, {root: true})
    
    return driver
  }
  
  getBean(
    state: SiteManagerAddDriverModuleState
  ): SiteManagerDriverCreationBean | undefined {
    const flowState = state.flow?.getState()
    if (
      flowState?.customer?.id === undefined
      || flowState?.driver?.phoneNumber === undefined
      || isBlankOrEmpty(flowState.driver.phoneNumber)
    ) {
      return undefined
    }
    
    return {
      customer_id: flowState.customer.id,
      
      firstname: flowState.driver.firstname,
      lastname: flowState.driver.lastname,
      phone_number: flowState.driver.phoneNumber
    }
  }
}
