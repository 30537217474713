import {JSONSchemaType} from 'ajv'
import {PersonBean, personSchema} from '@/service/model/PersonBean'

export interface CustomerBean extends PersonBean {
  id: string
  company_name?: string | null
  email?: string | null
  phone_number?: string | null
}

export const customerSchema: JSONSchemaType<CustomerBean> = {
  type: 'object',
  properties: {
    id: {
      type: ['string']
    },
    company_name: {
      type: ['string'],
      nullable: true
    },
    ...personSchema.properties,
    email: {
      type: ['string'],
      nullable: true
    },
    phone_number: {
      type: ['string'],
      nullable: true
    }
  },
  required: ['id'],
  additionalProperties: true
}
