import moment from 'moment'
import {Exception} from '@/exception/Exception'
import {ApiException} from '@/exception/ApiException'

/**
 * null is used to represent a non-existent item.
 * undefined is used to represent the fact we are waiting from the value.
 */
export default class Result<T> {
  readonly date: number
  
  constructor(
    readonly itemFromStore?: T | null,
    readonly itemFromNetwork?: T | null,
    readonly errorFromNetwork?: any
  ) {
    this.date = moment().valueOf()
  }
  
  item(): T | null | undefined {
    if (this.itemFromNetwork !== undefined) {
      return this.itemFromNetwork
    } else {
      return this.itemFromStore
    }
  }
  
  hasItem(): boolean {
    return this.itemFromStore !== undefined || this.itemFromNetwork !== undefined
  }
  
  hasItemFromNetwork(): boolean {
    return this.itemFromStore !== undefined
  }
  
  isFromNetwork(): boolean {
    return this.itemFromNetwork !== undefined || this.errorFromNetwork !== undefined
  }
  
  isError(): boolean {
    return this.errorFromNetwork !== undefined
  }
  
  networkStatus(): number | undefined {
    if (!this.isFromNetwork()) {
      return undefined
    } else if (!this.errorFromNetwork) {
      return 200
    } else if (this.errorFromNetwork instanceof ApiException) {
      return this.errorFromNetwork.response?.status || 0
    } else {
      return 0
    }
  }
  
  errorMessage(unknownReasonMessage?: string): string | undefined {
    if (!this.errorFromNetwork) {
      return undefined
    }
    if (this.errorFromNetwork instanceof Exception) {
      return this.errorFromNetwork.message
    }
    return unknownReasonMessage || this.errorFromNetwork.toString()
  }
}
