import {Module} from 'vuex'
import {AppState} from '@/stores'
import {SuperCustomerConfirmModule} from '@/stores/modules/supercustomer/CustomerConfimModule'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SuperCustomerModuleState {
}

export class SuperCustomerModule implements Module<SuperCustomerModuleState, AppState> {
  namespaced = true
  modules = {}
  
  constructor(
    confirmModule: SuperCustomerConfirmModule
  ) {
    this.modules = {
      confirm: confirmModule
    }
  }
}
