import {JSONSchemaType} from 'ajv'

export interface AddressBean {
  address_1?: string | null
  address_2?: string | null
  postal_code?: string | null
  city?: string | null
}

export const addressSchema: JSONSchemaType<AddressBean> = {
  type: 'object',
  properties: {
    address_1: {
      type: ['string'],
      nullable: true
    },
    address_2: {
      type: ['string'],
      nullable: true
    },
    postal_code: {
      type: ['string'],
      nullable: true
    },
    city: {
      type: ['string'],
      nullable: true
    },
  },
  additionalProperties: true
}
