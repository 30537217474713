export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "quotation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quotations"])},
        "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quotation requests"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New request"])}
      },
      "order": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])},
        "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])},
        "addExpressOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New express order"])},
        "deliveryRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New delivery request"])}
      },
      "deliveryRequest": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery calls"])},
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted"])},
        "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Progress"])},
        "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refused"])},
        "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired"])},
        "delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivered"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputes"])}
      },
      "deliveryNote": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery notes"])},
        "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming"])},
        "delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivered"])},
        "addPickupDeliveryNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New pickup delivery"])}
      },
      "config": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
        "drivers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drivers"])},
        "truckRegistrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trucks"])}
      }
    }
  })
}
