import {JSONSchemaType} from 'ajv'

export interface PicturesBean {
  load: boolean
  load_content_type?: string | null
  unload: boolean
  unload_content_type?: string | null
  weighing_slip: boolean
  weighing_slip_content_type?: string | null
}

export const picturesSchema: JSONSchemaType<PicturesBean> = {
  type: 'object',
  properties: {
    load: {
      type: ['boolean']
    },
    load_content_type: {
      type: ['string'],
      nullable: true
    },
    unload: {
      type: ['boolean']
    },
    unload_content_type: {
      type: ['string'],
      nullable: true
    },
    weighing_slip: {
      type: ['boolean']
    },
    weighing_slip_content_type: {
      type: ['string'],
      nullable: true
    },
  },
  required: ['load', 'unload', 'weighing_slip']
}
