import {AppState} from '@/stores'
import {ActionContext, Module} from 'vuex'
import {SiteManagerDeliveryNoteApi} from '@/service/api/sitemanager/SiteManagerDeliveryNoteApi'
import {SiteManagerDeliveryNotePictureApi} from '@/service/api/sitemanager/SiteManagerDeliveryNotePictureApi'
import {Picture, PictureBean} from '@/service/model/PictureBean'
import moment from 'moment'
import {UserPictureType} from '@/service/model/UserPictureType'
import {DeliveryNoteSyncService} from '@/service/DeliveryNoteSyncService'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SiteManagerDeliveryNoteState {

}

export class SiteManagerDeliveryNoteModule implements Module<SiteManagerDeliveryNoteState, AppState> {
  namespaced = true
  actions = {
    addWeighingSlip: this.addWeighingSlipAction.bind(this),
    getWeighingSlip: this.getWeighingSlipAction.bind(this),
    getLoad: this.getLoadAction.bind(this),
    getUnload: this.getUnloadAction.bind(this),
  }
  
  constructor(
    private readonly deliveryNoteApi: SiteManagerDeliveryNoteApi,
    private readonly deliveryNotePictureApi: SiteManagerDeliveryNotePictureApi,
    private readonly deliveryNoteSyncService: DeliveryNoteSyncService
  ) {
  }
  
  // noinspection JSUnusedLocalSymbols
  private async addWeighingSlipAction(
    context: ActionContext<SiteManagerDeliveryNoteState, AppState>,
    params: { deliveryNoteId: string; picture: Picture }
  ) {
    const picture: PictureBean = {
      picture: params.picture,
      date: moment().format(),
      uploaded: false
    }
    await this.deliveryNotePictureApi.uploadWeighingSlipPicture(
      'SM_ADD_WEIGHING_SLIP',
      params.deliveryNoteId,
      picture
    )
  }
  
  // noinspection JSUnusedLocalSymbols
  private getWeighingSlipAction(
    context: ActionContext<SiteManagerDeliveryNoteState, AppState>,
    deliveryNoteId: string
  ): Promise<Blob> {
    return this.deliveryNotePictureApi.getPictureBin(deliveryNoteId, UserPictureType.WEIGHING_SLIP)
  }
  
  // noinspection JSUnusedLocalSymbols
  private getLoadAction(
    context: ActionContext<SiteManagerDeliveryNoteState, AppState>,
    deliveryNoteId: string
  ): Promise<Blob> {
    return this.deliveryNotePictureApi.getPictureBin(deliveryNoteId, UserPictureType.LOAD)
  }
  
  // noinspection JSUnusedLocalSymbols
  private getUnloadAction(
    context: ActionContext<SiteManagerDeliveryNoteState, AppState>,
    deliveryNoteId: string
  ): Promise<Blob> {
    return this.deliveryNotePictureApi.getPictureBin(deliveryNoteId, UserPictureType.UNLOAD)
  }
}
