import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_ctx.message)
    ? (_openBlock(), _createBlock(_component_ion_item, {
        key: 0,
        lines: "full",
        color: _ctx.color
      }, {
        default: _withCtx(() => [
          (_ctx.icon)
            ? (_openBlock(), _createBlock(_component_ion_icon, {
                key: 0,
                slot: "start",
                icon: _ctx.icon
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.message), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["color"]))
    : _createCommentVNode("", true)
}