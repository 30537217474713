<template>
  <main-menu-divider v-bind:label="t('deliveryNote.title')"/>
  <main-menu-item v-bind:label="t('deliveryNote.upcoming')"
                  v-bind:to="{name: DriverDeliveryNotesRoute.UPCOMING}"/>
  <main-menu-item v-bind:label="t('deliveryNote.delivered')"
                  v-bind:to="{name: DriverDeliveryNotesRoute.DELIVERED}"/>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import MainMenuItem from '@/components/common/menu/MainMenuItem.vue'
import {useI18n} from 'vue-i18n'
import {DriverDeliveryNotesRoute} from '@/router/driver'
import MainMenuDivider from '@/components/common/menu/MainMenuDivider.vue'

export default defineComponent({
  name: 'driver-menu',
  setup() {
    const {t} = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })
    return {
      t,
      DriverDeliveryNotesRoute
    }
  },
  components: {
    MainMenuDivider,
    MainMenuItem
  }
})
</script>

<i18n locale="en">
{
  "deliveryNote": {
    "title": "Delivery notes",
    "upcoming": "Upcoming",
    "delivered": "Delivered"
  }
}
</i18n>

<i18n locale="fr">
{
  "deliveryNote": {
    "title": "Bons de livraison",
    "upcoming": "En cours",
    "delivered": "Terminés"
  }
}
</i18n>
