import {AppPictureConfigBean, appPictureConfigSchema} from '@/service/model/config/AppPictureConfigBean'
import {JSONSchemaType} from 'ajv'

export interface AppConfigBean {
  picture: AppPictureConfigBean
}

export const appConfigSchema: JSONSchemaType<AppConfigBean> = {
  type: 'object',
  properties: {
    picture: appPictureConfigSchema
  },
  required: ['picture'],
  additionalProperties: true
}

export const DEFAULT_CONFIG: AppConfigBean = {
  picture: {
    max_width: 1920,
    max_height: 1920,
    quality: 60
  }
}
