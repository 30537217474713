import {registerPlugin} from '@capacitor/core'

export interface MsalAccountChangedEvent {
  id: string | undefined
}

export type MsalAccountChangedEventCallback = (event: MsalAccountChangedEvent | null, err: string | undefined) => void;

export interface MsalUnregisterToAccountChangesParams {
  callback_id: string
}

interface MsalCommonAcquireTokenParams {
  client_id: string
  authority: string
  scope: string
  custom_policy: string
}

export interface MsalAuthenticationToken {
  token: string
  expires_on: number | undefined
}

export interface MsalAcquireTokenParams extends MsalCommonAcquireTokenParams {}

export interface MsalAcquireTokenResult {
  token: MsalAuthenticationToken
}

export interface MsalAcquireTokenSilentParams extends MsalCommonAcquireTokenParams {
  force_refresh?: boolean
}

export interface MsalSignOutParams extends MsalCommonAcquireTokenParams {}

export interface MsalAcquireTokenSilentResult {
  token?: MsalAuthenticationToken
}

export interface MsalPluginError {
  error_code?: string
  message?: string
}

/**
 * Custom plugin allowing to share the instance of the Msal client between the request made by the javascript
 * and the synchro plugin.
 *
 * /!\ On Android, the client id and authority MUST also be present in the msal_config.xml in raw resources.
 */
export interface MsalPlugin {
  acquireToken(params: MsalAcquireTokenParams): Promise<MsalAcquireTokenResult>
  acquireTokenSilent(params: MsalAcquireTokenSilentParams): Promise<MsalAcquireTokenSilentResult>
  signOut(params: MsalSignOutParams): Promise<void>
}

export const Msal = registerPlugin<MsalPlugin>('Msal')
