export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rockease requires the following permissions:"])},
      "location": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access to your location"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To ensure the proper tracking of the deliveries, Rockease requires access to your location."])}
      },
      "camera": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take picture"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To take picture of paper slips and materials."])}
      },
      "notification": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display notifications"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To notify of the departure/arrival of the deliveries, ."])}
      }
    }
  })
}
