import {JSONSchemaType} from 'ajv'

export interface OrderEventReceivedBean {
  order_id: string
}

export const orderEventReceivedSchema: JSONSchemaType<OrderEventReceivedBean> = {
  type: 'object',
  properties: {
    order_id: {
      type: ['string']
    }
  },
  required: ['order_id'],
  additionalProperties: true
}
