<template>
  <page container="fixed"
        v-bind:title="t('title')">
    <div class="content">
      <h1 class="title-md">{{ t('title') }}</h1>
    </div>
  </page>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Page from '@/components/Page.vue'
import {useI18n} from 'vue-i18n'

export default defineComponent({
  name: 'PathNotFoundPage',
  setup() {
    const {t} = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })
    return {t}
  },
  components: {
    Page,
  }
})
</script>

<style scoped>

.content {
  position: absolute;
  height: 100%;
  padding: var(--padding-md);
}

</style>

<i18n locale="en">
{
  "title": "Page not found"
}
</i18n>

<i18n locale="fr">
{
  "title": "Page non trouvée"
}
</i18n>
