<template>
  <page container="fixed"
        v-bind:title="t('title')">
    <h1 class="title">{{ t('header') }}</h1>
    <p class="text">{{ t('description') }}</p>
    <p class="text">{{ t('resolution') }}</p>
    <div class="buttons">
      <ion-button color="rockease"
                  expand="block"
                  v-bind:href="storeLink">{{ goToStore }}
      </ion-button>
    </div>
  </page>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Page from '@/components/Page.vue'
import {useI18n} from 'vue-i18n'
import {Capacitor} from '@capacitor/core'
import {getStoreUrl} from '@/utils/AppStoreUtils'

export default defineComponent({
  name: 'DeprecatedAppVersionModal',
  setup() {
    const {t} = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })
    return {t}
  },
  computed: {
    goToStore(): string {
      switch (Capacitor.getPlatform()) {
      case 'android':
        return this.t('button.goToStore.android')
      case 'ios':
        return this.t('button.goToStore.ios')
      default:
        return this.t('button.goToStore.web')
      }
    },
    storeLink(): string | undefined {
      return getStoreUrl()
    }
  },
  components: {Page}
})
</script>

<style scoped>

h1 {
  margin-bottom: var(--margin-md);
}

.buttons {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: var(--padding-md);
}

</style>

<i18n locale="en">
{
  "title": "Update required",
  "header": "Your Rockease app version is too old.",
  "description": "The app is disabled because some features are no longer supported.",
  "resolution": "Click on the button bellow to go to the store and manually update the app.",
  "button": {
    "goToStore": {
      "android": "Go to Play store",
      "ios": "Go to App store",
      "web": "Go to store"
    }
  }
}
</i18n>

<i18n locale="fr">
{
  "title": "Mise à jour nécessaire",
  "header": "La version de l'app Rockease est trop ancienne.",
  "description": "L'accès à l'application est désactivé car certaines fonctionnalités ne sont plus compatibles.",
  "resolution": "Cliquez sur le bouton ci-dessous pour accéder au store et mettre manuellement à jour l'app.",
  "button": {
    "goToStore": {
      "android": "Accéder au Play store",
      "ios": "Accéder a l'App store",
      "web": "Accéder au store"
    }
  }
}
</i18n>
