// eslint-disable-next-line @typescript-eslint/no-empty-interface
import {ActionContext, Module} from 'vuex'
import {AppState} from '@/stores'
import {I18n} from 'vue-i18n'
import {modalController} from '@ionic/vue'
import {Capacitor, PermissionState} from '@capacitor/core'
import {Geolocation} from '@capacitor/geolocation'
import {Exception, makeException} from '@/exception/Exception'
import {IntentOptions, WebIntent} from '@awesome-cordova-plugins/web-intent'
import {waitForAppToResume} from '@/utils/AppStateUtils'
import {PushNotifications} from '@capacitor/push-notifications'
import {PushNotificationService} from '@/service/PushNotificationService'
import {Camera, CameraPermissionState} from '@capacitor/camera'
import PermissionsModal from '@/views/modal/PermissionsModal.vue'
import _ from 'lodash'
import deepmerge from 'deepmerge'

export interface AskedPermissions {
  location: boolean
  camera: boolean
  notification: boolean
}

export interface PermissionChecks {
  locationRequired: boolean
}

export type GeoPermissionState = PermissionState | 'disabled'

export class PermissionStatuses {
  
  constructor(
    readonly locationState: GeoPermissionState,
    readonly cameraState: CameraPermissionState,
    readonly notificationState: PermissionState
  ) {
  }
  
  shouldAsk(askedPermissions: AskedPermissions): boolean {
    const allStates: Array<string> = []
    if (askedPermissions.location) {
      allStates.push(this.locationState)
    }
    if (askedPermissions.camera) {
      allStates.push(this.cameraState)
    }
    if (askedPermissions.notification) {
      allStates.push(this.notificationState)
    }
    console.log(`shouldAsk:  ${JSON.stringify(askedPermissions)} ${JSON.stringify(allStates)}`)
    // Any permission requiring a prompt ?
    return _.find(allStates, (state) => state === 'prompt' || state === 'prompt-with-rationale') !== undefined
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PermissionModuleState {
  askCount: number
  permissionStatuses?: PermissionStatuses
}

// noinspection JSMethodCanBeStatic
export class PermissionModule implements Module<PermissionModuleState, AppState> {
  namespaced = true
  state = {
    askCount: 0
  }
  mutations = {
    incrementAskCount: (state) => state.askCount++,
    setPermissionStatuses: (state, permissionStatuses) => state.permissionStatuses = permissionStatuses
  }
  getters = {
    permissionStatuses: (state) => state.permissionStatuses,
    locationPermissionDeniedError: this.getLocationPermissionDeniedError.bind(this)
  }
  actions = {
    getPermissionStatuses: this.getPermissionStatusesAction.bind(this),
    askForPermissions: this.askForPermissionsAction.bind(this),
    checkPermissions: this.checkPermissionsAction.bind(this),
    checkAndAskForPermissions: this.checkAndAskForPermissionsAction.bind(this),
    requestPermissions: this.requestPermissionsAction.bind(this),
    resolvePermissions: this.resolvePermissionAction.bind(this)
  }
  
  constructor(
    private readonly i18n: I18n,
    private readonly pushNotificationService: PushNotificationService
  ) {
  }
  
  getWebPermissionStatuses(): PermissionStatuses {
    // We make as we have all permissions on web since we cannot manage them as on Android/iOS devices.
    // You can change 'granted' to 'prompt' in order to display the dialog on Web for dev purposes.
    return new PermissionStatuses('granted', 'granted', 'granted')
  }
  
  async getPermissionStatusesAction(
    context: ActionContext<PermissionModuleState, AppState>
  ): Promise<PermissionStatuses> {
    let newStatuses: PermissionStatuses
    if (Capacitor.getPlatform() === 'web') {
      newStatuses = this.getWebPermissionStatuses()
    } else {
      const [locationState, cameraState, notificationState] = await Promise.all([
        this.checkGeolocationPermissionState(),
        this.checkCameraPermissionState(),
        this.checkPushNotificationsPermissionState()
      ])
  
      newStatuses = new PermissionStatuses(
        locationState,
        cameraState,
        notificationState
      )
    }
    
    context.commit('setPermissionStatuses', newStatuses)
    return newStatuses
  }
  
  private async checkGeolocationPermissionState(): Promise<GeoPermissionState> {
    try {
      const permissions = await Geolocation.checkPermissions()
      return permissions.location as PermissionState
    } catch (e) {
      return 'disabled'
    }
  }
  
  private async checkCameraPermissionState(): Promise<CameraPermissionState> {
    const permissions = await Camera.checkPermissions()
    return permissions.camera
  }
  
  private async checkPushNotificationsPermissionState(): Promise<PermissionState> {
    const permissions = await PushNotifications.checkPermissions()
    return permissions.receive
  }
  
  async askForPermissionsAction(
    context: ActionContext<PermissionModuleState, AppState>,
    config: Partial<AskedPermissions> | undefined
  ) {
    // Only display once per session.
    if (context.state.askCount > 0) {
      return
    }
    context.commit('incrementAskCount')
    
    let askedPermission: AskedPermissions = {
      notification: true,
      camera: false,
      location: false
    }
    if (config !== undefined) {
      askedPermission = deepmerge(askedPermission, config)
    }
    
    const permissionStatuses = await context.dispatch('getPermissionStatuses')
    if (permissionStatuses.shouldAsk(askedPermission)) {
      await this.presentPermissionsModal(askedPermission, permissionStatuses)
    }
  }
  
  async checkAndAskForPermissionsAction(
    context: ActionContext<PermissionModuleState, AppState>,
    params?: Partial<PermissionChecks>
  ): Promise<PermissionStatuses> {
    const askedPermissions: AskedPermissions = {
      location: true,
      camera: true,
      notification: true
    }
    
    const permissionStatuses = await context.dispatch('getPermissionStatuses')
    if (permissionStatuses.shouldAsk(askedPermissions)) {
      const newPermissionStatuses = await this.presentPermissionsModal(askedPermissions, permissionStatuses)
      this.checkPermissions(context, params, newPermissionStatuses)
    } else {
      this.checkPermissions(context, params, permissionStatuses)
    }
    return permissionStatuses
  }
  
  // noinspection JSUnusedLocalSymbols
  async requestPermissionsAction(
    context: ActionContext<PermissionModuleState, AppState>,
    askedPermissions: AskedPermissions
  ): Promise<PermissionStatuses> {
    if (Capacitor.getPlatform() === 'web') {
      return this.getWebPermissionStatuses()
    }
    
    let locationStatus
    if (askedPermissions.location) {
      locationStatus = await Geolocation.requestPermissions({permissions: ['location']})
    } else {
      locationStatus = await this.checkGeolocationPermissionState()
    }
    
    let cameraStatus
    if (askedPermissions.camera) {
      cameraStatus = await Camera.requestPermissions({permissions: ['camera']})
    } else {
      cameraStatus = await Camera.checkPermissions()
    }
    
    let notificationStatus
    if (askedPermissions.notification) {
      notificationStatus = await PushNotifications.requestPermissions()
    } else {
      notificationStatus = await PushNotifications.checkPermissions()
    }
    
    if (notificationStatus.receive === 'granted') {
      await this.pushNotificationService.register()
    }
    
    return new PermissionStatuses(
      locationStatus.location,
      cameraStatus.camera,
      notificationStatus.receive
    )
  }
  
  private async checkPermissionsAction(
    context: ActionContext<PermissionModuleState, AppState>,
    params?: Partial<PermissionChecks>
  ) {
    const permissionStatuses = await context.dispatch('getPermissionStatuses')
    this.checkPermissions(context, params, permissionStatuses)
  }
  
  private checkPermissions(
    context: ActionContext<PermissionModuleState, AppState>,
    checks: Partial<PermissionChecks> | undefined,
    permissionStatuses: PermissionStatuses
  ) {
    let checksToDo: PermissionChecks = {
      locationRequired: false
    }
    if (checks !== undefined) {
      checksToDo = deepmerge(checksToDo, checks)
    }
    
    if (permissionStatuses.locationState !== 'granted') {
      if (checksToDo.locationRequired) {
        if (permissionStatuses.locationState === 'disabled') {
          throw context.rootGetters['location/locationDisabledError']
        } else {
          throw this.getLocationPermissionDeniedError()
        }
      }
    }
  }
  
  private async presentPermissionsModal(
    askedPermissions: AskedPermissions,
    permissionStatuses: PermissionStatuses
  ): Promise<PermissionStatuses> {
    const modal = await modalController.create({
      component: PermissionsModal,
      componentProps: {
        askedPermissions,
        permissionStatuses
      }
    })
    await modal.present()
    
    const event = await modal.onDidDismiss()
    if (event.data instanceof PermissionStatuses) {
      return event.data
    } else {
      throw (event.data || makeException(this.i18n, 'error.permissionModalDenied'))
    }
  }
  
  private getLocationPermissionDeniedError(): Exception {
    switch (Capacitor.getPlatform()) {
    case 'android':
      return makeException(this.i18n, 'error.location.denied', undefined, this.resolvePermissionAndroid)
    default:
      return makeException(this.i18n, 'error.location.denied')
    }
  }
  
  private async resolvePermissionAction(): Promise<void> {
    const platform = Capacitor.getPlatform()
    if (platform === 'android') {
      await this.resolvePermissionAndroid()
    }
  }
  
  private async resolvePermissionAndroid() {
    const options: IntentOptions = {
      action: 'android.settings.APPLICATION_DETAILS_SETTINGS',
      url: 'package:io.rockease.app'
    }
    await WebIntent.startActivity(options)
    await waitForAppToResume()
  }
}
