import {JSONSchemaType} from 'ajv'
import {MaterialCategory} from '@/service/model/MaterialCategory'

export const MaterialType = {
  NORMAL: 'NORMAL',
  WASTE: 'WASTE'
}

export interface MaterialBean {
  id: string
  name?: string | null
  unit: string
  quarry_name?: string | null
  category: string
  type: string
}

export function isSameMaterial(material?: MaterialBean | null, other?: MaterialBean | null): boolean {
  if (material?.name !== other?.name) {
    return false
  }
  if (material?.unit !== other?.unit) {
    return false
  }
  if (material?.quarry_name !== other?.quarry_name) {
    return false
  }
  if (material?.category !== other?.category) {
    return false
  }
  // noinspection RedundantIfStatementJS
  if (material?.type !== other?.type) {
    return false
  }
  return true
}

export const materialSchema: JSONSchemaType<MaterialBean> = {
  type: 'object',
  properties: {
    id: {
      type: ['string']
    },
    name: {
      type: ['string'],
      nullable: true
    },
    unit: {
      type: ['string']
    },
    quarry_name: {
      type: ['string'],
      nullable: true
    },
    category: {
      type: ['string'],
      enum: [
        MaterialCategory.MATERIAL,
        MaterialCategory.WASTE,
        MaterialCategory.ASPHALT,
        MaterialCategory.CONCRETE
      ]
    },
    type: {
      type: ['string'],
      enum: [MaterialType.NORMAL, MaterialType.WASTE]
    }
  },
  required: ['id', 'unit', 'category', 'type'],
  additionalProperties: true
}
