// Must be first to import missing JS methods for some browser.
import 'core-js/actual'

import {createApp} from 'vue'
import App from './App.vue'
import {makeRouter} from './router'
import {makeStore} from './stores'
import i18n from '@/i18n'

import {IonicVue} from '@ionic/vue'

// Core CSS required for Ionic components to work properly
import '@ionic/vue/css/core.css'

// Basic CSS for apps built with Ionic
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

// Optional CSS utils that can be commented out
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

// Theme variables
import '@/theme/variables.css'
import '@/theme/common.css'

// v-viewer
import VueViewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

import {defineCustomElements} from '@ionic/pwa-elements/loader'
import {PushNotificationService, pushNotificationServiceKey} from '@/service/PushNotificationService'
import {IonicProxyService} from '@/service/IonicProxyService'
import {ErrorReportingService} from '@/service/ErrorReportingService'
import {AuthenticationService} from '@/service/authentication/AuthenticationService'
import {ErrorReportingApi} from '@/service/api/ErrorReportingApi'
import {registerErrorHandlers} from '@/errors'
import {SynchronizationService} from '@/service/SynchronizationService'
import {NativeAuthenticationService} from '@/service/authentication/NativeAuthenticationService'
import {WebAuthenticationService} from '@/service/authentication/WebAuthenticationService'

// Service
const ionicProxyService = new IonicProxyService()

const authenticationService = new AuthenticationService(i18n, [
  new WebAuthenticationService(i18n),
  new NativeAuthenticationService(i18n)
])

const errorReportingService = new ErrorReportingService(new ErrorReportingApi(i18n, authenticationService))
authenticationService.inject(errorReportingService)

const pushNotificationService = new PushNotificationService()
const synchronizationService = new SynchronizationService(i18n)

const store = makeStore(
  i18n,
  ionicProxyService,
  authenticationService,
  errorReportingService,
  pushNotificationService,
  synchronizationService
)
const router = makeRouter(store)

ionicProxyService.router = router

const app = createApp(App)
app.use(IonicVue)
app.use(VueViewer)
app.use(router)
app.use(i18n)
app.use(store)

// Inject services to Vue components
app.provide(pushNotificationServiceKey, pushNotificationService)

registerErrorHandlers(app, errorReportingService)

router.isReady().then(() => {
  app.mount('#app')
})

// Call the element loader after the app has been rendered the first time
defineCustomElements(window).catch((error) => {
  console.error(error)
})
