import {Flow, FlowStep} from '@/service/flow/Flow'
import {RouteLocationRaw} from 'vue-router'
import {SiteManagerQuotationRequestRoute} from '@/router/sitemanager'
import {SiteBean} from '@/service/model/SiteBean'
import {MaterialBean} from '@/service/model/MaterialBean'
import {DeliveryType} from '@/service/model/DeliveryNoteBean'
import {Address} from '@/utils/GeoFormatters'
import {LocationBean} from '@/service/model/LocationBean'
import {CustomerBean} from '@/service/model/CustomerBean'

export interface QuotationRequestTemporarySite extends Address {
  customer_id?: string
  name?: string
  reference?: string
  location?: LocationBean
}

export interface QuotationRequestFlowState {
  site?: SiteBean
  useTemporarySite?: boolean
  temporarySite?: QuotationRequestTemporarySite
  deliveryType?: string
  withBackload?: boolean
  material?: MaterialBean
  backloadMaterial?: MaterialBean
  materialQuantity?: number
  materialCadence?: number
  materialCadencePeriodicity?: number
  backloadMaterialQuantity?: number
  truckType?: string
  day?: string
  comment?: string
}

export const QuotationRequestFlowSelectSiteStep: FlowStep<null, QuotationRequestFlowState> = {
  routeName: SiteManagerQuotationRequestRoute.SELECT_SITE,
  isCompleted: state => state.site !== undefined && state.useTemporarySite !== undefined
}

export const QuotationRequestFlowSelectCustomerStep: FlowStep<null, QuotationRequestFlowState> = {
  routeName: SiteManagerQuotationRequestRoute.SELECT_CUSTOMER,
  canEnter: (item, state) => state.useTemporarySite === true,
  isCompleted: state => state.temporarySite?.customer_id !== undefined
}

export const QuotationRequestFlowCreateSiteStep: FlowStep<null, QuotationRequestFlowState> = {
  routeName: SiteManagerQuotationRequestRoute.CREATE_SITE,
  canEnter: (item, state) => state.useTemporarySite === true,
  isCompleted: state => state.temporarySite?.name !== undefined
}

export const QuotationRequestFlowDeliveryTypeStep: FlowStep<null, QuotationRequestFlowState> = {
  routeName: SiteManagerQuotationRequestRoute.DELIVERY_TYPE,
  isCompleted: state => state.deliveryType !== undefined && state.withBackload !== undefined
}

export const QuotationRequestFlowSelectMaterialStep: FlowStep<null, QuotationRequestFlowState> = {
  routeName: SiteManagerQuotationRequestRoute.SELECT_MATERIAL,
  isCompleted: state => state.material !== undefined
}

export const QuotationRequestFlowSelectBackloadMaterialStep: FlowStep<null, QuotationRequestFlowState> = {
  routeName: SiteManagerQuotationRequestRoute.SELECT_BACKLOAD_MATERIAL,
  canEnter: (item, state) => state.withBackload === true,
  isCompleted: state => state.backloadMaterial !== undefined
}

export const QuotationRequestFlowQuantityStep: FlowStep<null, QuotationRequestFlowState> = {
  routeName: SiteManagerQuotationRequestRoute.QUANTITY,
  isCompleted: state => {
    if (state.materialQuantity === undefined || state.materialCadence === undefined || state.materialCadencePeriodicity === undefined) {
      return false
    }
    // noinspection RedundantIfStatementJS
    if (state.backloadMaterial !== undefined) {
      if (state.backloadMaterialQuantity === undefined) {
        return false
      }
    }
    return true
  }
}

export const QuotationRequestFlowSelectTruckStep: FlowStep<null, QuotationRequestFlowState> = {
  routeName: SiteManagerQuotationRequestRoute.SELECT_TRUCK,
  canEnter: (item, state) => state.deliveryType === DeliveryType.QUARRY_HAULER,
  isCompleted: state => state.truckType !== undefined
}

export const QuotationRequestFlowDayStep: FlowStep<null, QuotationRequestFlowState> = {
  routeName: SiteManagerQuotationRequestRoute.DAY,
  isCompleted: state => state.day !== undefined
}

export const QuotationRequestFlowConfirmStep: FlowStep<null, QuotationRequestFlowState> = {
  routeName: SiteManagerQuotationRequestRoute.CONFIRM,
  isCompleted: state => state.comment !== undefined
}

export class QuotationRequestFlow extends Flow<null, null, QuotationRequestFlowState> {
  
  constructor(
    private readonly initialRoute?: RouteLocationRaw
  ) {
    super(null, null, {})
  }
  
  setSite(site: SiteBean | undefined) {
    this.assignState({
      site: site
    })
  }
  
  setUseTemporarySite(useTemporarySite: boolean | undefined) {
    const update: Partial<QuotationRequestFlowState> = {
      useTemporarySite: useTemporarySite
    }
    if (useTemporarySite === undefined || useTemporarySite) {
      update.site = undefined
    }
    if (useTemporarySite === undefined || !useTemporarySite) {
      update.temporarySite = undefined
    }
    this.assignState(update)
  }
  
  setCustomer(customer: CustomerBean | undefined) {
    if (customer !== undefined) {
      this.assignState({
        temporarySite: {
          customer_id: customer.id
        }
      })
    } else {
      this.assignState({
        temporarySite: undefined
      })
    }
  }
  
  setTemporarySite(site: QuotationRequestTemporarySite | undefined) {
    this.assignState({
      temporarySite: site
    })
  }
  
  setDeliveryType(deliveryType: string | undefined) {
    this.assignState({
      deliveryType: deliveryType
    })
    if (deliveryType === undefined || deliveryType === DeliveryType.CUSTOMER) {
      // Reset truck type
      this.assignState({
        truckType: undefined
      })
    }
  }
  
  setWithBackload(withBackload: boolean | undefined) {
    this.assignState({
      withBackload: withBackload
    })
    if (withBackload === undefined || !withBackload) {
      // Reset backload
      this.assignState({
        backloadMaterial: undefined,
        backloadMaterialQuantity: undefined
      })
    }
  }
  
  setMaterial(material: MaterialBean | undefined) {
    this.assignState({
      material: material
    })
  }
  
  setBackloadMaterial(material: MaterialBean | undefined) {
    this.assignState({
      backloadMaterial: material
    })
  }
  
  setMaterialQuantity(quantity: number | undefined) {
    this.assignState({
      materialQuantity: quantity
    })
  }
  
  setMaterialCadence(cadence: number | undefined) {
    this.assignState({
      materialCadence: cadence
    })
  }
  
  setMaterialCadencePeriodicity(period: number | undefined) {
    this.assignState({
      materialCadencePeriodicity: period
    })
  }
  
  setBackloadMaterialQuantity(quantity: number | undefined) {
    this.assignState({
      backloadMaterialQuantity: quantity
    })
  }
  
  setTruckType(truckType: string | undefined) {
    this.assignState({
      truckType: truckType
    })
  }
  
  setDay(day: string | undefined) {
    this.assignState({
      day: day
    })
  }
  
  setComment(comment: string | undefined) {
    this.assignState({
      comment: comment
    })
  }
  
  getOutputRoute(): RouteLocationRaw {
    return {
      name: 'SiteManagerQuotationRequest'
    }
  }
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getCancelRoute(): RouteLocationRaw {
    if (this.initialRoute !== undefined) {
      return this.initialRoute
    } else {
      return {
        name: 'SiteManagerQuotationRequests'
      }
    }
  }
  
  getSteps(): Array<FlowStep<null, QuotationRequestFlowState>> {
    return [
      QuotationRequestFlowSelectSiteStep,
      QuotationRequestFlowSelectCustomerStep,
      QuotationRequestFlowCreateSiteStep,
      QuotationRequestFlowDeliveryTypeStep,
      QuotationRequestFlowSelectMaterialStep,
      QuotationRequestFlowSelectBackloadMaterialStep,
      QuotationRequestFlowQuantityStep,
      QuotationRequestFlowSelectTruckStep,
      QuotationRequestFlowDayStep,
      QuotationRequestFlowConfirmStep
    ]
  }
}
