<template>
  <ion-item lines="full" v-if="message" v-bind:color="color">
    <ion-icon v-if="icon" slot="start" :icon="icon"/>
    <ion-label class="ion-text-wrap">{{ message }}</ion-label>
  </ion-item>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {IonIcon, IonItem, IonLabel} from '@ionic/vue'
import {mapGetters} from 'vuex'

export default defineComponent({
  name: 'MessageHeader',
  computed: {
    ...mapGetters('message', ['message', 'icon', 'color'])
  },
  components: {
    IonItem, IonIcon, IonLabel
  }
})
</script>
