import {AbstractApi, QueryOptions} from '@/service/api/AbstractApi'
import {Picture, PictureBean} from '@/service/model/PictureBean'
import _ from 'lodash'
import {Filesystem} from '@capacitor/filesystem'
import {I18n} from 'vue-i18n'
import {AuthenticationService} from '@/service/authentication/AuthenticationService'
import {SynchronizationService, SynchronizeParamsFormField, SynchronizeResult} from '@/service/SynchronizationService'
import {Capacitor} from '@capacitor/core'

export const PICTURE_REQUEST_TIME = 300000

export class AbstractPictureApi extends AbstractApi {
  
  constructor(
    i18n: I18n,
    authenticationService: AuthenticationService,
    readonly synchronizationService: SynchronizationService
  ) {
    super(i18n, authenticationService)
  }
  
  async getPicture(options: QueryOptions): Promise<Blob> {
    const queryOptions: QueryOptions = {
      ...options,
      requestTimeout: PICTURE_REQUEST_TIME
    }
    const response = await this.internalFetch('get', queryOptions)
    await this.checkResponseOk(response, queryOptions)
    return this.parseImageContent(response, queryOptions)
  }
  
  async putOrSyncPicture(
    type: string,
    id: string,
    path: string,
    fileName: string,
    picture: PictureBean
  ): Promise<SynchronizeResult<void>> {
    if (Capacitor.isNativePlatform()) {
      const formFields = this.createFormFields(picture)
      this.addPictureToFormFields(formFields, fileName, picture.picture)
      return this.synchronizationService.synchronize<void>({
        type: type,
        id: id,
        method: 'PUT',
        subType: fileName,
        path: path,
        formContent: formFields,
        expectContent: false
      })
    } else {
      await this.putPicture(path, fileName, picture)
      return {
        pending: false
      }
    }
  }
  
  async putPicture(path: string, fileName: string, picture: PictureBean): Promise<void> {
    const formData = this.createFormData(picture)
    await AbstractPictureApi.addPictureToFormData(formData, fileName, picture.picture)
    
    return this.putVoid({
      path: path,
      authentication: 'required',
      rawBody: formData,
      requestTimeout: PICTURE_REQUEST_TIME
    })
  }
  
  private async parseImageContent(response: Response, options: QueryOptions): Promise<Blob> {
    const content = await response.blob()
    if (!response.ok) {
      console.error('Server responded with status %d while fetching path %o: %o', response.status, options.path, content)
      throw this.convertResponseToApiException(content, response)
    }
    return content
  }
  
  private createFormData(picture: PictureBean): FormData {
    const formData = new FormData()
    
    const location = picture.location
    if (location) {
      formData.set('gps-latitude', `${location.latitude}`)
      formData.set('gps-longitude', `${location.longitude}`)
    }
    
    const date = picture.date
    if (date) {
      formData.set('timestamp', date)
    }
    
    return formData
  }
  
  static async addPictureToFormData(formData: FormData, fileName: string, picture: Picture, formDataName: string = 'file'): Promise<FormData> {
    if (_.isString(picture)) {
      formData.set(formDataName, await AbstractPictureApi.readPictureFromFile(picture), fileName)
    } else {
      formData.set(formDataName, picture, fileName)
    }
    return formData
  }
  
  private static async readPictureFromFile(path: string): Promise<Blob> {
    const file = await Filesystem.readFile({path})
    const response = await fetch('data:image/jpeg;base64, ' + file.data)
    return response.blob()
  }
  
  private createFormFields(picture: PictureBean): {[name: string]: SynchronizeParamsFormField} {
    const formFields = {}
    
    const location = picture.location
    if (location) {
      formFields['gps-latitude'] = {
        content: `${location.latitude}`
      }
      formFields['gps-longitude'] = {
        content: `${location.longitude}`
      }
    }
    
    const date = picture.date
    if (date) {
      formFields['timestamp'] = {
        content: date
      }
    }
    
    return formFields
  }
  
  private addPictureToFormFields(
    formFields: {[name: string]: SynchronizeParamsFormField},
    fileName: string,
    picture: Picture
  ) {
    if (_.isString(picture)) {
      formFields['file'] = {
        fileName: fileName,
        contentType: this.determineContentTypeFromFileName(picture),
        path: picture
      }
    } else {
      throw Error('Unsupported picture type. Synchro only support stored file.')
    }
  }
  
  private determineContentTypeFromFileName(fileName: string): string {
    if (fileName.endsWith('.jpg')) {
      return 'application/jpeg'
    } else {
      throw Error('Cannot determine content type from filename.')
    }
  }
}
