import {getItemOperation, getListOperation, GetOperation} from '@/service/operation/GetOperation'
import Result from '@/service/operation/Result'
import {ActionContext, Module} from 'vuex'
import {AppState} from '@/stores'
import {I18n} from 'vue-i18n'
import {LocalStore} from '@/service/store/LocalStore'
import {SiteManagerOrderApi} from '@/service/api/sitemanager/SiteManagerOrderApi'
import {SiteManagerOrderBean} from '@/service/model/sitemanager/SiteManagerOrderBean'
import {makeException} from '@/exception/Exception'
import {DeliveryNoteState} from '@/stores/modules/DeliveryNoteModule'
import {SiteManagerQuotationRequestModuleState} from '@/stores/modules/sitemanager/SiteManagerQuotationRequestModule'
import {DeliveryNoteBean} from '@/service/model/DeliveryNoteBean'

const ordersStoreKey = 'orders'
const currentOrderDeliveryNotesStoreKey = 'currentOrderDeliveryNotes'

export interface SiteManagerOrderModuleState {
  ordersOperation?: GetOperation<Array<SiteManagerOrderBean>, string>
  ordersResult?: Result<Array<SiteManagerOrderBean>>
  currentOrderOperation?: GetOperation<SiteManagerOrderBean, string>
  currentOrderResult?: Result<SiteManagerOrderBean>
  currentOrderDeliveryNotesOperation?: GetOperation<Array<DeliveryNoteBean>, string>
  currentOrderDeliveryNotesResult?: Array<DeliveryNoteBean>
}

export class SiteManagerOrderModule implements Module<SiteManagerOrderModuleState, AppState> {
  namespaced = true
  actions = {
    fetchOrders: this.fetchOrdersAction.bind(this),
    refreshOrders: this.refreshOrdersAction.bind(this),
    
    selectOrder: this.selectOrderAction.bind(this),
    loadMoreDeliveryNotes: this.loadMoreDeliveryNotesAction.bind(this),
    
    clearOrderModule: this.clearOrderModuleAction.bind(this),
  }
  mutations = {
    setOrdersOperation: (state, operation) => state.ordersOperation = operation,
    setOrdersResult: (state, result) => state.ordersResult = result,
    setCurrentOrderOperation: (state, operation) => state.currentOrderOperation = operation,
    setCurrentOrderResult: (state, result) => state.currentOrderResult = result,
    setCurrentOrderDeliveryNotesOperation: (state, operation) => state.currentOrderDeliveryNotes = operation,
    setCurrentOrderDeliveryNotesResult: (state, result) => state.currentOrderDeliveryNotesResult = result
  }
  getters = {
    ordersResult: (state) => state.ordersResult,
    currentOrderResult: (state) => state.currentOrderResult,
    currentOrderDeliveryNotesResult: (state) => state.currentOrderDeliveryNotesResult
  }
  
  constructor(
    private readonly i18n: I18n,
    private readonly listStore: LocalStore<Array<string>, string>,
    private readonly deliveryNoteStore: LocalStore<DeliveryNoteBean, string>,
    private readonly orderStore: LocalStore<SiteManagerOrderBean, string>,
    private readonly orderApi: SiteManagerOrderApi,
  ) {
  }
  
  private async loadMoreDeliveryNotesAction(
    context: ActionContext<SiteManagerOrderModuleState, AppState>,
    params  : {orderId: string; limit: number; pageNumber: number}) {
    const deliveryNotes= await this.orderApi.fetchDeliveryNotes(params.orderId, params.limit, params.pageNumber)
    const existingDeliveryNotes = context.state.currentOrderDeliveryNotesResult
    const allItems = (existingDeliveryNotes || []).concat(deliveryNotes || [])
    context.commit('setCurrentOrderDeliveryNotesResult', allItems)
  }
  private async fetchOrdersAction(
    context: ActionContext<SiteManagerOrderModuleState, AppState>
  ): Promise<Array<SiteManagerOrderBean> | null> {
    const operation = getListOperation(
      this.orderStore,
      this.listStore,
      ordersStoreKey,
      it => it.id,
      () => this.orderApi.fetchOrders()
    )
    
    context.commit('setOrdersOperation', operation)
    
    return await operation.get(
      {params: null},
      it => {
        context.commit('setOrdersResult', it)
      }
    )
  }
  
  async refreshOrdersAction(
    context: ActionContext<SiteManagerOrderModuleState, AppState>
  ): Promise<Array<SiteManagerOrderBean> | null> {
    const operation = context.state.ordersOperation
    if (!operation) {
      throw makeException(this.i18n, 'error.unknown')
    }
    return operation.refresh(true)
  }
  
  private async selectOrderAction(
    context: ActionContext<DeliveryNoteState, AppState>,
    orderId: string
  ): Promise<any> {
    const orderOperation = getItemOperation(
      this.orderStore,
      id => this.orderApi.fetchOrder(id)
    )
    
    const deliveryNotesOperation = getListOperation(
      this.deliveryNoteStore,
      this.listStore,
      currentOrderDeliveryNotesStoreKey,
      (it: DeliveryNoteBean) => it.id,
      () => this.orderApi.fetchDeliveryNotes(orderId, 10, 0)
    )
  
    context.commit('setCurrentOrderOperation', orderOperation)
    context.commit('setCurrentOrderResult', undefined)
    context.commit('setCurrentOrderDeliveryNotesOperation', deliveryNotesOperation)
    context.commit('setCurrentOrderDeliveryNotesResult', undefined)
    
    const orderPromise = orderOperation.get(
      {params: orderId},
      it => context.commit('setCurrentOrderResult', it)
    )
    const deliveryNotesPromise = deliveryNotesOperation.get(
      {params: null},
      it => context.commit('setCurrentOrderDeliveryNotesResult', it.item())
    )
    return Promise.all([orderPromise, deliveryNotesPromise])
  }
  
  clearOrderModuleAction(
    context: ActionContext<SiteManagerQuotationRequestModuleState, AppState>
  ) {
    context.commit('setOrdersResult', undefined)
    context.commit('setOrdersOperation', undefined)
    context.commit('setCurrentOrderOperation', undefined)
    context.commit('setCurrentOrderResult', undefined)
    context.commit('setCurrentOrderDeliveryNotesOperation', undefined)
    context.commit('setCurrentOrderDeliveryNotesResult', undefined)
    this.listStore.deleteItem(ordersStoreKey)
    this.listStore.deleteItem(currentOrderDeliveryNotesStoreKey)
    this.orderStore.clear()
  }
}
