import {AbstractApi} from '@/service/api/AbstractApi'
import {QuotationRequestBean, quotationRequestSchema} from '@/service/model/QuotationRequestBean'
import {QuotationResponseBean, quotationResponseSchema} from '@/service/model/QuotationResponseBean'
import {JSONSchemaType} from 'ajv'
import {I18n} from 'vue-i18n'
import {AuthenticationService} from '@/service/authentication/AuthenticationService'
import {AlternativeQuarryBean, alternativeQuarrySchema} from '@/service/model/sitemanager/AlternativeQuarryBean'
import {SiteManagerOrderBean, siteManagerOrderSchema} from '@/service/model/sitemanager/SiteManagerOrderBean'
import {Address} from '@/utils/GeoFormatters'
import {LocationBean} from '@/service/model/LocationBean'
import {
  siteManagerQuotationRequestArraySchema,
  SiteManagerQuotationRequestBean, siteManagerQuotationRequestSchema
} from '@/service/model/sitemanager/SiteManagerQuotationRequestBean'

export interface QuotationRequestCreationBean {
  site_id?: string
  temporary_site?: QuotationRequestTemporarySiteBean
  
  express_order: boolean
  delivery_type?: string // ignored for express order
  truck_type?: string // ignored for express order
  
  start_day?: string // ignored for express order
  
  material_type: string
  material_id: string
  material_quantity: number
  material_cadence?: number // ignored for express order
  material_cadence_periodicity?: number // ignored for express order
  
  backload_material_id?: string
  backload_material_quantity?: number
  
  comment?: string
}

export interface QuotationRequestTemporarySiteBean extends Address {
  customer_id: string
  
  name?: string | null
  
  reference?: string | null
  
  location?: LocationBean
}

export interface QuotationRequestAndResponsesBean {
  fee_percent: number
  spend_limit?: number | null
  request: QuotationRequestBean
  responses: Array<QuotationResponseBean>
  alternative_quarries: Array<AlternativeQuarryBean>
}

export const quotationRequestAndResponsesSchema: JSONSchemaType<QuotationRequestAndResponsesBean> = {
  type: 'object',
  properties: {
    fee_percent: {
      type: 'number'
    },
    spend_limit: {
      type: ['number'],
      nullable: true
    },
    request: quotationRequestSchema,
    responses: {
      type: 'array',
      items: quotationResponseSchema
    },
    alternative_quarries: {
      type: 'array',
      items: alternativeQuarrySchema
    }
  },
  required: ['fee_percent', 'request', 'responses', 'alternative_quarries'],
  additionalProperties: true
}

export interface QuotationRequestValidationResultBean {
  order_created: boolean
  requires_validation: boolean
  order?: SiteManagerOrderBean | null
}

export const quotationRequestValidationResultBeanSchema: JSONSchemaType<QuotationRequestValidationResultBean> = {
  type: 'object',
  properties: {
    order_created: {
      type: 'boolean'
    },
    requires_validation: {
      type: 'boolean'
    },
    order: {
      ...siteManagerOrderSchema,
      nullable: true
    }
  },
  required: ['order_created', 'requires_validation'],
  additionalProperties: true
}

export class QuotationRequestApi extends AbstractApi {
  
  constructor(i18n: I18n, authenticationService: AuthenticationService) {
    super(i18n, authenticationService)
  }
  
  async fetchQuotationRequests(): Promise<Array<SiteManagerQuotationRequestBean>> {
    return this.get({
      path: '/api/mp/v1/site-manager/quotation-requests',
      authentication: 'required',
      schema: siteManagerQuotationRequestArraySchema
    })
  }
  
  async fetchQuotationRequest(id: string): Promise<SiteManagerQuotationRequestBean> {
    return this.get({
      path: `/api/mp/v1/site-manager/quotation-requests/${id}`,
      authentication: 'required',
      schema: siteManagerQuotationRequestSchema
    })
  }
  
  async create(
    body: QuotationRequestCreationBean
  ): Promise<QuotationRequestAndResponsesBean> {
    return this.post<QuotationRequestAndResponsesBean>({
      path: '/api/mp/v1/site-manager/quotation-requests',
      authentication: 'required',
      body: body,
      schema: quotationRequestAndResponsesSchema
    })
  }
  
  async validateResponse(
    requestId: string,
    responseId: string
  ): Promise<QuotationRequestValidationResultBean> {
    return this.put<QuotationRequestValidationResultBean>({
      path: `/api/mp/v1/site-manager/quotation-requests/${requestId}/responses/${responseId}/validate`,
      authentication: 'required',
      schema: quotationRequestValidationResultBeanSchema
    })
  }
}
