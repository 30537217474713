import {AddressBean, addressSchema} from '@/service/model/AddressBean'
import {LocationBean, locationSchema} from '@/service/model/LocationBean'
import {JSONSchemaType} from 'ajv'
import {isSameLocation} from '@/utils/GeoFormatters'

export interface SiteBean extends AddressBean {
  id: string
  name: string
  reference?: string | null
  
  location?: LocationBean | null
}

export function isSameSite(site?: SiteBean | null, other?: SiteBean | null): boolean {
  if (site?.id !== other?.id) {
    return false
  }
  if (site?.name !== other?.name) {
    return false
  }
  if (site?.reference !== other?.reference) {
    return false
  }
  // noinspection RedundantIfStatementJS
  if (!isSameLocation(site?.location, other?.location)) {
    return false
  }
  return true
}

export const siteSchema: JSONSchemaType<SiteBean> = {
  type: 'object',
  properties: {
    id: {
      type: ['string']
    },
    name: {
      type: ['string']
    },
    reference: {
      type: ['string'],
      nullable: true
    },
    ...addressSchema.properties,
    location: {
      ...locationSchema,
      type: ['object'],
      nullable: true
    }
  },
  required: ['id', 'name'],
  additionalProperties: true
}

export const siteManagerSiteArraySchema: JSONSchemaType<Array<SiteBean>> = {
  type: 'array',
  items: siteSchema
}
