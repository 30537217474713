import {PictureBean, PictureType} from '@/service/model/PictureBean'
import {I18n} from 'vue-i18n'
import {AuthenticationService} from '@/service/authentication/AuthenticationService'
import {AbstractPictureApi} from '@/service/api/AbstractPictureApi'
import {SynchronizationService, SynchronizeResult} from '@/service/SynchronizationService'

export class DeliveryNotePictureApi extends AbstractPictureApi {
  
  constructor(
    i18n: I18n,
    authenticationService: AuthenticationService,
    synchronizationService: SynchronizationService
  ) {
    super(i18n, authenticationService, synchronizationService)
  }
  
  async uploadPicture(
    type: string,
    deliveryNoteId: string,
    pictureType: PictureType,
    picture: PictureBean
  ): Promise<SynchronizeResult<void>> {
    return this.putOrSyncPicture(
      type, deliveryNoteId,
      DeliveryNotePictureApi.getPath(deliveryNoteId, pictureType),
      pictureType,
      picture
    )
  }
  
  private static getPath(deliveryNoteId: string, pictureType: PictureType): string {
    switch (pictureType) {
    case 'WEIGHING':
      return `/api/mp/v1/driver/delivery-notes/${deliveryNoteId}/weighing-slip`
    case 'LOAD':
      return `/api/mp/v1/driver/delivery-notes/${deliveryNoteId}/load`
    case 'UNLOAD':
      return `/api/mp/v1/driver/delivery-notes/${deliveryNoteId}/unload`
    }
  }
}
