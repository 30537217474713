import {CreatedDeliveryNoteBean, SiteManagerDeliveryNoteApi} from '@/service/api/sitemanager/SiteManagerDeliveryNoteApi'
import {SiteManagerOrderBean} from '@/service/model/sitemanager/SiteManagerOrderBean'
import {MaterialBean} from '@/service/model/MaterialBean'
import {DeliveryNoteBean} from '@/service/model/DeliveryNoteBean'
import {ActionContext, Module} from 'vuex'
import {AppState} from '@/stores'
import {I18n} from 'vue-i18n'
import {makeException} from '@/exception/Exception'
import {
  SiteManagerCreatePickupFlow,
  SiteManagerCreatePickupFlowState
} from '@/service/flow/sitemanager/SiteManagerCreatePickupFlow'
import {RouteLocationRaw} from 'vue-router'

interface SiteManagerCreatePickupState {
  flow?: SiteManagerCreatePickupFlow
}

// noinspection JSMethodCanBeStatic
export class SiteManagerCreatePickupModule implements Module<SiteManagerCreatePickupState, AppState> {
  namespaced = true
  state = {}
  mutations = {
    setFlow: (state, flow) => state.flow = flow,
    setPreselectedOrder: (state, order) => state.flow?.setPreselectedOrder(order),
    setOrder: (state, order) => state.flow?.setOrder(order),
    setRoundTripsCount: (state, roundTripsCount) => state.flow?.setRoundTripsCount(roundTripsCount),
    setMaterial: (state, material) => state.flow?.setMaterial(material),
    setBackloadMaterial: (state, material) => state.flow?.setBackloadMaterial(material),
    setDriverPhoneNumber: (state, phoneNumber) => state.flow?.setDriverPhoneNumber(phoneNumber),
    setTruckRegistration: (state, truckRegistration) => state.flow?.setTruckRegistration(truckRegistration),
    setComment: (state, comment) => state.flow?.setComment(comment)
  }
  getters = {
    flow: (state) => state.flow,
    order: (state) => state.flow?.getState()?.order,
    roundTripsCount: (state) => state.flow?.getState()?.roundTripsCount,
    material: (state) => state.flow?.getState()?.material,
    backloadMaterial: (state) => state.flow?.getState()?.backloadMaterial
  }
  actions = {
    createPickupFlow: this.createPickupFlowAction.bind(this),
    setOrder: this.setOrderAction.bind(this),
    setRoundTripsCount: this.setRoundTripsCountAction.bind(this),
    setMaterial: this.setMaterialAction.bind(this),
    setBackloadMaterial: this.setBackloadMaterialAction.bind(this),
    setDriverPhoneNumber: this.setDriverPhoneNumberAction.bind(this),
    setTruckRegistration: this.setTruckRegistrationAction.bind(this),
    setComment: this.setCommentAction.bind(this),
    createPickupDeliveryNotes: this.createPickupDeliveryNotesAction.bind(this)
  }
  
  constructor(
    private readonly i18n: I18n,
    private readonly siteManagerDeliveryNoteApi: SiteManagerDeliveryNoteApi
  ) {
  }
  
  async createPickupFlowAction(
    context: ActionContext<SiteManagerCreatePickupState, AppState>,
    params: { initialRoute?: RouteLocationRaw; order: SiteManagerOrderBean }
  ): Promise<SiteManagerCreatePickupFlow> {
    const flow = new SiteManagerCreatePickupFlow(params?.initialRoute)
    context.commit('setFlow', flow)
    
    if (params?.order !== undefined) {
      context.commit('setPreselectedOrder', params.order)
    }
    
    return flow
  }
  
  private setOrderAction(
    context: ActionContext<SiteManagerCreatePickupState, AppState>,
    order?: SiteManagerOrderBean
  ) {
    context.commit('setOrder', order)
  }
  
  setRoundTripsCountAction(
    context: ActionContext<SiteManagerCreatePickupState, AppState>,
    count: number | undefined
  ) {
    context.commit('setRoundTripsCount', count)
  }
  
  setMaterialAction(
    context: ActionContext<SiteManagerCreatePickupState, AppState>,
    material: MaterialBean | undefined
  ) {
    context.commit('setMaterial', material)
  }
  
  setBackloadMaterialAction(
    context: ActionContext<SiteManagerCreatePickupState, AppState>,
    material: MaterialBean | undefined
  ) {
    context.commit('setBackloadMaterial', material)
  }
  
  setDriverPhoneNumberAction(
    context: ActionContext<SiteManagerCreatePickupState, AppState>,
    phoneNumber: string | undefined
  ) {
    context.commit('setDriverPhoneNumber', phoneNumber)
  }
  
  setTruckRegistrationAction(
    context: ActionContext<SiteManagerCreatePickupState, AppState>,
    truckRegistration: string | undefined
  ) {
    context.commit('setTruckRegistration', truckRegistration)
  }
  
  setCommentAction(
    context: ActionContext<SiteManagerCreatePickupState, AppState>,
    comment: string | undefined
  ) {
    context.commit('setComment', comment)
  }
  
  async createPickupDeliveryNotesAction(
    context: ActionContext<SiteManagerCreatePickupState, AppState>,
  ): Promise<Array<DeliveryNoteBean>> {
    const flowState = context.state.flow?.getState()
    
    const bean = this.getBean(flowState)
    if (bean === undefined) {
      throw makeException(this.i18n, 'error.unknown')
    }
    
    const deliveryNotes = await this.siteManagerDeliveryNoteApi.create(bean)
    
    await context.dispatch('addUpcomingDeliveryNotes', deliveryNotes, {root: true})
    return deliveryNotes
  }
  
  getBean(flowState?: SiteManagerCreatePickupFlowState): CreatedDeliveryNoteBean | undefined {
    if (
      flowState?.order?.id === undefined ||
      flowState?.roundTripsCount === undefined ||
      flowState?.material === undefined
    ) {
      return undefined
    }
    return {
      order_id: flowState.order.id,
      count: flowState.roundTripsCount,
      material_type: flowState.material.type,
      with_backload: flowState.backloadMaterial !== undefined,
      driver_phone_number: flowState.driverPhoneNumber,
      truck_registration: flowState.truckRegistration,
      comment: flowState.comment
    }
  }
}
