import {SiteManagerMaterialBean, siteManagerMaterialSchema} from '@/service/model/sitemanager/SiteManagerMaterialBean'
import {CustomerBean, customerSchema} from '@/service/model/CustomerBean'
import {QuarryBean, quarrySchema} from '@/service/model/QuarryBean'
import {JSONSchemaType} from 'ajv'
import {SiteBean, siteSchema} from '@/service/model/SiteBean'

export interface CustomerOpenOrder {
  id: string
  delivery_type: string
  
  customer: CustomerBean
  site: SiteBean
  quarry: QuarryBean
  
  material: SiteManagerMaterialBean
  backload_material?: SiteManagerMaterialBean | null
}

export const customerOpenOrderSchema: JSONSchemaType<CustomerOpenOrder> = {
  type: 'object',
  properties: {
    id: {
      type: ['string']
    },
    delivery_type: {
      type: ['string']
    },
    
    customer: customerSchema,
    site: siteSchema,
    quarry: quarrySchema,
    
    material: siteManagerMaterialSchema,
    backload_material: {
      ...siteManagerMaterialSchema,
      nullable: true
    }
  },
  required: ['id', 'customer', 'quarry', 'material'],
  additionalProperties: true
}

export const customerOpenOrderArraySchema: JSONSchemaType<Array<CustomerOpenOrder>> = {
  type: 'array',
  items: customerOpenOrderSchema
}
