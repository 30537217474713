import {JSONSchemaType} from 'ajv'

export interface DeliveryNoteStatusChangedBean {
  delivery_note_id: string
  status: string
}

export const deliveryNoteStatusChangedSchema: JSONSchemaType<DeliveryNoteStatusChangedBean> = {
  type: 'object',
  properties: {
    delivery_note_id: {
      type: ['string']
    },
    status: {
      type: ['string']
    }
  },
  required: ['delivery_note_id', 'status'],
  additionalProperties: true
}
