import moment, {Moment} from 'moment'
import {MaterialBean} from '@/service/model/MaterialBean'

export const EMPTY_DAY = '--/--'
export const EMPTY_HOUR = '--:--'
export const UNKNOWN_HOUR = '##:##'

export function isMidnight(date: Moment): boolean {
  return date.hour() === 0 && date.minute() === 0 && date.second() === 0 && date.millisecond() === 0
}

export function formatHoursOrUnknown(s?: string | null): string {
  if (!s) {
    return EMPTY_HOUR
  }
  const date = moment.utc(s, moment.HTML5_FMT.DATETIME_LOCAL)
  if (isMidnight(date)) {
    return UNKNOWN_HOUR
  }
  const localDate = date.local()
  return localDate.format('HH:mm')
}

export function formatFullDate(s?: string): string | undefined {
  if (!s) {
    return undefined
  }
  const date = moment.utc(s, moment.HTML5_FMT.DATETIME_LOCAL)
  const localDate = date.local()
  return localDate.format('DD/MM/YYYY HH:mm')
}

export function formatQuantity(weight?: number, material?: MaterialBean): string | undefined {
  if (!weight) {
    return undefined
  }
  const unit = material?.unit || '-'
  return `${weight.toFixed(0)}${unit}`
}

export function formatWeight(weight?: number, material?: MaterialBean): string | undefined {
  if (!weight) {
    return undefined
  }
  const unit = material?.unit || '-'
  return `${weight.toFixed(1)}${unit}`
}
