<template>
  <div class="header ion-activatable ripple-parent" v-on:click="onClick">
    <ion-icon size="small" v-bind:icon="locationSharp"/>
    <ion-label class="ion-text-wrap">{{ t('message') }}</ion-label>
    <ion-ripple-effect></ion-ripple-effect>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {AlertButton, alertController, IonIcon, IonLabel, IonRippleEffect} from '@ionic/vue'
import {locationSharp} from 'ionicons/icons'
import {useI18n} from 'vue-i18n'
import {mapActions, mapGetters} from 'vuex'
import {ROLE_SITE_MANAGER} from '@/service/api/CurrentUserApi'
import {Capacitor} from '@capacitor/core'

export default defineComponent({
  name: 'gps-permission-denied',
  setup() {
    const {t} = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })
    return {
      t,
      locationSharp
    }
  },
  methods: {
    ...mapActions('permission', ['getPermissionStatuses', 'resolvePermissions']),
    async onClick() {
      const buttons: Array<AlertButton> = [{
        text: this.t('button.ok'),
        role: 'ok'
      }]
      if (this.canFix) {
        buttons.push({
          text:this.t('button.fix'),
          role: 'fix'
        })
      }

      const alert = await alertController.create({
        header: this.t('alert.header'),
        message: this.alertMessage,
        buttons: buttons
      })
      await alert.present()

      const {role} = await alert.onDidDismiss()
      if (role === 'fix') {
        await this.resolvePermissions()
        await this.getPermissionStatuses()
      }
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    platform(): string {
      return Capacitor.getPlatform()
    },
    canFix(): boolean {
      return this.platform === 'android'
    },
    alertMessage(): string {
      let message = ''
      switch (this.currentUser?.role) {
      case ROLE_SITE_MANAGER:
        message += this.t('alert.message.siteManager')
        break
      default:
        message += this.t('alert.message.default')
      }
      if (this.platformAlertMessage !== undefined) {
        message += '<br/><br/>' + this.platformAlertMessage
      }
      return message
    },
    platformAlertMessage(): string | undefined {
      switch (this.platform) {
      case 'android':
        return this.t('error.location.fix.android')
      case 'ios':
        return this.t('error.location.fix.ios')
      default:
        return undefined
      }
    }
  },
  components: {
    IonIcon, IonLabel,
    IonRippleEffect,
  }
})
</script>

<style scoped>

.header {
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: var(--padding-ion-item);

  background-color: darkred;
  color: var(--ion-color-dark-contrast);
}

ion-label {
  margin-left: var(--margin-md);
}

</style>

<i18n locale="en">
{
  "message": "GPS is disabled.",
  "alert": {
    "header": "Enable your GPS position",
    "message": {
      "siteManager": "Please enable your GPS location to unlock all app features. Your position will be shared exclusively with Rockease when creating a temporary site or completing a delivery note.",
      "default": "Please enable your GPS location to unlock all app features. Your position will be shared exclusively with Rockease when completing a delivery note."
    }
  }
}
</i18n>

<i18n locale="fr">
{
  "message": "Votre position n'est pas disponible",
  "alert": {
    "header": "Activer votre position",
    "message": {
      "siteManager": "Veuillez activer le partage de votre position afin de permettre à l'application de fonctionner pleinement. Elle ne sera partagée avec Rockease uniquement lors de la création d'un chantier géolocalisé ou de la prise en compte d'un bon de livraison.",
      "default": "Veuillez activer le partage de votre position afin de permettre à l'application de fonctionner pleinement. Elle ne sera partagée avec Rockease uniquement lors de la prise en compte d'un bon de livraison."
    }
  }
}
</i18n>
