import {ActionContext, Module} from 'vuex'
import {AppState} from '@/stores'
import {I18n} from 'vue-i18n'
import {SiteManagerCustomerBean} from '@/service/model/sitemanager/SiteManagerCustomerBean'
import {makeException} from '@/exception/Exception'
import {translateMessage} from '@/i18n'
import {isBlankOrEmpty} from '@/utils/StringUtils'
import {AddTruckFlow} from '@/service/flow/sitemanager/AddTruckFlow'
import {SiteManagerTruckApi, SiteManagerTruckCreationBean} from '@/service/api/sitemanager/SiteManagerTruckApi'
import {GetOptions} from '@/service/operation/GetAndTransformOperation'

interface SiteManagerAddTruckModuleState {
  flow?: AddTruckFlow
}

export class SiteManagerAddTruckModule implements Module<SiteManagerAddTruckModuleState, AppState> {
  namespaced = true
  state = {}
  getters = {
    flow: (state) => state.flow
  }
  actions = {
    createAddTruckFlow: this.createAddTruckFlowAction.bind(this),
    fetchCustomers: this.fetchCustomersAction.bind(this),
    setCustomer: this.setCustomerAction.bind(this),
    setTruckRegistration: this.setTruckRegistrationAction.bind(this),
    add: this.addAction.bind(this)
  }
  mutations = {
    setFlow: (state, flow) => state.flow = flow,
    setCustomer: (state, customer) => state.flow?.setCustomer(customer),
    setTruckRegistration: (state, truckRegistration) => state.flow?.setTruckRegistration(truckRegistration)
  }
  
  constructor(
    private readonly i18n: I18n,
    private readonly truckApi: SiteManagerTruckApi
  ) {
  }
  
  createAddTruckFlowAction(
    context: ActionContext<SiteManagerAddTruckModuleState, AppState>
  ) {
    const flow = new AddTruckFlow()
    context.commit('setFlow', flow)
  }
  
  async fetchCustomersAction(
    context: ActionContext<SiteManagerAddTruckModuleState, AppState>,
    params: GetOptions<any>
  ): Promise<boolean> {
    const customers: Array<SiteManagerCustomerBean> = await context.dispatch(
      'siteManager/customer/fetchCustomers',
      params,
      {root: true}
    )
    
    if (customers.length === 1) {
      await context.dispatch('setCustomer', customers[0])
      return true
    }
    
    return false
  }
  
  setCustomerAction(
    context: ActionContext<SiteManagerAddTruckModuleState, AppState>,
    customer: SiteManagerCustomerBean | undefined
  ) {
    context.commit('setCustomer', customer)
  }
  
  setTruckRegistrationAction(
    context: ActionContext<SiteManagerAddTruckModuleState, AppState>,
    truckRegistration: string | undefined
  ) {
    context.commit('setTruckRegistration', truckRegistration)
  }
  
  async addAction(
    context: ActionContext<SiteManagerAddTruckModuleState, AppState>
  ): Promise<void> {
    const bean = this.getBean(context.state)
    if (bean === undefined) {
      throw makeException(this.i18n, 'addTruck.unknown')
    }
    
    await this.truckApi.add(bean)
    context.commit('setFlow', undefined)
    
    await context.dispatch(
      'message/setMessage',
      {message: translateMessage(this.i18n, 'addTruck.success')},
      {root: true}
    )
    
    await context.dispatch('siteManager/truck/fetchTrucks', undefined, {root: true})
  }
  
  getBean(
    state: SiteManagerAddTruckModuleState
  ): SiteManagerTruckCreationBean | undefined {
    const flowState = state.flow?.getState()
    if (
      flowState?.customer?.id === undefined
      || flowState?.truckRegistration === undefined
      || isBlankOrEmpty(flowState.truckRegistration)
    ) {
      return undefined
    }
    
    return {
      customer_id: flowState.customer.id,
      
      truck_registration: flowState.truckRegistration
    }
  }
}
