import {AbstractApi} from '@/service/api/AbstractApi'
import {I18n} from 'vue-i18n'
import {AuthenticationService} from '@/service/authentication/AuthenticationService'
import {siteManagerTruckArraySchema, SiteManagerTruckBean} from '@/service/model/sitemanager/SiteManagerTruckBean'

export interface SiteManagerTruckCreationBean {
  customer_id: string
  truck_registration: string
}

export class SiteManagerTruckApi extends AbstractApi {
  
  constructor(i18n: I18n, authenticationService: AuthenticationService) {
    super(i18n, authenticationService)
  }
  
  listTrucks(): Promise<Array<SiteManagerTruckBean>> {
    return this.get<Array<SiteManagerTruckBean>>({
      path: '/api/mp/v1/site-manager/trucks',
      authentication: 'required',
      schema: siteManagerTruckArraySchema
    })
  }
  
  add(bean: SiteManagerTruckCreationBean): Promise<void> {
    return this.postVoid({
      path: '/api/mp/v1/site-manager/trucks',
      authentication: 'required',
      body: bean
    })
  }
}
