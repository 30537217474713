import {ErrorReportBean, ErrorReportingApi} from '@/service/api/ErrorReportingApi'
import {ComponentPublicInstance} from 'vue'
import {ApiException} from '@/exception/ApiException'
import {isBlankOrEmpty} from '@/utils/StringUtils'
import {ComponentStackTraceFormatter} from '@/utils/error/ComponentStackTraceFormatter'

const ENABLE_ERROR_REPORTING = process.env.VUE_APP_ENABLE_ERROR_REPORTING

export class ErrorReportingService {
  
  private printer = new ComponentStackTraceFormatter()
  
  constructor(private readonly errorReportingApi: ErrorReportingApi) {
  }
  
  report(
    tag: string,
    error: any
  ) {
    if (!this.shouldReport(error)) {
      return
    }
    
    const body: ErrorReportBean = {
      tag,
      path: document.location.pathname,
      message: this.formatError(error),
      stacktrace: this.formatStacktrace(error)
    }
    this.sendReport(body)
  }
  
  reportVueComponent(
    tag: string,
    instance: ComponentPublicInstance,
    error: any
  ) {
    if (!this.shouldReport(error)) {
      return
    }
    
    const body: ErrorReportBean = {
      tag,
      path: instance.$route?.path,
      message: this.formatMessage(instance, error),
      stacktrace: this.formatStacktrace(error)
    }
    this.sendReport(body)
  }
  
  shouldReport(error: any): boolean {
    // noinspection RedundantIfStatementJS
    if (error instanceof ApiException) {
      return false
    }
    return true
  }
  
  formatMessage(instance: ComponentPublicInstance | null, error: any): string {
    if (instance) {
      const errorMessage = this.formatError(error)
      return this.printer.format(errorMessage, instance)
    } else {
      return this.formatError(error)
    }
  }
  
  getComponentName(instance: ComponentPublicInstance | null): string | undefined {
    if (!instance) {
      return undefined
    }
    if (!isBlankOrEmpty(instance.$options.name)) {
      return instance.$options.name
    } else if (instance.$options.displayName) {
      return instance.$options.displayName
    } else {
      return undefined
    }
  }
  
  formatError(error: any): string {
    if (error instanceof Error) {
      return error.message
    }
    if (typeof error === 'string') {
      return error
    }
    if (typeof error === 'object') {
      return JSON.stringify(error)
    }
    return `unsupported error type: ${typeof error}`
  }
  
  formatStacktrace(error: any): string | undefined {
    if (error instanceof Error) {
      return error.stack
    }
    return undefined
  }
  
  private sendReport(body: ErrorReportBean) {
    if (`${ENABLE_ERROR_REPORTING}` !== 'true') {
      return
    }
    this.errorReportingApi.report(body).catch(error => console.error('Failed to report error due to error.', error))
  }
}
