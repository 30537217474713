import {LocationBean, locationSchema} from '@/service/model/LocationBean'
import {JSONSchemaType} from 'ajv'

export type PictureType = 'WEIGHING' | 'LOAD' | 'UNLOAD'
export type Picture = string | Blob

export interface PictureBean {
  picture: Picture
  date?: string
  location?: LocationBean
  uploaded: boolean
}

export const pictureSchema: JSONSchemaType<PictureBean> = {
  type: 'object',
  properties: {
    picture: {
      type: ['string']
    },
    date: {
      type: ['string'],
      nullable: true
    },
    location: {
      ...locationSchema,
      nullable: true
    },
    uploaded: {
      type: ['boolean']
    }
  },
  required: ['picture', 'uploaded']
}
