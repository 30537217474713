export default {
  "error": {
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite."])},
    "permissionModalDenied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rockease nécessite les autorisations présentées pour continuer. Réessayez et appuyez sur 'Ok'."])},
    "location": {
      "denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rockease nécessite l'accès à votre position."])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre position GPS n'est pas activé. Activez votre position GPS et réessayez."])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre position GPS n'est pas disponible actuellement."])},
      "fix": {
        "android": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 - Ouvrez les Paramètres de votre téléphone. <br/>2 - Appuyez sur 'Applications'. <br/>Sélectionnez 'Rockease' dans la liste, puis 'Autorisations'. <br/>3 - Appuyez sur 'Position' et choisissez 'Autoriser'."])},
        "ios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 - Ouvrez les Réglage de votre téléphone. <br/>2 - Sélectionnez 'Rockease' dans la liste. <br/>3 - Appuyez sur 'Position'. <br/>4 - Choisissez 'Lorsque l'app est active' et activez Position exacte."])}
      }
    }
  },
  "auth": {
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite durant votre authentification. Veuillez vous déconnecter et recommencer."])},
    "msal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une erreur s'est produite durant votre authentification (", _interpolate(_named("errorCode")), "). Veuillez vous déconnecter et recommencer."])},
    "noDeliveryNoteAssociatedToCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun bon de livraison associé à ce code."])},
    "noDeliveryRequestAssociatedToCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun appel de livraison associé à ce code."])}
  },
  "api": {
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur de communication est survenue. Veuillez réessayer plus tard."])},
    "timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La connexion à Internet est lente ou indisponible. Veuillez réessayer plus tard."])},
    "requiresAuth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez vous connecter pour effectuer cette operation."])},
    "missingPicturePath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune image disponible."])}
  },
  "sync": {
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronisation échouée. Veuillez réessayer dès que votre connexion sera rétablie."])},
    "failedIds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " bon (", _interpolate(_named("id")), ") a changé avant la sync."]), _normalize([_interpolate(_named("count")), " bons (", _interpolate(_named("id")), ") ont changé avant la sync."])])},
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue. Veuillez réessayer plus tard."])}
  },
  "confirm": {
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue. Veuillez retourner sur le bon de livraison et réessayer plus tard."])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le bon de livraison a été supprimé."])},
    "alreadyDelivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le bon de livraison a déjà été livré."])},
    "cannotConfirmByCodeOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce bon de livraison ne peut pas être validé par code. Veuillez réessayer dès que votre connexion est rétablie."])},
    "siteManager": {
      "pictureUploadFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le téléchargement de la photo à échoué. Veuillez réessayer plus tard."])}
    },
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le bon de livraison sera mis à jour automatiquement dès que votre connexion sera rétablie."])}
  },
  "expressOrder": {
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue. Veuillez réessayer plus tard."])}
  },
  "customerConfirm": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bon de livraison ajouté !"])},
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue. Veuillez réessayer plus tard."])}
  },
  "deliveryRequest": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande enregistrée !"])},
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue. Veuillez réessayer plus tard."])},
    "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci de nous avoir informé du problème rencontré. Nous allons contacter le fournisseur et reviendrons vers vous dans les plus brefs délais."])},
    "disputeReasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motif du problème"])},
    "disputeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description du problème"])},
    "NON_HONORED_DELIVERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraison non honorée"])},
    "INCORRECT_QUANTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité incorrecte"])},
    "PRODUCT_NOT_CONFORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit de mauvaise qualité"])},
    "TRANSPORTATION_ISSUES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur transport"])},
    "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])}
  },
  "addDriver": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chauffeur enregistré !"])},
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue. Veuillez réessayer plus tard."])}
  },
  "addTruck": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immatriculation enregistrée !"])},
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue. Veuillez réessayer plus tard."])}
  },
  "quotationRequest": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande de Devis enregistrée !"])},
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue. Veuillez réessayer plus tard."])}
  },
  "selectQuotationResponse": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande enregistrée !"])},
    "requiresValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette commande nécessite la confirmation du conducteur de travaux. Un email et un SMS lui ont été envoyés automatiquement."])},
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue. Veuillez réessayer plus tard."])}
  },
  "validation": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ est requis."])},
    "notNumeric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La valeur doit être entre ", _interpolate(_named("min")), " et ", _interpolate(_named("max")), "."])},
    "belowMin": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La valeur doit être supérieur à ", _interpolate(_named("min")), "."])},
    "aboveMax": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La valeur doit être inférieur à ", _interpolate(_named("max")), "."])}
  },
  "category": {
    "material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matériaux"])},
    "concrete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bétons / Ciments"])},
    "asphalt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrobés"])},
    "waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déblais"])}
  },
  "truckType": {
    "semi_11m3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toupie Semi 11m3"])},
    "_8x4_8m3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toupie 8x4 8m3"])},
    "_6x4_6m3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toupie 6x4 6m3"])},
    "_4x2_4m3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toupie 4x2 4m3"])}
  },
  "deliveryType": {
    "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Départ"])},
    "quarryHauler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendu"])},
    "thirdPartyHauler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendu"])}
  },
  "cadencePeriodicity": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jour"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["semaine"])}
  },
  "title": {
    "deliveryNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bon de Livraison"])},
    "newDeliveryNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau BL"])},
    "deliveryNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bons de Livraison"])},
    "expressOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande Express"])},
    "customerConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bon de Livraison papier"])},
    "deliveryRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appel de livraison"])},
    "deliveryRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appels de livraison"])},
    "addDriver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un Chauffeur"])},
    "addTruck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un Camion"])},
    "quotationRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande de Devis"])}
  },
  "button": {
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
    "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réessayer"])},
    "fix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corriger"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorer"])},
    "gpsDisabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GPS désactivé"])},
    "deliveryNote": {
      "confirmLoad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le chargement"])},
      "confirmArrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer l'arrivée"])},
      "confirmDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer la livraison"])},
      "forceConfirmDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forcer la clôture"])}
    }
  },
  "externalConfirmation": {
    "driver": {
      "material": {
        "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le chargement a été validé par la carrière. Complétez les étapes restantes pour revenir sur le bon de livraison."])},
        "unload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La livraison a été validée par le chef de chantier. Complétez les étapes restantes pour revenir sur le bon de livraison."])}
      },
      "waste": {
        "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le chargement a été validé par le chef de chantier. Complétez les étapes restantes pour revenir sur le bon de livraison."])},
        "unload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La livraison a été validée par le carrière. Complétez les étapes restantes pour revenir sur le bon de livraison."])}
      }
    },
    "siteManager": {
      "material": {
        "unload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La livraison a été validée par le chauffeur. Complétez les étapes restantes pour revenir sur le bon de livraison."])}
      },
      "waste": {
        "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le chargement a été validé par le chauffeur. Complétez les étapes restantes pour revenir sur le bon de livraison."])},
        "unload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La livraison a été validée par le chauffeur. Complétez les étapes restantes pour revenir sur le bon de livraison."])}
      }
    }
  },
  "format": {
    "percentage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percentage")), " %"])},
    "quantity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("quantity")), " ", _interpolate(_named("unit"))])},
    "cadence": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cadence")), " ", _interpolate(_named("unit")), " / ", _interpolate(_named("period"))])},
    "fullDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dddd Do MMMM YYYY"])}
  },
  "pdf": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargement"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le document est en cours de téléchargement. Veuillez patienter."])}
  },
  "disputeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous signalez un problème, vous ne pourrez plus confirmer d’autres livraisons."])}
}