import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_ripple_effect = _resolveComponent("ion-ripple-effect")!

  return (_openBlock(), _createElementBlock("div", {
    class: "header ion-activatable ripple-parent",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    _createVNode(_component_ion_icon, {
      size: "small",
      icon: _ctx.locationSharp
    }, null, 8, ["icon"]),
    _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t('message')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_ion_ripple_effect)
  ]))
}