import moment, {Moment} from 'moment'

export function nowUTC(): Moment {
  return moment.utc()
}

export function todayLocal(): Moment {
  return moment().startOf('day')
}

export function yesterdayLocal(): Moment {
  return todayLocal().add(-1, 'd')
}

export function tomorrowLocal(): Moment {
  return todayLocal().add(1, 'd')
}

export function parseDateTimeUTC(dateTime: string): Moment {
  return moment.utc(dateTime, moment.HTML5_FMT.DATETIME_LOCAL_MS)
}

export function parseDateLocal(date: string): Moment {
  return moment(date, moment.HTML5_FMT.DATE)
}

export function parseDateUTC(date: string): Moment {
  return moment.utc(date, moment.HTML5_FMT.DATE)
}

export function isAllowedDay(day: Moment, allowedWeekDays?: string | null): boolean {
  if (allowedWeekDays === null || allowedWeekDays === undefined) {
    return true
  }
  const weekDay = day.isoWeekday().toString()
  return allowedWeekDays.includes(weekDay)
}

export function currentOrNextAllowedDay(day: Moment, allowedWeekDays?: string): Moment {
  let allowedDay = day
  while (!isAllowedDay(allowedDay, allowedWeekDays)) {
    allowedDay = day.add(1, 'd')
  }
  return allowedDay
}
